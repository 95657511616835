import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  CardHeader,
  Hidden,
  TextField,
  InputAdornment,
  Divider,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Grow,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { Alert, AlertTitle } from "@material-ui/lab";
import { PropagateLoader } from "react-spinners";
import { AuthRepository } from "./AuthRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthService } from "./AuthService";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { SETTINGS } from "../../properties/ApiProps";
import { jsPDF } from "jspdf";
import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import {
  ChangeTokenFormValidator,
  LoginTokenFormValidator,
} from "./LoginFormValidator";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { ChangeCertificateRepository } from "../../repositories/ChangeCertificateRepository";

const useStyles = makeStyles(styles);

export default function ForgotTokenComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [credentialsData, setCredentialsData] = React.useState({
    username: "",
    password: "",
  });
  const [globalFormError, setGlobalFormError] = React.useState();
  const [credentialsDataErrors, setCredentialsDataErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [tokenSuccess, setTokenSuccess] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState();
  const [recaptchaToken, setRecaptchaToken] = React.useState();
  const [success, setSuccess] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    loadReCaptcha("6LfhEwQcAAAAANpzc7aB0cAYpJFkbQkgC4yCM8qu", verifyCallback);
  }, []);

  const verifyCallback = (recaptchaToken) => {
    setRecaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    recaptcha.execute();
  };

  const focusFirstField = () => {
    var fieldUsername = document.getElementById("username");
    if (fieldUsername) fieldUsername.focus();
  };

  const loginSubmitHandler = () => {
    setGlobalFormError(null);
    setCredentialsDataErrors({});
    setLoading(true);
    if (!ChangeTokenFormValidator.isValidSync(credentialsData)) {
      var validationErrors = {};
      (async () => {
        await ChangeTokenFormValidator.validate(credentialsData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setCredentialsDataErrors(validationErrors);
          setLoading(false);
          focusFirstField();
          return;
        });
      })();
    } else {
      changeToken();
    }
  };

  const changeToken = () => {
    ChangeCertificateRepository.changeCertificateRequest(
      credentialsData?.username,
      credentialsData?.password,
      recaptchaToken
    )
      .then((res) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        setLoading(false);
        focusFirstField();
      });
  };

  const handleChangeFormField = (e, name) => {
    let data = { ...credentialsData };
    data[name] = e.target.value;
    setCredentialsData(data);
  };

  const signDocument = () => {
    if (typeof AuctaSignerApp === "function") {
      var xml = "<root>UVMK</root>";
      window.ext.signAndCertXML(xml, (status, message) => {
        signAndCertCallback(status, message);
      });
    } else {
      setGlobalFormError(
        "Ве молиме инсталирајте ги потребните алатки за дигитално потпишување"
      );
    }
  };

  function signAndCertCallback(status, message) {
    if (status === false && message.status === "Error") {
      if (message.error === "No valid certificates.") {
        setGlobalFormError(
          "Не е избран валиден сертификат за дигитално потпишување"
        );
      } else if (
        message.error.includes("The action was cancelled by the user.")
      ) {
        setGlobalFormError("Потпишувањето беше откажано на ваше барање");
      } else {
        setGlobalFormError(
          "Грешка при потпишување на електронскиот документ. Ве молиме пријавете го овој проблем кај администраторот."
        );
      }
    } else if (status === true) {
      // console.log(status);
      // console.log(message);
      let data = { ...credentialsData };
      data["signedXml"] = message.signedContent;
      setTokenSuccess(status);
      setCredentialsData(data);
      setCredentialsDataErrors({});
    }
  }

  return (
    <React.Fragment>
      {AuthService.getCurrentUser() !== null && <Redirect to={"/"} push />}
      {redirectTo && <Redirect to={redirectTo} push />}
      <Backdrop className={classes.backdrop} open={loading}>
        <PropagateLoader size={15} color={"#fff"} loading={loading} />
      </Backdrop>
      <ReCaptcha
        ref={(ref) => setRecaptcha(ref)}
        sitekey="6LfhEwQcAAAAANpzc7aB0cAYpJFkbQkgC4yCM8qu"
        action="request"
        verifyCallback={verifyCallback}
      />
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Container>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Hidden smDown>
                <IconButton
                  onClick={() => setRedirectTo("/loginToken")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "45%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  onClick={() => setRedirectTo("/loginToken")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "5%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Hidden smDown>
                <div style={{ marginTop: "10vh" }}></div>
              </Hidden>
            </Grid>
            <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
            <Grid item md={6} lg={6} sm={6} xs={12} style={{ padding: "15px" }}>
              <Grid
                item
                xs={12}
                md={12}
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <Hidden smDown>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="60px"
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: "15px",
                      paddingTop: "5px",
                    }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      textAlign: "left",
                      paddingTop: "5px",
                      marginLeft: "15px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="30px"
                    style={{ paddingRight: "5px", marginTop: "5px" }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      paddingTop: "0px",
                      marginLeft: "5px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                      fontSize: "11px",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
              </Grid>

              <Card>
                <div
                  style={{
                    textAlign: "center",
                    padding: "0px",
                    margin: "0px",
                    color: "white",
                    backgroundColor: "#70c1c2",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "10px",
                    }}
                  >
                    <Icon>card_membership</Icon>
                    <span style={{ verticalAlign: "top", lineHeight: "1.4" }}>
                      {LOCALE.certificate_change}
                    </span>
                  </Typography>
                </div>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={false} sm={false} md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                      <Hidden smDown>
                        <br />
                      </Hidden>
                      <Grid container spacing={2}>
                        {globalFormError && (
                          <Grid item xs={12}>
                            <Alert
                              severity="error"
                              style={{ marginTop: "10px" }}
                            >
                              {globalFormError}
                            </Alert>
                          </Grid>
                        )}
                        {success && (
                          <Grid item xs={12}>
                            <Alert severity="success">
                              На вашата е-пошта е испратен линк за промена на
                              сертификатот!
                            </Alert>
                          </Grid>
                        )}
                        <Grow in={true} timeout={750}>
                          <Grid item xs={12}>
                            <TextField
                              id="username"
                              label={LOCALE.username}
                              size="small"
                              variant="outlined"
                              autoFocus
                              value={credentialsData.username}
                              onChange={(e) =>
                                handleChangeFormField(e, "username")
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  loginSubmitHandler();
                                }
                              }}
                              fullWidth={true}
                              disabled={loading}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Icon>person</Icon>
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                credentialsDataErrors.username != undefined
                              }
                              helperText={credentialsDataErrors.username}
                            />
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={900}>
                          <Grid item xs={12}>
                            <TextField
                              id="password"
                              label={LOCALE.password}
                              type="password"
                              variant="outlined"
                              value={credentialsData.password}
                              onChange={(e) =>
                                handleChangeFormField(e, "password")
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  loginSubmitHandler();
                                }
                              }}
                              size="small"
                              disabled={loading}
                              fullWidth={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Icon>vpn_key</Icon>
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                credentialsDataErrors.password != undefined
                              }
                              helperText={credentialsDataErrors.password}
                            />
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1050}>
                          <Grid item xs={12}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                              error={credentialsDataErrors?.signedXml}
                            >
                              <Button
                                disabled={loading || tokenSuccess}
                                size="medium"
                                variant="contained"
                                color="primary"
                                style={{ color: "white" }}
                                fullWidth={true}
                                startIcon={
                                  tokenSuccess ? (
                                    <Icon>done</Icon>
                                  ) : (
                                    <Icon>usb</Icon>
                                  )
                                }
                                onClick={() => signDocument()}
                                error={
                                  credentialsDataErrors.signedXml != undefined
                                }
                                helperText={credentialsDataErrors.signedXml}
                              >
                                {tokenSuccess
                                  ? LOCALE.load_certificate_success
                                  : LOCALE.load_new_certificate}
                              </Button>
                              {credentialsDataErrors?.signedXml && (
                                <FormHelperText error={true}>
                                  {credentialsDataErrors.signedXml}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1050}>
                          <Grid item xs={12}>
                            <Button
                              disabled={loading}
                              size="medium"
                              variant="contained"
                              color="secondary"
                              fullWidth={true}
                              onClick={() => loginSubmitHandler()}
                            >
                              {LOCALE.certificate_change}
                            </Button>
                          </Grid>
                        </Grow>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
