import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Grow,
  Paper,
  LinearProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Divider,
  Hidden,
  TextField,
  MenuItem,
  Select,
  CircularProgress,
  InputLabel,
  FormHelperText,
  Button,
  Tooltip,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { moment } from "../../App";
import MagicHover from "magic-hover";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";
import "moment/locale/mk"; // without this line it didn't work
import "moment/locale/sq"; // without this line it didn't work
import { history } from "../..";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { RequestBirthCertificateRepository } from "../../repositories/RequestBirthCertificateRepository";
import { RegistryAreaRepository } from "../../repositories/RegistryAreaRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { CountryRepository } from "../../repositories/CountryRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { Transliteration } from "../../common/helpers/Transliteration";
import {
  AllFormDataValidator,
  AllFormDataValidatorLegalEntity,
} from "./RequestBirthCertificateVaidator";
import { notifyShowErrorMessage } from "../../common/actions/CommonActions";
import PaymentComponent from "../payment/PaymentComponent";
import HttpClient from "../../api/HttpClient";
import { SETTINGS } from "../../properties/ApiProps";
import axios from "axios";
import Upload from "../../common/components/Upload";
import { GetMimeType } from "../../common/helpers/GetMimeType";
import { FilesRepository } from "../../repositories/FilesRepository";

const useStyles = makeStyles(styles);

export default function RequestBirthCertificateEdit() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [printDialog, setPrintDialog] = React.useState(false);
  const [globalFormError, setGlobalFormError] = React.useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [registryAreas, setRegistryAreas] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState();
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [places, setPlaces] = React.useState([]);
  const [saving, setSaving] = React.useState(false);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [changeDetected, setChangeDetected] = React.useState(false);
  const [deliveryPlaceSearch, setDeliveryPlaceSearch] = React.useState("");
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");
  const [powerOfAttorneyScan, setPowerOfAttorneyScan] = React.useState([]);
  const [mode, setMode] = React.useState("EDIT");
  const [ecertProcessStatus, setEcertProcessStatus] =
    React.useState("NOT_GENERATED");
  const [generatedDocumentURL, setGeneratedDocumentURL] = React.useState();
  const [generatedDocumentFile, setGeneratedDocumentFile] = React.useState();
  const [formData, setFormData] = React.useState({
    certForChild: "false",
    typeRequest: "BASIC",
    firstName: "",
    lastName: "",
    country: {
      countryCode: "807",
      id: "0",
      name: "СЕВЕРНА МАКЕДОНИЈА",
      nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      nameEn: "REPUBLIC OF NORTH MACEDONIA",
      printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
      printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      printNameEn: "REPUBLIC OF NORTH MACEDONIA",
    },
    placeBirth: { name: "" },
    placeBirthManual: "",
    fatherName: "",
    fatherSurname: "",
    fatherEMBG: "",
    motherName: "",
    motherSurname: "",
    motherEMBG: "",
    pickupWayCert: "",
    deliveryRegistryArea: "",
    deliveryPlace: { name: "" },
    deliveryAddress: "",
  });

  React.useEffect(() => {
    if (AuthService.getCurrentUser() == null) {
      setRedirectTo("/login");
    } else {
      loadFormData(id);
    }
  }, []);

  const onUploadComponentClose = (files) => {
    setPowerOfAttorneyScan(files);
  };

  const getActiveRegistryAreas = () => {
    RegistryAreaRepository.getActiveRegistryAreas().then((res) => {
      setRegistryAreas(res.data);
    });
  };

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  useEffect(() => {
    if (deliveryPlaceSearch?.length > 1) {
      getPlaces(deliveryPlaceSearch);
    }
  }, [deliveryPlaceSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  const handleChange = (name, value) => {
    let tmp = { ...formData };
    if (name === "country") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "placeBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryPlace") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryRegistryArea") {
      registryAreas.map((registryArea) => {
        if (registryArea.title === value) {
          tmp[name] = registryArea;
        }
      });
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
    setChangeDetected(true);
  };

  React.useEffect(() => {
    UsersRepository.fetchCurrentUser().then(
      (res) => {
        setCurrentUser(res.data);
      },
      (err) => {
        setRedirectTo("/login");
      }
    );
    getActiveRegistryAreas();
  }, []);

  const generateCertificate = () => {
    setGlobalFormError();
    setSaving(true);
    setPrintDialog(false);
    setGlobalFormError();
    axios({
      url: `${SETTINGS.API_URL}/requestBirthCertificate/generate/${id}`,
      method: "POST",
      params: {
        access_token: AuthService.getToken().access_token,
      },
      responseType: "blob",
    })
      .then((res) => {
        // console.log(res.data);
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setEcertProcessStatus("GENERATED");
        setGeneratedDocumentFile(file);
        setGeneratedDocumentURL(fileURL);
        setPrintDialog(true);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err.message);
        setSaving(false);
        setGlobalFormError("Грешка при генерирање на документот");
      });
  };

  const loadFormData = (id) => {
    setLoading(true);
    RequestBirthCertificateRepository.get(id)
      .then((res) => {
        let data = formData;
        data = { ...res.data };
        data.certForChild = res.data.certForChild.toString();
        data.deliveryAddress = data.deliveryAddress ? data.deliveryAddress : "";
        data.deliveryPlace = data.deliveryPlace
          ? data.deliveryPlace
          : { name: "" };
        setPlaces([data.deliveryPlace]);
        setFormData(data);
        FilesRepository.checkSignedRequestCertificate(data.id, "BIRTHS")
          .then((res) => {
            if (res.data) {
              setChangeDetected(false);
            } else {
              setChangeDetected(true);
            }
          })
          .catch((err) => {
            setChangeDetected(true);
          });
        FilesRepository.getPowerOfAttorneyScan(data.id, "BIRTHS")
          .then((res) => {
            if (res.data) {
              let blob = new Blob([res.data]);
              function callback(result) {
                var file = new File(
                  [blob],
                  `${data.id}.${result?.split("/")[1]}`,
                  {
                    type: result,
                  }
                );
                let tmp = [...powerOfAttorneyScan];
                tmp.push(file);
                setPowerOfAttorneyScan(tmp);
              }
              GetMimeType.getType(blob, callback);
            } else {
              setPowerOfAttorneyScan([]);
            }
          })
          .catch((err) => {
            setPowerOfAttorneyScan([]);
          });
        setLoading(false);
      })
      .catch((err) => {
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        setLoading(false);
      });
  };

  const signDocument = () => {
    if (typeof AuctaSignerApp === "function") {
      setLoading(true);
      console.log(window.ext);
      var reader = new FileReader();
      reader.readAsDataURL(generatedDocumentFile);
      reader.onloadend = function () {
        var base64data = reader.result.split(",")[1];
        // console.log(base64data);
        window.ext.signAndCertPDF(
          base64data,
          null,
          null,
          false,
          0,
          400,
          75,
          530,
          135,
          false,
          "null",
          (status, message) => {
            signAndCertCallback(status, message);
          }
        );
      };
    } else {
      setPrintDialog(false);
      setLoading(false);
      setGlobalFormError(
        "Ве молиме инсталирајте ги потребните алатки за дигитално потпишување"
      );
    }
  };

  const uploadSignedContent = (signedContent) => {
    FilesRepository.uploadSignedRequestCertificate(
      signedContent,
      formData.id,
      "BIRTHS"
    )
      .then((res) => {
        if (res.data === true) {
          setGlobalFormError();
          setEcertProcessStatus("SIGNED_AND_UPLOADED");
          setLoading(false);
          setChangeDetected(false);
        } else {
          setEcertProcessStatus("GENERATED");
          setPrintDialog(false);
          setGlobalFormError(
            "Грешка при прикачување на дигитално потпишаниот документ"
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setEcertProcessStatus("GENERATED");
        setPrintDialog(false);
        setGlobalFormError(
          "Грешка при прикачување на дигитално потпишаниот документ"
        );
        setLoading(false);
      });
  };

  function signAndCertCallback(status, message) {
    if (status === false && message.status === "Error") {
      if (message.error === "No valid certificates.") {
        setGlobalFormError(
          "Не е избран валиден сертификат за дигитално потпишување"
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else if (
        message.error.includes("The action was cancelled by the user.")
      ) {
        setGlobalFormError("Потпишувањето беше откажано на ваше барање");
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else {
        setGlobalFormError(
          "Грешка при потпишување на електронскиот документ. Ве молиме пријавете го овој проблем кај администраторот."
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      }
    } else if (status === true) {
      // console.log(status);
      // console.log(message);
      fetch(message.blobURL).then((r) => {
        r.blob().then((blob) => {
          // console.log(blob);
          let file = new Blob([blob], { type: "application/pdf" });
          uploadSignedContent(file);
        });
      });
    }
  }

  const downloadGeneratedDocument = () => {
    window.open(generatedDocumentURL, "_blank");
  };

  const handleSubmitLegalEntity = () => {
    if (!AllFormDataValidatorLegalEntity.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await AllFormDataValidatorLegalEntity.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
      setLoading(true);
      RequestBirthCertificateRepository.update(formData)
        .then((res) => {
          let data = formData;
          data = { ...res.data };
          data.certForChild = res.data.certForChild.toString();
          data.deliveryAddress = data.deliveryAddress
            ? data.deliveryAddress
            : "";
          data.deliveryPlace = data.deliveryPlace
            ? data.deliveryPlace
            : { name: "" };
          setPlaces([data.deliveryPlace]);
          setFormData(data);
          setLoading(false);
          generateCertificate();
          setGlobalFormError(null);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
          setGlobalFormError(ErrorMessageResolver.resolve(err));
          setLoading(false);
        });
    }
  };

  const handleSubmit = () => {
    if (!AllFormDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await AllFormDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
      setLoading(true);
      RequestBirthCertificateRepository.update(formData)
        .then((res) => {
          let data = formData;
          data = { ...res.data };
          data.certForChild = res.data.certForChild.toString();
          data.deliveryAddress = data.deliveryAddress
            ? data.deliveryAddress
            : "";
          data.deliveryPlace = data.deliveryPlace
            ? data.deliveryPlace
            : { name: "" };
          setPlaces([data.deliveryPlace]);
          setFormData(data);
          setLoading(false);
          setMode("PAYMENT");
          setGlobalFormError(null);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
          setGlobalFormError(ErrorMessageResolver.resolve(err));
          setLoading(false);
        });
    }
  };

  const backToEdit = () => {
    setMode("EDIT");
  };

  const initializePayment = () => {
    window.location.href = `${
      SETTINGS.API_URL
    }/payment/initialization?reasonType=birth&rel=${formData.id}&access_token=${
      AuthService.getToken().access_token
    }`;
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Header />

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {LOCALE.request_birth_certificate_full}
              </Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: "0px" }}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                {mode === "EDIT" && (
                  <Grid container spacing={3} style={{ padding: "20px" }}>
                    {globalFormError && (
                      <Grid item xs={12}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                    )}
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={3}>
                          {AuthService.getCurrentUser()?.proposerType !==
                            "ORGANIZATION" && (
                            <Grid item md={6} sm={6} xs={12}>
                              <FormControl
                                component="fieldset"
                                style={{ paddingLeft: "20px" }}
                                disabled={
                                  loading || formData?.paymentStatus === "PAID"
                                }
                              >
                                <RadioGroup
                                  aria-label="purpose"
                                  name="purpose"
                                  size="small"
                                  value={formData.certForChild}
                                  onChange={(e) =>
                                    handleChange("certForChild", e.target.value)
                                  }
                                >
                                  <FormControlLabel
                                    value={"false"}
                                    control={<Radio size="small" />}
                                    label={LOCALE.personaly}
                                  />
                                  <FormControlLabel
                                    value={"true"}
                                    control={<Radio size="small" />}
                                    label={LOCALE.child}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          )}
                          <Hidden smUp>
                            <Grid item md={6} xs={12}>
                              <Divider
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "20px",
                                }}
                              ></Divider>
                            </Grid>
                          </Hidden>
                          <Grid item md={6} sm={6} xs={12}>
                            <FormControl
                              component="fieldset"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              style={{ paddingLeft: "20px" }}
                            >
                              <RadioGroup
                                aria-label="typeRequest"
                                name="typeRequest"
                                value={formData.typeRequest}
                                onChange={(e) =>
                                  handleChange("typeRequest", e.target.value)
                                }
                                size="small"
                              >
                                <FormControlLabel
                                  value={"BASIC"}
                                  control={<Radio size="small" />}
                                  label={LOCALE.regular_cert}
                                />
                                <FormControlLabel
                                  value={"INTERNATIONAL"}
                                  control={<Radio size="small" />}
                                  label={LOCALE.internationale_cert}
                                />
                                <FormControlLabel
                                  value={"MULTILINGUAL"}
                                  control={<Radio size="small" />}
                                  label={LOCALE.multilingual_cert}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.embg}
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              size="small"
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange("embg", e.target.value)
                              }
                              value={formData?.embg ? formData.embg : ""}
                              error={formDataErrors.embg !== undefined}
                              helperText={formDataErrors.embg}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              label={LOCALE.firstName}
                              size="small"
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "firstName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={
                                formData?.firstName ? formData?.firstName : ""
                              }
                              error={formDataErrors.firstName !== undefined}
                              helperText={formDataErrors.firstName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              label={LOCALE.lastName}
                              size="small"
                              fullWidth
                              value={
                                formData?.lastName ? formData?.lastName : ""
                              }
                              error={formDataErrors.lastName !== undefined}
                              helperText={formDataErrors.lastName}
                              onChange={(e) =>
                                handleChange(
                                  "lastName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              variant="outlined"
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              noOptionsText={LOCALE.noOptionsText}
                              autoComplete={false}
                              size="small"
                              value={
                                formData.country.name
                                  ? formData.country.name
                                  : ""
                              }
                              onChange={(event, newValue) => {
                                handleChange("country", newValue);
                              }}
                              inputValue={countrySearch}
                              onInputChange={(event, newInputValue) => {
                                setCountrySearch(
                                  Transliteration.toUpperCyrilic(newInputValue)
                                );
                              }}
                              options={countries?.map(
                                (country) => country.name
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={LOCALE.country_born}
                                  fullWidth
                                  error={formDataErrors.country !== undefined}
                                  helperText={formDataErrors.country}
                                  onFocus={onFocus}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          {LOCALE && LOCALE.getLanguage() === "al" && (
                            <Grid item xs={12}>
                              <TextField
                                size="small"
                                variant="outlined"
                                fullWidth
                                disabled={true}
                                label={`${LOCALE.country_born} (SQ)`}
                                value={formData.country.nameAl ?? ""}
                              />
                            </Grid>
                          )}
                          {formData.country.id === "0" ? (
                            <>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  value={
                                    formData.placeBirth.name
                                      ? formData.placeBirth.name
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange("placeBirth", newValue);
                                  }}
                                  inputValue={placeSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setPlaceSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  id="birthPlace"
                                  options={places?.map((place) => place.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      disabled={
                                        loading ||
                                        formData?.paymentStatus === "PAID"
                                      }
                                      {...params}
                                      label={LOCALE.place_born}
                                      fullWidth
                                      error={
                                        formDataErrors.placeBirth !== undefined
                                      }
                                      helperText={formDataErrors.placeBirth}
                                      onFocus={onFocus}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>

                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.place_born} (SQ)`}
                                    value={formData.placeBirth.nameAl ?? ""}
                                  />
                                </Grid>
                              )}
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.place_born}
                                size="small"
                                variant="outlined"
                                disabled={
                                  loading || formData?.paymentStatus === "PAID"
                                }
                                autoComplete="false"
                                fullWidth={true}
                                error={
                                  formDataErrors.placeBirthManual !== undefined
                                }
                                helperText={formDataErrors.placeBirthManual}
                                onChange={(e) => {
                                  handleChange(
                                    "placeBirthManual",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  );
                                }}
                                value={formData?.placeBirthManual}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.mother_name}
                              size="small"
                              fullWidth
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "motherName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.motherName}
                              error={formDataErrors.motherName !== undefined}
                              helperText={formDataErrors.motherName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.mother_surname}
                              size="small"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "motherSurname",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.motherSurname}
                              error={formDataErrors.motherSurname !== undefined}
                              helperText={formDataErrors.motherSurname}
                            ></TextField>
                          </Grid>
                          {formData.certForChild == "true" && (
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.mother_embg}
                                size="small"
                                fullWidth
                                disabled={
                                  loading || formData?.paymentStatus === "PAID"
                                }
                                variant="outlined"
                                error={formDataErrors.motherEMBG ? true : false}
                                helperText={
                                  formDataErrors.motherEMBG
                                    ? formDataErrors.motherEMBG
                                    : ""
                                }
                                onChange={(e) =>
                                  handleChange(
                                    "motherEMBG",

                                    e.target.value
                                  )
                                }
                                value={formData?.motherEMBG}
                              ></TextField>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.father_name}
                              size="small"
                              fullWidth
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              variant="outlined"
                              onChange={(e) =>
                                handleChange(
                                  "fatherName",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.fatherName}
                              error={formDataErrors.fatherName !== undefined}
                              helperText={formDataErrors.fatherName}
                            ></TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={LOCALE.father_surname}
                              size="small"
                              fullWidth
                              variant="outlined"
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              onChange={(e) =>
                                handleChange(
                                  "fatherSurname",
                                  Transliteration.toUpperCyrilic(e.target.value)
                                )
                              }
                              value={formData?.fatherSurname}
                              error={formDataErrors.fatherSurname !== undefined}
                              helperText={formDataErrors.fatherSurname}
                            ></TextField>
                          </Grid>
                          {formData.certForChild == "true" && (
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.father_embg}
                                size="small"
                                fullWidth
                                disabled={
                                  loading || formData?.paymentStatus === "PAID"
                                }
                                variant="outlined"
                                error={formDataErrors.fatherEMBG ? true : false}
                                helperText={
                                  formDataErrors.fatherEMBG
                                    ? formDataErrors.fatherEMBG
                                    : ""
                                }
                                onChange={(e) =>
                                  handleChange("fatherEMBG", e.target.value)
                                }
                                value={formData?.fatherEMBG}
                              ></TextField>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              disabled={
                                loading || formData?.paymentStatus === "PAID"
                              }
                              size="small"
                            >
                              <InputLabel id="pickup_way-label">
                                {LOCALE.pickup_way}
                              </InputLabel>
                              <Select
                                labelId="pickup_way-outlined-label"
                                id="pickup_way-outlined"
                                value={formData.pickupWayCert}
                                onChange={(e) =>
                                  handleChange("pickupWayCert", e.target.value)
                                }
                                label={LOCALE.pickup_way}
                              >
                                <MenuItem value="PICKUP_WAY_UVMK">
                                  {LOCALE.pickup_way_uvmk}
                                </MenuItem>
                                {currentUser?.proposerType !=
                                  "ORGANIZATION" && (
                                  <MenuItem value="PICKUP_WAY_POSTOFFICE">
                                    {LOCALE.pickup_way_postoffice}
                                  </MenuItem>
                                )}
                                {(currentUser?.nationalEid !== null ||
                                  currentUser?.proposerType ==
                                    "ORGANIZATION") &&
                                  formData.typeRequest != 1 && (
                                    <MenuItem value="PICKUP_WAY_ELECTRONIC_CERT">
                                      {LOCALE.pickup_way_el}
                                    </MenuItem>
                                  )}
                              </Select>
                            </FormControl>
                          </Grid>
                          {currentUser?.proposerType == "ORGANIZATION" &&
                            currentUser?.institution?.institutionType == 2 && (
                              <Grid item xs={12}>
                                <TextField
                                  label={"Број на предмет (правен интерес)"}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  onChange={(e) =>
                                    handleChange(
                                      "legalInterestNumber",
                                      e.target.value
                                    )
                                  }
                                  value={
                                    formData?.legalInterestNumber
                                      ? formData?.legalInterestNumber
                                      : ""
                                  }
                                  error={
                                    formDataErrors.legalInterestNumber !==
                                    undefined
                                  }
                                  helperText={
                                    formDataErrors.legalInterestNumber
                                  }
                                ></TextField>
                              </Grid>
                            )}
                          {formData.pickupWayCert === "PICKUP_WAY_UVMK" && (
                            <>
                              <Grid item xs={12}>
                                <Autocomplete
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  value={
                                    formData?.deliveryRegistryArea?.title
                                      ? formData?.deliveryRegistryArea?.title
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange(
                                      "deliveryRegistryArea",
                                      newValue
                                    );
                                  }}
                                  inputValue={deliveryRegistryAreaSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setDeliveryRegistryAreaSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={registryAreas?.map(
                                    (regArea) => regArea.title
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.pickup_way_reg_area}
                                      fullWidth
                                      error={
                                        formDataErrors.deliveryRegistryArea
                                      }
                                      helperText={
                                        formDataErrors.deliveryRegistryArea
                                      }
                                      onFocus={onFocus}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.pickup_way_reg_area} (SQ)`}
                                    value={
                                      formData?.deliveryRegistryArea?.titleAl
                                    }
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                          {currentUser?.proposerType === "ORGANIZATION" && (
                            <>
                              <Dialog
                                open={printDialog}
                                onClose={() => setPrintDialog(false)}
                                aria-labelledby="form-dialog-title"
                              >
                                <DialogTitle>
                                  {LOCALE.generate_el_doc}
                                </DialogTitle>
                                <DialogContent>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    {saving && (
                                      <>
                                        <CircularProgress color="secondary" />
                                        <p>
                                          <strong>{LOCALE.generating}</strong>
                                        </p>
                                      </>
                                    )}
                                    {ecertProcessStatus !== "NOT_GENERATED" && (
                                      <>
                                        <Grid container spacing={2}>
                                          {ecertProcessStatus ===
                                          "SIGNED_AND_UPLOADED" ? (
                                            <Grid item xs={12}>
                                              <Alert severity="success">
                                                <span>
                                                  {
                                                    LOCALE.electronic_doc_signed_success
                                                  }
                                                </span>
                                              </Alert>
                                            </Grid>
                                          ) : (
                                            <Grid item xs={12}>
                                              <Alert severity="info">
                                                <>
                                                  <span>
                                                    {
                                                      LOCALE.electronic_doc_generated_success
                                                    }
                                                  </span>
                                                </>
                                              </Alert>
                                            </Grid>
                                          )}
                                          <Grid item xs={12}>
                                            <div
                                              style={{
                                                paddingTop: "25px",
                                                paddingBottom: "25px",
                                              }}
                                            >
                                              <Button
                                                color="secondary"
                                                onClick={() => {
                                                  downloadGeneratedDocument();
                                                }}
                                                fullWidth
                                                size="small"
                                              >
                                                <Icon
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                >
                                                  description
                                                </Icon>
                                                <strong>
                                                  {LOCALE.gen_doc_preview}
                                                </strong>
                                              </Button>
                                            </div>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <div
                                              style={{
                                                paddingTop: "25px",
                                                paddingBottom: "25px",
                                              }}
                                            >
                                              <Button
                                                color="primary"
                                                size="small"
                                                disabled={
                                                  ecertProcessStatus ===
                                                  "SIGNED_AND_UPLOADED"
                                                }
                                                variant="contained"
                                                onClick={() => {
                                                  signDocument();
                                                }}
                                              >
                                                Потпиши
                                              </Button>
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() => {
                                      setPrintDialog(false);
                                    }}
                                    color="secondary"
                                    size="small"
                                  >
                                    {LOCALE.cancel}
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </>
                          )}
                          {formData.pickupWayCert ===
                            "PICKUP_WAY_POSTOFFICE" && (
                            <React.Fragment>
                              <Grid item xs={12}>
                                <Autocomplete
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  value={
                                    formData?.deliveryPlace?.name
                                      ? formData?.deliveryPlace?.name
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange("deliveryPlace", newValue);
                                  }}
                                  inputValue={deliveryPlaceSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setDeliveryPlaceSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={places?.map((place) => place.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.delivery_place}
                                      fullWidth
                                      error={
                                        formDataErrors.deliveryPlace !==
                                        undefined
                                      }
                                      helperText={formDataErrors.deliveryPlace}
                                      onFocus={onFocus}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.delivery_place} (SQ)`}
                                    value={
                                      formData?.deliveryPlace?.nameAl ?? ""
                                    }
                                  />
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <TextField
                                  label={LOCALE.delivery_address}
                                  size="small"
                                  fullWidth
                                  disabled={
                                    loading ||
                                    formData?.paymentStatus === "PAID"
                                  }
                                  variant="outlined"
                                  error={formDataErrors.deliveryAddress}
                                  helperText={formDataErrors.deliveryAddress}
                                  onChange={(e) =>
                                    handleChange(
                                      "deliveryAddress",
                                      Transliteration.toUpperCyrilic(
                                        e.target.value
                                      )
                                    )
                                  }
                                  value={formData?.deliveryAddress}
                                ></TextField>
                              </Grid>
                              {LOCALE && LOCALE.getLanguage() === "al" && (
                                <Grid item xs={12}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    label={`${LOCALE.delivery_address} (SQ)`}
                                    value={
                                      formData?.deliveryAddress &&
                                      Transliteration.macedonianToAlbanianTransliteration(
                                        formData?.deliveryAddress
                                      )
                                    }
                                  />
                                </Grid>
                              )}
                            </React.Fragment>
                          )}
                          <Grid item xs={12}>
                            {formData?.pickupWayCert ===
                              "PICKUP_WAY_ELECTRONIC_CERT" &&
                            formData?.paymentStatus === "PAID" &&
                            formData?.verificationCode !== null ? (
                              <a
                                href={
                                  "/external/verify/certificate/" +
                                  formData.verificationCode
                                }
                                target="blank"
                              >
                                <Button
                                  fullWidth
                                  color="secondary"
                                  size="small"
                                  variant="contained"
                                  style={{ float: "right" }}
                                  startIcon={
                                    <Icon style={{ color: "white" }}>
                                      cloud_download
                                    </Icon>
                                  }
                                >
                                  {LOCALE.download}
                                </Button>
                              </a>
                            ) : (
                              ""
                            )}
                          </Grid>
                          {currentUser?.proposerType === "ORGANIZATION" &&
                            currentUser?.institution?.institutionType != 2 &&
                            formData?.paymentStatus !== "PAID" && (
                              <Grid
                                item
                                xs={12}
                                style={{ textAlign: "center" }}
                              >
                                <br></br>
                                <Alert severity="info">
                                  <AlertTitle>{LOCALE.warning}!</AlertTitle>
                                  <>
                                    <span>
                                      Овде треба да прикачите полномошно од
                                      лицето за кое барате извод.
                                    </span>
                                  </>
                                </Alert>
                                <br></br>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    style={{
                                      alignItems: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <Upload
                                      onUploadComponentClose={(files) =>
                                        onUploadComponentClose(files)
                                      }
                                      files={powerOfAttorneyScan}
                                    ></Upload>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={3} style={{ padding: "20px" }}>
                  {mode === "PAYMENT" && (
                    <Grid item xs={12}>
                      <PaymentComponent reqBirthCert={formData} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {currentUser?.proposerType === "ORGANIZATION" &&
                    changeDetected &&
                    formData?.paymentStatus !== "PAID" ? (
                      <>
                        <Button
                          color="primary"
                          size="small"
                          variant="contained"
                          style={{ float: "right" }}
                          onClick={() => {
                            handleSubmitLegalEntity();
                          }}
                        >
                          Генерирај документ
                        </Button>
                      </>
                    ) : (
                      mode === "EDIT" &&
                      formData?.paymentStatus !== "PAID" && (
                        <Button
                          disabled={
                            loading ||
                            formData?.paymentStatus === "PAID" ||
                            (currentUser?.proposerType === "ORGANIZATION" &&
                              ecertProcessStatus !== "SIGNED_AND_UPLOADED" &&
                              changeDetected) ||
                            (currentUser?.proposerType === "ORGANIZATION" &&
                              powerOfAttorneyScan?.length === 0)
                          }
                          color="secondary"
                          size="small"
                          variant="contained"
                          style={{ float: "right" }}
                          onClick={() => {
                            if (currentUser.proposerType === "ORGANIZATION") {
                              setLoading(true);
                              FilesRepository.uploadPowerOfAttorneyScan(
                                powerOfAttorneyScan,
                                formData.id,
                                "BIRTHS"
                              ).then(
                                (res) => {
                                  setLoading(false);
                                  setGlobalFormError(null);
                                  setMode("PAYMENT");
                                },
                                (err) => {
                                  dispatch(
                                    notifyShowErrorMessage(
                                      ErrorMessageResolver.resolve(err)
                                    )
                                  );
                                  setGlobalFormError(
                                    ErrorMessageResolver.resolve(err)
                                  );
                                  setLoading(false);
                                }
                              );
                            } else handleSubmit();
                          }}
                        >
                          {LOCALE.pay}
                        </Button>
                      )
                    )}
                    {mode === "EDIT" && (
                      <Button
                        disabled={loading}
                        color="default"
                        size="small"
                        variant="contained"
                        onClick={() => setRedirectTo("/orders")}
                      >
                        {LOCALE.back}
                      </Button>
                    )}
                    {mode === "PAYMENT" && (
                      <Button
                        disabled={loading}
                        color="default"
                        size="small"
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        onClick={() => backToEdit()}
                      >
                        {LOCALE.back}
                      </Button>
                    )}

                    {mode === "PAYMENT" && (
                      <Button
                        disabled={loading}
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{ marginTop: "10px", float: "right" }}
                        onClick={() => initializePayment()}
                      >
                        {LOCALE.iAgree}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}
