import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const RequestDeathCertificateRepository = {
  create: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestDeathCertificate`,
      method: "POST",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  update: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestDeathCertificate`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  get: (id) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestDeathCertificate/${id}`,
      method: "GET",
    });
  },

  getPersonDeath: (embg) => {
    return Axios({
      url: `${SETTINGS.API_URL}personDeath/embg`,
      method: "GET",
      params: { embg: embg },
    });
  },
};
