import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";
import { EmbgValidator } from "../../common/helpers/EmbgValidator";

export const UserProfileValidator = yup.object().shape({
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  embg: yup
    .string()
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_format),
  countryLiving: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeLiving: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_living + LOCALE.required_field,
      function (value) {
        if (this.options.parent.countryLiving?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeLivingManual: yup
    .string()
    .nullable()
    .test(
      "placeLivingManual",
      LOCALE.place_living + LOCALE.required_field,
      function (value) {
        if (this.options.parent.countryLiving?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  addressLiving: yup
    .string()
    .required(LOCALE.address_living + LOCALE.required_field),
  username: yup
    .string()
    .matches(/^[A-Za-z_-_.][A-Za-z0-9_-_.]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи латинични букви, цифри и -._)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  phoneNumber: yup
    .string()
    .required(LOCALE.contact_phone + LOCALE.required_field)
    .min(8, "Минимум 7 цифри"),
});
