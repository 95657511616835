import { LOCALE } from "../../properties/Locale";

export const ErrorMessageResolver = {
  resolve: (err) => {
    if (
      err.response &&
      err.response.data &&
      err.response.data &&
      err.response.data.error === "unauthorized"
    ) {
      if (err.response.data.error_description === "User not enabled") {
        return LOCALE.user_not_enabled;
      } else {
        if (LOCALE.getString(err.response.data.error_description)) {
          return LOCALE.getString(err.response.data.error_description);
        } else {
          return LOCALE.bad_credentials;
        }
      }
    } else if (err.message === "Network Error") {
      return LOCALE.networkErrorMessage;
    }
    if (
      err?.response?.data?.message &&
      err?.response?.data?.message.includes("Could not get JDBC Connection;")
    ) {
      return LOCALE.JDBCConnectonError;
    } else if (
      err.response &&
      err.response.data &&
      err.response.data.error === "invalid_grant"
    ) {
      return LOCALE.bad_credentials;
    } else if (
      err.response &&
      err.response.data &&
      err.response.data.status === 401
    ) {
      return LOCALE.connection_error;
    } else if (
      err.response &&
      err.response.data &&
      err.response.data.status === 404
    ) {
      return LOCALE.data_not_found;
    } else if (
      err.response &&
      err.response.data &&
      err.response.data.status === 403
    ) {
      return LOCALE.forbidden;
    } else if (
      err.response &&
      err.response.data &&
      err.response.data.status === 400
    ) {
      if (LOCALE.getString(err.response?.data?.message)) {
        return LOCALE.getString(err.response?.data?.message);
      } else {
        return err.response?.data?.message;
      }
    } else {
      return LOCALE.connection_error;
    }
  },
};
