import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { AuthService } from "../auth/AuthService";
import { EmbgValidator } from "../../common/helpers/EmbgValidator";

export const NewbornDataValidator = yup.object().shape({
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  gender: yup.string().required(LOCALE.gender + LOCALE.required_field),
  dateBirth: yup
    .date()
    .required(LOCALE.groomBirthDate + LOCALE.required_field)
    .nullable(),
  birthTime: yup
    .string()
    .required(LOCALE.birth_time + " " + LOCALE.required_field)
    //validate the last char to be a number
    .test("is-number", LOCALE.incorrect_format, (value) => {
      if (value) {
        return !isNaN(value[value.length - 1]);
      }
      return true;
    })
    .nullable(),
  birthPlace: yup.object().shape({
    id: yup.string().required(LOCALE.place_born + LOCALE.required_field),
  }),
  hospital: yup.object().shape({
    id: yup
      .string()
      .required(LOCALE.health_institution + LOCALE.required_field),
  }),
});

export const MarriageDetailsValidator = yup.object().shape({
  dateMarriage: yup
    .date()
    .required(LOCALE.marriageDate + LOCALE.required_field)
    .nullable(),
  placeMarriage: yup.object().shape({
    id: yup.string().required(LOCALE.marriagePlace + LOCALE.required_field),
  }),
});

export const MotherDataValidator = yup.object().shape({
  parentsData: yup
    .array()
    .of(
      yup.object().shape({
        embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
        firstName: yup
          .string()
          .required(LOCALE.firstName + LOCALE.required_field),
        lastName: yup
          .string()
          .required(LOCALE.lastName + LOCALE.required_field),
        maidenName: yup
          .string()
          .required(LOCALE.birth_surname + LOCALE.required_field),
        dateBirth: yup
          .date()
          .required(LOCALE.birthDate + LOCALE.required_field),
        birthCountry: yup.object().shape({
          id: yup
            .string()
            .required(LOCALE.birthCountry + LOCALE.required_field),
        }),
        // birthPlace: yup.object().shape({
        //   id: yup.string().required(LOCALE.place_born + LOCALE.required_field),
        // }),
        birthPlace: yup
          .object()
          .test(
            "birthPlace",
            LOCALE.place_born + LOCALE.required_field,
            function (value) {
              if (this.options.parent.birthCountry?.id === "0") {
                if (!value?.id) return false;
                return true;
              } else {
                return true;
              }
            }
          ),
        birthPlaceManual: yup
          .string()
          .test(
            "birthPlaceManual",
            LOCALE.place_born + LOCALE.required_field,
            function (value) {
              if (this.options.parent.birthCountry?.id !== "0") {
                if (!value) return false;
                return true;
              } else {
                return true;
              }
            }
          ),
        citizenship: yup.object().shape({
          id: yup.string().required(LOCALE.citizenship + LOCALE.required_field),
        }),
        nationality: yup.object().shape({
          id: yup.string().required(LOCALE.nationality + LOCALE.required_field),
        }),
        education: yup.object().shape({
          id: yup.string().required(LOCALE.education + LOCALE.required_field),
        }),
        schoolName: yup
          .string()
          .required("Име на училиште" + LOCALE.required_field),
        occupation: yup.object().shape({
          id: yup.string().required(LOCALE.occupation + LOCALE.required_field),
        }),
        workingPosition: yup
          .string()
          .required(LOCALE.workingPosition + LOCALE.required_field),
        livingCountry: yup.object().shape({
          id: yup
            .string()
            .required(LOCALE.country_living + LOCALE.required_field),
        }),
        livingPlace: yup.object().shape({
          id: yup
            .string()
            .required(LOCALE.place_living + LOCALE.required_field),
        }),
        livingAddress: yup
          .string()
          .required(LOCALE.address_living + " " + LOCALE.required_field),
        phoneNumber: yup
          .string()
          .min(12, LOCALE.not_valid_format)
          .required(LOCALE.contact_phone + LOCALE.required_field),
      })
    )
    .compact((v) => {
      // console.log(v);
      return v.parentType === "FATHER";
    }),
});

export const FatherDataValidator = yup.object().shape({
  parentsData: yup
    .array()
    .of(
      yup.object().shape({
        embg: yup.string().required(LOCALE.embg + LOCALE.required_field),
        firstName: yup
          .string()
          .required(LOCALE.firstName + LOCALE.required_field),
        lastName: yup
          .string()
          .required(LOCALE.lastName + LOCALE.required_field),
        maidenName: yup
          .string()
          .required(LOCALE.birth_surname + LOCALE.required_field),
        dateBirth: yup
          .date()
          .required(LOCALE.birthDate + LOCALE.required_field),
        birthCountry: yup.object().shape({
          id: yup
            .string()
            .required(LOCALE.birthCountry + LOCALE.required_field),
        }),
        birthPlace: yup.object().shape({
          id: yup.string().required(LOCALE.place_born + LOCALE.required_field),
        }),
        citizenship: yup.object().shape({
          id: yup.string().required(LOCALE.citizenship + LOCALE.required_field),
        }),
        nationality: yup.object().shape({
          id: yup.string().required(LOCALE.nationality + LOCALE.required_field),
        }),
        education: yup.object().shape({
          id: yup.string().required(LOCALE.education + LOCALE.required_field),
        }),
        schoolName: yup
          .string()
          .required("Име на училиште" + LOCALE.required_field),
        occupation: yup.object().shape({
          id: yup.string().required(LOCALE.occupation + LOCALE.required_field),
        }),
        workingPosition: yup
          .string()
          .required(LOCALE.workingPosition + LOCALE.required_field),
        livingCountry: yup.object().shape({
          id: yup
            .string()
            .required(LOCALE.country_living + LOCALE.required_field),
        }),
        livingPlace: yup.object().shape({
          id: yup
            .string()
            .required(LOCALE.place_living + LOCALE.required_field),
        }),
        livingAddress: yup
          .string()
          .required(LOCALE.address_living + " " + LOCALE.required_field),
        phoneNumber: yup
          .string()
          .min(12, LOCALE.not_valid_format)
          .required(LOCALE.contact_phone + LOCALE.required_field),
      })
    )
    .compact((v) => {
      // console.log(v);
      return v.parentType === "MOTHER";
    }),
});

export const MotherDocumentsValidator = yup.object().shape({
  formData: yup.object().shape({
    parentsData: yup
      .array()
      .of(
        yup.object().shape({
          idDocumentNumber: yup
            .string()
            .min(2, LOCALE.at_least_2_chars_required)
            .required(LOCALE.number + LOCALE.required_field),
          idIssuingDate: yup

            .string()
            .required(LOCALE.date_of_issue + " " + LOCALE.required_field),
          idExpiryDate: yup
            .string()

            .required("Датум на Истекување " + LOCALE.required_field),
        })
      )
      .compact((v) => {
        // console.log(v);
        return v.parentType === "FATHER";
      }),
  }),
  motherUploadedDocs: yup
    .array()
    .of(yup.mixed().required("You need to provide a file"))
    .test({
      message: "The error message if length == 0",
      test: (arr) => arr.length > 0,
    }),
});

export const ApplicationUvmkDocumentsValidator = yup.object().shape({
  formData: yup.object().shape({
    parentsData: yup.array().of(
      yup.object().shape({
        idDocumentNumber: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.number + LOCALE.required_field),
        idIssuingDate: yup
          .string()
          .required(LOCALE.date_of_issue + " " + LOCALE.required_field),
        idExpiryDate: yup
          .string()
          .required("Датум на Истекување " + LOCALE.required_field),
      })
    ),
  }),
  motherUploadedDocs: yup
    .array()
    .of(yup.mixed().required("You need to provide a file"))
    .test({
      message: "The error message if length == 0",
      test: (arr) => arr.length > 0,
    }),
  fatherUploadedDocs: yup
    .array()
    .of(yup.mixed().required("You need to provide a file"))
    .test({
      message: "The error message if  length == 0",
      test: (arr) => arr.length > 0,
    }),
});
