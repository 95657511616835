import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { AuthService } from "../auth/AuthService";
import { moment } from "../../App";
import { EmbgValidator } from "../../common/helpers/EmbgValidator";

export const PersonDeathInfoValidator = yup.object().shape({
  embg: yup
    .string()
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  country: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeBirth: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeBirthManual: yup
    .string()
    .test(
      "placeLivingManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});

export const DeathInfoValidator = yup.object().shape({
  deathDate: yup
    .date()
    .required(LOCALE.dateDeath + LOCALE.required_field)
    .max(moment(), "Датум на смртта не може да биде датум од иднината")
    .nullable(),
  deathCountry: yup.object().shape({
    id: yup.string().required(LOCALE.deathCountry + LOCALE.required_field),
  }),
  deathPlace: yup
    .object()
    .test(
      "deathPlace",
      LOCALE.deathPlace + LOCALE.required_field,
      function (value) {
        if (this.options.parent.deathCountry?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deathPlaceManual: yup
    .string()
    .test(
      "deathPlaceManual",
      LOCALE.deathPlace + LOCALE.required_field,
      function (value) {
        if (this.options.parent.deathCountry?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  registryArea: yup
    .object()
    .test(
      "registryArea",
      "Матично подрачје во кое е направен уписот" + LOCALE.required_field,
      function (value) {
        if (this.options.parent.deathCountry?.id !== "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deathAddress: yup
    .string()
    .required(LOCALE.deathAddress + LOCALE.required_field),
});

export const PickUpWayValidator = yup.object().shape({
  legalInterestNumber: yup
    .string()
    .nullable()
    .test(
      "legalInterestNumber",
      LOCALE.legal_interest_number + LOCALE.required_field,
      function (value) {
        if (AuthService.getCurrentUser()?.institution?.institutionType == 2) {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (!value?.id) return false;
        return true;
      }
    ),
});

export const ApplicantDocumentsValidator = yup.object().shape({
  idDocumentNumber: yup
    .string()
    .test(
      "idDocumentNumber",
      LOCALE.number + LOCALE.required_field,
      function (value) {
        if (this.options.parent.passportDocumentNumber.length === 8) {
          return true;
        } else {
          if (value.length === 8) {
            return true;
          } else {
            return false;
          }
        }
      }
    ),
  // idExpiryDate
  // idIssuingDate

  passportDocumentNumber: yup
    .string()
    .test(
      "idDocumentNumber",
      LOCALE.number + LOCALE.required_field,
      function (value) {
        if (this.options.parent.idDocumentNumber.length === 8) {
          return true;
        } else {
          if (value.length === 8) {
            return true;
          } else {
            return false;
          }
        }
      }
    ),
  // passportExpiryDate
  // passportIssuingDate
});

export const AllFormDataValidator = yup.object().shape({
  embg: yup
    .string()
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  country: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeBirth: yup
    .object()
    .nullable()
    .test(
      "placeLivingObject",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeBirthManual: yup
    .string()
    .nullable()
    .test(
      "placeLivingManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deathDate: yup
    .date()
    .required(LOCALE.dateDeath + LOCALE.required_field)
    .max(moment(), "Датум на смртта не може да биде датум од иднината"),
  deathCountry: yup.object().shape({
    id: yup.string().required(LOCALE.deathCountry + LOCALE.required_field),
  }),
  deathPlace: yup
    .object()
    .nullable()
    .test(
      "deathPlace",
      LOCALE.deathPlace + LOCALE.required_field,
      function (value) {
        if (this.options.parent.deathCountry?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deathPlaceManual: yup
    .string()
    .nullable()
    .test(
      "deathPlaceManual",
      LOCALE.deathPlace + LOCALE.required_field,
      function (value) {
        if (this.options.parent.deathCountry?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  registryArea: yup
    .object()
    .test(
      "registryArea",
      "Матично подрачје во кое е направен уписот" + LOCALE.required_field,
      function (value) {
        if (this.options.parent.deathCountry?.id !== "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deathAddress: yup
    .string()
    .required(LOCALE.deathAddress + LOCALE.required_field),
  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (!value?.id) return false;
        return true;
      }
    ),
  idDocumentNumber: yup
    .string()
    .test(
      "idDocumentNumber",
      LOCALE.number + LOCALE.required_field,
      function (value) {
        if (this.options.parent.passportDocumentNumber.length === 8) {
          return true;
        } else {
          if (value.length === 8) {
            return true;
          } else {
            return false;
          }
        }
      }
    ),
  passportDocumentNumber: yup
    .string()
    .test(
      "idDocumentNumber",
      LOCALE.number + LOCALE.required_field,
      function (value) {
        if (this.options.parent.idDocumentNumber.length === 8) {
          return true;
        } else {
          if (value.length === 8) {
            return true;
          } else {
            return false;
          }
        }
      }
    ),
});
