import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { Redirect } from "react-router-dom";
import { AuthService } from "./AuthService";
import { UsersRepository } from "../../repositories/UsersRepository";
import { CircularProgress } from "@material-ui/core";

const queryString = require("query-string");
const useStyles = makeStyles(styles);

export default function LoginTokenEidComponent(props) {
  const [redirectTo, setRedirectTo] = React.useState();

  useEffect(() => {
    window.localStorage.setItem(
      "accessToken",
      queryString.parse(props.location.search).token
    );
    UsersRepository.fetchCurrentUser()
      .then((res) => {
        if (res.data) {
          window.localStorage.setItem("currentUser", JSON.stringify(res.data));
          setRedirectTo(queryString.parse(props.location.search)?.redirectUrl);
        }
      })
      .catch((err) => {
        setRedirectTo("/login");
      });
  }, []);

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} />}
    </React.Fragment>
  );
}
