export const GetMimeType = {
  getType: (blob, callback) => {
    var fileReader = new FileReader();
    fileReader.readAsArrayBuffer(blob);
    fileReader.onloadend = function (e) {
      var arr = new Uint8Array(e.target.result).subarray(0, 4);
      var header = "";
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      // Check the file signature against known types
      var type = "unknown";
      switch (header) {
        case "89504e47":
          type = "image/png";
          break;
        case "47494638":
          type = "image/gif";
          break;
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
          type = "image/jpeg";
          break;
        case "25504446":
          type = "application/pdf";
          break;
      }
      callback(type);
    };
  },
};
