import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Divider,
  Grow,
  Paper,
  CircularProgress,
  LinearProgress,
  TextField,
  Hidden,
  TablePagination,
  TableRow,
  TableCell,
  Table,
  IconButton,
  Tooltip,
  Chip,
  TableBody,
  Button,
} from "@material-ui/core";
import { moment } from "../../App";
import MagicHover from "magic-hover";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";
import { history } from "../..";
import { OrdersRepository } from "../../repositories/OrdersRepository";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { SETTINGS } from "../../properties/ApiProps";
import { ConvertFromUtcToLocalTimezone } from "../../common/helpers/ConvertFromUtcToLocalTimezone";
import _ from "lodash";
import { Transliteration } from "../../common/helpers/Transliteration";
const useStyles = makeStyles(styles);

export default function OrdersComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [data, setData] = React.useState();
  const [globalFormError, setGlobalFormError] = React.useState();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = React.useState({});

  React.useEffect(() => {
    if (AuthService.getCurrentUser() == null) {
      setRedirectTo("/login");
    } else {
      loadData(0, 7, filterData);
    }
  }, []);

  const loadData = (page, size, filterData) => {
    setLoading(true);
    OrdersRepository.getAll(page, size, filterData).then(
      (res) => {
        // console.log(res.data);
        setData(ConvertFromUtcToLocalTimezone.convert(res.data));
        setLoading(false);
      },
      (err) => {
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        setLoading(false);
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    if (loading) return;
    if (!data || !data.size) return;
    loadData(newPage, data.size, filterData);
  };

  const handleChangeFilterData = (name, value) => {
    setFilterData(_.set({ ...filterData }, name, value));
  };

  const handleChangeRowsPerPage = (event) => {
    if (!data || (!data.page && !data.size)) return;
    let rowsPerPage = parseInt(event.target.value, 10);
    loadData(0, rowsPerPage, filterData);
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">{LOCALE.myOrders}</Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "20px" }}>
                  {globalFormError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{globalFormError}</Alert>
                    </Grid>
                  )}
                  <Grid item md={12}>
                    <Grid container spacing={2}>
                      <Grid item md={3} xs={12}>
                        <TextField
                          disabled={loading}
                          label={LOCALE.embg}
                          size="small"
                          fullWidth
                          value={filterData?.embg ? filterData.embg : ""}
                          onChange={(e) =>
                            handleChangeFilterData("embg", e.target.value)
                          }
                          variant="outlined"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              loadData(0, 10, filterData);
                            }
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          disabled={loading}
                          label={LOCALE.firstName}
                          size="small"
                          fullWidth
                          value={
                            filterData?.firstName ? filterData.firstName : ""
                          }
                          onChange={(e) =>
                            handleChangeFilterData(
                              "firstName",

                              Transliteration.toUpperCyrilic(e.target.value)
                            )
                          }
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              loadData(0, 10, filterData);
                            }
                          }}
                          variant="outlined"
                        ></TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          disabled={loading}
                          label={LOCALE.lastName}
                          size="small"
                          fullWidth
                          value={
                            filterData?.lastName ? filterData.lastName : ""
                          }
                          onChange={(e) =>
                            handleChangeFilterData(
                              "lastName",

                              Transliteration.toUpperCyrilic(e.target.value)
                            )
                          }
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              loadData(0, 10, filterData);
                            }
                          }}
                          variant="outlined"
                        ></TextField>
                      </Grid>

                      <Grid item md={3} xs={12}>
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={loading}
                          size="small"
                          fullWidth
                          onClick={() => loadData(0, 10, filterData)}
                        >
                          {LOCALE.search}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={12} item>
                    <Grid item xs={12} sm={12} md={12}>
                      <Hidden smUp>
                        <TablePagination
                          component="div"
                          count={data?.totalElements ? data?.totalElements : 0}
                          rowsPerPage={data?.size || 7}
                          page={data ? data.number : 0}
                          rowsPerPageOptions={[7, 14, 21]}
                          labelRowsPerPage={""}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </Hidden>
                      <Hidden xsDown>
                        <TablePagination
                          component="div"
                          count={data?.totalElements ? data?.totalElements : 0}
                          rowsPerPage={data?.size || 7}
                          page={data ? data.number : 0}
                          rowsPerPageOptions={[7, 14, 21]}
                          labelRowsPerPage={LOCALE.lines_per_page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </Hidden>
                    </Grid>
                    <Grid>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ textAlign: "center" }}>
                              <strong>{LOCALE.requestType}</strong>
                            </TableCell>
                            <Hidden xsDown>
                              <TableCell style={{ textAlign: "center" }}>
                                <strong>{LOCALE.embg}</strong>
                              </TableCell>
                            </Hidden>
                            <Hidden xsDown>
                              <TableCell style={{ textAlign: "center" }}>
                                <strong>{LOCALE.requestDate}</strong>
                              </TableCell>
                            </Hidden>
                            <TableCell style={{ textAlign: "center" }}>
                              <strong>{LOCALE.paymentStatus}</strong>
                            </TableCell>
                            <Hidden xsDown>
                              <TableCell style={{ textAlign: "center" }}>
                                <strong>{LOCALE.pickup_way}</strong>
                              </TableCell>
                            </Hidden>
                            <Hidden xsDown>
                              <TableCell style={{ textAlign: "center" }}>
                                <strong>{LOCALE.downloadURL}</strong>
                              </TableCell>
                            </Hidden>
                            <TableCell style={{ textAlign: "center" }}>
                              <strong>{LOCALE.preview}</strong>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          {loading && (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <div
                                  style={{
                                    marginTop: "75px",
                                    marginBottom: "75px",
                                    textAlign: "center",
                                    display: "block",
                                  }}
                                >
                                  <CircularProgress color="secondary" />
                                  <p>
                                    <strong>{LOCALE.searching}</strong>
                                  </p>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                          {data &&
                            !loading &&
                            data.content &&
                            data.content.map((item, index) => (
                              <TableRow
                                key={index}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0 ? "#fbfafa" : "white",
                                }}
                              >
                                <TableCell style={{ textAlign: "center" }}>
                                  {item.type
                                    ? LOCALE.getString(item.type)
                                    : "/"}
                                </TableCell>
                                <Hidden xsDown>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {`${item.firstName} ${item.lastName} `}
                                    {LOCALE &&
                                      LOCALE.getLanguage() === "al" && (
                                        <>
                                          <br />
                                          {Transliteration.macedonianToAlbanianTransliteration(
                                            item.firstName ?? ""
                                          )}{" "}
                                          {Transliteration.macedonianToAlbanianTransliteration(
                                            item.lastName ?? ""
                                          )}{" "}
                                          <br />
                                        </>
                                      )}
                                    {item.embg}
                                  </TableCell>
                                </Hidden>
                                <Hidden xsDown>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {item.createdDate && item.createdDate
                                      ? moment(item.createdDate).format(
                                          "DD.MM.YYYY HH:mm:ss"
                                        ) + " "
                                      : "N/A"}
                                  </TableCell>
                                </Hidden>
                                <TableCell style={{ textAlign: "center" }}>
                                  {!item.paymentStatus && (
                                    <Chip
                                      label={LOCALE.UNPAID}
                                      color="secondary"
                                      style={{
                                        textTransform: "uppercase",
                                      }}
                                    />
                                  )}
                                  {item.paymentStatus &&
                                    item.paymentStatus === "PAID" && (
                                      <Chip
                                        label={LOCALE.getString(
                                          item.paymentStatus
                                        )}
                                        color="primary"
                                        style={{ textTransform: "uppercase" }}
                                      />
                                    )}
                                  {item.paymentStatus &&
                                    item.paymentStatus === "UNPAID" && (
                                      <Chip
                                        label={LOCALE.UNPAID}
                                        color="secondary"
                                        style={{ textTransform: "uppercase" }}
                                      />
                                    )}
                                  {item.paymentStatus &&
                                    item.paymentStatus ===
                                      "PAYMENT_STATUS_CANCELLED" && (
                                      <Chip
                                        label={LOCALE.getString(
                                          item.paymentStatus
                                        )}
                                        color="secondary"
                                        style={{ textTransform: "uppercase" }}
                                      />
                                    )}
                                  {item.paymentStatus &&
                                    item.paymentStatus ===
                                      "PAYMENT_STATUS_IN_PROCESS" && (
                                      <Chip
                                        label={LOCALE.getString(
                                          item.paymentStatus
                                        )}
                                        color="primary"
                                        style={{ textTransform: "uppercase" }}
                                      />
                                    )}
                                </TableCell>
                                <Hidden xsDown>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {item.pickUpWayCert
                                      ? LOCALE.getString(item.pickUpWayCert)
                                      : "-"}
                                  </TableCell>
                                </Hidden>
                                <Hidden xsDown>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {item.pickUpWayCert !==
                                    "PICKUP_WAY_ELECTRONIC_CERT" ? (
                                      <Tooltip
                                        title={
                                          LOCALE.urlDownloadDisabledDescription
                                        }
                                      >
                                        <Icon style={{ color: "black" }}>
                                          block
                                        </Icon>
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                    {item.pickUpWayCert ===
                                      "PICKUP_WAY_ELECTRONIC_CERT" &&
                                    item.paymentStatus === "PAID" &&
                                    item.taskRequestStatus === "COMPLETED" &&
                                    item.verificationCode !== null ? (
                                      <a
                                        href={
                                          "/external/verify/certificate/" +
                                          item.verificationCode
                                        }
                                        target="_blank"
                                      >
                                        <Icon style={{ color: "black" }}>
                                          cloud_download
                                        </Icon>
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                    {item.pickUpWayCert ===
                                      "PICKUP_WAY_ELECTRONIC_CERT" &&
                                    item.paymentStatus === "UNPAID" ? (
                                      <Tooltip title={LOCALE.UNPAID}>
                                        <Icon style={{ color: "black" }}>
                                          money_off
                                        </Icon>
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                    {item.pickUpWayCert ===
                                      "PICKUP_WAY_ELECTRONIC_CERT" &&
                                    item.paymentStatus === "PAID" &&
                                    item.verificationCode === null ? (
                                      <Tooltip title={LOCALE.processing}>
                                        <Icon style={{ color: "black" }}>
                                          cloud_download
                                        </Icon>
                                      </Tooltip>
                                    ) : (
                                      ""
                                    )}
                                  </TableCell>
                                </Hidden>
                                <TableCell style={{ textAlign: "center" }}>
                                  <IconButton
                                    color="secondary"
                                    size="small"
                                    onClick={() => {
                                      if (
                                        item.type ===
                                        "REQUEST_BIRTH_CERTIFICATE"
                                      ) {
                                        setRedirectTo(
                                          `/births/requestBirthCertificate/${item.requestId}`
                                        );
                                      } else if (
                                        item.type ===
                                        "REQUEST_MARRIAGE_CERTIFICATE"
                                      ) {
                                        setRedirectTo(
                                          `/marriages/requestMarriageCertificate/${item.requestId}`
                                        );
                                      } else if (
                                        item.type ===
                                        "REQUEST_DEATH_CERTIFICATE"
                                      ) {
                                        setRedirectTo(
                                          `/deaths/requestDeathCertificate/${item.requestId}`
                                        );
                                      } else if (
                                        item.type ===
                                        "REQUEST_BIRTH_TRANSCRIPTS"
                                      ) {
                                        setRedirectTo(
                                          `/births/requestSingleMartialStatus/${item.requestId}`
                                        );
                                      }
                                    }}
                                  >
                                    <Tooltip title={LOCALE.open}>
                                      <Icon>open_in_new</Icon>
                                    </Tooltip>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                        {data && data.content && data.content.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={6}>
                              <Icon
                                style={{
                                  color: "orange",
                                  marginRight: "5px",
                                }}
                              >
                                warning
                              </Icon>
                              <span
                                style={{
                                  lineHeight: "2",
                                  verticalAlign: "top",
                                  fontWeight: "bold",
                                }}
                              >
                                {LOCALE.no_records_found}
                              </span>
                            </TableCell>
                          </TableRow>
                        )}
                      </Table>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
      </div>{" "}
      <Footer />
    </React.Fragment>
  );
}
