import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const ForgotPasswordRepository = {
  forgotPassword: (email) => {
    return Axios({
      url: `${SETTINGS.API_URL}/forgotPassword`,
      method: "POST",
      params: {
        email: email,
      },
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  resetPassword: (token, password) => {
    return Axios({
      url: `${SETTINGS.API_URL}/forgotPassword`,
      method: "PUT",
      data: { token: token, password: password },
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
};
