import {
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { LOCALE } from "../../properties/Locale";

export default function ApplicationUvmkPayment({
  getParentIndex,
  formData,
  s,
}) {
  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ paddingTop: "10px" }}>
        {formData?.id &&
        (formData?.status == "APPROVED" || formData?.bornInMarriage == 1) ? (
          <React.Fragment>
            <Grid item xs={false} md={2}></Grid>
            <Grid item xs={12} md={8}>
              <Alert severity="info">
                {LOCALE.payment_terms_agreement_text}
              </Alert>
            </Grid>
            <Grid item xs={false} md={2}></Grid>
            <Grid item xs={false} md={2}></Grid>
            <Grid item xs={12} md={8}>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Упис на новороденче и издавање на извод од матична книга
                        на родени
                      </TableCell>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        Услуга
                      </TableCell>
                      <Hidden xsDown>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {LOCALE.administrative_tax}
                        </TableCell>
                      </Hidden>
                      <Hidden xsDown>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Провизија
                        </TableCell>
                      </Hidden>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBottom: "none" }}
                      >
                        {!formData.bornInMarriage
                          ? `Упис на новороденче и барање за издавање на извод (Родени - ${LOCALE.getString(
                              formData?.pickupWayCert
                            )}) за ${formData?.firstName} ${
                              formData.lastName
                            } од родителите ${
                              formData?.parentsData[getParentIndex("FATHER")]
                                ?.firstName
                            } ${
                              formData?.parentsData[getParentIndex("FATHER")]
                                ?.lastName
                            } ${LOCALE.with_EMBG}  ${
                              formData?.parentsData[getParentIndex("FATHER")]
                                ?.embg
                            } и 
                          ${
                            formData?.parentsData[getParentIndex("MOTHER")]
                              ?.firstName
                          } ${
                              formData?.parentsData[getParentIndex("MOTHER")]
                                ?.lastName
                            } ${LOCALE.with_EMBG}
                          ${
                            formData?.parentsData[getParentIndex("MOTHER")]
                              ?.embg
                          } `
                          : `Упис на новороденче и барање за издавање на извод (Родени - ${LOCALE.getString(
                              formData?.pickupWayCert
                            )}) за ${formData?.firstName} ${
                              formData.lastName
                            } од родителот 
                            ${
                              formData?.parentsData[getParentIndex("MOTHER")]
                                ?.firstName
                            } ${
                              formData?.parentsData[getParentIndex("MOTHER")]
                                ?.lastName
                            } ${LOCALE.with_EMBG} 
                            ${
                              formData?.parentsData[getParentIndex("MOTHER")]
                                ?.embg
                            } `}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ borderBottom: "none" }}
                      >
                        {50}
                      </TableCell>
                      <Hidden xsDown>
                        <TableCell align="center">{100}</TableCell>
                      </Hidden>
                      <Hidden xsDown>
                        <TableCell align="center">10%</TableCell>
                      </Hidden>
                    </TableRow>
                    <TableRow>
                      <Hidden xsDown>
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell align="center"></TableCell>
                      </Hidden>
                      <TableCell align="center">{LOCALE.commission}</TableCell>
                      <TableCell align="center">{(+50 + 100) * 0.1}</TableCell>
                    </TableRow>

                    <Hidden smUp>
                      <TableRow>
                        <Hidden xsDown>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell align="center"></TableCell>
                        </Hidden>
                        <TableCell align="center">
                          {LOCALE.administrative_tax}
                        </TableCell>
                        <TableCell align="center">{100}</TableCell>
                      </TableRow>
                    </Hidden>
                    <TableRow>
                      <Hidden xsDown>
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell align="center"></TableCell>
                      </Hidden>
                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        {LOCALE.total_amount}
                      </TableCell>

                      <TableCell align="center" style={{ fontWeight: "bold" }}>
                        {Math.round((+50 + 100) * 1.1)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>{" "}
            <Grid item xs={false} md={2}></Grid>
            <Grid item xs={false} md={2}></Grid>
            <Grid item xs={12} md={8}>
              <Alert
                style={{ cursor: "help" }}
                onClick={() =>
                  window.open(
                    "/PaymentPolicy.pdf",
                    "_blank" // <- This is what makes it open in a new window.
                  )
                }
              >
                {LOCALE.payment_policy}
              </Alert>
            </Grid>{" "}
            <Grid item xs={false} md={2}></Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={false} md={2}></Grid>
            <Grid item xs={12} md={8}>
              <Alert severity="info">{LOCALE.information_1}</Alert>
            </Grid>
            <Grid item xs={false} md={2}></Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
}
