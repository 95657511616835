export const hospitals = [
  {
    id: "8a94e4ff60b859900160bb1b5106729d",
    name: "СБ ПО ГИНЕКОЛОГИЈА И АКУШЕРСТВО ЧАИР - СКОПЈЕ",
    registryAreaId: "8ae680183b1d03c1013b1d1cde810010",
    type: 1,
    hospitalcode: "25054901",
    edb: null,
    place: null,
    title: "СКОПЈЕ",
  },
  {
    id: "8a9448ef3b95227a013b95324c5e0002",
    name: "ЈЗУ УК ЗА ГИНЕКОЛОГИЈА И АКУШЕРСТВО - СКОПЈЕ",
    registryAreaId: "8ae680183b1d03c1013b1d1cde810010",
    type: 1,
    hospitalcode: "25096303",
    edb: null,
    place: null,
    title: "СКОПЈЕ",
  },
  {
    id: "8a94e4ff4b543913014b544153d508aa",
    name: "ПЗУ МОЈА БОЛНИЦА - СКОПЈЕ",
    registryAreaId: "8ae680183b1d03c1013b1d1cde810010",
    type: 1,
    hospitalcode: "25120501",
    edb: null,
    place: null,
    title: "СКОПЈЕ",
  },
  {
    id: "8a94e4ff609c155f0160b7771a5034da",
    name: "РЕ-МЕДИКА - СКОПЈЕ",
    registryAreaId: "8ae680183b1d03c1013b1d1cde810010",
    type: 1,
    hospitalcode: "25105901",
    edb: null,
    place: null,
    title: "СКОПЈЕ",
  },
  {
    id: "8a9448ef3b90709b013b90ba50b00018",
    name: "СИСТИНА - СКОПЈЕ",
    registryAreaId: "8ae680183b1d03c1013b1d1cde810010",
    type: 0,
    hospitalcode: "1376901263",
    edb: null,
    place: null,
    title: "СКОПЈЕ",
  },
  {
    id: "8a9448ef3b90709b013b90afa9610016",
    name: "ЈЗУ КЛИНИЧКА БОЛНИЦА „Д-Р ТРИФУН ПАНОВСКИ“ - БИТОЛА",
    registryAreaId: "8a9e9e8e3b89d5c0013b89e40b75000a",
    type: 0,
    hospitalcode: "2055302",
    edb: null,
    place: null,
    title: "БИТОЛА",
  },
  {
    id: "8a9448ef3ca5b0fb013ca73614a403db",
    name: "ПЗУ ПЛОДНОСТ - БИТОЛА",
    registryAreaId: "8a9e9e8e3b89d5c0013b89e40b75000a",
    type: 1,
    hospitalcode: "2064401",
    edb: null,
    place: null,
    title: "БИТОЛА",
  },
  {
    id: "8a94e4ff431ee26b0143571a9d0215ff",
    name: "ЈЗУ - КРАТОВО",
    registryAreaId: "8a9452ff427f3c130142facf53b63f47",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "КРАТОВО",
  },
  {
    id: "8a94e4ff44974e1c0144b68d6b8f6a1c",
    name: "ЈЗУ ГОЦЕ ДЕЛЧЕВ - МАКЕДОНСКА КАМЕНИЦА",
    registryAreaId: "8a9452ff427f3c130142fad195413f52",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "МАКЕДОНСКА КАМЕНИЦА",
  },
  {
    id: "8a94e4ff5b8b35c1015b8fb64fc9790b",
    name: "ЈЗУ ДР РУСЕ БОШКОСКИ",
    registryAreaId: "8a9448ef3b962f03013b9640f1e00009",
    type: 1,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "РОСТУШЕ",
  },
  {
    id: "8a9448ef3b911159013b91158b060002",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ - ВИНИЦА",
    registryAreaId: "8a9e9e8e3b89d5c0013b89dcf7cc0008",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "ВИНИЦА",
  },
  {
    id: "8a94e4ff4396179a01439f513f2c45a8",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ - ДЕЛЧЕВО",
    registryAreaId: "8a9452ff427f3c130142fad0d5673f4d",
    type: 0,
    hospitalcode: "9000100",
    edb: null,
    place: null,
    title: "ДЕЛЧЕВО",
  },
  {
    id: "8a94e4ff4396179a0143b484066c5206",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ - ДЕМИР ХИСАР",
    registryAreaId: "8a9452ff427f3c130142facce70e3f3e",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "ДЕМИР ХИСАР",
  },
  {
    id: "8a94e4ff43dde8c90143ddf9f3410337",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ - КРУШЕВО",
    registryAreaId: "8a9452ff427f3c130142facd5aa83f3f",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "КРУШЕВО",
  },
  {
    id: "8a94e4ff44d7dd800144e401d190755e",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ - МАКЕДОНСКИ БРОД",
    registryAreaId: "8a9452ff427f3c130142facdac243f40",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "МАКЕДОНСКИ БРОД",
  },
  {
    id: "8a94e4ff43e2ffe60143fc5f4a982f70",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ - НЕГОТИНО",
    registryAreaId: "8a9e9e8e3b89d5c0013b89dbcdc30005",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "НЕГОТИНО",
  },
  {
    id: "8a94e4ff4bb59e3a014bba977bdd6ae5",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ - ПЕХЧЕВО",
    registryAreaId: "8a9452ff427f3c130142fad145e93f51",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "ПЕХЧЕВО",
  },
  {
    id: "8a9448ef3b95227a013b9535ce95000f",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ - РЕСЕН",
    registryAreaId: "8a9e9e8e3b89d5c0013b89d9f1dc0002",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "РЕСЕН",
  },
  {
    id: "8a94e4ff43e2ffe60143fd5f68295292",
    name: "ЈЗУ ЗДРАВСТВЕН ДОМ `ЗДРАВЈЕ` - ВАЛАНДОВО",
    registryAreaId: "8a9452ff427f3c130142fad017933f4a",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "ВАЛАНДОВО",
  },
  {
    id: "8a94e4ff4396179a01439b32aaf52e27",
    name: "ЈЗУ КЛИНИЧКА БОЛНИЦА - ТЕТОВО",
    registryAreaId: "8a9452e43fceca2b013ff23be79a0d9f",
    type: 0,
    hospitalcode: "28008701",
    edb: null,
    place: null,
    title: "ТЕТОВО",
  },
  {
    id: "8a9448ef3b90709b013b90ac38530014",
    name: "ЈЗУ КЛИНИЧКА БОЛНИЦА - ШТИП",
    registryAreaId: "8a9e9e8e3b89d5c0013b89dc7f4a0007",
    type: 0,
    hospitalcode: "30011610",
    edb: null,
    place: null,
    title: "ШТИП",
  },

  {
    id: "8a94e4ff431ee26b014357c22d2f186b",
    name: "ЈЗУ МАКЕДОНСКИ БРОД",
    registryAreaId: "8a9452ff427f3c130142facdac243f40",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "МАКЕДОНСКИ БРОД",
  },
  {
    id: "8a94e4ff43b4d0ba0143bf779f5f7ba7",
    name: "ЈЗУ НАДА МИХАЈЛОВА - ПРОБИШТИП",
    registryAreaId: "8a9452ff427f3c130142fad110613f4e",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "ПРОБИШТИП",
  },
  {
    id: "8a94e4ff4addfafa014af2b269004dd3",
    name: "ЈЗУ ОПШТА БОЛНИЦА - ВЕЛЕС",
    registryAreaId: "8a9452ff427f3c130142face571e3f41",
    type: 0,
    hospitalcode: "29005701",
    edb: null,
    place: null,
    title: "ВЕЛЕС",
  },
  {
    id: "8a94e4ff44974e1c0144ac1b8ed22242",
    name: "ЈЗУ ОПШТА БОЛНИЦА - ГЕВГЕЛИЈА",
    registryAreaId: "8a9452ff427f3c130142facfd48b3f49",
    type: 0,
    hospitalcode: "6009201",
    edb: null,
    place: null,
    title: "ГЕВГЕЛИЈА",
  },
  {
    id: "8a9448ef3b90709b013b90af1f240015",
    name: "ЈЗУ ОПШТА БОЛНИЦА - ДЕБАР",
    registryAreaId: "8a9e9e8e3b89d5c0013b89e56130000c",
    type: 0,
    hospitalcode: "8005501",
    edb: null,
    place: null,
    title: "ДЕБАР",
  },
  {
    id: "8a9448ef3b90709b013b90c9b174001a",
    name: "ЈЗУ ОПШТА БОЛНИЦА - КАВАДАРЦИ",
    registryAreaId: "8a9e9e8e3b89d5c0013b89da58ca0003",
    type: 0,
    hospitalcode: "11012601",
    edb: null,
    place: null,
    title: "КАВАДАРЦИ",
  },
  {
    id: "8a94e4ff5937c5bd01594a762bc0315d",
    name: "ЈЗУ ОПШТА БОЛНИЦА - КИЧЕВО",
    registryAreaId: "8a9452ff427f3c130142facf93fe3f48",
    type: 0,
    hospitalcode: "12002501",
    edb: null,
    place: null,
    title: "КИЧЕВО",
  },
  {
    id: "8a9448ef3b95227a013b95364f560010",
    name: "ЈЗУ ОПШТА БОЛНИЦА - КОЧАНИ",
    registryAreaId: "8a9e9e8e3b89d5c0013b89e3b2cb0009",
    type: 0,
    hospitalcode: "13080801",
    edb: null,
    place: null,
    title: "КОЧАНИ",
  },
  {
    id: "8a9448ef3b95227a013b953335830003",
    name: "ЈЗУ ОПШТА БОЛНИЦА - КУМАНОВО",
    registryAreaId: "8a9e9e8e3b89d5c0013b89dc2c7c0006",
    type: 0,
    hospitalcode: "17020601",
    edb: null,
    place: null,
    title: "КУМАНОВО",
  },
  {
    id: "8a9448ef3b95227a013b9533d1680004",
    name: "ЈЗУ ОПШТА БОЛНИЦА - ОХРИД",
    registryAreaId: "8a9e9e8e3b89d5c0013b89e52a91000b",
    type: 0,
    hospitalcode: "19004901",
    edb: null,
    place: null,
    title: "ОХРИД",
  },
  {
    id: "8a94e4ff5937c5bd01594a75c5d130f7",
    name: "ЈЗУ ОПШТА БОЛНИЦА - ПРИЛЕП",
    registryAreaId: "8a9448ef3c4a3b44013c4a652c77007d",
    type: 0,
    hospitalcode: "20067001",
    edb: null,
    place: null,
    title: "ПРИЛЕП",
  },
  {
    id: "8a94e4ff43b4d0ba0143bea26fd25bf2",
    name: "ЈЗУ ОПШТА БОЛНИЦА - СТРУГА",
    registryAreaId: "8a9448ef3c5c496c013c63dfd91d0602",
    type: 0,
    hospitalcode: "26003515",
    edb: null,
    place: null,
    title: "СТРУГА",
  },
  {
    id: "8a9448ef3b90709b013b90b32a500017",
    name: "ЈЗУ ОПШТА БОЛНИЦА - СТРУМИЦА",
    registryAreaId: "8a9e9e8e3b89d5c0013b89db6d4d0004",
    type: 0,
    hospitalcode: "27006318",
    edb: null,
    place: null,
    title: "СТРУМИЦА",
  },
  {
    id: "8a94e4ff44d7dd800144dff068dc37b7",
    name: "ЈЗУ ОПШТА БОЛНИЦА Д-Р ФЕРИД МУРАД - ГОСТИВАР",
    registryAreaId: "8a9452e43f2f4c41013f2f7bb65a0057",
    type: 0,
    hospitalcode: "7005904",
    edb: null,
    place: null,
    title: "ГОСТИВАР",
  },
  {
    id: "8a94e4ff431ee26b01435847d59219ba",
    name: "ЈЗУ РАДОВИШ",
    registryAreaId: "8a9452e44117b12b0141182f663501a6",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "РАДОВИШ",
  },

  {
    id: "8a94e4ff431ee26b01435744b3f416a5",
    name: "ЗДРАВСТВЕН ДОМ - БЕРОВО",
    registryAreaId: "8a9452ff427f3c130142fad099073f4c",
    type: 0,
    hospitalcode: "1070100",
    edb: null,
    place: null,
    title: "БЕРОВО",
  },
  {
    id: "8a94e4ff431ee26b0143582bad9e195e",
    name: "ЗДРАВСТВЕН ДОМ - КРИВА ПАЛАНКА",
    registryAreaId: "8a9452ff427f3c130142facf0c0a3f46",
    type: 0,
    hospitalcode: "15000101",
    edb: null,
    place: null,
    title: "КРИВА ПАЛАНКА",
  },
  {
    id: "8a94e4ff441cb641014420fcfa893098",
    name: "ЗДРАВСТВЕН ДОМ - СВЕТИ НИКОЛЕ",
    registryAreaId: "8a9448ef3cdb0225013cfd57e59d0a66",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "СВЕТИ НИКОЛЕ",
  },
  {
    id: "8a94e4ff44d7dd800144d9f34e7e48e5",
    name: "ПЗУ Д-Р КАТИЦА БАКИЕВА БОЈЧЕВА - ДЕМИР КАПИЈА",
    registryAreaId: "8a9452ff427f3c130142facebc1b3f45",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "ДЕМИР КАПИЈА",
  },
  {
    id: "8a94e4ff44d7dd800144d9f434b04940",
    name: "ПЗУ Д-Р ОЛГИЦА САКЛАМАЕВА АНГЕЛОВА - ДЕМИР КАПИЈА",
    registryAreaId: "8a9452ff427f3c130142facebc1b3f45",
    type: 0,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "ДЕМИР КАПИЈА",
  },
  {
    id: "8a94e4007c26f46a017c2b7a74605398",
    name: "ПЗУ Д-Р ОЛИВЕРА КОЧЕВА - НОВ ДОЈРАН",
    registryAreaId: "8a94e4ff481713ce014840ee8a593507",
    type: 1,
    hospitalcode: null,
    edb: null,
    place: null,
    title: "НОВ ДОЈРАН",
  },
  {
    id: "8a94e4008604190901862b1018b367a7",
    name: "ПЗУ Д-Р ОРГАНЏИСКИ - ШТИП",
    registryAreaId: "8a9e9e8e3b89d5c0013b89dc7f4a0007",
    type: 1,
    hospitalcode: "30000705",
    edb: null,
    place: null,
    title: "ШТИП",
  },

  {
    id: "8a9452ff78ac964b0178f375e6dc2995",
    name: "ПЗУ ОПШТА БОЛНИЦА ОНЕ ХОСПИТАЛ - ТЕТОВО",
    registryAreaId: "8a9452e43fceca2b013ff23be79a0d9f",
    type: 1,
    hospitalcode: "2422332690",
    edb: null,
    place: null,
    title: "ТЕТОВО",
  },
];
