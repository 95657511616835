import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  Divider,
  Grow,
  Paper,
  CircularProgress,
  LinearProgress,
  TextField,
  Hidden,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";
import { UsersRepository } from "../../repositories/UsersRepository";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { Transliteration } from "../../common/helpers/Transliteration";
import { CountryRepository } from "../../repositories/CountryRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { UserProfileValidator } from "./UserProfileValidator";
import { notifyShowSuccessMessage } from "../../common/actions/CommonActions";
import { history } from "../..";
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles(styles);

export default function UserProfileComponentPerson() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [formData, setFormData] = React.useState({});
  const [globalFormError, setGlobalFormError] = React.useState();
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (AuthService.getCurrentUser() == null) {
      setRedirectTo("/login");
    } else {
      loadFormData();
    }
  }, []);

  React.useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  React.useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const loadFormData = () => {
    setLoading(true);
    UsersRepository.fetchCurrentUser()
      .then((res) => {
        setFormData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setGlobalFormError(ErrorMessageResolver.resolve(err));
      });
  };

  const handleSubmit = () => {
    setGlobalFormError(null);
    setLoading(true);
    validateFormData();
    if (UserProfileValidator.isValidSync(formData)) {
      UsersRepository.updateMyProfile(formData)
        .then((res) => {
          loadFormData();
          window.localStorage.setItem("currentUser", JSON.stringify(res.data));
          dispatch(notifyShowSuccessMessage(LOCALE.changes_saved_success));
        })
        .catch((err) => {
          setLoading(false);
          setGlobalFormError(ErrorMessageResolver.resolve(err));
        });
    } else {
      validateFormData();
    }
  };

  const validateFormData = () => {
    if (!UserProfileValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await UserProfileValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
      setLoading(true);
    }
  };

  const handleChangeFormData = (name, value) => {
    let tmp = { ...formData };
    if (name === "countryLiving") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "placeLiving") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  const renderAlField = (fieldType) => {
    if (fieldType === "firstName") {
      return <></>;
    }
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}

      <Grid container spacing={2}>
        <Grow in={true} timeout={600}>
          <Grid item xs={12} md={12}>
            <TextField
              label={LOCALE.username}
              size="small"
              variant="outlined"
              autoFocus
              disabled={true}
              fullWidth
              error={formDataErrors.username !== undefined}
              helperText={formDataErrors.username}
              onChange={(e) => {
                handleChangeFormData(
                  "username",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formData?.username ? formData.username : ""}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={750}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.firstName}
              size="small"
              variant="outlined"
              autoFocus
              disabled={loading}
              fullWidth={true}
              error={formDataErrors.firstName !== undefined}
              helperText={formDataErrors.firstName}
              onChange={(e) => {
                handleChangeFormData(
                  "firstName",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formData?.firstName ? formData.firstName : ""}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={900}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.lastName}
              size="small"
              disabled={loading}
              variant="outlined"
              autoFocus
              fullWidth={true}
              error={formDataErrors.lastName !== undefined}
              helperText={formDataErrors.lastName}
              onChange={(e) => {
                handleChangeFormData(
                  "lastName",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formData?.lastName ? formData.lastName : ""}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={900}>
          <Grid item xs={12} md={12}>
            <TextField
              label={LOCALE.email}
              size="small"
              disabled={loading}
              variant="outlined"
              autoFocus
              fullWidth={true}
              error={formDataErrors.email !== undefined}
              helperText={formDataErrors.email}
              onChange={(e) => {
                handleChangeFormData("email", e.target.value);
              }}
              value={formData?.email ? formData.email : ""}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1050}>
          <Grid item xs={12} md={12}>
            <TextField
              label={LOCALE.embg}
              size="small"
              disabled
              variant="outlined"
              autoFocus
              fullWidth={true}
              error={formDataErrors.embg !== undefined}
              helperText={formDataErrors.embg}
              onChange={(e) => {
                handleChangeFormData(
                  "embg",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formData?.embg ? formData.embg : ""}
            />
          </Grid>
        </Grow>

        <Grow in={true} timeout={1350}>
          <Grid item xs={12} md={12}>
            <Autocomplete
              error={formDataErrors?.countryLiving !== undefined}
              disabled={loading}
              helperText={formDataErrors?.countryLiving}
              noOptionsText={LOCALE.noOptionsText}
              size="small"
              value={
                formData?.countryLiving?.name ? formData.countryLiving.name : ""
              }
              onChange={(event, newValue) => {
                handleChangeFormData("countryLiving", newValue);
              }}
              inputValue={countrySearch}
              onInputChange={(event, newInputValue) => {
                setCountrySearch(Transliteration.toUpperCyrilic(newInputValue));
              }}
              id="country"
              options={countries?.map((country) => country.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={LOCALE.country_living}
                  disabled={loading}
                  fullWidth
                  onFocus={onFocus}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grow>

        {/* Albanian field for country living */}
        {LOCALE && LOCALE.getLanguage().includes("al") && (
          <Grow in={true} timeout={1350}>
            <Grid item xs={12} md={12}>
              <TextField
                label={`${LOCALE.country_living} (SQ)`}
                disabled={true}
                size="small"
                fullWidth
                value={formData?.countryLiving?.nameAl ?? ""}
                variant="outlined"
              />
            </Grid>
          </Grow>
        )}

        {formData?.countryLiving?.id === "0" ? (
          <>
            <Grow in={true} timeout={1450}>
              <Grid item xs={12}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  disabled={loading}
                  value={
                    formData.placeLiving.name ? formData.placeLiving.name : ""
                  }
                  onChange={(event, newValue) => {
                    handleChangeFormData("placeLiving", newValue);
                  }}
                  inputValue={placeSearch}
                  onInputChange={(event, newInputValue) => {
                    setPlaceSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  id="place"
                  options={places?.map((place) => place.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.place_living}
                      fullWidth
                      disabled={loading}
                      error={formDataErrors.placeLiving !== undefined}
                      helperText={formDataErrors.placeLiving}
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grow>

            {/* Albanian field for country living */}
            {LOCALE && LOCALE.getLanguage().includes("al") && (
              <Grow in={true} timeout={1450}>
                <Grid item xs={12} md={12}>
                  <TextField
                    label={`${LOCALE.place_living} (SQ)`}
                    disabled={true}
                    size="small"
                    fullWidth
                    value={formData?.placeLiving?.nameAl ?? ""}
                    variant="outlined"
                  />
                </Grid>
              </Grow>
            )}
          </>
        ) : (
          <>
            <Grow in={true} timeout={1450}>
              <Grid item xs={12}>
                <TextField
                  label={LOCALE.place_living}
                  size="small"
                  variant="outlined"
                  autoComplete="false"
                  disabled={loading}
                  fullWidth={true}
                  error={formDataErrors.placeLivingManual !== undefined}
                  helperText={formDataErrors.placeLivingManual}
                  onChange={(e) => {
                    handleChangeFormData(
                      "placeLivingManual",
                      Transliteration.toUpperCyrilic(e.target.value)
                    );
                  }}
                  value={formData?.placeLivingManual}
                />
              </Grid>
            </Grow>
          </>
        )}
        <Grow in={true} timeout={1600}>
          <Grid item xs={12} md={12}>
            <TextField
              label={LOCALE.addressLiving}
              size="small"
              disabled={loading}
              variant="outlined"
              autoFocus
              fullWidth={true}
              error={formDataErrors.addressLiving !== undefined}
              helperText={formDataErrors.addressLiving}
              onChange={(e) => {
                handleChangeFormData(
                  "addressLiving",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formData?.addressLiving ? formData.addressLiving : ""}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1750}>
          <Grid item xs={12} md={12}>
            <MuiPhoneNumber
              size="small"
              disabled={loading}
              autoFocus
              fullWidth={true}
              label={LOCALE.phoneNumber}
              error={formDataErrors.phoneNumber !== undefined}
              helperText={formDataErrors.phoneNumber}
              variant="outlined"
              defaultCountry={"mk"}
              value={formData?.phoneNumber}
              onChange={(e) => {
                handleChangeFormData("phoneNumber", e);
              }}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1900}>
          <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => handleSubmit()}
            >
              {LOCALE.save}
            </Button>
          </Grid>
        </Grow>
      </Grid>
    </React.Fragment>
  );
}
