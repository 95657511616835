import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { AuthService } from "../auth/AuthService";
import { EmbgValidator } from "../../common/helpers/EmbgValidator";
import moment from "moment";

const defaultCountry = {
  countryCode: "807",
  id: "0",
  name: "СЕВЕРНА МАКЕДОНИЈА",
  nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
  nameEn: "REPUBLIC OF NORTH MACEDONIA",
  printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
  printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
  printNameEn: "REPUBLIC OF NORTH MACEDONIA",
};

export const BrideDataValidator = yup.object().shape({
  brideEmbg: yup
    .string()
    .test("embg-doesnt-match", LOCALE.embg_doesnt_match, function (value) {
      if (AuthService.getCurrentUser().gender === "FEMALE") {
        if (AuthService.getCurrentUser().embg !== value) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
    .test("bride-embg-required", LOCALE.bride_embg_required, function (value) {
      if (!this.options.parent.brideCountry) return false;
      if (!this.options.parent.brideCountry.id) return false;
      if (this.options.parent.brideCountry.id === defaultCountry.id) {
        if (!value) return false;
      }
      return true;
    })
    .test("embg-female-format", LOCALE.invalid_female_embg, function (value) {
      if (!this.options.parent.brideCountry) return false;
      if (!this.options.parent.brideCountry.id) return false;
      if (this.options.parent.brideCountry.id === defaultCountry.id) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (parseInt(value.substring(9, 12)) <= 499) {
          return false;
        }
      }
      return true;
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!this.options.parent.brideCountry) return false;
      if (!this.options.parent.brideCountry.id) return false;
      if (this.options.parent.brideCountry.id === defaultCountry.id) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
      }
      return true;
    }),
  brideFirstName: yup
    .string()
    .min(2, LOCALE.at_least_2_chars_required)
    .required(LOCALE.firstName + LOCALE.required_field),
  brideLastName: yup
    .string()
    .min(2, LOCALE.at_least_2_chars_required)
    .required(LOCALE.lastName + LOCALE.required_field),
  brideBirthDate: yup
    .date()
    .required(LOCALE.brideBirthDate + LOCALE.required_field)
    .nullable(),
  brideCountry: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  bridePlaceBirth: yup
    .object()
    .test(
      "brideBirthPlaceConditionalRequired",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.brideCountry) return false;
        if (!this.options.parent.brideCountry.id) return false;
        if (this.options.parent.brideCountry.id === defaultCountry.id) {
          if (!value?.id) return false;
          return true;
        }
        return true;
      }
    )
    .nullable(),
  bridePlaceBirthManual: yup
    .string()
    .test(
      "brideBirthPlaceManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.brideCountry) return false;
        if (!this.options.parent.brideCountry.id) return false;
        if (this.options.parent.brideCountry.id !== defaultCountry.id) {
          if (!value) return false;
          return true;
        }
        return true;
      }
    ),
});

export const GroomDataValidator = yup.object().shape({
  groomEmbg: yup
    .string()
    .test("embg-doesnt-match", LOCALE.embg_doesnt_match, function (value) {
      if (AuthService.getCurrentUser().gender === "MALE") {
        if (AuthService.getCurrentUser().embg !== value) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
    .test("bride-embg-required", LOCALE.bride_embg_required, function (value) {
      if (!this.options.parent.groomCountry) return false;
      if (!this.options.parent.groomCountry.id) return false;
      if (this.options.parent.groomCountry.id === defaultCountry.id) {
        if (!value) return false;
      }
      return true;
    })
    .test("embg-female-format", LOCALE.invalid_male_embg, function (value) {
      if (!this.options.parent.groomCountry) return false;
      if (!this.options.parent.groomCountry.id) return false;
      if (this.options.parent.groomCountry.id === defaultCountry.id) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (parseInt(value.substring(9, 12)) >= 500) {
          return false;
        }
      }
      return true;
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!this.options.parent.groomCountry) return false;
      if (!this.options.parent.groomCountry.id) return false;
      if (this.options.parent.groomCountry.id === defaultCountry.id) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
      }
      return true;
    }),
  groomFirstName: yup
    .string()
    .min(2, LOCALE.at_least_2_chars_required)
    .required(LOCALE.firstName + LOCALE.required_field),
  groomLastName: yup
    .string()
    .min(2, LOCALE.at_least_2_chars_required)
    .required(LOCALE.lastName + LOCALE.required_field),
  groomBirthDate: yup
    .date()
    .required(LOCALE.groomBirthDate + LOCALE.required_field)
    .nullable(),
  groomCountry: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  groomPlaceBirth: yup
    .object()
    .test(
      "brideBirthPlaceConditionalRequired",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.groomCountry) return false;
        if (!this.options.parent.groomCountry.id) return false;
        if (this.options.parent.groomCountry.id === defaultCountry.id) {
          if (!value?.id) return false;
          return true;
        }
        return true;
      }
    )
    .nullable(),
  groomPlaceBirthManual: yup
    .string()
    .test(
      "brideBirthPlaceManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.groomCountry) return false;
        if (!this.options.parent.groomCountry.id) return false;
        if (this.options.parent.groomCountry.id !== defaultCountry.id) {
          if (!value) return false;
          return true;
        }
        return true;
      }
    ),
});

export const MarriageDataValidator = yup.object().shape({
  // validate marriageDate to be before today
  marriageDate: yup
    .date()
    .max(moment(), LOCALE.not_valid_format)
    .required(LOCALE.marriageDate + LOCALE.required_field)
    .nullable(),
  marriageCountry: yup.object().shape({
    id: yup.string().required(LOCALE.marriageCountry + LOCALE.required_field),
  }),
  marriagePlace: yup
    .object()
    .test(
      "marriagePlace",
      LOCALE.marriagePlace + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.marriageCountry) return false;
        if (!this.options.parent.marriageCountry.id) return false;
        if (this.options.parent.marriageCountry.id === defaultCountry.id) {
          if (!value?.id) return false;
          return true;
        }
        return true;
      }
    ),
  marriagePlaceManual: yup
    .string()
    .test(
      "marriagePlaceManual",
      LOCALE.marriagePlaceManual + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.marriageCountry) return false;
        if (!this.options.parent.marriageCountry.id) return false;
        if (this.options.parent.marriageCountry.id !== defaultCountry.id) {
          if (!value) return false;
          return true;
        }
        return true;
      }
    ),
});

export const MarriageCertificatePickupDataValidator = yup.object().shape({
  legalInterestNumber: yup
    .string()
    .nullable()
    .test(
      "legalInterestNumber",
      LOCALE.legal_interest_number + LOCALE.required_field,
      function (value) {
        if (AuthService.getCurrentUser()?.institution?.institutionType == 2) {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickUpWayCert === "PICKUP_WAY_UVMK") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryPlace: yup
    .object()
    .test(
      "deliveryPlace",
      LOCALE.delivery_place + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickUpWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryAddress: yup
    .string()
    .test(
      "deliveryAddress",
      LOCALE.delivery_address + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickUpWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});

export const RequestMarriageCertificateDataValidator = yup.object().shape({
  brideEmbg: yup
    .string()
    .nullable()
    .test("embg-doesnt-match", LOCALE.embg_doesnt_match, function (value) {
      if (AuthService.getCurrentUser().gender === "FEMALE") {
        if (AuthService.getCurrentUser().embg !== value) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
    .test("bride-embg-required", LOCALE.bride_embg_required, function (value) {
      if (!this.options.parent.brideCountry) return false;
      if (!this.options.parent.brideCountry.id) return false;
      if (this.options.parent.brideCountry.id === defaultCountry.id) {
        if (!value) return false;
      }
      return true;
    })
    .test("embg-female-format", LOCALE.invalid_female_embg, function (value) {
      if (!this.options.parent.brideCountry) return false;
      if (!this.options.parent.brideCountry.id) return false;
      if (this.options.parent.brideCountry.id === defaultCountry.id) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (parseInt(value.substring(9, 12)) <= 499) {
          return false;
        }
      }
      return true;
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!this.options.parent.brideCountry) return false;
      if (!this.options.parent.brideCountry.id) return false;
      if (this.options.parent.brideCountry.id === defaultCountry.id) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
      }
      return true;
    }),
  brideFirstName: yup
    .string()
    .min(2, LOCALE.at_least_2_chars_required)
    .required(LOCALE.firstName + LOCALE.required_field),
  brideLastName: yup
    .string()
    .min(2, LOCALE.at_least_2_chars_required)
    .required(LOCALE.lastName + LOCALE.required_field),
  brideCountry: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  bridePlaceBirth: yup
    .object()
    .test(
      "brideBirthPlaceConditionalRequired",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.brideCountry) return false;
        if (!this.options.parent.brideCountry.id) return false;
        if (this.options.parent.brideCountry.id === defaultCountry.id) {
          if (!value?.id) return false;
          return true;
        }
        return true;
      }
    )
    .nullable(),
  bridePlaceBirthManual: yup
    .string()
    .nullable()
    .test(
      "brideBirthPlaceManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.brideCountry) return false;
        if (!this.options.parent.brideCountry.id) return false;
        if (this.options.parent.brideCountry.id !== defaultCountry.id) {
          if (!value) return false;
          return true;
        }
        return true;
      }
    ),

  groomEmbg: yup
    .string()
    .nullable()
    .test("embg-doesnt-match", LOCALE.embg_doesnt_match, function (value) {
      if (AuthService.getCurrentUser().gender === "MALE") {
        if (AuthService.getCurrentUser().embg !== value) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
    .test("bride-embg-required", LOCALE.bride_embg_required, function (value) {
      if (!this.options.parent.groomCountry) return false;
      if (!this.options.parent.groomCountry.id) return false;
      if (this.options.parent.groomCountry.id === defaultCountry.id) {
        if (!value) return false;
      }
      return true;
    })
    .test("embg-female-format", LOCALE.invalid_male_embg, function (value) {
      if (!this.options.parent.groomCountry) return false;
      if (!this.options.parent.groomCountry.id) return false;
      if (this.options.parent.groomCountry.id === defaultCountry.id) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (parseInt(value.substring(9, 12)) >= 500) {
          return false;
        }
      }
      return true;
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!this.options.parent.groomCountry) return false;
      if (!this.options.parent.groomCountry.id) return false;
      if (this.options.parent.groomCountry.id === defaultCountry.id) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
      }
      return true;
    }),
  groomFirstName: yup
    .string()
    .min(2, LOCALE.at_least_2_chars_required)
    .required(LOCALE.firstName + LOCALE.required_field),
  groomLastName: yup
    .string()
    .min(2, LOCALE.at_least_2_chars_required)
    .required(LOCALE.lastName + LOCALE.required_field),
  groomCountry: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  groomPlaceBirth: yup
    .object()
    .test(
      "brideBirthPlaceConditionalRequired",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.groomCountry) return false;
        if (!this.options.parent.groomCountry.id) return false;
        if (this.options.parent.groomCountry.id === defaultCountry.id) {
          if (!value?.id) return false;
          return true;
        }
        return true;
      }
    ),
  groomPlaceBirthManual: yup
    .string()
    .nullable()
    .test(
      "brideBirthPlaceManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.groomCountry) return false;
        if (!this.options.parent.groomCountry.id) return false;
        if (this.options.parent.groomCountry.id !== defaultCountry.id) {
          if (!value) return false;
          return true;
        }
        return true;
      }
    ),
  marriageCountry: yup.object().shape({
    id: yup.string().required(LOCALE.marriageCountry + LOCALE.required_field),
  }),
  marriagePlace: yup
    .object()
    .test(
      "marriagePlace",
      LOCALE.marriagePlace + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.marriageCountry) return false;
        if (!this.options.parent.marriageCountry.id) return false;
        if (this.options.parent.marriageCountry.id === defaultCountry.id) {
          if (!value?.id) return false;
          return true;
        }
        return true;
      }
    ),
  marriagePlaceManual: yup
    .string()
    .nullable()
    .test(
      "marriagePlaceManual",
      LOCALE.marriagePlaceManual + LOCALE.required_field,
      function (value) {
        if (!this.options.parent.marriageCountry) return false;
        if (!this.options.parent.marriageCountry.id) return false;
        if (this.options.parent.marriageCountry.id !== defaultCountry.id) {
          if (!value) return false;
          return true;
        }
        return true;
      }
    ),

  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickUpWayCert === "PICKUP_WAY_UVMK") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryPlace: yup
    .object()
    .nullable()
    .test(
      "deliveryPlace",
      LOCALE.delivery_place + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickUpWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryAddress: yup
    .string()
    .nullable()
    .test(
      "deliveryAddress",
      LOCALE.delivery_address + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickUpWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});
