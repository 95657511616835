import Axios from "axios";
import {
  notifyLoaderApiCallStart,
  notifyLoaderApiCallFinish,
  notifyShowErrorMessage,
} from "../common/actions/CommonActions";
import { AuthService } from "../modules/auth/AuthService";
import { AuthRepository } from "../modules/auth/AuthRepository";
import { SETTINGS } from "../properties/ApiProps";
import { LOCALE } from "../properties/Locale";

export default {
  setupInterceptors: (store) => {
    // Add a request interceptor
    Axios.interceptors.request.use(
      function (config) {
        store.dispatch(notifyLoaderApiCallStart());
        // Do something before request is sent
        if (!config.url.includes("/metrics/")) {
          store.dispatch(notifyLoaderApiCallStart());
        }
        if (
          !config.url.includes("access_token=") &&
          !config.url.includes("/token") &&
          !config.url.includes("/register")
        ) {
          if (!config.params) {
            config["params"] = {};
          }
          config.headers["Authorization"] = SETTINGS.API_AUTHORIZATION_HEADER;
          if (AuthService.getToken()) {
            config.params["access_token"] = AuthService.getToken().access_token;
          }
        }

        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    // Add a response interceptor
    Axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.dispatch(notifyLoaderApiCallFinish());
        return response;
      },
      function (err) {
        if (err?.response?.data?.path === "/users/verify") {
          return Promise.reject(err);
        }

        if (err?.message === "Network Error") {
          window.location.href = "/error/network";
          store.dispatch(notifyShowErrorMessage(LOCALE.networkErrorMessage));
        }

        if (
          err?.response?.data?.message &&
          err?.response?.data?.message.includes(
            "Could not get JDBC Connection;"
          )
        ) {
          store.dispatch(notifyShowErrorMessage(LOCALE.JDBCConnectonError));
          return Promise.reject(err);
        }

        if (err.response?.data?.error?.includes("invalid_token")) {
          AuthRepository.logout();
        }
        if (err.response?.data?.error?.includes("access_denied")) {
          window.location.href = "/error/403";
        }
        if (err.response?.data?.message) {
          if (LOCALE.getString(err.response?.data?.message)) {
            store.dispatch(
              notifyShowErrorMessage(
                LOCALE.getString(err.response?.data?.message)
              )
            );
          } else {
            store.dispatch(notifyShowErrorMessage(err.response?.data?.message));
          }
        }
        store.dispatch(notifyLoaderApiCallFinish());
        return Promise.reject(err);
      }
    );
  },
};
