import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  CardHeader,
  Hidden,
  TextField,
  InputAdornment,
  Divider,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Grow,
  FormControl,
  Select,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { Alert, AlertTitle } from "@material-ui/lab";
import { LoginFormValidator } from "./LoginFormValidator";
import { PropagateLoader } from "react-spinners";
import { AuthRepository } from "./AuthRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthService } from "./AuthService";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { SETTINGS } from "../../properties/ApiProps";
import Flag from "react-world-flags";

const useStyles = makeStyles(styles);

export default function LoginComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [voucherTypes, setVoucherTypes] = React.useState();
  const [credentialsData, setCredentialsData] = React.useState({
    username: "",
    password: "",
  });
  const [globalFormError, setGlobalFormError] = React.useState();
  const [credentialsDataErrors, setCredentialsDataErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const focusFirstField = () => {
    var fieldUsername = document.getElementById("username");
    if (fieldUsername) fieldUsername.focus();
  };

  const loginSubmitHandler = () => {
    setGlobalFormError(null);
    setCredentialsDataErrors({});
    setLoading(true);
    if (!LoginFormValidator.isValidSync(credentialsData)) {
      var validationErrors = {};
      (async () => {
        await LoginFormValidator.validate(credentialsData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setCredentialsDataErrors(validationErrors);
          setLoading(false);
          focusFirstField();
          return;
        });
      })();
    } else {
      authenticate();
    }
  };

  const authenticate = () => {
    AuthRepository.fetchToken(credentialsData)
      .then((res) => {
        AuthService.setToken(res.data);
        UsersRepository.fetchCurrentUser()
          .then((res) => {
            if (res.data) {
              window.localStorage.setItem(
                "currentUser",
                JSON.stringify(res.data)
              );
              setLoading(false);
              setRedirectTo("/");
            }
          })
          .catch((err) => {
            setGlobalFormError(LOCALE.connection_error);
            setLoading(false);
            focusFirstField();
          });
      })
      .catch((err) => {
        setGlobalFormError(ErrorMessageResolver.resolve(err));
        setLoading(false);
        focusFirstField();
      });
  };

  const handleChangeFormField = (e, name) => {
    let data = { ...credentialsData };
    data[name] = e.target.value;
    setCredentialsData(data);
  };

  return (
    <React.Fragment>
      {AuthService.getCurrentUser() !== null && <Redirect to={"/"} push />}
      {redirectTo && <Redirect to={redirectTo} push />}
      <Backdrop className={classes.backdrop} open={loading}>
        <PropagateLoader size={15} color={"#fff"} loading={loading} />
      </Backdrop>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <FormControl
          variant="outlined"
          size="small"
          style={{ float: "right", marginRight: "20px", marginTop: "5px" }}
        >
          <Select
            size="small"
            style={{
              marginLeft: "15px",
            }}
            classes={{ select: classes.select }}
            value={
              window.localStorage.getItem("locale")
                ? window.localStorage.getItem("locale")
                : "mk"
            }
            onChange={(e) => {
              changeLanguage(e.target.value);
            }}
          >
            <MenuItem value={"mk"} style={{ textAlign: "center" }}>
              <Flag
                code={"mk"}
                height="15"
                width="25"
                style={{
                  padding: "0px",
                  paddingTop: "4px",
                }}
              />
            </MenuItem>
            <MenuItem value={"al"} style={{ textAlign: "center" }}>
              <Flag
                code={"al"}
                height="15"
                width="25"
                style={{
                  padding: "0px",
                  paddingTop: "4px",
                }}
              />
            </MenuItem>
          </Select>
        </FormControl>
        <Container>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Hidden smDown>
                <IconButton
                  onClick={() => setRedirectTo("/")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "45%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  onClick={() => setRedirectTo("/")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "3%",
                    top: "2%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Hidden smDown>
                <div style={{ marginTop: "10vh" }}></div>
              </Hidden>
            </Grid>
            <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
            <Grid item md={6} lg={6} sm={6} xs={12} style={{ padding: "15px" }}>
              <Grid
                item
                xs={12}
                md={12}
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <Hidden smDown>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="60px"
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: "15px",
                      paddingTop: "5px",
                    }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      textAlign: "left",
                      paddingTop: "5px",
                      marginLeft: "15px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="30px"
                    style={{ paddingRight: "5px", marginTop: "5px" }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      paddingTop: "0px",
                      marginLeft: "5px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                      fontSize: "11px",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
              </Grid>

              <Card>
                <div
                  style={{
                    textAlign: "center",
                    padding: "0px",
                    margin: "0px",
                    color: "white",
                    backgroundColor: "#70c1c2",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "10px",
                    }}
                  >
                    <Icon>lock_open</Icon>
                    <span style={{ verticalAlign: "top", lineHeight: "1.4" }}>
                      {LOCALE.login}
                    </span>
                  </Typography>
                </div>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={false} sm={false} md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                      <Hidden smDown>
                        <br />
                      </Hidden>
                      <Grid container spacing={2}>
                        {globalFormError && (
                          <Grid item xs={12}>
                            <Alert
                              severity="error"
                              style={{ marginTop: "10px" }}
                            >
                              {globalFormError}
                            </Alert>
                          </Grid>
                        )}
                        <Grow in={true} timeout={750}>
                          <Grid item xs={12}>
                            <TextField
                              id="username"
                              label={LOCALE.username}
                              size="small"
                              variant="outlined"
                              autoFocus
                              value={credentialsData.username}
                              onChange={(e) =>
                                handleChangeFormField(e, "username")
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  loginSubmitHandler();
                                }
                              }}
                              fullWidth={true}
                              disabled={loading}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Icon>person</Icon>
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                credentialsDataErrors.username != undefined
                              }
                              helperText={credentialsDataErrors.username}
                            />
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={900}>
                          <Grid item xs={12}>
                            <TextField
                              id="password"
                              label={LOCALE.password}
                              type="password"
                              variant="outlined"
                              value={credentialsData.password}
                              onChange={(e) =>
                                handleChangeFormField(e, "password")
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  loginSubmitHandler();
                                }
                              }}
                              size="small"
                              disabled={loading}
                              fullWidth={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Icon>vpn_key</Icon>
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                credentialsDataErrors.password != undefined
                              }
                              helperText={credentialsDataErrors.password}
                            />
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1050}>
                          <Grid item xs={12}>
                            <Button
                              disabled={loading}
                              size="medium"
                              variant="contained"
                              color="secondary"
                              fullWidth={true}
                              onClick={() => loginSubmitHandler()}
                            >
                              {LOCALE.log_in}
                            </Button>
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1200}>
                          <Grid item xs={4} md={3}>
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/")}
                            >
                              {LOCALE.home}
                            </Button>
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1200}>
                          <Grid
                            item
                            xs={4}
                            md={6}
                            style={{ textAlign: "center" }}
                          >
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/forgotPassword")}
                            >
                              {LOCALE.forgotten_password}
                            </Button>
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1200}>
                          <Grid
                            item
                            xs={4}
                            md={3}
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/signup")}
                            >
                              {LOCALE.registration}
                            </Button>
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1200}>
                          <Grid item xs={12}>
                            <Divider style={{ marginTop: "20px" }} />
                          </Grid>
                        </Grow>
                        <Grid item xs={12}>
                          <Grow in={true} timeout={1350}>
                            <Button
                              size="medium"
                              fullWidth
                              color="primary"
                              style={{ color: "white" }}
                              variant="contained"
                              onClick={() => setRedirectTo("/loginToken")}
                            >
                              {LOCALE.login_legal_entities}
                            </Button>
                          </Grow>
                        </Grid>
                        <Grid item xs={12}>
                          <Grow in={true} timeout={1350}>
                            <Button
                              size="medium"
                              fullWidth
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                setLoading(true);
                                window.location.href = `https://eid.mk/issue/wsfed?wa=wsignin1.0&wtrealm=https%3A%2F%2Fe-portal.uvmk.gov.mk%2F&wctx=rm%3d0%26id%3dpassive%26ru%3d%252f&wreply=https%3A%2F%2Fe-portal.uvmk.gov.mk%2Flogin_token_eid`;
                              }}
                            >
                              {LOCALE.login_with_eid}
                            </Button>
                          </Grow>
                          <Grow in={true} timeout={1500}>
                            <Alert
                              severity="info"
                              style={{ marginTop: "10px" }}
                            >
                              <AlertTitle>
                                <strong>{LOCALE.login_with_eid}</strong>
                              </AlertTitle>
                              {LOCALE.login_with_eid_description}
                            </Alert>
                          </Grow>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
