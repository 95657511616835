import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  CardHeader,
  Hidden,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grow,
  Divider,
  Tooltip,
  Backdrop,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { AuthRepository } from "./AuthRepository";
import { Autocomplete } from "@material-ui/lab";
import { CountryRepository } from "../../repositories/CountryRepository";
import { Transliteration } from "../../common/helpers/Transliteration";
import { SignUpFormValidator } from "./SignUpFormValidator";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { PropagateLoader } from "react-spinners";
import MuiPhoneNumber from "material-ui-phone-number";
import { SignUpFormValidatorLegalEntity } from "./SignUpFormValidatorLegalEntity";

const useStyles = makeStyles(styles);

export default function SignUpComponentPerson() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");

  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");

  const [confirmPassword, setConfirmPasswrod] = React.useState("");
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [globalFormError, setGlobalFormError] = React.useState();
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    embg: "",
    countryLiving: {
      countryCode: "807",
      id: "0",
      name: "СЕВЕРНА МАКЕДОНИЈА",
      nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      nameEn: "REPUBLIC OF NORTH MACEDONIA",
      printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
      printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      printNameEn: "REPUBLIC OF NORTH MACEDONIA",
    },
    placeLiving: { name: "" },
    placeLivingManual: "",
    addressLiving: "",
    email: "",
    phoneNumber: "",
    username: "",
    password: "",
    confirmPassword: "",
    institution: {
      institutionType: null,
    },
  });

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    validateFormData();
    if (SignUpFormValidator.isValidSync(formData)) {
      AuthRepository.registerUser(formData).then(
        (res) => {
          setRedirectTo("/login");
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    }
  };

  const validateFormData = () => {
    if (!SignUpFormValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await SignUpFormValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const handleChangeFormData = (name, value) => {
    let tmp = { ...formData };
    if (name === "countryLiving") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "placeLiving") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <Backdrop className={classes.backdrop} open={loading}>
        <PropagateLoader size={15} color={"#fff"} loading={loading} />
      </Backdrop>
      <Grid container spacing={2}>
        <Grow in={true} timeout={700}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.firstName}
              size="small"
              variant="outlined"
              autoFocus
              fullWidth={true}
              error={formDataErrors.firstName !== undefined}
              helperText={formDataErrors.firstName}
              onChange={(e) => {
                handleChangeFormData(
                  "firstName",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formData?.firstName}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={800}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.lastName}
              size="small"
              variant="outlined"
              fullWidth={true}
              error={formDataErrors.lastName !== undefined}
              helperText={formDataErrors.lastName}
              onChange={(e) => {
                handleChangeFormData(
                  "lastName",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formData?.lastName}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={900}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.embg}
              size="small"
              variant="outlined"
              fullWidth={true}
              error={formDataErrors.embg !== undefined}
              helperText={formDataErrors.embg}
              onChange={(e) => {
                handleChangeFormData("embg", e.target.value);
              }}
              value={formData?.embg}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1000}>
          <Grid item xs={12}>
            <Autocomplete
              error={formDataErrors.countryLiving !== undefined}
              helperText={formDataErrors.countryLiving}
              noOptionsText={LOCALE.noOptionsText}
              size="small"
              value={
                formData.countryLiving.name ? formData.countryLiving.name : ""
              }
              onChange={(event, newValue) => {
                handleChangeFormData("countryLiving", newValue);
              }}
              inputValue={countrySearch}
              onInputChange={(event, newInputValue) => {
                setCountrySearch(Transliteration.toUpperCyrilic(newInputValue));
              }}
              id="countryLiving"
              options={countries?.map((country) => country.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={LOCALE.country_living}
                  fullWidth
                  onFocus={onFocus}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grow>
        {formData.countryLiving.id === "0" ? (
          <Grow in={true} timeout={1000}>
            <Grid item xs={12}>
              <Autocomplete
                noOptionsText={LOCALE.noOptionsText}
                size="small"
                value={
                  formData.placeLiving.name ? formData.placeLiving.name : ""
                }
                onChange={(event, newValue) => {
                  handleChangeFormData("placeLiving", newValue);
                }}
                inputValue={placeSearch}
                onInputChange={(event, newInputValue) => {
                  setPlaceSearch(Transliteration.toUpperCyrilic(newInputValue));
                }}
                id="placeLiving"
                options={places?.map((place) => place.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={LOCALE.place_living}
                    fullWidth
                    error={formDataErrors.placeLiving !== undefined}
                    helperText={formDataErrors.placeLiving}
                    onFocus={onFocus}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grow>
        ) : (
          <Grow in={true} timeout={900}>
            <Grid item xs={12}>
              <TextField
                label={LOCALE.place_living}
                size="small"
                variant="outlined"
                autoComplete="false"
                fullWidth={true}
                error={formDataErrors.placeLivingManual !== undefined}
                helperText={formDataErrors.placeLivingManual}
                onChange={(e) => {
                  handleChangeFormData(
                    "placeLivingManual",
                    Transliteration.toUpperCyrilic(e.target.value)
                  );
                }}
                value={formData?.placeLivingManual}
              />
            </Grid>
          </Grow>
        )}
        <Grow in={true} timeout={1100}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.address_living}
              size="small"
              variant="outlined"
              error={formDataErrors.addressLiving !== undefined}
              helperText={formDataErrors.addressLiving}
              fullWidth={true}
              onChange={(e) => {
                handleChangeFormData(
                  "addressLiving",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formData?.addressLiving}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1200}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.email}
              error={formDataErrors.email !== undefined}
              helperText={formDataErrors.email}
              size="small"
              variant="outlined"
              fullWidth={true}
              onChange={(e) => {
                handleChangeFormData("email", e.target.value);
              }}
              value={formData?.email}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1300}>
          <Grid item xs={12} md={6}>
            <MuiPhoneNumber
              size="small"
              label={LOCALE.contact_phone}
              fullWidth={true}
              error={formDataErrors.phoneNumber !== undefined}
              helperText={formDataErrors.phoneNumber}
              variant="outlined"
              defaultCountry={"mk"}
              value={formData?.phoneNumber}
              onChange={(e) => {
                handleChangeFormData("phoneNumber", e);
              }}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1400}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.username}
              error={formDataErrors.username !== undefined}
              helperText={formDataErrors.username}
              size="small"
              variant="outlined"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>person</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleChangeFormData("username", e.target.value);
              }}
              value={formData?.username}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1500}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.password}
              error={formDataErrors.password !== undefined}
              helperText={formDataErrors.password}
              type="password"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>vpn_key</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleChangeFormData("password", e.target.value);
              }}
              value={formData?.password}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1600}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.confirm_password}
              error={formDataErrors.confirmPassword !== undefined}
              helperText={formDataErrors.confirmPassword}
              type="password"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>vpn_key</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleChangeFormData("confirmPassword", e.target.value);
              }}
              value={formData?.confirmPassword}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1700}>
          <Grid item xs={12}>
            <Button
              size="medium"
              variant="contained"
              disabled={loading}
              color="secondary"
              fullWidth={true}
              onClick={() => handleSubmit()}
            >
              {LOCALE.sign_up}
            </Button>
          </Grid>
        </Grow>
      </Grid>
    </React.Fragment>
  );
}
