import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const FilesRepository = {
  uploadSignedRequestCertificate: (
    signedContent,
    requestCertificateId,
    certificateType
  ) => {
    let data = new FormData();
    data.append("signedFile", signedContent);
    data.append("requestCertificateId", requestCertificateId);
    data.append("certificateType", certificateType);
    return Axios({
      url: `${SETTINGS.API_URL}files/uploadSignedRequestCertificate`,
      method: "POST",
      data: data,
      header: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  checkSignedRequestCertificate: (requestCertificateId, certificateType) => {
    return Axios({
      url: `${SETTINGS.API_URL}files/checkSignedRequestCertificate`,
      method: "GET",
      params: {
        requestCertificateId: requestCertificateId,
        certificateType: certificateType,
      },
    });
  },

  getPowerOfAttorneyScan: (requestCertificateId, certificateType) => {
    return Axios({
      url: `${SETTINGS.API_URL}files/getPowerOfAttorneyScan`,
      method: "GET",
      params: {
        requestCertificateId: requestCertificateId,
        certificateType: certificateType,
      },
      responseType: "blob",
    });
  },

  uploadPowerOfAttorneyScan: (
    images,
    requestCertificateId,
    certificateType
  ) => {
    let data = new FormData();
    for (var i = 0; i < images.length; i++) {
      data.append("powerOfAttorneyScan", images[i]);
    }
    data.append("requestCertificateId", requestCertificateId);
    data.append("certificateType", certificateType);
    return Axios({
      url: `${SETTINGS.API_URL}files/uploadPowerOfAttorneyScan`,
      method: "POST",
      data: data,
      header: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
