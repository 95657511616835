import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  Divider,
  Grow,
  Paper,
  CircularProgress,
  LinearProgress,
  TextField,
  Hidden,
  InputLabel,
  Select,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";
import { UsersRepository } from "../../repositories/UsersRepository";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { Transliteration } from "../../common/helpers/Transliteration";
import { CountryRepository } from "../../repositories/CountryRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { UserProfileValidator } from "./UserProfileValidator";
import { notifyShowSuccessMessage } from "../../common/actions/CommonActions";
import { history } from "../..";
import MuiPhoneNumber from "material-ui-phone-number";
import { InputAdornment } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import { SignUpFormValidatorLegalEntity } from "../auth/SignUpFormValidatorLegalEntity";
import { LegalEntityUserProfileValidator } from "./LegalEntityUserProfileValidator";

const useStyles = makeStyles(styles);

export default function UserProfileComponentLegalEntity() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [globalFormError, setGlobalFormError] = React.useState();
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const dispatch = useDispatch();

  const [formDataLegalEntity, setFormDataLegalEntity] = React.useState({});
  var _ = require("lodash");

  React.useEffect(() => {
    if (AuthService.getCurrentUser() == null) {
      setRedirectTo("/login");
    } else {
      loadFormData();
    }
  }, []);

  React.useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  React.useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const loadFormData = () => {
    setLoading(true);
    UsersRepository.fetchCurrentUser()
      .then((res) => {
        // console.log(res.data);
        setFormDataLegalEntity(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setGlobalFormError(ErrorMessageResolver.resolve(err));
      });
  };

  const handleSubmitLegalEntity = () => {
    setGlobalFormError(null);
    setLoading(true);
    validateFormDataLegalEntity();
    if (LegalEntityUserProfileValidator.isValidSync(formDataLegalEntity)) {
      UsersRepository.updateMyProfileLegalEntity(formDataLegalEntity).then(
        (res) => {
          loadFormData();
          window.localStorage.setItem("currentUser", JSON.stringify(res.data));
          dispatch(notifyShowSuccessMessage(LOCALE.changes_saved_success));
        },
        (err) => {
          setLoading(false);
          setGlobalFormError(ErrorMessageResolver.resolve(err));
        }
      );
    } else {
      validateFormDataLegalEntity();
    }
  };

  const validateFormDataLegalEntity = () => {
    if (!LegalEntityUserProfileValidator.isValidSync(formDataLegalEntity)) {
      var validationErrors = {};
      (async () => {
        await LegalEntityUserProfileValidator.validate(formDataLegalEntity, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const handleChangeFormDataLegalEntity = (name, value) => {
    setFormDataLegalEntity(_.set({ ...formDataLegalEntity }, name, value));
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}

      <Grid container spacing={2}>
        <Grow in={true} timeout={600}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.username}
              error={formDataErrors.username !== undefined}
              helperText={formDataErrors.username}
              size="small"
              disabled
              variant="outlined"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>person</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleChangeFormDataLegalEntity("username", e.target.value);
              }}
              value={formDataLegalEntity?.username}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={700}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.firstName}
              size="small"
              variant="outlined"
              autoFocus
              fullWidth={true}
              error={formDataErrors.firstName !== undefined}
              helperText={formDataErrors.firstName}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "firstName",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={
                formDataLegalEntity?.firstName
                  ? formDataLegalEntity?.firstName
                  : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={800}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.lastName}
              size="small"
              variant="outlined"
              fullWidth={true}
              error={formDataErrors.lastName !== undefined}
              helperText={formDataErrors.lastName}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "lastName",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={
                formDataLegalEntity?.lastName
                  ? formDataLegalEntity?.lastName
                  : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={900}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              disabled
              error={
                _.get(formDataErrors, "institution.institutionType") !==
                undefined
              }
            >
              <InputLabel>{LOCALE.institution}</InputLabel>
              <Select
                value={
                  formDataLegalEntity?.institution?.institutionType
                    ? formDataLegalEntity?.institution?.institutionType
                    : ""
                }
                onChange={(e) => {
                  handleChangeFormDataLegalEntity(
                    "institution.institutionType",
                    e.target.value
                  );
                }}
                label={LOCALE.institution}
              >
                {/* <MenuItem value={1}>Извршител</MenuItem> */}
                {/* <MenuItem value={2}>Адвокат</MenuItem> */}
                <MenuItem value={3}>Нотар</MenuItem>
              </Select>
              <FormHelperText>
                {_.get(formDataErrors, "institution.institutionType")}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grow>
        <Grow in={true} timeout={1000}>
          <Grid item xs={12}>
            <TextField
              label="Назив"
              size="small"
              variant="outlined"
              fullWidth={true}
              error={_.get(formDataErrors, "institution.name") !== undefined}
              helperText={_.get(formDataErrors, "institution.name")}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "institution.name",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={
                formDataLegalEntity?.institution?.name
                  ? formDataLegalEntity?.institution?.name
                  : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1100}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.edb}
              size="small"
              type="number"
              variant="outlined"
              fullWidth={true}
              disabled
              error={_.get(formDataErrors, "institution.edb") !== undefined}
              helperText={_.get(formDataErrors, "institution.edb")}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "institution.edb",
                  e.target.value
                );
              }}
              value={
                formDataLegalEntity?.institution?.edb
                  ? formDataLegalEntity?.institution?.edb
                  : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1200}>
          <Grid item xs={12}>
            <Autocomplete
              noOptionsText={LOCALE.noOptionsText}
              size="small"
              value={
                formDataLegalEntity?.institution?.countryLocation?.name
                  ? formDataLegalEntity?.institution?.countryLocation?.name
                  : ""
              }
              onChange={(event, newValue) => {
                handleChangeFormDataLegalEntity(
                  "institution.countryLocation",
                  countries?.filter((country) => country.name === newValue)[0]
                );
              }}
              disabled
              inputValue={countrySearch}
              onInputChange={(event, newInputValue) => {
                setCountrySearch(Transliteration.toUpperCyrilic(newInputValue));
              }}
              id="countryLocation"
              options={countries?.map((country) => country.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    _.get(formDataErrors, "institution.countryLocation") !==
                    undefined
                  }
                  helperText={_.get(
                    formDataErrors,
                    "institution.countryLocation"
                  )}
                  label={LOCALE.country}
                  fullWidth
                  onFocus={onFocus}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grow>
        {formDataLegalEntity?.institution?.countryLocation?.id === "0" ? (
          <Grow in={true} timeout={1300}>
            <Grid item xs={12}>
              <Autocomplete
                noOptionsText={LOCALE.noOptionsText}
                size="small"
                value={
                  formDataLegalEntity?.institution?.placeLocation?.name
                    ? formDataLegalEntity?.institution?.placeLocation?.name
                    : ""
                }
                onChange={(event, newValue) => {
                  handleChangeFormDataLegalEntity(
                    "institution.placeLocation",
                    places?.filter((place) => place.name === newValue)[0]
                  );
                }}
                inputValue={placeSearch}
                onInputChange={(event, newInputValue) => {
                  setPlaceSearch(Transliteration.toUpperCyrilic(newInputValue));
                }}
                id="placeLiving"
                options={places?.map((place) => place.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={LOCALE.place}
                    fullWidth
                    error={
                      _.get(formDataErrors, "institution.placeLocation") !==
                      undefined
                    }
                    helperText={_.get(
                      formDataErrors,
                      "institution.placeLocation"
                    )}
                    onFocus={onFocus}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grow>
        ) : (
          <Grow in={true} timeout={1300}>
            <Grid item xs={12}>
              <TextField
                label={LOCALE.place}
                size="small"
                variant="outlined"
                autoComplete="false"
                fullWidth={true}
                error={
                  _.get(formDataErrors, "institution.placeLocationManual") !==
                  undefined
                }
                helperText={_.get(
                  formDataErrors,
                  "institution.placeLocationManual"
                )}
                onChange={(e) => {
                  handleChangeFormDataLegalEntity(
                    "institution.placeLocationManual",
                    Transliteration.toUpperCyrilic(e.target.value)
                  );
                }}
                value={
                  formDataLegalEntity?.institution?.placeLocationManual
                    ? formDataLegalEntity?.institution?.placeLocationManual
                    : ""
                }
              />
            </Grid>
          </Grow>
        )}
        <Grow in={true} timeout={1400}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.sedishte}
              size="small"
              variant="outlined"
              error={_.get(formDataErrors, "institution.address") !== undefined}
              helperText={_.get(formDataErrors, "institution.address")}
              fullWidth={true}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "institution.address",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={
                formDataLegalEntity?.institution?.address
                  ? formDataLegalEntity?.institution?.address
                  : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1500}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.email}
              error={formDataErrors.email !== undefined}
              helperText={formDataErrors.email}
              size="small"
              variant="outlined"
              fullWidth={true}
              onChange={(e) => {
                handleChangeFormDataLegalEntity("email", e.target.value);
              }}
              value={
                formDataLegalEntity?.email ? formDataLegalEntity?.email : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1600}>
          <Grid item xs={12} md={6}>
            <MuiPhoneNumber
              size="small"
              label={LOCALE.contact_phone}
              fullWidth={true}
              error={formDataErrors.phoneNumber !== undefined}
              helperText={formDataErrors.phoneNumber}
              variant="outlined"
              defaultCountry={"mk"}
              value={formDataLegalEntity?.phoneNumber}
              onChange={(e) => {
                handleChangeFormDataLegalEntity("phoneNumber", e);
              }}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1700}>
          <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => handleSubmitLegalEntity()}
            >
              {LOCALE.save}
            </Button>
          </Grid>
        </Grow>
      </Grid>
    </React.Fragment>
  );
}
