import {
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  Icon,
  LinearProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import InfoIcon from "@material-ui/icons/Info";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import bgImg from "../../assets/img/productCurvyLines.png";
import Header from "../../common/Header";
import { LOCALE } from "../../properties/Locale";

import { Alert } from "@material-ui/lab";
import _ from "lodash";
import Footer from "../../common/Footer";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { CountryRepository } from "../../repositories/CountryRepository";
import { EducationRepository } from "../../repositories/EducationRepository";
import { NationalityRepository } from "../../repositories/NationalityRepository";
import { OccupationRepository } from "../../repositories/OccupationRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { RegistryAreaRepository } from "../../repositories/RegistryAreaRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthRepository } from "../auth/AuthRepository";
import { AuthService } from "../auth/AuthService";
import ApplicationUvmkDocuments from "./ApplicationUvmkDocuments";
import ApplicationUvmkMarriageDetails from "./ApplicationUvmkMarriageDetails";
import ApplicationUvmkNewbornData from "./ApplicationUvmkNewbornData";
import ApplicationUvmkPortalParentsData from "./ApplicationUvmkPortalParentsData";
import ApplicationUvmkPreview from "./ApplicationUvmkPreview";
import { ApplicationUvmkPortalRepository } from "../../repositories/ApplicationUvmkPortalRepository";
import ApplicationUvmkPayment from "./ApplicationUvmkPayment";
import { SETTINGS } from "../../properties/ApiProps";
import {
  ApplicationUvmkDocumentsValidator,
  FatherDataValidator,
  MarriageDetailsValidator,
  MotherDataValidator,
  MotherDocumentsValidator,
  NewbornDataValidator,
  ValidateApplicationUvmkDocuments,
} from "./ApplicationUvmkPortalValidator";

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const defaultCountry = {
  countryCode: "807",
  id: "0",
  name: "СЕВЕРНА МАКЕДОНИЈА",
  nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
  nameEn: "REPUBLIC OF NORTH MACEDONIA",
  printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
  printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
  printNameEn: "REPUBLIC OF NORTH MACEDONIA",
};

export default function ApplicationUVMKPortal() {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [registryAreas, setRegistryAreas] = React.useState([]);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [globalFormError, setGlobalFormError] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState();
  const [citizenshipSearch, setCitizenshipSearch] = React.useState("");
  const [educations, setEducations] = React.useState([]);
  const [educationSearch, setEducationSearch] = React.useState("");
  const [nationalities, setNationalities] = React.useState([]);
  const [nationalitySearch, setNationalitySearch] = React.useState("");
  const [occupationSearch, setOccupationSearch] = React.useState("");
  const [occupations, setOccupations] = React.useState([]);
  const [livingCountrySearch, setLivingCountrySearch] = React.useState("");
  const [livingPlaceSearch, setLivingPlaceSearch] = React.useState("");
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");
  const { id } = useParams();
  const [marriageCountrySearch, setMarriageCountrySearch] = React.useState("");
  const [marriagePlaceSearch, setMarriagePlaceSearch] = React.useState("");
  const [fatherUploadedDocs, setFatherUploadedDocs] = React.useState([]);
  const [motherUploadedDocs, setMotherUploadedDocs] = React.useState([]);
  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    dateBirth: "",
    birthTime: null,
    birthPlace: { id: undefined },
    bornInMarriage: 0,
    typeRequest: "BASIC",
    pickupWayCert: "PICKUP_WAY_ELECTRONIC_CERT",
    deliveryRegistryArea: {},
    dateMarriage: null,
    countryMarriage: defaultCountry,
    placeMarriage: { id: undefined },
    hospital: { id: undefined },
    parentsData: [
      {
        parentType: "MOTHER",
        firstName: "",
        lastName: "",
        maidenName: "",
        embg: "",
        dateBirth: "",
        citizenship: defaultCountry,
        nationality: { id: undefined },
        educattion: { id: undefined },
        schoolName: "",
        occupation: { id: undefined },
        workingPosition: "",
        livingAddress: "",
        livingCountry: { id: undefined },
        livingPlace: { id: undefined },
        birthCountry: defaultCountry,
        birthPlace: { id: undefined },
        idDocumentNumber: "",
        idIssuingDate: "",
        idExpiryDate: "",
        phoneNumber: "",
      },
      {
        parentType: "FATHER",
        firstName: "",
        lastName: "",
        maidenName: "",
        embg: "",
        dateBirth: "",
        citizenship: defaultCountry,
        nationality: { id: undefined },
        educattion: { id: undefined },
        schoolName: "",
        occupation: { id: undefined },
        workingPosition: "",
        livingAddress: "",
        livingCountry: { id: undefined },
        livingPlace: { id: undefined },
        birthCountry: defaultCountry,
        birthPlace: { id: undefined },
        idDocumentNumber: "",
        idIssuingDate: "",
        idExpiryDate: "",
        phoneNumber: "",
      },
    ],
  });

  function getSteps() {
    if (formData.bornInMarriage == 0) {
      return [
        LOCALE.newborn_information,
        LOCALE.marriage_data,
        LOCALE.mother_information,
        LOCALE.father_information,
        LOCALE.documents,
        LOCALE.preview,
        LOCALE.payment,
      ];
    } else {
      return [
        LOCALE.newborn_information,
        LOCALE.marriage_data,
        LOCALE.mother_information,
        LOCALE.documents,
        LOCALE.preview,
        LOCALE.payment,
      ];
    }
  }

  const steps = getSteps();

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    let icons = {};

    if (formData.bornInMarriage == 0) {
      icons = {
        1: <Icon>child_friendly</Icon>,
        2: <Icon>favorite</Icon>,
        3: <PermIdentityIcon />,
        4: <PermIdentityIcon />,
        5: <Icon>post_add</Icon>,
        6: <InfoIcon />,
        7: <CreditCardIcon />,
      };
    } else {
      icons = {
        1: <Icon>child_friendly</Icon>,
        2: <Icon>favorite</Icon>,
        3: <PermIdentityIcon />,
        4: <Icon>post_add</Icon>,
        5: <InfoIcon />,
        6: <CreditCardIcon />,
      };
    }

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  useEffect(() => {
    if (!AuthService.getCurrentUser()) {
      if (window.location.search.includes("eid=true")) {
        window.location.href = `https://eid.mk/issue/wsfed?wa=wsignin1.0&wtrealm=https://e-portal.uvmk.gov.mk/&wctx=redirectUrl=${window.location.pathname}&id=passive&ru=%2f&wreply=https://e-portal.uvmk.gov.mk/login_token_eid`;
      } else {
        setRedirectTo("/login");
        return;
      }
    } else {
      if (
        !AuthService.getCurrentUser() ||
        !AuthService.getCurrentUser()?.nationalEid
      ) {
        setRedirectTo("/login");
        return;
      }
      setLoading(true);
      if (id) {
        ApplicationUvmkPortalRepository.getById(id).then(
          (response) => {
            // console.log(response);
            setFormData(response.data);
            if (response?.data?.bornInMarriage == 0) {
              setActiveStep(5);
            } else {
              setActiveStep(4);
            }
            setLoading(false);
          },
          (error) => {
            console.log(error);
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
      }
      UsersRepository.fetchCurrentUser().then(
        (res) => {
          setCurrentUser(res.data);
        },
        (err) => {
          if (err.message === "Network Error") {
            setGlobalFormError(ErrorMessageResolver.resolve(err));
          } else {
            AuthRepository.logout();
          }
        }
      );
      getActiveRegistryAreas();
    }
  }, []);

  useEffect(() => {
    if (currentUser != undefined) {
      // console.log(currentUser);
      let parentType = currentUser?.gender == "MALE" ? "FATHER" : "MOTHER";
      let tmp = { ...formData };
      tmp.parentsData[getParentIndex(parentType)].embg = currentUser?.embg;
      tmp.parentsData[getParentIndex(parentType)].firstName =
        currentUser?.firstName;
      tmp.parentsData[getParentIndex(parentType)].lastName =
        currentUser?.lastName;
      tmp.parentsData[getParentIndex(parentType)].maidenName =
        currentUser?.lastName;
      tmp.parentsData[getParentIndex(parentType)].birthPlace =
        currentUser?.birthPlace;
      tmp.parentsData[getParentIndex(parentType)].livingCountry =
        currentUser?.countryLiving;
      tmp.parentsData[getParentIndex(parentType)].livingPlace =
        currentUser?.placeLiving;
      tmp.parentsData[getParentIndex(parentType)].livingAddress =
        currentUser?.addressLiving;
      tmp.parentsData[getParentIndex(parentType)].dateBirth =
        EmbgToDate.getDate(currentUser?.embg);
      setFormData(tmp);
    }
  }, [currentUser]);

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  useEffect(() => {
    if (livingCountrySearch?.length > 1) {
      getCountries(livingCountrySearch);
    }
  }, [livingCountrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  useEffect(() => {
    if (marriagePlaceSearch?.length > 1) {
      getPlaces(marriagePlaceSearch);
    }
  }, [marriagePlaceSearch]);

  useEffect(() => {
    if (livingPlaceSearch?.length > 1) {
      getPlaces(livingPlaceSearch);
    }
  }, [livingPlaceSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (citizenshipSearch?.length > 1) {
      getCountries(citizenshipSearch);
    }
  }, [citizenshipSearch]);

  useEffect(() => {
    if (nationalitySearch?.length > 1) {
      getNationalities(nationalitySearch);
    }
  }, [nationalitySearch]);

  const getNationalities = (name) => {
    NationalityRepository.getNationalities(name).then(
      (res) => {
        setNationalities(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (educationSearch?.length > 1) {
      getEducations(educationSearch);
    }
  }, [educationSearch]);

  const getEducations = (name) => {
    EducationRepository.getEducations(name).then((res) => {
      setEducations(res.data);
    });
  };

  useEffect(() => {
    if (occupationSearch?.length > 2) {
      getOccupations(occupationSearch);
    }
  }, [occupationSearch]);

  const getOccupations = (search) => {
    OccupationRepository.getOccupations(search)
      .then((res) => {
        setOccupations(res.data);
      })
      .catch((err) => setGlobalFormError(ErrorMessageResolver.resolve(err)));
  };

  const getActiveRegistryAreas = () => {
    RegistryAreaRepository.getActiveRegistryAreas().then((res) => {
      setRegistryAreas(res.data);
    });
  };

  const handleChange = (name, value) => {
    setFormData(_.set({ ...formData }, name, value));
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  const handleNextStep = () => {
    // console.log(activeStep);
    if (activeStep == 0) {
      validateNewbornData();
      if (!NewbornDataValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep == 1) {
      if (formData.bornInMarriage == 0) {
        validateMarriageData();
        if (!MarriageDetailsValidator.isValidSync(formData)) {
          return;
        } else {
          setActiveStep(activeStep + 1);
        }
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep == 2) {
      validateMotherData();
      if (!MotherDataValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep == 3) {
      if (formData.bornInMarriage == 0) {
        validateFatherData();
        if (!FatherDataValidator.isValidSync(formData)) {
          return;
        } else {
          setActiveStep(activeStep + 1);
        }
      } else {
        validateMotherDocuments();
        if (
          !MotherDocumentsValidator.isValidSync({
            formData,
            motherUploadedDocs,
          })
        ) {
          return;
        } else {
          setActiveStep(activeStep + 1);
        }
      }
    } else if (activeStep == 4) {
      if (formData.bornInMarriage == 1) {
        handleCreate();
      } else {
        validateApplicationUvmkDocuments();
        if (
          !ApplicationUvmkDocumentsValidator.isValidSync({
            formData,
            motherUploadedDocs,
            fatherUploadedDocs,
          })
        ) {
          return;
        } else {
          setActiveStep(activeStep + 1);
        }
      }
    } else if (activeStep == 5) {
      // console.log(formData);
      if (formData.bornInMarriage == 0) {
        handleCreate();
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setFormDataErrors({});
    }
  };

  const handlePrevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setFormDataErrors({});
  };

  const getParentIndex = (parentType) => {
    return formData.parentsData.findIndex(
      (parent) => parent.parentType === parentType
    );
  };

  const handleCreate = () => {
    setLoading(true);
    let tmp = { ...formData };
    tmp.bornInMarriage = tmp.bornInMarriage == 1 ? true : false;
    if (tmp.bornInMarriage) {
      //remove father from parentsData
      tmp.parentsData.splice(getParentIndex("FATHER"), 1);
      delete tmp.dateMarriage;
      delete tmp.countryMarriage;
      delete tmp.placeMarriage;
    }
    //check if parentsData, each parent has birthPlace with id, if not, set it to null, be aware that birthPlace could be null
    tmp.parentsData.forEach((parent) => {
      if (parent.birthPlace?.id == undefined) {
        parent.birthPlace = null;
      }
    });
    ApplicationUvmkPortalRepository.create(
      tmp,
      fatherUploadedDocs,
      motherUploadedDocs
    ).then(
      (res) => {
        setLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setFormData(res.data);
        // console.log(res);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };

  const approveApplication = () => {
    setLoading(true);
    ApplicationUvmkPortalRepository.approveApplicationUVMKPortalById(
      formData.id
    ).then(
      (res) => {
        setLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setFormData(res.data);
        // console.log(res);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  };

  const validateNewbornData = () => {
    if (!NewbornDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await NewbornDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateMarriageData = () => {
    if (!MarriageDetailsValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await MarriageDetailsValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateMotherData = () => {
    if (!MotherDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await MotherDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          console.log(validationErrors);
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateFatherData = () => {
    if (!FatherDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await FatherDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateMotherDocuments = () => {
    if (
      !MotherDocumentsValidator.isValidSync({
        formData,
        motherUploadedDocs,
      })
    ) {
      var validationErrors = {};
      (async () => {
        await MotherDocumentsValidator.validate(
          {
            formData,
            motherUploadedDocs,
          },
          {
            abortEarly: false,
          }
        ).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateApplicationUvmkDocuments = () => {
    if (
      !ApplicationUvmkDocumentsValidator.isValidSync({
        formData,
        motherUploadedDocs,
        fatherUploadedDocs,
      })
    ) {
      var validationErrors = {};
      (async () => {
        await ApplicationUvmkDocumentsValidator.validate(
          {
            formData,
            motherUploadedDocs,
            fatherUploadedDocs,
          },
          {
            abortEarly: false,
          }
        ).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  useEffect(() => {
    // console.log(formDataErrors);
  }, [formDataErrors]);

  useEffect(() => {
    // console.log(formData);
  }, [formData]);
  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />
        <Container>
          <Grid container>
            <Grid item md={12} style={{ marginBottom: "20px" }}>
              <Typography variant="h5">
                {LOCALE.newborn_registration_and_issuanse_of_birth_certificate}
              </Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: "0px" }}
                    display="flex"
                  >
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                          ></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      backgroundColor: "rgb(212 212 212 / 0.85)",
                      textAlign: "center",
                      marginTop: "-20px",
                      marginBottom: "20px",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      <strong>{steps[activeStep]}</strong>
                    </Typography>
                  </Grid>
                  {globalFormError && (
                    <React.Fragment>
                      <Hidden xsDown>
                        <Grid item xs={12} md={3}></Grid>
                      </Hidden>
                      <Grid item xs={12} md={6}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                      <Hidden xsDown>
                        <Grid item xs={12} md={3}></Grid>
                      </Hidden>
                    </React.Fragment>
                  )}
                  {activeStep === 0 && (
                    <React.Fragment>
                      <ApplicationUvmkNewbornData
                        handleChange={handleChange}
                        formData={formData}
                        formDataErrors={formDataErrors}
                        loading={loading}
                        currentUser={currentUser}
                        deliveryRegistryAreaSearch={deliveryRegistryAreaSearch}
                        setDeliveryRegistryAreaSearch={
                          setDeliveryRegistryAreaSearch
                        }
                        registryAreas={registryAreas}
                        places={places}
                        placeSearch={placeSearch}
                        setPlaceSearch={setPlaceSearch}
                      ></ApplicationUvmkNewbornData>
                    </React.Fragment>
                  )}
                  {activeStep === 1 && (
                    <React.Fragment>
                      <ApplicationUvmkMarriageDetails
                        handleChange={handleChange}
                        formData={formData}
                        formDataErrors={formDataErrors}
                        loading={loading}
                        currentUser={currentUser}
                        deliveryRegistryAreaSearch={deliveryRegistryAreaSearch}
                        setDeliveryRegistryAreaSearch={
                          setDeliveryRegistryAreaSearch
                        }
                        registryAreas={registryAreas}
                        marriageCountrySearch={marriageCountrySearch}
                        setMarriageCountrySearch={setMarriageCountrySearch}
                        countries={countries}
                        marriagePlaceSearch={marriagePlaceSearch}
                        setMarriagePlaceSearch={setMarriagePlaceSearch}
                        places={places}
                      ></ApplicationUvmkMarriageDetails>
                    </React.Fragment>
                  )}
                  {activeStep === 2 && (
                    <React.Fragment>
                      <ApplicationUvmkPortalParentsData
                        handleChange={handleChange}
                        getParentIndex={getParentIndex}
                        formData={formData}
                        formDataErrors={formDataErrors}
                        countrySearch={countrySearch}
                        setCountrySearch={setCountrySearch}
                        onFocus={onFocus}
                        countries={countries}
                        defaultCountry={defaultCountry}
                        placeSearch={placeSearch}
                        setPlaceSearch={setPlaceSearch}
                        places={places}
                        parentType={"MOTHER"}
                        citizenshipSearch={citizenshipSearch}
                        setCitizenshipSearch={setCitizenshipSearch}
                        educations={educations}
                        setEducationSearch={setEducationSearch}
                        educationSearch={educationSearch}
                        nationalities={nationalities}
                        setNationalitySearch={setNationalitySearch}
                        nationalitySearch={nationalitySearch}
                        occupationSearch={occupationSearch}
                        setOccupationSearch={setOccupationSearch}
                        occupations={occupations}
                        livingCountrySearch={livingCountrySearch}
                        setLivingCountrySearch={setLivingCountrySearch}
                        livingPlaceSearch={livingPlaceSearch}
                        setLivingPlaceSearch={setLivingPlaceSearch}
                      ></ApplicationUvmkPortalParentsData>
                    </React.Fragment>
                  )}
                  {activeStep === 3 && (
                    <React.Fragment>
                      {formData.bornInMarriage == 0 ? (
                        <ApplicationUvmkPortalParentsData
                          handleChange={handleChange}
                          getParentIndex={getParentIndex}
                          formData={formData}
                          formDataErrors={formDataErrors}
                          countrySearch={countrySearch}
                          setCountrySearch={setCountrySearch}
                          onFocus={onFocus}
                          countries={countries}
                          defaultCountry={defaultCountry}
                          placeSearch={placeSearch}
                          setPlaceSearch={setPlaceSearch}
                          places={places}
                          parentType={"FATHER"}
                          citizenshipSearch={citizenshipSearch}
                          setCitizenshipSearch={setCitizenshipSearch}
                          educations={educations}
                          setEducationSearch={setEducationSearch}
                          educationSearch={educationSearch}
                          nationalities={nationalities}
                          setNationalitySearch={setNationalitySearch}
                          nationalitySearch={nationalitySearch}
                          occupationSearch={occupationSearch}
                          setOccupationSearch={setOccupationSearch}
                          occupations={occupations}
                          livingCountrySearch={livingCountrySearch}
                          setLivingCountrySearch={setLivingCountrySearch}
                          livingPlaceSearch={livingPlaceSearch}
                          setLivingPlaceSearch={setLivingPlaceSearch}
                        ></ApplicationUvmkPortalParentsData>
                      ) : (
                        <ApplicationUvmkDocuments
                          handleChange={handleChange}
                          getParentIndex={getParentIndex}
                          formData={formData}
                          formDataErrors={formDataErrors}
                          fatherUploadedDocs={fatherUploadedDocs}
                          setFatherUploadedDocs={setFatherUploadedDocs}
                          motherUploadedDocs={motherUploadedDocs}
                          setMotherUploadedDocs={setMotherUploadedDocs}
                        ></ApplicationUvmkDocuments>
                      )}
                    </React.Fragment>
                  )}
                  {activeStep === 4 && (
                    <React.Fragment>
                      {formData.bornInMarriage == 0 ? (
                        <ApplicationUvmkDocuments
                          handleChange={handleChange}
                          getParentIndex={getParentIndex}
                          formData={formData}
                          formDataErrors={formDataErrors}
                          fatherUploadedDocs={fatherUploadedDocs}
                          setFatherUploadedDocs={setFatherUploadedDocs}
                          motherUploadedDocs={motherUploadedDocs}
                          setMotherUploadedDocs={setMotherUploadedDocs}
                        ></ApplicationUvmkDocuments>
                      ) : (
                        <ApplicationUvmkPreview
                          formData={formData}
                          getParentIndex={getParentIndex}
                        ></ApplicationUvmkPreview>
                      )}
                    </React.Fragment>
                  )}
                  {activeStep === 5 && (
                    <React.Fragment>
                      {formData.bornInMarriage == 0 ? (
                        <ApplicationUvmkPreview
                          formData={formData}
                          getParentIndex={getParentIndex}
                        ></ApplicationUvmkPreview>
                      ) : (
                        <React.Fragment>
                          <ApplicationUvmkPayment
                            formData={formData}
                            getParentIndex={getParentIndex}
                          ></ApplicationUvmkPayment>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  {activeStep === 6 && (
                    <React.Fragment>
                      {formData.bornInMarriage == 0 ? (
                        <ApplicationUvmkPayment
                          formData={formData}
                          getParentIndex={getParentIndex}
                        ></ApplicationUvmkPayment>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Divider></Divider>
                    {activeStep !== 0 &&
                      activeStep !== steps.length - 1 &&
                      !formData?.id && (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => handlePrevStep()}
                        >
                          {LOCALE.prev}
                        </Button>
                      )}
                    {activeStep !== steps.length - 1 && !formData?.id && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={loading || saving}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => {
                          handleNextStep();
                        }}
                      >
                        {LOCALE.next}
                      </Button>
                    )}
                    {formData?.id &&
                      formData?.status == "NEW" &&
                      formData?.proposer?.embg != currentUser?.embg && (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          disabled={loading || saving}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => {
                            approveApplication();
                          }}
                        >
                          Одобри
                        </Button>
                      )}
                    {formData?.id &&
                      (formData?.status == "APPROVED" ||
                        formData?.bornInMarriage == 1) &&
                      activeStep !== steps.length - 1 && (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          disabled={loading || saving}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => {
                            setActiveStep(
                              (prevActiveStep) => prevActiveStep + 1
                            );
                          }}
                        >
                          {LOCALE.pay}
                        </Button>
                      )}
                    {formData?.id &&
                      (formData?.status == "APPROVED" ||
                        formData?.bornInMarriage == 1) &&
                      activeStep === steps.length - 1 && (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => {
                            window.location.href = `${
                              SETTINGS.API_URL
                            }/payment/initialization?reasonType=appUVMKRequest&rel=${
                              formData.id
                            }&access_token=${
                              AuthService.getToken().access_token
                            }`;
                          }}
                        >
                          {LOCALE.iAgree}
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br></br> <br></br>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
