import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/components/headerLinkStyle";
import { AuthService } from "../modules/auth/AuthService";

const useStyles = makeStyles(styles);

export default function DrawerMenu(props) {
  const [redirectTo, setRedirectTo] = React.useState();
  const [voucherTypes, setVoucherTypes] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [openLanguage, setOpenLanguage] = React.useState(null);

  const handleCloseLanguage = (item) => {
    setOpenLanguage(null);
    changeLanguage(item);
  };

  const handleLogout = () => {
    window.localStorage.removeItem("currentUser");
    window.localStorage.removeItem("accessToken");
    window.location.href =
      "https://eid.mk/issue/wsfed?wa=wsignout1.0&wreply=https%3A%2F%2Fe-portal.uvmk.gov.mk";
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <Drawer
        anchor={props.anchor}
        open={props.open}
        className="mobilemenudrawer"
        onClose={props.onCloseHandler}
      >
        <List>
          {AuthService.getCurrentUser() !== null ? (
            <div>
              <ListItem
                button
                key="profile"
                onClick={() => setRedirectTo("/profile")}
              >
                <ListItemIcon>
                  <Icon>person</Icon>
                </ListItemIcon>
                <ListItemText primary={LOCALE.myProfile} />
              </ListItem>
              <ListItem
                button
                key="payment"
                onClick={() => setRedirectTo("/orders")}
              >
                <ListItemIcon>
                  <Icon>payment</Icon>
                </ListItemIcon>
                <ListItemText primary={LOCALE.myOrders} />
              </ListItem>
              <ListItem
                button
                key="payment"
                onClick={() => setRedirectTo("/appointments ")}
              >
                <ListItemIcon>
                  <Icon>payment</Icon>
                </ListItemIcon>
                <ListItemText primary={"Мои термини"} />
              </ListItem>
              <ListItem
                button
                key="payment"
                onClick={() => setRedirectTo("/births/applicationUvmk")}
              >
                <ListItemIcon>
                  <Icon>payment</Icon>
                </ListItemIcon>
                <ListItemText primary={"Новороденчиња"} />
              </ListItem>
            </div>
          ) : (
            <ListItem
              button
              key="login"
              onClick={() => setRedirectTo("/login")}
            >
              <ListItemIcon>
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary={LOCALE.login} />
            </ListItem>
          )}
          <ListItem
            button
            key="priceList"
            onClick={() => setRedirectTo("/priceList")}
          >
            <ListItemIcon>
              <Icon>euro</Icon>
            </ListItemIcon>
            <ListItemText primary={LOCALE.priceList} />
          </ListItem>
          <Divider />
          {AuthService.getCurrentUser() === null && (
            <ListItem
              button
              key="registration"
              onClick={() => setRedirectTo("/signup")}
            >
              <ListItemIcon>
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary={LOCALE.registration} />
            </ListItem>
          )}
          {AuthService.getCurrentUser() !== null && (
            <ListItem button key="logout" onClick={() => handleLogout()}>
              <ListItemIcon>
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary={LOCALE.logout} />
            </ListItem>
          )}
        </List>
      </Drawer>
    </React.Fragment>
  );
}
