import MomentUtils from "@date-io/moment";
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { LOCALE } from "../../properties/Locale";

import { Alert, Autocomplete } from "@material-ui/lab";
import { moment } from "../../App";
import { Transliteration } from "../../common/helpers/Transliteration";
import ReactInputMask from "react-input-mask";

if (localStorage.getItem("locale") && localStorage.getItem("locale") === "al") {
  moment.locale("al");
} else {
  moment.locale("mk");
}

export default function RequestMarriageWizardMarraigeDetails({
  handleChange,
  formData,
  formDataErrors,
  onFocus,
  defaultCountry,
  setPlaceSearch,
  placeSearch,
  places,
  marriagePlaceSearch,
  setMarriagePlaceSearch,
  marriageLocations,
  marriageLocationSearch,
  setMarriageLocationSearch,
  marriageAppointments,
  disableAppointmentChoice,
}) {
  return (
    <React.Fragment>
      <Grid item xs={12} style={{ paddingBottom: "10px" }}>
        <Grid container>
          <Hidden smUp>
            <Grid item xs={12}>
              <Divider
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              ></Divider>
            </Grid>
          </Hidden>
          <Grid item xs={false} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Alert severity="info">
                  <strong>{LOCALE.appointment_information}</strong> -{" "}
                  {LOCALE.appointment_warning}
                </Alert>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={formDataErrors?.requestType}
                >
                  <InputLabel>{LOCALE.appointment_type}</InputLabel>
                  <Select
                    size="small"
                    value={formData?.requestType ? formData?.requestType : ""}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      handleChange("requestType", e.target.value);
                    }}
                    label={LOCALE.appointment_type}
                  >
                    {/* <MenuItem value={"REGULAR_INSIDE"} size="small">
                      Склучување на брак во управата
                    </MenuItem> */}
                    <MenuItem value={"REGULAR_OUTSIDE"} size="small">
                      Склучување на брак на терен/ресторан
                    </MenuItem>
                  </Select>
                  {formDataErrors?.requestType && (
                    <FormHelperText error={true}>
                      {formDataErrors.requestType}
                    </FormHelperText>
                  )}
                </FormControl>
                {formDataErrors?.requestType && (
                  <FormHelperText error={true}>
                    {formDataErrors.requestType}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      cancelLabel={LOCALE.cancel}
                      okLabel={LOCALE.ok}
                      disableToolbar
                      disablePast
                      format="DD/MM/YYYY"
                      margin="dense"
                      inputVariant="outlined"
                      id="bride-birth-date"
                      label={LOCALE.marriageDate}
                      value={
                        formData?.dateOfMarriage
                          ? formData?.dateOfMarriage
                          : null
                      }
                      onChange={(date) => handleChange(`dateOfMarriage`, date)}
                      error={formDataErrors.dateOfMarriage !== undefined}
                      helperText={formDataErrors.dateOfMarriage}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  value={
                    formData?.placeOfMarriage?.name
                      ? formData?.placeOfMarriage?.name
                      : ""
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `placeOfMarriage`,
                      places.filter((place) => place.name === newValue)[0]
                    );
                  }}
                  inputValue={marriagePlaceSearch}
                  onInputChange={(event, newInputValue) => {
                    setMarriagePlaceSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={places?.map((place) => place.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.habitant_place}
                      fullWidth
                      variant="outlined"
                      error={formDataErrors["placeOfMarriage.id"] !== undefined}
                      helperText={formDataErrors["placeOfMarriage.id"]}
                    />
                  )}
                />
              </Grid>
              {formData?.requestType === "REGULAR_INSIDE" && (
                <Grid item xs={12}>
                  <Autocomplete
                    noOptionsText={LOCALE.noOptionsText}
                    size="small"
                    value={
                      formData?.marriageLocation?.name
                        ? formData?.marriageLocation?.name
                        : ""
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "marriageLocation",
                        marriageLocations.filter(
                          (location) => location.name === newValue
                        )[0]
                      );
                    }}
                    inputValue={marriageLocationSearch}
                    onInputChange={(event, newInputValue) => {
                      setMarriageLocationSearch(
                        Transliteration.toUpperCyrilic(newInputValue)
                      );
                    }}
                    options={marriageLocations?.map(
                      (location) => location.name
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={LOCALE.premises}
                        fullWidth
                        variant="outlined"
                        error={formDataErrors["marriageLocation"] !== undefined}
                        helperText={formDataErrors["marriageLocation"]}
                      />
                    )}
                  />
                </Grid>
              )}
              {formData?.requestType === "REGULAR_OUTSIDE" && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={LOCALE.location}
                    size="small"
                    value={
                      formData?.marriageLocationManual
                        ? formData?.marriageLocationManual
                        : ""
                    }
                    onChange={(e) =>
                      handleChange(
                        `marriageLocationManual`,
                        Transliteration.toUpperCyrilic(e.target.value)
                      )
                    }
                    error={
                      formDataErrors["marriageLocationManual"] !== undefined
                    }
                    helperText={formDataErrors["marriageLocationManual"]}
                    variant="outlined"
                  ></TextField>
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <ReactInputMask
                  mask="HH:MM"
                  maskChar="_"
                  value={formData?.appointment ? formData?.appointment : ""}
                  onChange={(e) => {
                    handleChange("appointment", e.target.value);
                  }}
                  formatChars={{
                    H: "[0-9]",
                    M: "[0-9]",
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      label={LOCALE.select_appointment}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={formDataErrors.appointment !== undefined}
                      helperText={formDataErrors.appointment}
                    />
                  )}
                </ReactInputMask>
                {/* <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  disabled={disableAppointmentChoice}
                  error={formDataErrors?.appointment}
                >
                  <InputLabel>{LOCALE.select_appointment}</InputLabel>
                  <Select
                    size="small"
                    disabled={disableAppointmentChoice}
                    value={formData?.appointment ? formData?.appointment : ""}
                    onChange={(e) => {
                      console.log(e.target.value);
                      handleChange("appointment", e.target.value);
                    }}
                    label={LOCALE.select_appointment}
                  >
                    {marriageAppointments &&
                      marriageAppointments.map((element, index) => (
                        <MenuItem
                          key={index}
                          value={element?.appointment}
                          size="small"
                          disabled={!element.free}
                        >
                          {element?.appointment}
                        </MenuItem>
                      ))}
                  </Select>
                  {formDataErrors?.appointment && (
                    <FormHelperText error={true}>
                      {formDataErrors.appointment}
                    </FormHelperText>
                  )}
                </FormControl> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
