import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Divider,
  Grow,
  Paper,
  CircularProgress,
  LinearProgress,
  TextField,
  Hidden,
  TablePagination,
  TableRow,
  TableCell,
  Table,
  IconButton,
  Tooltip,
  Chip,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { moment } from "../../App";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";

const useStyles = makeStyles(styles);

export default function PaymentComponent(props) {
  const [redirectTo, setRedirectTo] = React.useState();
  const [deliveryType, setDeliveryType] = React.useState();
  const [type, setType] = React.useState();
  const [totalPrice, setTotalPrice] = React.useState();
  const [price, setPrice] = React.useState();
  const [postPrice, setPostPrice] = React.useState();
  const [administrativeFee, setAdministrativeFee] = React.useState();

  React.useEffect(() => {
    var deliveryType = props?.reqBirthCert
      ? props?.reqBirthCert.pickupWayCert
      : props?.reqMarriageCert
      ? props?.reqMarriageCert.pickUpWayCert
      : props?.reqDeathCert
      ? props?.reqDeathCert.pickupWayCert
      : props?.reqSingleMartialStatus
      ? props?.reqSingleMartialStatus.pickupWayCert
      : "PICKUP_WAY_UVMK";
    var type = props?.reqBirthCert
      ? "REQ_BIRTH_CERT"
      : props?.reqMarriageCert
      ? "REQ_MARR_CERT"
      : props?.reqDeathCert
      ? "REQ_DEATH_CERT"
      : props?.reqSingleMartialStatus
      ? "SINGLE_MARITAL_STATUS"
      : "UNKNOWN";

    var priceCalc = 0;
    var administrativeFeeCalc = 0;
    var pricePostCalc = 0;
    if (type === "SINGLE_MARITAL_STATUS") {
      priceCalc = 0;
      administrativeFeeCalc = 1050;
    } else {
      priceCalc = 50;
      administrativeFeeCalc = 100;
      if (AuthService.getCurrentUser().proposerType === "ORGANIZATION") {
        // priceCalc *= 0.5;
      }
    }
    if (deliveryType === "PICKUP_WAY_POSTOFFICE") {
      pricePostCalc = 100;
    }

    setPrice(priceCalc);
    setAdministrativeFee(administrativeFeeCalc);
    setPostPrice(pricePostCalc);
    setDeliveryType(deliveryType);
    setType(type);

    let totalPrice = 0;
    let fee = 0;

    if (
      Math.round((priceCalc + administrativeFeeCalc + pricePostCalc) * 0.09) >
      150
    ) {
      fee = 150;
    } else if (
      Math.round((priceCalc + administrativeFeeCalc + pricePostCalc) * 0.09) <
      10
    ) {
      fee = 10;
    } else {
      fee = Math.round(
        (priceCalc + administrativeFeeCalc + pricePostCalc) * 0.09
      );
    }

    totalPrice = Math.round(
      priceCalc + administrativeFeeCalc + pricePostCalc + fee
    );

    setTotalPrice(totalPrice);
  }, []);

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">{LOCALE.payment_terms_agreement_text}</Alert>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {LOCALE.requestType}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    {LOCALE.service}
                  </TableCell>
                  <Hidden xsDown>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      {LOCALE.administrative_tax}
                    </TableCell>
                  </Hidden>
                  <Hidden xsDown>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>
                      {LOCALE.commission}
                    </TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ borderBottom: "none" }}
                  >
                    {LOCALE.request_for_cert +
                      " (" +
                      (props?.reqBirthCert
                        ? LOCALE.born
                        : props?.reqMarriageCert
                        ? LOCALE.married
                        : props?.reqDeathCert
                        ? LOCALE.dead
                        : props?.reqSingleMartialStatus
                        ? LOCALE.single_marital_status
                        : "Unknown") +
                      ") - " +
                      (props?.reqBirthCert?.typeRequest
                        ? LOCALE.getString(props?.reqBirthCert?.typeRequest)
                        : props?.reqDeathCert?.typeRequest
                        ? LOCALE.getString(props?.reqDeathCert?.typeRequest)
                        : props?.reqMarriageCert?.typeRequest
                        ? LOCALE.getString(props?.reqMarriageCert?.typeRequest)
                        : props?.reqSingleMartialStatus?.typeRequest
                        ? LOCALE.getString(
                            props?.reqSingleMartialStatus?.typeRequest
                          )
                        : "Unknown") +
                      " " +
                      LOCALE.for +
                      " " +
                      (props?.reqBirthCert?.firstName
                        ? props?.reqBirthCert?.firstName
                        : props?.reqDeathCert?.firstName
                        ? props?.reqDeathCert?.firstName
                        : props?.reqSingleMartialStatus?.firstName
                        ? props?.reqSingleMartialStatus?.firstName
                        : props?.reqMarriageCert?.groomFirstName
                        ? props?.reqMarriageCert?.groomFirstName +
                          "/" +
                          props?.reqMarriageCert?.brideFirstName
                        : "unknown") +
                      " " +
                      (props?.reqBirthCert?.lastName
                        ? props?.reqBirthCert?.lastName
                        : props?.reqDeathCert?.lastName
                        ? props?.reqDeathCert?.lastName
                        : props?.reqSingleMartialStatus?.lastName
                        ? props?.reqSingleMartialStatus?.lastName
                        : props?.reqMarriageCert?.groomLastName
                        ? props?.reqMarriageCert?.groomLastName +
                          "/" +
                          props?.reqMarriageCert?.brideLastName
                        : "unknown") +
                      " " +
                      LOCALE.with_EMBG +
                      " " +
                      (props?.reqBirthCert?.embg
                        ? props?.reqBirthCert?.embg
                        : props?.reqDeathCert?.embg
                        ? props?.reqDeathCert?.embg
                        : props?.reqSingleMartialStatus?.embg
                        ? props?.reqSingleMartialStatus?.embg
                        : props?.reqMarriageCert?.groomEmbg
                        ? props?.reqMarriageCert?.groomEmbg +
                          "/" +
                          props?.reqMarriageCert?.brideEmbg
                        : "unknown")}
                  </TableCell>
                  <TableCell align="center" style={{ borderBottom: "none" }}>
                    {price}
                  </TableCell>
                  <Hidden xsDown>
                    <TableCell align="center">{administrativeFee}</TableCell>
                  </Hidden>
                  <Hidden xsDown>
                    <TableCell align="center">9%</TableCell>
                  </Hidden>
                </TableRow>
                <TableRow>
                  <Hidden xsDown>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell align="center"></TableCell>
                  </Hidden>
                  <TableCell align="center">{LOCALE.commission}</TableCell>
                  <TableCell align="center">
                    {(administrativeFee + postPrice + price) * 0.09 < 10
                      ? 10
                      : (administrativeFee + postPrice + price) * 0.09 > 150
                      ? 150
                      : Math.round(
                          (administrativeFee + postPrice + price) * 0.09
                        )}
                  </TableCell>
                </TableRow>
                {deliveryType === "PICKUP_WAY_POSTOFFICE" && (
                  <TableRow>
                    <Hidden xsDown>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell align="center"></TableCell>
                    </Hidden>
                    <TableCell align="center">{LOCALE.post_office}</TableCell>
                    <TableCell align="center">{postPrice}</TableCell>
                  </TableRow>
                )}
                <Hidden smUp>
                  <TableRow>
                    <Hidden xsDown>
                      <TableCell component="th" scope="row"></TableCell>
                      <TableCell align="center"></TableCell>
                    </Hidden>
                    <TableCell align="center">
                      {LOCALE.administrative_tax}
                    </TableCell>
                    <TableCell align="center">{administrativeFee}</TableCell>
                  </TableRow>
                </Hidden>
                <TableRow>
                  <Hidden xsDown>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell align="center"></TableCell>
                  </Hidden>
                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    {LOCALE.total_amount}
                  </TableCell>

                  <TableCell align="center" style={{ fontWeight: "bold" }}>
                    {totalPrice}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Alert
            style={{ cursor: "help" }}
            onClick={() => window.open("/PaymentPolicy.pdf", "_blank")}
          >
            {LOCALE.payment_policy}
          </Alert>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
