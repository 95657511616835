import React from "react";
import ReactDOM from "react-dom";
import App, { moment } from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./common/reducers/MainReducer";
import httpService from "./api/HttpClient";
import {
  Route,
  Redirect,
  BrowserRouter,
  useHistory,
  useLocation,
  Switch,
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ForbidenComponent from "./modules/error/ForbidenComponent";
import IndexPageComponent from "./modules/index/IndexPageComponent";
import LoginComponent from "./modules/auth/LoginComponent";
import SignUpComponent from "./modules/auth/SignUpComponent";
import UserProfileComponent from "./modules/profile/UserProfileComponent";
import RequestBirthCertificateComponent from "./modules/birth/RequestBirthCertificateWizard";
import GlobalNotificationSnackbar from "./common/GlobalNotificationSnackbar";
import PasswordChangeComponent from "./modules/profile/PasswordChangeComponent";
import { createBrowserHistory } from "history";
import OrdersComponent from "./modules/orders/OrdersComponent";
import RequestBirthCertificateEdit from "./modules/birth/RequestBirthCertificateEdit";
import PriceListComponent from "./modules/priceList/PriceListComponent";
import ForgotPasswordComponent from "./modules/auth/ForgotPasswordComponent";
import ResetPasswordComponent from "./modules/auth/ResetPasswordComponent";
import VerifyRegistrationComponent from "./modules/auth/VerifyRegistrationComponent";
import RequestDeathCertificateWizard from "./modules/death/RequestDeathCertificateWizard";
import RequestMarriageCertificateComponent from "./modules/marriages/RequestMarriageCertificateWizard";
import RequestMarriageCertificateEdit from "./modules/marriages/RequestMarriageCertificateEdit";
import RequestDeathCertificateEdit from "./modules/death/RequestDeathCertificateEdit";
import NetworkErrorCompoenent from "./modules/error/NetworkErrorComponent";
import PaymentResponseCanceledComponent from "./modules/payment/PaymentResponseCanceledComponent";
import PaymentResponseSuccessComponent from "./modules/payment/PaymentResponseSuccessComponent";
import PaymentResponseErrorComponent from "./modules/payment/PaymentResponseErrorComponent";
import RequestSingleMartialStatusWizzard from "./modules/birth/RequestSingleMaritalStatusWizzard";
import LoginTokenEidComponent from "./modules/auth/LoginTokenEidComponent";
import RequestSingleMartialStatusEdit from "./modules/birth/RequestSingleMartialStatusEdit";
import { EmbgToDate } from "./common/helpers/EmbgToDate";
import VerifyDigitalCertificate from "./common/Verify";
import RedirectFromExternalHomePage from "./common/Redirect";
import LoginTokenComponent from "./modules/auth/LoginTokenComponent";
import ForgotTokenComponent from "./modules/auth/ForgotTokenComponent";
import ResetCertificateComponent from "./modules/auth/ResetCertificateComponent";
import RequestMarriageComponent from "./modules/marriages/RequestMarriageWizard";
import AppointmentsComponent from "./modules/orders/AppointmentsComponent";
import ApplicationUVMKPortal from "./modules/birthApplicationPortal/ApplicationUVMKPortal";
import ApplicationUvmk from "./modules/orders/ApplicationUvmk";
import RequestForInformation from "./modules/requestForInformation/RequestForInformation";
import RequestForInformationList from "./modules/requestForInformation/RequestForInformationList";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#70c1c2",
    },
    secondary: {
      main: "#ee6c56",
    },
    warning: {
      main: "#f5a623",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontSize: 12.5,
  },
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
  },
});

const store = createStore(reducer);
httpService.setupInterceptors(store);
const history = createBrowserHistory({ forceRefresh: true });

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter history={history}>
        <GlobalNotificationSnackbar />
        <Route
          path="/loginTokenEid"
          exact={true}
          component={LoginTokenEidComponent}
        ></Route>
        <Route
          path="/loginToken"
          exact={true}
          component={LoginTokenComponent}
        ></Route>
        <Route
          path="/forgotToken"
          exact={true}
          component={ForgotTokenComponent}
        ></Route>
        <Route path="/" exact={true} component={IndexPageComponent}></Route>
        <Route
          path="/external/verify/certificate/:id"
          exact={true}
          component={VerifyDigitalCertificate}
        ></Route>
        <Route
          path="/births/requestBirthCertificateWizard"
          exact={true}
          component={RequestBirthCertificateComponent}
        />
        <Route
          path="/births/requestBirthCertificate/:id"
          exact={true}
          component={RequestBirthCertificateEdit}
        />
        <Route
          path="/births/applicationUvmkPortalWizzard"
          exact={true}
          component={ApplicationUVMKPortal}
        />
        <Route
          path="/births/applicationUvmkPortalWizzard/:id"
          exact={true}
          component={ApplicationUVMKPortal}
        />
        <Route
          path="/births/applicationUvmk"
          exact={true}
          component={ApplicationUvmk}
        />
        <Route
          path="/marriages/requestMarriageCertificateWizard"
          exact={true}
          component={RequestMarriageCertificateComponent}
        />
        <Route
          path="/marriages/requestMarriageWizard"
          exact={true}
          component={RequestMarriageComponent}
        />
        <Route
          path="/marriages/requestMarriageWizard/:id"
          exact={true}
          component={RequestMarriageComponent}
        />
        <Route
          path="/marriages/requestMarriageCertificate/:id"
          exact={true}
          component={RequestMarriageCertificateEdit}
        />
        <Route
          path="/deaths/requestDeathCertificateWizard"
          exact={true}
          component={RequestDeathCertificateWizard}
        />
        <Route
          path="/deaths/requestDeathCertificate/:id"
          exact={true}
          component={RequestDeathCertificateEdit}
        />
        <Route
          path="/births/requestSingleMartialStatusWizzard"
          exact={true}
          component={RequestSingleMartialStatusWizzard}
        />
        <Route
          path="/births/requestSingleMartialStatus/:id"
          exact={true}
          component={RequestSingleMartialStatusEdit}
        />
        <Route path="/login" exact={true} component={LoginComponent}></Route>
        <Route path="/signup" exact={true} component={SignUpComponent}></Route>
        <Route
          path="/forgotPassword"
          exact={true}
          component={ForgotPasswordComponent}
        ></Route>
        <Route
          path="/resetPassword/:id"
          exact={true}
          component={ResetPasswordComponent}
        ></Route>
        <Route
          path="/resetCertificate/:id"
          exact={true}
          component={ResetCertificateComponent}
        ></Route>
        <Route
          path="/verification/:id"
          exact={true}
          component={VerifyRegistrationComponent}
        ></Route>
        <Route
          path="/payment/canceled"
          exact={true}
          component={PaymentResponseCanceledComponent}
        ></Route>
        <Route
          path="/payment/success"
          exact={true}
          component={PaymentResponseSuccessComponent}
        ></Route>
        <Route
          path="/payment/error"
          exact={true}
          component={PaymentResponseErrorComponent}
        ></Route>
        <Route
          path="/profile"
          exact={true}
          component={UserProfileComponent}
        ></Route>
        <Route path="/orders" exact={true} component={OrdersComponent} />
        <Route
          path="/appointments"
          exact={true}
          component={AppointmentsComponent}
        />
        <Route path="/priceList" exact={true} component={PriceListComponent} />
        <Route
          path="/passwordChange"
          exact={true}
          component={PasswordChangeComponent}
        ></Route>
        <Route
          path="/requestForInformation"
          exact={true}
          component={RequestForInformation}
        ></Route>
        <Route
          path="/requestForInformation/:id"
          exact={true}
          component={RequestForInformation}
        ></Route>
        <Route
          path="/requestForInformationList"
          exact={true}
          component={RequestForInformationList}
        ></Route>

        {/* ======================= ERRORS ROUTE ======================= */}
        <Route exact path="/error/403" component={ForbidenComponent} />
        <Route exact path="/error/network" component={NetworkErrorCompoenent} />
        <Route
          exact
          path="/external/portalHomePage"
          component={RedirectFromExternalHomePage}
        ></Route>
        <Route
          exact
          path="/external/portalRegistration"
          component={RedirectFromExternalHomePage}
        ></Route>
        <Route path="/file" component={RedirectFromExternalHomePage}></Route>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
