import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const RequestMarriageRepository = {
  getByDateOfMarriage: (dateOfMarriage, registryAreaId) => {
    return Axios({
      url: `${SETTINGS.API_URL}marriages/requests/dateOfMarriage`,
      method: "GET",
      params: {
        dateOfMarriage: dateOfMarriage,
        registryAreaId: registryAreaId,
      },
    });
  },
};
