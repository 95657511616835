import React from "react";
import {
  Grid,
  Typography,
  Container,
  Button,
  Grow,
  Paper,
  LinearProgress,
  TextField,
  Hidden,
  Divider,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";
import { UsersRepository } from "../../repositories/UsersRepository";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { notifyShowSuccessMessage } from "../../common/actions/CommonActions";
import { PasswordChangeValidator } from "./PasswordChangeValidator";
import { AuthRepository } from "../auth/AuthRepository";

const useStyles = makeStyles(styles);

export default function PasswordChangeComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [globalFormError, setGlobalFormError] = React.useState();
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (AuthService.getCurrentUser() == null) {
      setRedirectTo("/login");
    }
  }, []);

  const handleLogout = () => {
    AuthRepository.logout();
    window.location.href =
      "https://eid.mk/issue/wsfed?wa=wsignout1.0&wreply=https%3A%2F%2Fe-portal.uvmk.gov.mk";
  };

  const handleSubmit = () => {
    setGlobalFormError();
    setLoading(true);
    validateFormData();
    if (PasswordChangeValidator.isValidSync(formData)) {
      UsersRepository.changePassword(formData)
        .then((res) => {
          dispatch(
            notifyShowSuccessMessage(LOCALE.password_changed_successfully)
          );
          setFormData({});
          setLoading(false);
          handleLogout();
        })
        .catch((err) => {
          setLoading(false);
          setGlobalFormError(ErrorMessageResolver.resolve(err));
        });
    } else {
      validateFormData();
    }
  };

  const validateFormData = () => {
    if (!PasswordChangeValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await PasswordChangeValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
      setLoading(true);
    }
  };

  const handleChangeFormData = (name, value) => {
    let tmp = { ...formData };
    tmp[name] = value;
    setFormData(tmp);
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">{LOCALE.passwordChange}</Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "20px" }}>
                  {globalFormError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{globalFormError}</Alert>
                    </Grid>
                  )}
                  <Grid md={3}></Grid>
                  <Grid md={6}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Hidden smDown>
                        <br />
                      </Hidden>
                      <Grid container spacing={2}>
                        <Grow in={true} timeout={800}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              label={LOCALE.oldPassword}
                              size="small"
                              disabled={loading}
                              variant="outlined"
                              type="password"
                              autoFocus
                              fullWidth={true}
                              error={formDataErrors.oldPassword !== undefined}
                              helperText={formDataErrors.oldPassword}
                              onChange={(e) => {
                                handleChangeFormData(
                                  "oldPassword",
                                  e.target.value
                                );
                              }}
                              value={
                                formData?.oldPassword
                                  ? formData.oldPassword
                                  : ""
                              }
                            />
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={950}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              label={LOCALE.newPassword}
                              size="small"
                              disabled={loading}
                              variant="outlined"
                              type="password"
                              fullWidth={true}
                              error={formDataErrors.newPassword !== undefined}
                              helperText={formDataErrors.newPassword}
                              onChange={(e) => {
                                handleChangeFormData(
                                  "newPassword",
                                  e.target.value
                                );
                              }}
                              value={
                                formData?.newPassword
                                  ? formData.newPassword
                                  : ""
                              }
                            />
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1100}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              label={LOCALE.confirmPassword}
                              size="small"
                              disabled={loading}
                              variant="outlined"
                              type="password"
                              fullWidth={true}
                              error={
                                formDataErrors.confirmPassword !== undefined
                              }
                              helperText={formDataErrors.confirmPassword}
                              onChange={(e) => {
                                handleChangeFormData(
                                  "confirmPassword",
                                  e.target.value
                                );
                              }}
                              value={
                                formData?.confirmPassword
                                  ? formData.confirmPassword
                                  : ""
                              }
                            />
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1250}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginBottom: "10px" }}
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => handleSubmit()}
                            >
                              {LOCALE.save}
                            </Button>
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1900}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ marginBottom: "10px" }}
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => setRedirectTo("/profile")}
                            >
                              {LOCALE.myProfile}
                            </Button>
                          </Grid>
                        </Grow>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}
