import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { SETTINGS } from "../properties/ApiProps";
import { PropagateLoader } from "react-spinners";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";

export default function VerifyDigitalCertificate() {
  const { id } = useParams();
  const [recaptcha, setRecaptcha] = React.useState();
  const [recaptchaToken, setRecaptchaToken] = React.useState();

  useEffect(() => {
    loadReCaptcha("6LfhEwQcAAAAANpzc7aB0cAYpJFkbQkgC4yCM8qu", verifyCallback);
  }, []);

  const verifyCallback = (recaptchaToken) => {
    setRecaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    recaptcha.execute();
  };

  useEffect(() => {
    if (id && recaptchaToken) {
      window.location.href = `${SETTINGS.API_URL}external/verify/certificate/${id}?g-recaptcha-response=${recaptchaToken}`;
    }
  }, [id, recaptchaToken]);

  return (
    <React.Fragment>
      <div
        style={{
          marginTop: "20%",
          textAlign: "center",
          display: "block",
        }}
      >
        <CircularProgress color="secondary" />
        <p>
          <strong>Процесирање...</strong>
        </p>
      </div>
      <ReCaptcha
        ref={(ref) => setRecaptcha(ref)}
        sitekey="6LfhEwQcAAAAANpzc7aB0cAYpJFkbQkgC4yCM8qu"
        action="request"
        verifyCallback={verifyCallback}
      />
    </React.Fragment>
  );
}
