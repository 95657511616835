import { Container, Grid, Icon, Typography } from "@material-ui/core";
import React from "react";
import Snake from "snake-game-react";

export default function NetworkErrorCompoenent() {
  return (
    <React.Fragment>
      <Container style={{ marginTop: "10vh" }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Icon style={{ fontSize: 80 }}>warning</Icon>
                <Typography variant="h4">Проблем со конекција</Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <Typography>
                  Настана проблем при воспоставување на конекција со серверите
                  на УВМК. Ве молиме обидете се подоцна.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Snake
                  color1="#6b6b6b"
                  color2="black"
                  backgroundColor="transparent"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
