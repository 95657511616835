import React, { useEffect } from "react";
import MomentUtils from "@date-io/moment";
import {
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Grow,
  Hidden,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { LOCALE } from "../../properties/Locale";
import Header from "../../common/Header";
import { Redirect } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import TelegramIcon from "@material-ui/icons/Telegram";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import clsx from "clsx";
import bgImg from "../../assets/img/productCurvyLines.png";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { CountryRepository } from "../../repositories/CountryRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { Transliteration } from "../../common/helpers/Transliteration";
import Footer from "../../common/Footer";
import { RegistryAreaRepository } from "../../repositories/RegistryAreaRepository";
import {
  ApplicantDocumentsValidator,
  BasicDataValidator,
  ParentsDataValidator,
  PickUpWayValidator,
} from "./RequestSingleMartialStatusValidator";
import { RequestSingleMartialStatusRepository } from "../../repositories/RequestSingleMartialStatusRepository";
import { moment } from "../../App";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import PaymentComponent from "../payment/PaymentComponent";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { AuthService } from "../auth/AuthService";
import { AuthRepository } from "../auth/AuthRepository";
import { SETTINGS } from "../../properties/ApiProps";
import { notifyShowErrorMessage } from "../../common/actions/CommonActions";
import Upload from "../../common/components/Upload";

if (localStorage.getItem("locale") && localStorage.getItem("locale") === "al") {
  moment.locale("al");
} else {
  moment.locale("mk");
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function getSteps() {
  return [
    LOCALE.basic_info,
    LOCALE.parents_info,
    LOCALE.pickup_way,
    LOCALE.purposer_info,
    LOCALE.payment,
  ];
}
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <InfoIcon />,
    2: <PermIdentityIcon />,
    3: <TelegramIcon />,
    4: <PermIdentityIcon />,
    5: <CreditCardIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
export default function RequestSingleMartialStatusWizzard() {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");

  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [registryAreas, setRegistryAreas] = React.useState([]);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [persistedRequest, setPersistedRequest] = React.useState();
  const [globalFormError, setGlobalFormError] = React.useState(null);
  const steps = getSteps();

  const [noFatherData, setNoFatherData] = React.useState(false);
  const [noMotherData, setNoMotherData] = React.useState(false);

  const [currentUser, setCurrentUser] = React.useState();
  const [formData, setFormData] = React.useState({
    embg: "",
    firstName: "",
    lastName: "",
    country: {
      countryCode: "807",
      id: "0",
      name: "СЕВЕРНА МАКЕДОНИЈА",
      nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      nameEn: "REPUBLIC OF NORTH MACEDONIA",
      printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
      printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      printNameEn: "REPUBLIC OF NORTH MACEDONIA",
    },
    placeBirth: { name: "" },
    placeBirthManual: "",
    registryArea: {},
    fatherName: "",
    fatherSurname: "",
    motherName: "",
    motherSurname: "",
    pickupWayCert: "PICKUP_WAY_UVMK",
    deliveryRegistryArea: {},
    passportDocumentNumber: "",
    passportExpiryDate: null,
    passportIssuingDate: null,
    idDocumentNumber: "",
    idExpiryDate: null,
    idIssuingDate: null,
  });
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");
  const [passportScan, setPassportScan] = React.useState([]);

  useEffect(() => {
    if (!AuthService.getCurrentUser()) {
      if (window.location.search.includes("eid=true")) {
        window.location.href = `https://eid.mk/issue/wsfed?wa=wsignin1.0&wtrealm=https://e-portal.uvmk.gov.mk/&wctx=redirectUrl=${window.location.pathname}&id=passive&ru=%2f&wreply=https://e-portal.uvmk.gov.mk/login_token_eid`;
      } else {
        setRedirectTo("/login");
        return;
      }
    } else {
      if (AuthService.getCurrentUser()?.proposerType === "ORGANIZATION") {
        setRedirectTo("/");
        return;
      }
      UsersRepository.fetchCurrentUser().then(
        (res) => {
          setCurrentUser(res.data);
        },
        (err) => {
          if (err.message === "Network Error") {
            setGlobalFormError(ErrorMessageResolver.resolve(err));
          } else {
            AuthRepository.logout();
          }
        }
      );
      getActiveRegistryAreas();
    }
  }, []);

  useEffect(() => {
    if (noFatherData) {
      let data = { ...formData };
      data.fatherName = "НЕМА ПОДАТОЦИ";
      data.fatherSurname = "НЕМА ПОДАТОЦИ";
      setFormData(data);
    } else {
      let data = { ...formData };
      data.fatherName = "";
      data.fatherSurname = "";
      setFormData(data);
    }
  }, [noFatherData]);

  useEffect(() => {
    if (noMotherData) {
      let data = { ...formData };
      data.motherName = "НЕМА ПОДАТОЦИ";
      data.motherSurname = "НЕМА ПОДАТОЦИ";
      setFormData(data);
    } else {
      let data = { ...formData };
      data.motherName = "";
      data.motherSurname = "";
      setFormData(data);
    }
  }, [noMotherData]);

  useEffect(() => {
    if (currentUser != undefined) {
      let tmp = { ...formData };
      tmp.embg = currentUser?.embg;
      tmp.firstName = currentUser?.firstName;
      tmp.lastName = currentUser?.lastName;
      if (currentUser?.birthPlace != undefined)
        tmp.placeBirth = currentUser?.birthPlace;
      setFormData(tmp);
    }
  }, [currentUser]);

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const getActiveRegistryAreas = () => {
    RegistryAreaRepository.getActiveRegistryAreas().then((res) => {
      setRegistryAreas(res.data);
    });
  };

  const handleChange = (name, value) => {
    let tmp = { ...formData };
    if (name === "country") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "placeBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryPlace") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deliveryRegistryArea") {
      registryAreas.map((registryArea) => {
        if (registryArea.title === value) {
          tmp[name] = registryArea;
        }
      });
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
  };

  const handleChangeDate = (date, name) => {
    let data = { ...formData };
    if (date) {
      data[name] = moment(date.set({ hour: 0, minute: 0, second: 0 }));
    } else {
      data[name] = null;
    }
    setFormData(data);
  };

  const validateBasicData = () => {
    if (!BasicDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await BasicDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateParentsData = () => {
    if (!ParentsDataValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await ParentsDataValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validatePickupWay = () => {
    if (!PickUpWayValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await PickUpWayValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateApplicantDocuments = () => {
    if (!ApplicantDocumentsValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await ApplicantDocumentsValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      validateBasicData();
      if (!BasicDataValidator.isValidSync(formData)) {
        return;
      } else {
        let dateBirthTmp = EmbgToDate.getDate(formData.embg);
        let tmp = { ...formData };
        tmp.dateBirth = dateBirthTmp;
        setFormData(tmp);
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 1) {
      validateParentsData();
      if (!ParentsDataValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      validatePickupWay();
      if (!PickUpWayValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 3) {
      validateApplicantDocuments();
      if (!ApplicantDocumentsValidator.isValidSync(formData)) {
        return;
      } else {
        setLoading(true);
        RequestSingleMartialStatusRepository.create(
          formData,
          passportScan
        ).then(
          (res) => {
            setPersistedRequest(res.data);
            setLoading(false);
            setGlobalFormError(null);
            setActiveStep(activeStep + 1);
          },
          (err) => {
            dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
            setGlobalFormError(ErrorMessageResolver.resolve(err));
            setLoading(false);
          }
        );
      }
    }
    setFormDataErrors({});
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const onUploadComponentClose = (files) => {
    setPassportScan(files);
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />
        <Container>
          <Grid container>
            <Grid item md={12} style={{ marginBottom: "20px" }}>
              <Typography variant="h5">
                {LOCALE.req_free_martial_status}
              </Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: "0px" }}
                    display="flex"
                    alignItems="center"
                  >
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                          ></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      backgroundColor: "rgb(212 212 212 / 0.85)",
                      textAlign: "center",
                      marginTop: "-20px",
                      marginBottom: "20px",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      <strong>{steps[activeStep]}</strong>
                    </Typography>
                  </Grid>
                  {globalFormError && (
                    <React.Fragment>
                      <Hidden xsDown>
                        <Grid item xs={12} md={3}></Grid>
                      </Hidden>
                      <Grid item xs={12} md={6}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                    </React.Fragment>
                  )}
                  {activeStep === 0 && (
                    <Grid item xs={12} md={12}>
                      <Grid container>
                        <Grid item md={4} xs={false}></Grid>
                        <Grid item md={4} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.embg}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange("embg", e.target.value)
                                }
                                value={formData?.embg}
                                error={formDataErrors.embg !== undefined}
                                helperText={formDataErrors.embg}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.firstName}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "firstName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.firstName}
                                error={formDataErrors.firstName !== undefined}
                                helperText={formDataErrors.firstName}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.lastName}
                                size="small"
                                fullWidth
                                value={formData?.lastName}
                                error={formDataErrors.lastName !== undefined}
                                helperText={formDataErrors.lastName}
                                onChange={(e) =>
                                  handleChange(
                                    "lastName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                variant="outlined"
                              ></TextField>
                            </Grid>{" "}
                            <Grid item xs={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                autoComplete={false}
                                size="small"
                                value={
                                  formData.country.name
                                    ? formData.country.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("country", newValue);
                                }}
                                inputValue={countrySearch}
                                onInputChange={(event, newInputValue) => {
                                  setCountrySearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={countries?.map(
                                  (country) => country.name
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.country_born}
                                    fullWidth
                                    error={formDataErrors.country !== undefined}
                                    helperText={formDataErrors.country}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            {formData.country.id === "0" ? (
                              <Grid item xs={12}>
                                <Autocomplete
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  value={
                                    formData.placeBirth.name
                                      ? formData.placeBirth.name
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange("placeBirth", newValue);
                                  }}
                                  inputValue={placeSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setPlaceSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={places?.map((place) => place.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.place_born}
                                      fullWidth
                                      error={
                                        formDataErrors.placeBirth !== undefined
                                      }
                                      helperText={formDataErrors.placeBirth}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                            ) : (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <TextField
                                    label={LOCALE.place_born}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="false"
                                    fullWidth={true}
                                    error={
                                      formDataErrors.placeBirthManual !==
                                      undefined
                                    }
                                    helperText={formDataErrors.placeBirthManual}
                                    onChange={(e) => {
                                      handleChange(
                                        "placeBirthManual",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      );
                                    }}
                                    value={formData?.placeBirthManual}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    error={formDataErrors?.registryArea}
                                  >
                                    <InputLabel>
                                      {
                                        "Матично подрачје во кое е направен уписот"
                                      }
                                    </InputLabel>
                                    <Select
                                      size="small"
                                      value={formData.registryArea}
                                      onChange={(e) =>
                                        handleChange(
                                          "registryArea",
                                          e.target.value
                                        )
                                      }
                                      label={
                                        "Матично подрачје во кое е направен уписот"
                                      }
                                    >
                                      {registryAreas &&
                                        registryAreas.map((regArea, index) => (
                                          <MenuItem
                                            key={index}
                                            value={regArea}
                                            size="small"
                                          >
                                            {regArea.title}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    {formDataErrors?.registryArea && (
                                      <FormHelperText error={true}>
                                        {formDataErrors.registryArea}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                  {formDataErrors?.deliveryRegistryArea && (
                                    <FormHelperText error={true}>
                                      {formDataErrors.deliveryRegistryArea}
                                    </FormHelperText>
                                  )}
                                </Grid>
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={4} xs={false}></Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={2} xs={false}></Grid>
                        <Grid item md={8} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label={LOCALE.mother_name}
                                size="small"
                                fullWidth
                                variant="outlined"
                                disabled={noMotherData}
                                onChange={(e) =>
                                  handleChange(
                                    "motherName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.motherName}
                                error={formDataErrors.motherName !== undefined}
                                helperText={formDataErrors.motherName}
                              ></TextField>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label={LOCALE.mother_surname}
                                size="small"
                                fullWidth
                                disabled={noMotherData}
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "motherSurname",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.motherSurname}
                                error={
                                  formDataErrors.motherSurname !== undefined
                                }
                                helperText={formDataErrors.motherSurname}
                              ></TextField>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={noMotherData}
                                    onChange={(e) =>
                                      setNoMotherData(e.target.checked)
                                    }
                                  />
                                }
                                label={LOCALE.no_mother_data}
                              />
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label={LOCALE.father_name}
                                size="small"
                                fullWidth
                                disabled={noFatherData}
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "fatherName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.fatherName}
                                error={formDataErrors.fatherName !== undefined}
                                helperText={formDataErrors.fatherName}
                              ></TextField>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                label={LOCALE.father_surname}
                                size="small"
                                fullWidth
                                disabled={noFatherData}
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "fatherSurname",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.fatherSurname}
                                error={
                                  formDataErrors.fatherSurname !== undefined
                                }
                                helperText={formDataErrors.fatherSurname}
                              ></TextField>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={noFatherData}
                                    onChange={(e) =>
                                      setNoFatherData(e.target.checked)
                                    }
                                  />
                                }
                                label={LOCALE.no_father_data}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 2 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={4} xs={false}></Grid>
                        <Grid item md={4} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormControl
                                disabled={loading}
                                variant="outlined"
                                fullWidth
                                size="small"
                              >
                                <InputLabel>{LOCALE.pickup_way}</InputLabel>
                                <Select
                                  value={formData.pickupWayCert}
                                  onChange={(e) =>
                                    handleChange(
                                      "pickupWayCert",
                                      e.target.value
                                    )
                                  }
                                  label={LOCALE.pickup_way}
                                >
                                  <MenuItem value="PICKUP_WAY_UVMK">
                                    {LOCALE.pickup_way_uvmk}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            {formData.pickupWayCert === "PICKUP_WAY_UVMK" && (
                              <Grid item xs={12}>
                                <Autocomplete
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  disabled={loading}
                                  value={
                                    formData?.deliveryRegistryArea?.title
                                      ? formData?.deliveryRegistryArea?.title
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange(
                                      "deliveryRegistryArea",
                                      newValue
                                    );
                                  }}
                                  inputValue={deliveryRegistryAreaSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setDeliveryRegistryAreaSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={registryAreas?.map(
                                    (regArea) => regArea.title
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.pickup_way_reg_area}
                                      fullWidth
                                      error={
                                        formDataErrors.deliveryRegistryArea
                                      }
                                      helperText={
                                        formDataErrors.deliveryRegistryArea
                                      }
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 3 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={2} xs={false}></Grid>
                        <Grid item md={8} xs={12}>
                          <Alert severity="info">
                            <AlertTitle>{LOCALE.warning}!</AlertTitle>
                            {LOCALE.death_cert_warning_4}
                          </Alert>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} md={3}>
                              <Typography
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "4px",
                                }}
                              >
                                {LOCALE.applicant_identity_card}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                              <TextField
                                label={LOCALE.number}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "idDocumentNumber",
                                    e.target.value
                                  )
                                }
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "4px",
                                }}
                                value={formData.idDocumentNumber}
                                error={
                                  formDataErrors.idDocumentNumber !== undefined
                                }
                                helperText={formDataErrors.idDocumentNumber}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider
                                  libInstance={moment}
                                  utils={MomentUtils}
                                >
                                  <KeyboardDatePicker
                                    cancelLabel={LOCALE.cancel}
                                    okLabel={LOCALE.ok}
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    margin="dense"
                                    inputVariant="outlined"
                                    id="date-to"
                                    label={LOCALE.valid_from}
                                    value={formData.idIssuingDate}
                                    onChange={(date) =>
                                      handleChangeDate(date, "idIssuingDate")
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider
                                  libInstance={moment}
                                  utils={MomentUtils}
                                >
                                  <KeyboardDatePicker
                                    cancelLabel={LOCALE.cancel}
                                    okLabel={LOCALE.ok}
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    margin="dense"
                                    inputVariant="outlined"
                                    id="date-to"
                                    label={LOCALE.valid_to}
                                    value={formData.idExpiryDate}
                                    onChange={(date) =>
                                      handleChangeDate(date, "idExpiryDate")
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                            <Divider />
                            <Grid item xs={12} sm={3} md={3}>
                              <Typography
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "4px",
                                }}
                              >
                                {LOCALE.applicant_passport}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                              <TextField
                                label={LOCALE.number}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "passportDocumentNumber",
                                    e.target.value
                                  )
                                }
                                style={{
                                  marginTop: "8px",
                                  marginBottom: "4px",
                                }}
                                value={formData.passportDocumentNumber}
                                error={
                                  formDataErrors.passportDocumentNumber !==
                                  undefined
                                }
                                helperText={
                                  formDataErrors.passportDocumentNumber
                                }
                              ></TextField>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider
                                  libInstance={moment}
                                  utils={MomentUtils}
                                >
                                  <KeyboardDatePicker
                                    cancelLabel={LOCALE.cancel}
                                    okLabel={LOCALE.ok}
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    margin="dense"
                                    inputVariant="outlined"
                                    id="date-to"
                                    label={LOCALE.valid_from}
                                    value={formData.passportIssuingDate}
                                    onChange={(date) =>
                                      handleChangeDate(
                                        date,
                                        "passportIssuingDate"
                                      )
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider
                                  libInstance={moment}
                                  utils={MomentUtils}
                                >
                                  <KeyboardDatePicker
                                    cancelLabel={LOCALE.cancel}
                                    okLabel={LOCALE.ok}
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    margin="dense"
                                    inputVariant="outlined"
                                    id="date-to"
                                    label={LOCALE.valid_to}
                                    value={formData.passportExpiryDate}
                                    onChange={(date) =>
                                      handleChangeDate(
                                        date,
                                        "passportExpiryDate"
                                      )
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={2} xs={false}></Grid>
                        <Grid item md={2} xs={false}></Grid>
                        <Grid item md={8} xs={false}>
                          <br></br>
                          <Alert severity="info">
                            <AlertTitle>{LOCALE.warning}!</AlertTitle>
                            {LOCALE.martial_warning_1}
                          </Alert>
                          <br></br>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              style={{
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Upload
                                onUploadComponentClose={(files) =>
                                  onUploadComponentClose(files)
                                }
                              ></Upload>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={2} xs={false}></Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 4 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid md={2} xs={false}></Grid>
                        <Grid md={8} xs={12}>
                          <PaymentComponent
                            reqSingleMartialStatus={persistedRequest}
                          ></PaymentComponent>
                        </Grid>
                        <Grid md={2} xs={false}></Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Divider></Divider>

                    {activeStep !== 0 && activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => handlePrevStep()}
                      >
                        {LOCALE.prev}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={
                          loading ||
                          (activeStep === 3 && passportScan?.length === 0)
                        }
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => handleNextStep()}
                      >
                        {LOCALE.next}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() =>
                          setRedirectTo(
                            "/births/requestSingleMartialStatus/" +
                              persistedRequest.id
                          )
                        }
                      >
                        {LOCALE.requestPreview}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => {
                          window.location.href = `${
                            SETTINGS.API_URL
                          }/payment/initialization?reasonType=transcript&rel=${
                            persistedRequest.id
                          }&access_token=${
                            AuthService.getToken().access_token
                          }`;
                        }}
                      >
                        {LOCALE.iAgree}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <br></br>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
