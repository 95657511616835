import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import Upload from "../../common/components/Upload";
import { LOCALE } from "../../properties/Locale";

export default function RequestMarriageWizardPreview({
  getSpouseIndex,
  formData,
  groomIdUpload,
  brideIdUpload,
}) {
  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ paddingTop: "10px" }}>
        <Grid item xs={12}>
          <Table size="small" id="app-h-prev-tabl" className="table-stripped">
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: "lightgray" }}>
                  <strong>{LOCALE.marriage_data}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.appointment_type}</TableCell>
                <TableCell colSpan={2}>
                  {formData?.requestType
                    ? formData?.requestType == "REGULAR_INSIDE"
                      ? "Склучување на брак во управата"
                      : "Склучување на брак на терен/ресторан"
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.habitant_place}</TableCell>
                <TableCell colSpan={2}>
                  {formData?.placeOfMarriage?.name
                    ? formData?.placeOfMarriage?.name
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.premises}</TableCell>
                <TableCell colSpan={2}>
                  {formData?.requestType == "REGULAR_INSIDE"
                    ? formData?.marriageLocation?.name
                      ? formData?.marriageLocation?.name
                      : ""
                    : formData?.marriageLocationManual}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.marriage_date}</TableCell>
                <TableCell colSpan={2}>
                  {formData?.dateOfMarriage
                    ? moment(formData?.dateOfMarriage).format("DD.MM.YYYY")
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.appointment}</TableCell>
                <TableCell colSpan={2}>
                  {formData.id
                    ? moment(formData?.dateOfMarriage).format("HH:mm ")
                    : formData?.appointment
                    ? formData?.appointment
                    : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: "lightgray" }}>
                  <strong>{LOCALE.spouses_information}</strong>
                </TableCell>
                <TableCell style={{ backgroundColor: "lightgray" }}>
                  <strong>{LOCALE.the_bride}</strong>
                </TableCell>
                <TableCell style={{ backgroundColor: "lightgray" }}>
                  <strong>{LOCALE.the_groom}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.embg}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.embg
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]?.embg
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.embg
                    ? formData?.spousesData[getSpouseIndex("GROOM")]?.embg
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.firstName}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.name
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]?.name
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.name
                    ? formData?.spousesData[getSpouseIndex("GROOM")]?.name
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.lastName}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.surname
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]?.surname
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.surname
                    ? formData?.spousesData[getSpouseIndex("GROOM")]?.surname
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.surnameMarriage}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]
                    ?.surnameMarriage
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.surnameMarriage
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]
                    ?.surnameMarriage
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.surnameMarriage
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.birthDate}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.dateBirth
                    ? moment(
                        formData?.spousesData[getSpouseIndex("BRIDE")]
                          ?.dateBirth
                      ).format("DD.MM.YYYY")
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.dateBirth
                    ? moment(
                        formData?.spousesData[getSpouseIndex("GROOM")]
                          ?.dateBirth
                      ).format("DD.MM.YYYY")
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.country_born}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.country?.name
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]?.country
                        ?.name
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.country?.name
                    ? formData?.spousesData[getSpouseIndex("GROOM")]?.country
                        ?.name
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.place_born}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.place?.name
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]?.place
                        ?.name
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.place?.name
                    ? formData?.spousesData[getSpouseIndex("GROOM")]?.place
                        ?.name
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.citizenship}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.citizenship
                    ?.name
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.citizenship?.name === "СЕВЕРНА МАКЕДОНИЈА"
                      ? "Македонско/граѓанин на Република Северна Македонија"
                      : formData?.spousesData[getSpouseIndex("BRIDE")]
                          ?.citizenship?.name
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.citizenship
                    ?.name
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.citizenship?.name === "СЕВЕРНА МАКЕДОНИЈА"
                      ? "Македонско/граѓанин на Република Северна Македонија"
                      : formData?.spousesData[getSpouseIndex("GROOM")]
                          ?.citizenship?.name
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.address_living}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.addressLiving
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.addressLiving
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.addressLiving
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.addressLiving
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.education}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.education
                    ?.title
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]?.education
                        ?.title
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.education
                    ?.title
                    ? formData?.spousesData[getSpouseIndex("GROOM")]?.education
                        ?.title
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.name_school}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.schoolName
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]?.schoolName
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.schoolName
                    ? formData?.spousesData[getSpouseIndex("GROOM")]?.schoolName
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.fatherFirstName}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]
                    ?.fatherFirstName
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.fatherFirstName
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]
                    ?.fatherFirstName
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.fatherFirstName
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.fatherMaidenLastname}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]
                    ?.fatherMaidenLastname
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.fatherMaidenLastname
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]
                    ?.fatherMaidenLastname
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.fatherMaidenLastname
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.fatherLastName}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]
                    ?.fatherLastName
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.fatherLastName
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]
                    ?.fatherLastName
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.fatherLastName
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.motherFirstName}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]
                    ?.motherFirstName
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.motherFirstName
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]
                    ?.motherFirstName
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.motherFirstName
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.motherMaidenLastname}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]
                    ?.motherMaidenLastname
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.motherMaidenLastname
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]
                    ?.motherMaidenLastname
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.motherMaidenLastname
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.motherLastName}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]
                    ?.motherLastName
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.motherLastName
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]
                    ?.motherLastName
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.motherLastName
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.id_number}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]
                    ?.idDocumentNumber
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.idDocumentNumber
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]
                    ?.idDocumentNumber
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.idDocumentNumber
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.id_card_issue_date}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.idIssuingDate
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.idIssuingDate
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.idIssuingDate
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.idIssuingDate
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.id_card_expiry_date}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.idExpiryDate
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.idExpiryDate
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.idExpiryDate
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.idExpiryDate
                    : "/"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.contact_phone}</TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("BRIDE")]?.phoneNumber
                    ? formData?.spousesData[getSpouseIndex("BRIDE")]
                        ?.phoneNumber
                    : "/"}
                </TableCell>
                <TableCell>
                  {formData?.spousesData[getSpouseIndex("GROOM")]?.phoneNumber
                    ? formData?.spousesData[getSpouseIndex("GROOM")]
                        ?.phoneNumber
                    : "/"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
