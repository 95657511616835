import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const RequestSingleMartialStatusRepository = {
  create: (formData, images) => {
    let data = new FormData();
    for (var i = 0; i < images.length; i++) {
      data.append("passportScan", images[i]);
    }
    data.append(
      "requestSingleMartialStatus",
      new Blob([JSON.stringify(formData)], {
        type: "application/json",
      })
    );
    return Axios({
      url: `${SETTINGS.API_URL}requestSingleMartialStatus`,
      method: "POST",
      data: data,
      header: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  update: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestSingleMartialStatus`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  get: (id) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestSingleMartialStatus/${id}`,
      method: "GET",
    });
  },
  getPassport: (id) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestSingleMartialStatus/${id}/passportScan`,
      method: "GET",
    });
  },
};
