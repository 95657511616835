import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {} from "./api/HttpClient";

const momentjs = require("moment-timezone");
momentjs.tz.setDefault("Etc/UTC");

if (localStorage.getItem("locale") && localStorage.getItem("locale") === "al") {
  momentjs.locale("al");
} else {
  momentjs.locale("mk");
}

export const moment = momentjs;

export default function App() {
  const location = useLocation();
  const history = useHistory();

  return <React.Fragment></React.Fragment>;
}
