import { moment } from "../../App";

export const EmbgToDate = {
  getDate: (embg) => {
    let day = embg.substr(0, 2);
    let month = embg.substr(2, 2);
    let year = embg.substr(4, 3);
    if (year.substr(0, 1) === "0") {
      year = "2" + year;
    } else {
      year = "1" + year;
    }
    var dateBirthTmp = moment(year + "-" + month + "-" + day);
    return dateBirthTmp;
  },

  getAge: (DOB) => {
    var today = moment();
    var birthDate = moment(DOB);
    var age = today.year() - birthDate.year();
    var m = today.month() - birthDate.month();
    if (m < 0 || (m === 0 && today.isBefore(birthDate))) {
      age--;
    }
    return age;
  },
};
