import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { AuthService } from "../auth/AuthService";
import { EmbgValidator } from "../../common/helpers/EmbgValidator";

const defaultCountry = {
  countryCode: "807",
  id: "0",
  name: "СЕВЕРНА МАКЕДОНИЈА",
  nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
  nameEn: "REPUBLIC OF NORTH MACEDONIA",
  printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
  printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
  printNameEn: "REPUBLIC OF NORTH MACEDONIA",
};

export const RequestMarriageBrideValidator = yup.object().shape({
  spousesData: yup
    .array()
    .of(
      yup.object().shape({
        embg: yup
          .string()
          .test(
            "embg-format-invalid",
            LOCALE.invalid_embg_format,
            function (value) {
              if (!this.options.parent.country) return false;
              if (!this.options.parent.country.id) return false;
              if (this.options.parent.country.id === defaultCountry.id) {
                if (!value) return false;
                if (value.length !== 13) return false;
                if (!value.match(/^[0-9]*$/)) return false;
                if (parseInt(value.substring(9, 12)) <= 499) {
                  return false;
                }
              }
              return true;
            }
          ),
        name: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.lastName + LOCALE.required_field),
        surname: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.firstName + LOCALE.required_field),
        surnameMarriage: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.surnameMarriage + LOCALE.required_field),
        dateBirth: yup.date().required(LOCALE.required_field).nullable(),
        country: yup.object().shape({
          id: yup
            .string()
            .required(LOCALE.country_born + LOCALE.required_field),
        }),
        place: yup
          .object()
          .test(
            "brideBirthPlaceConditionalRequired",
            LOCALE.place_born + LOCALE.required_field,
            function (value) {
              if (!this.options.parent.country) return false;
              if (!this.options.parent.country.id) return false;
              if (this.options.parent.country.id === defaultCountry.id) {
                if (!value?.id) return false;
                return true;
              }
              return true;
            }
          ),
        placeBirth: yup
          .string()
          .test(
            "brideBirthPlaceManual",
            LOCALE.place_born + LOCALE.required_field,
            function (value) {
              if (!this.options.parent.country) return false;
              if (!this.options.parent.country.id) return false;
              if (this.options.parent.country.id !== defaultCountry.id) {
                if (!value) return false;
                return true;
              }
              return true;
            }
          ),
        citizenship: yup.object().shape({
          id: yup.string().required(LOCALE.citizenship + LOCALE.required_field),
        }),
        addressLiving: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.addressLiving + LOCALE.required_field),
        education: yup.object().shape({
          id: yup.string().required(LOCALE.education + LOCALE.required_field),
        }),
        schoolName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.schoolName + LOCALE.required_field),
        fatherFirstName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.fatherFirstName + LOCALE.required_field),
        fatherLastName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.fatherLastName + LOCALE.required_field),
        motherFirstName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.motherFirstName + LOCALE.required_field),
        motherLastName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.motherLastName + LOCALE.required_field),
        motherMaidenLastname: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.motherMaidenLastname + LOCALE.required_field),
        fatherMaidenLastname: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.fatherMaidenLastname + LOCALE.required_field),
        phoneNumber: yup
          .string()
          .min(12, LOCALE.not_valid_format)
          .required(LOCALE.contact_phone + LOCALE.required_field),
      })
    )
    .compact((v) => {
      // console.log(v);
      return v.spousesType === "GROOM";
    }),
});

export const RequestMarriageGroomValidator = yup.object().shape({
  spousesData: yup
    .array()
    .of(
      yup.object().shape({
        embg: yup
          .string()
          .test(
            "embg-format-invalid",
            LOCALE.invalid_embg_format,
            function (value) {
              if (!this.options.parent.country) return false;
              if (!this.options.parent.country.id) return false;
              if (this.options.parent.country.id === defaultCountry.id) {
                if (!value) return false;
                if (value.length !== 13) return false;
                if (!value.match(/^[0-9]*$/)) return false;
                if (parseInt(value.substring(9, 12)) >= 500) {
                  return false;
                }
              }
              return true;
            }
          ),
        name: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.lastName + LOCALE.required_field),
        surname: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.firstName + LOCALE.required_field),
        surnameMarriage: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.surnameMarriage + LOCALE.required_field),
        dateBirth: yup.date().required(LOCALE.required_field).nullable(),
        country: yup.object().shape({
          id: yup
            .string()
            .required(LOCALE.country_born + LOCALE.required_field),
        }),
        place: yup
          .object()
          .test(
            "brideBirthPlaceConditionalRequired",
            LOCALE.place_born + LOCALE.required_field,
            function (value) {
              if (!this.options.parent.country) return false;
              if (!this.options.parent.country.id) return false;
              if (this.options.parent.country.id === defaultCountry.id) {
                if (!value?.id) return false;
                return true;
              }
              return true;
            }
          ),
        placeBirth: yup
          .string()
          .test(
            "brideBirthPlaceManual",
            LOCALE.place_born + LOCALE.required_field,
            function (value) {
              if (!this.options.parent.country) return false;
              if (!this.options.parent.country.id) return false;
              if (this.options.parent.country.id !== defaultCountry.id) {
                if (!value) return false;
                return true;
              }
              return true;
            }
          ),
        citizenship: yup.object().shape({
          id: yup.string().required(LOCALE.citizenship + LOCALE.required_field),
        }),
        addressLiving: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.addressLiving + LOCALE.required_field),
        education: yup.object().shape({
          id: yup.string().required(LOCALE.education + LOCALE.required_field),
        }),
        schoolName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.schoolName + LOCALE.required_field),
        fatherFirstName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.fatherFirstName + LOCALE.required_field),
        fatherLastName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.fatherLastName + LOCALE.required_field),

        motherFirstName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.motherFirstName + LOCALE.required_field),
        motherLastName: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.motherLastName + LOCALE.required_field),
        motherMaidenLastname: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.motherMaidenLastname + LOCALE.required_field),
        fatherMaidenLastname: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.fatherMaidenLastname + LOCALE.required_field),
        phoneNumber: yup
          .string()
          .min(12, LOCALE.not_valid_format)
          .required(LOCALE.contact_phone + LOCALE.required_field),
      })
    )
    .compact((v) => {
      // console.log(v);
      return v.spousesType === "BRIDE";
    }),
});

export const ValidateRequestMarriageData = yup.object().shape({
  requestType: yup
    .string()
    .required(LOCALE.appointment_type + " " + LOCALE.required_field),
  dateOfMarriage: yup
    .date()
    .required(LOCALE.marriage_date + " " + LOCALE.required_field)
    .nullable(),
  placeOfMarriage: yup.object().shape({
    id: yup
      .string()
      .required(LOCALE.habitant_place + " " + LOCALE.required_field),
  }),
  marriageLocation: yup
    .object()
    .test(
      "marriageLocationConditionalRequired",
      LOCALE.premises + " " + LOCALE.required_field,
      function (value) {
        if (this.options.parent.requestType === "REGULAR_INSIDE") {
          if (!value?.id) return false;
          return true;
        }
        return true;
      }
    )
    .nullable(),
  marriageLocationManual: yup
    .string()
    .test(
      "marriageLocationManual",
      "Локација " + LOCALE.required_field,
      function (value) {
        if (this.options.parent.requestType !== "REGULAR_INSIDE") {
          if (!value) return false;
          return true;
        }
        return true;
      }
    )
    .nullable(),
  appointment: yup
    .string()
    .test(
      "appointment",
      "Ве молиме изберете термин во периодот од 08:00 до 21:00 часот",
      function (value) {
        if (!value) return false;
        else if (parseInt(value.substring(0, 2)) < 8) return false;
        else if (parseInt(value.substring(0, 2)) > 20) return false;
        else return true;
      }
    ),
});

export const ValidateRequestMarriageDocuments = yup.object().shape({
  formData: yup.object().shape({
    spousesData: yup.array().of(
      yup.object().shape({
        idDocumentNumber: yup
          .string()
          .min(2, LOCALE.at_least_2_chars_required)
          .required(LOCALE.number + LOCALE.required_field),
        idIssuingDate: yup
          .string()
          .required(LOCALE.date_of_issue + " " + LOCALE.required_field),
        idExpiryDate: yup
          .string()
          .required("Датум на Истекување " + LOCALE.required_field),
      })
    ),
  }),
  brideIdUpload: yup
    .array()
    .of(yup.mixed().required("You need to provide a file"))
    .test({
      message: "The error message if length == 0",
      test: (arr) => arr.length > 0,
    }),
  groomIdUpload: yup
    .array()
    .of(yup.mixed().required("You need to provide a file"))
    .test({
      message: "The error message if  length == 0",
      test: (arr) => arr.length > 0,
    }),
});
