import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { LOCALE } from "../../properties/Locale";

import { Alert, AlertTitle } from "@material-ui/lab";
import ReactInputMask from "react-input-mask";
import Upload from "../../common/components/Upload";

export default function ApplicationUvmkDocuments({
  handleChange,
  getParentIndex,
  formData,
  formDataErrors,
  fatherUploadedDocs,
  setFatherUploadedDocs,
  motherUploadedDocs,
  setMotherUploadedDocs,
}) {
  const onUploadFatherComponentClose = (files) => {
    // console.log("FATHER", files);
    setFatherUploadedDocs(files);
  };
  const onUploadMotherComponentClose = (files) => {
    // console.log("MOTHER", files);
    setMotherUploadedDocs(files);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={8} xs={false}>
            <Alert severity="info">
              <AlertTitle>{LOCALE.warning}!</AlertTitle>
              {LOCALE.warning_1}
            </Alert>
          </Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={8}>
            <Divider />
          </Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={8} xs={12}>
            <Grid container spacing={2}>
              {formData.bornInMarriage == 0 && (
                <React.Fragment>
                  <Grid item xs={12} sm={3} md={3}>
                    <Typography style={{ marginTop: "8px" }}>
                      {LOCALE.father_identity_card}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      label={LOCALE.number}
                      size="small"
                      fullWidth
                      variant="outlined"
                      onChange={(e) =>
                        handleChange(
                          `parentsData[${getParentIndex(
                            "FATHER"
                          )}].idDocumentNumber`,
                          e.target.value
                        )
                      }
                      value={
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.idDocumentNumber
                          ? formData?.parentsData[getParentIndex("FATHER")]
                              ?.idDocumentNumber
                          : ""
                      }
                      error={
                        formDataErrors[
                          `formData.parentsData[${getParentIndex(
                            "FATHER"
                          )}].idDocumentNumber`
                        ] !== undefined
                      }
                      helperText={
                        formDataErrors[
                          `formData.parentsData[${getParentIndex(
                            "FATHER"
                          )}].idDocumentNumber`
                        ]
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <ReactInputMask
                      mask="DD/MM/YYYY"
                      maskChar="_"
                      value={
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.idIssuingDate
                          ? formData?.parentsData[getParentIndex("FATHER")]
                              ?.idIssuingDate
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(
                          `parentsData[${getParentIndex(
                            "FATHER"
                          )}].idIssuingDate`,
                          e.target.value
                        )
                      }
                      formatChars={{
                        D: "[0-9]",
                        M: "[0-9]",
                        Y: "[0-9]",
                      }}
                    >
                      {(inputProps) => (
                        <TextField
                          label={LOCALE.date_of_issue}
                          size="small"
                          fullWidth
                          variant="outlined"
                          error={
                            formDataErrors[
                              `formData.parentsData[${getParentIndex(
                                "FATHER"
                              )}].idIssuingDate`
                            ] !== undefined
                          }
                          helperText={
                            formDataErrors[
                              `formData.parentsData[${getParentIndex(
                                "FATHER"
                              )}].idIssuingDate`
                            ]
                          }
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <ReactInputMask
                      mask="DD/MM/YYYY"
                      maskChar="_"
                      value={
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.idExpiryDate
                          ? formData?.parentsData[getParentIndex("FATHER")]
                              ?.idExpiryDate
                          : ""
                      }
                      onChange={(e) =>
                        handleChange(
                          `parentsData[${getParentIndex(
                            "FATHER"
                          )}].idExpiryDate`,
                          e.target.value
                        )
                      }
                      formatChars={{
                        D: "[0-9]",
                        M: "[0-9]",
                        Y: "[0-9]",
                      }}
                    >
                      {(inputProps) => (
                        <TextField
                          label={LOCALE.expiry_date}
                          size="small"
                          fullWidth
                          variant="outlined"
                          error={
                            formDataErrors[
                              `formData.parentsData[${getParentIndex(
                                "FATHER"
                              )}].idExpiryDate`
                            ] !== undefined
                          }
                          helperText={
                            formDataErrors[
                              `formData.parentsData[${getParentIndex(
                                "FATHER"
                              )}].idExpiryDate`
                            ]
                          }
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Upload
                      onUploadComponentClose={(files) =>
                        onUploadFatherComponentClose(files)
                      }
                      files={fatherUploadedDocs}
                      error={formDataErrors.fatherUploadedDocs ? true : false}
                    ></Upload>
                  </Grid>
                  <Grid item md={12}>
                    <Divider />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12} sm={3} md={3}>
                <Typography style={{ marginTop: "8px" }}>
                  {LOCALE.mother_identity_card}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                  label={LOCALE.number}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex(
                        "MOTHER"
                      )}].idDocumentNumber`,
                      e.target.value
                    )
                  }
                  value={
                    formData?.parentsData[getParentIndex("MOTHER")]
                      ?.idDocumentNumber
                      ? formData?.parentsData[getParentIndex("MOTHER")]
                          ?.idDocumentNumber
                      : ""
                  }
                  error={
                    formDataErrors[
                      `formData.parentsData[${getParentIndex(
                        "MOTHER"
                      )}].idDocumentNumber`
                    ] !== undefined
                  }
                  helperText={
                    formDataErrors[
                      `formData.parentsData[${getParentIndex(
                        "MOTHER"
                      )}].idDocumentNumber`
                    ]
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <ReactInputMask
                  mask="DD/MM/YYYY"
                  maskChar="_"
                  value={
                    formData?.parentsData[getParentIndex("MOTHER")]
                      ?.idIssuingDate
                      ? formData?.parentsData[getParentIndex("MOTHER")]
                          ?.idIssuingDate
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex("MOTHER")}].idIssuingDate`,
                      e.target.value
                    )
                  }
                  formatChars={{
                    D: "[0-9]",
                    M: "[0-9]",
                    Y: "[0-9]",
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      label={LOCALE.date_of_issue}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={
                        formDataErrors[
                          `formData.parentsData[${getParentIndex(
                            "MOTHER"
                          )}].idIssuingDate`
                        ] !== undefined
                      }
                      helperText={
                        formDataErrors[
                          `formData.parentsData[${getParentIndex(
                            "MOTHER"
                          )}].idIssuingDate`
                        ]
                      }
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <ReactInputMask
                  mask="DD/MM/YYYY"
                  maskChar="_"
                  value={
                    formData?.parentsData[getParentIndex("MOTHER")]
                      ?.idExpiryDate
                      ? formData?.parentsData[getParentIndex("MOTHER")]
                          ?.idExpiryDate
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex("MOTHER")}].idExpiryDate`,
                      e.target.value
                    )
                  }
                  formatChars={{
                    D: "[0-9]",
                    M: "[0-9]",
                    Y: "[0-9]",
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      label={LOCALE.expiry_date}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={
                        formDataErrors[
                          `formData.parentsData[${getParentIndex(
                            "MOTHER"
                          )}].idExpiryDate`
                        ] !== undefined
                      }
                      helperText={
                        formDataErrors[
                          `formData.parentsData[${getParentIndex(
                            "MOTHER"
                          )}].idExpiryDate`
                        ]
                      }
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Upload
                  onUploadComponentClose={(files) =>
                    onUploadMotherComponentClose(files)
                  }
                  files={motherUploadedDocs}
                  error={formDataErrors.motherUploadedDocs ? true : false}
                ></Upload>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} xs={false}></Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
