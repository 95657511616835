import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Button,
  Grow,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";

const useStyles = makeStyles(styles);

export default function IndexPageComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [voucherTypes, setVoucherTypes] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState();
  const location = useLocation();
  const history = useHistory();

  const handleCloseLanguage = (item) => {
    if (item === "al") {
      setOpenLanguage(null);
      changeLanguage(item);
    } else {
      setOpenLanguage(null);
      changeLanguage(item);
    }
  };

  useEffect(() => {
    setCurrentUser(AuthService.getCurrentUser());
  }, []);

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />
        <Container>
          <Grid container>
            <Grid item md={12} sm={12} style={{ padding: "15px" }}>
              <Grid container spacing={4}>
                <Grow in={true} timeout={750}>
                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <MagicHover options={{ max: 10 }}>
                      <Card>
                        <CardActionArea
                          onClick={() =>
                            currentUser === null
                              ? setRedirectTo("/login")
                              : setRedirectTo(
                                  "/births/requestBirthCertificateWizard"
                                )
                          }
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                            >
                              {LOCALE.request_birth_certificate}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  alt=""
                                  style={{ marginRight: "5px" }}
                                  height="150px"
                                  src={require("../../assets/img/cardBirth.png")}
                                ></img>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                  style={{ minHeight: "120px" }}
                                >
                                  {LOCALE.request_birth_certificate_description}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <div style={{ width: "100%", textAlign: "right" }}>
                            <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                currentUser === null
                                  ? setRedirectTo("/login")
                                  : setRedirectTo(
                                      "/births/requestBirthCertificateWizard"
                                    )
                              }
                            >
                              {LOCALE.submit_request}
                            </Button>
                          </div>
                        </CardActions>
                      </Card>
                    </MagicHover>
                  </Grid>
                </Grow>
                <Grow in={true} timeout={1250}>
                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <MagicHover options={{ max: 10 }}>
                      <Card>
                        <CardActionArea
                          onClick={() =>
                            currentUser === null
                              ? setRedirectTo("/login")
                              : setRedirectTo(
                                  "/marriages/requestMarriageCertificateWizard"
                                )
                          }
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                            >
                              {LOCALE.request_marriage_certificate}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  alt=""
                                  style={{ marginRight: "5px" }}
                                  height="150px"
                                  src={require("../../assets/img/cardMarriage.png")}
                                ></img>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                  style={{ minHeight: "120px" }}
                                >
                                  {
                                    LOCALE.request_marriage_certificate_description
                                  }
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <div style={{ width: "100%", textAlign: "right" }}>
                            <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                currentUser === null
                                  ? setRedirectTo("/login")
                                  : setRedirectTo(
                                      "/marriages/requestMarriageCertificateWizard"
                                    )
                              }
                            >
                              {LOCALE.submit_request}
                            </Button>
                          </div>
                        </CardActions>
                      </Card>
                    </MagicHover>
                  </Grid>
                </Grow>
                <Grow in={true} timeout={1750}>
                  <Grid item xs={12} md={6} sm={6} lg={6}>
                    <MagicHover options={{ max: 10 }}>
                      <Card>
                        <CardActionArea
                          onClick={() =>
                            currentUser === null
                              ? setRedirectTo("/login")
                              : setRedirectTo(
                                  "/deaths/requestDeathCertificateWizard"
                                )
                          }
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                            >
                              {LOCALE.request_death_certificate}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  alt=""
                                  style={{ marginRight: "5px" }}
                                  height="150px"
                                  src={require("../../assets/img/cardDeath.png")}
                                ></img>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                  style={{ minHeight: "120px" }}
                                >
                                  {LOCALE.request_death_certificate_description}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <div style={{ width: "100%", textAlign: "right" }}>
                            <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                currentUser === null
                                  ? setRedirectTo("/login")
                                  : setRedirectTo(
                                      "/deaths/requestDeathCertificateWizard"
                                    )
                              }
                            >
                              {LOCALE.submit_request}
                            </Button>
                          </div>
                        </CardActions>
                      </Card>
                    </MagicHover>
                  </Grid>
                </Grow>
                {AuthService.getCurrentUser()?.proposerType !==
                  "ORGANIZATION" && (
                  <Grow in={true} timeout={2250}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <MagicHover options={{ max: 10 }}>
                        <Card>
                          <CardActionArea
                            onClick={() =>
                              currentUser === null
                                ? setRedirectTo("/login")
                                : setRedirectTo(
                                    "/births/requestSingleMartialStatusWizzard"
                                  )
                            }
                          >
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="h2"
                              >
                                {LOCALE.request_marital_status_certificate}
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    alt=""
                                    style={{ marginRight: "5px" }}
                                    height="150px"
                                    src={require("../../assets/img/cardMarital.png")}
                                  ></img>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    style={{ minHeight: "120px" }}
                                  >
                                    {
                                      LOCALE.request_marital_status_certificate_description
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  currentUser === null
                                    ? setRedirectTo("/login")
                                    : setRedirectTo(
                                        "/births/requestSingleMartialStatusWizzard"
                                      )
                                }
                              >
                                {LOCALE.submit_request}
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </MagicHover>
                    </Grid>
                  </Grow>
                )}
                {AuthService.getCurrentUser()?.proposerType !==
                  "ORGANIZATION" && (
                  <Grow in={true} timeout={2500}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <MagicHover options={{ max: 10 }}>
                        <Card>
                          <CardActionArea
                            disabled={
                              AuthService.getCurrentUser() &&
                              !AuthService.getCurrentUser()?.nationalEid
                            }
                            onClick={() =>
                              currentUser === null
                                ? setRedirectTo("/login")
                                : setRedirectTo(
                                    "/marriages/requestMarriageWizard"
                                  )
                            }
                          >
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="h2"
                              >
                                {LOCALE.request_marriage}
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    alt=""
                                    style={{ marginRight: "5px" }}
                                    height="150px"
                                    src={require("../../assets/img/cardMarriage.png")}
                                  ></img>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    style={{ minHeight: "120px" }}
                                  >
                                    {LOCALE.request_marriage_description}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                disabled={
                                  AuthService.getCurrentUser() &&
                                  !AuthService.getCurrentUser()?.nationalEid
                                }
                                onClick={() =>
                                  currentUser === null
                                    ? setRedirectTo("/login")
                                    : setRedirectTo(
                                        "/marriages/requestMarriageWizard"
                                      )
                                }
                              >
                                {LOCALE.submit_request}
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </MagicHover>
                    </Grid>
                  </Grow>
                )}
                {AuthService.getCurrentUser()?.proposerType !==
                  "ORGANIZATION" && (
                  <Grow in={true} timeout={2500}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <MagicHover options={{ max: 10 }}>
                        <Card>
                          <CardActionArea
                            disabled={
                              AuthService.getCurrentUser() &&
                              !AuthService.getCurrentUser()?.nationalEid
                            }
                            onClick={() =>
                              currentUser === null
                                ? setRedirectTo("/login")
                                : setRedirectTo(
                                    "/births/applicationUvmkPortalWizzard"
                                  )
                            }
                          >
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="h2"
                              >
                                {LOCALE.registration_of_newborn}
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    alt=""
                                    style={{ marginRight: "5px" }}
                                    height="150px"
                                    src={require("../../assets/img/cardBirth.png")}
                                  ></img>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    style={{ minHeight: "120px" }}
                                  >
                                    {LOCALE.registration_of_newborn_description}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                disabled={
                                  AuthService.getCurrentUser() &&
                                  !AuthService.getCurrentUser()?.nationalEid
                                }
                                onClick={() =>
                                  currentUser === null
                                    ? setRedirectTo("/login")
                                    : setRedirectTo(
                                        "/births/applicationUvmkPortalWizzard"
                                      )
                                }
                              >
                                {LOCALE.submit_request}
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </MagicHover>
                    </Grid>
                  </Grow>
                )}
                {/* {AuthService.getCurrentUser()?.proposerType ===
                  "ORGANIZATION" && (
                  <Grow in={true} timeout={2500}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <MagicHover options={{ max: 10 }}>
                        <Card>
                          <CardActionArea
                            onClick={() =>
                              currentUser === null
                                ? setRedirectTo("/login")
                                : setRedirectTo("/requestForInformation")
                            }
                          >
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="h2"
                              >
                                {"Барање за информации и податоци"}
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    alt=""
                                    style={{ marginRight: "5px" }}
                                    height="150px"
                                    src={require("../../assets/img/requestForInfromation.png")}
                                  ></img>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    style={{ minHeight: "120px" }}
                                  >
                                    {
                                      "Поднесете електронско барање за информации и податоци, оваа услуга е достапна само за правни лица."
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>
                            <div style={{ width: "100%", textAlign: "right" }}>
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  currentUser === null
                                    ? setRedirectTo("/login")
                                    : setRedirectTo("/requestForInformation")
                                }
                              >
                                {LOCALE.submit_request}
                              </Button>
                            </div>
                          </CardActions>
                        </Card>
                      </MagicHover>
                    </Grid>
                  </Grow>
                )} */}
              </Grid>
            </Grid>
          </Grid>
          <br></br>
        </Container>
        <br></br>
        <br></br>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
