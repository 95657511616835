import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const NationalityRepository = {
  getNationalities: (name) => {
    return Axios({
      url: SETTINGS.API_URL + "nationality/filteredByName",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        nameFilter: name,
      },
    });
  },
  getNationalityById: (id) => {
    return Axios({
      url: SETTINGS.API_URL + `nationality/${id}`,
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
  getNationalitiesPageable: (title, page, size) => {
    return Axios({
      url: SETTINGS.API_URL + "nationality/filteredByNamePageable",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        title: title,
        page: page,
        size: size,
      },
    });
  },
};
