import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const ResetPasswordValidator = yup.object().shape({
  password: yup
    .string()
    .required(LOCALE.newPassword + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      LOCALE.password_complexity_validation_msg
    ),
  confirmPassword: yup
    .string()
    .required(LOCALE.confirmPassword + LOCALE.required_field)
    .test("confrimPassword", LOCALE.doesnt_match, function (value) {
      if (this.options.parent.password === value) {
        return true;
      } else {
        return false;
      }
    }),
});
