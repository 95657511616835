import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const OrdersRepository = {
  getAll: (page, size, filterMap) => {
    return Axios({
      url: SETTINGS.API_URL + "orders",
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: filterMap,
      },
    });
  },
};
