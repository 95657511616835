import React from "react";
import { Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Snackbar from "./components/Snackbar/Snackbar.js";
import { COMMON_ACTIONS } from "./actions/CommonActions";

export default function GlobalNotificationSnackbar() {
  const globalState = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (globalState.open) {
      dispatch({
        type: COMMON_ACTIONS.CLEAR_NOTIFICATIONS,
        payload: null,
      });
    }
  };

  return (
    <React.Fragment>
      {globalState.open && (
        <Snackbar
          place="br"
          icon={globalState.variant}
          color={globalState.variant}
          message={globalState.message}
          open={true}
          closeNotification={handleClose}
          close
        />
      )}
    </React.Fragment>
  );
}
