import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  CardHeader,
  Hidden,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grow,
  Divider,
  Tooltip,
  Backdrop,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { AuthRepository } from "./AuthRepository";
import { Autocomplete } from "@material-ui/lab";
import { CountryRepository } from "../../repositories/CountryRepository";
import { Transliteration } from "../../common/helpers/Transliteration";
import { SignUpFormValidator } from "./SignUpFormValidator";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { PropagateLoader } from "react-spinners";
import MuiPhoneNumber from "material-ui-phone-number";
import { SignUpFormValidatorLegalEntity } from "./SignUpFormValidatorLegalEntity";
import SignUpComponentPerson from "./SignUpComponentPerson";
import { RegisterTokenFormValidator } from "./LoginFormValidator";
import { AuthService } from "./AuthService";
import { UsersRepository } from "../../repositories/UsersRepository";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { HelpOutline } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function SignUpComponentLegalEntity() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");

  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [credentialsDataErrors, setCredentialsDataErrors] = React.useState({});
  const [confirmPassword, setConfirmPasswrod] = React.useState("");
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [globalFormError, setGlobalFormError] = React.useState();
  const [tokenSuccess, setTokenSuccess] = React.useState(false);
  const [credentialsData, setCredentialsData] = React.useState({
    signedXml: "",
  });

  const [formDataLegalEntity, setFormDataLegalEntity] = React.useState({
    firstName: "",
    lastName: "",
    edb: "",
    placeLiving: { name: "" },
    placeLivingManual: "",
    addressLiving: "",
    email: "",
    phoneNumber: "",
    username: "",
    password: "",
    confirmPassword: "",
    confirmConditions: false,
    institution: {
      countryLocation: {
        countryCode: "807",
        id: "0",
        name: "СЕВЕРНА МАКЕДОНИЈА",
        nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
        nameEn: "REPUBLIC OF NORTH MACEDONIA",
        printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
        printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
        printNameEn: "REPUBLIC OF NORTH MACEDONIA",
      },
    },
  });
  var _ = require("lodash");

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const signDocument = () => {
    if (typeof AuctaSignerApp === "function") {
      var xml = "<root>UVMK</root>";
      window.ext.signAndCertXML(xml, (status, message) => {
        signAndCertCallback(status, message);
      });
    } else {
      setGlobalFormError(
        "Ве молиме инсталирајте ги потребните алатки за дигитално потпишување"
      );
    }
  };

  function signAndCertCallback(status, message) {
    if (status === false && message.status === "Error") {
      if (message.error === "No valid certificates.") {
        setGlobalFormError(
          "Не е избран валиден сертификат за дигитално потпишување"
        );
      } else if (
        message.error.includes("The action was cancelled by the user.")
      ) {
        setGlobalFormError("Потпишувањето беше откажано на ваше барање");
      } else {
        setGlobalFormError(
          "Грешка при потпишување на електронскиот документ. Ве молиме пријавете го овој проблем кај администраторот."
        );
      }
    } else if (status === true) {
      let data = { ...credentialsData };
      data["signedXml"] = message.signedContent;
      setTokenSuccess(status);
      setCredentialsData(data);
      setCredentialsDataErrors({});
    }
  }

  const loginSubmitHandler = () => {
    setGlobalFormError(null);
    setCredentialsDataErrors({});
    setLoading(true);
    if (!RegisterTokenFormValidator.isValidSync(credentialsData)) {
      var validationErrors = {};
      (async () => {
        await RegisterTokenFormValidator.validate(credentialsData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setCredentialsDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      handleSubmitLegalEntity();
    }
  };

  const handleSubmitLegalEntity = () => {
    setLoading(true);
    validateFormDataLegalEntity();
    if (SignUpFormValidatorLegalEntity.isValidSync(formDataLegalEntity)) {
      AuthRepository.registerLegalEntityUser(
        formDataLegalEntity,
        credentialsData.signedXml
      ).then(
        (res) => {
          setRedirectTo("/login");
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    }
  };

  const validateFormDataLegalEntity = () => {
    if (!SignUpFormValidatorLegalEntity.isValidSync(formDataLegalEntity)) {
      var validationErrors = {};
      (async () => {
        await SignUpFormValidatorLegalEntity.validate(formDataLegalEntity, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const handleChangeFormDataLegalEntity = (name, value) => {
    setFormDataLegalEntity(_.set({ ...formDataLegalEntity }, name, value));
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <Backdrop className={classes.backdrop} open={loading}>
        <PropagateLoader size={15} color={"#fff"} loading={loading} />
      </Backdrop>
      <Grid container spacing={2}>
        <Grow in={true} timeout={600}>
          <>
            <Grid
              item
              xs={10}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="outlined"
                fullWidth
                size="small"
                error={credentialsDataErrors?.signedXml}
              >
                <Button
                  disabled={loading || tokenSuccess}
                  size="medium"
                  variant="contained"
                  color="primary"
                  style={{
                    color: "white",
                  }}
                  fullWidth={true}
                  startIcon={
                    tokenSuccess ? <Icon>done</Icon> : <Icon>usb</Icon>
                  }
                  onClick={() => signDocument()}
                  error={credentialsDataErrors.signedXml != undefined}
                  helperText={credentialsDataErrors.signedXml}
                >
                  {tokenSuccess
                    ? LOCALE.load_certificate_success
                    : LOCALE.load_certificate}
                </Button>
                {credentialsDataErrors?.signedXml && (
                  <FormHelperText error={true}>
                    {credentialsDataErrors.signedXml}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                size="medium"
                variant="contained"
                color="primary"
                onClick={() =>
                  window.open("/organizationsTutorial.pdf", "_blank")
                }
              >
                <HelpOutline />
              </IconButton>
            </Grid>
          </>
        </Grow>
        <Grow in={true} timeout={700}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.firstName}
              size="small"
              variant="outlined"
              autoFocus
              fullWidth={true}
              error={formDataErrors.firstName !== undefined}
              helperText={formDataErrors.firstName}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "firstName",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formDataLegalEntity?.firstName}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={800}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.lastName}
              size="small"
              variant="outlined"
              fullWidth={true}
              error={formDataErrors.lastName !== undefined}
              helperText={formDataErrors.lastName}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "lastName",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={formDataLegalEntity?.lastName}
            />
          </Grid>
        </Grow>

        <Grow in={true} timeout={900}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={
                _.get(formDataErrors, "institution.institutionType") !==
                undefined
              }
            >
              <InputLabel>{LOCALE.institution}</InputLabel>
              <Select
                value={
                  formDataLegalEntity?.institution.institutionType
                    ? formDataLegalEntity?.institution.institutionType
                    : ""
                }
                onChange={(e) => {
                  handleChangeFormDataLegalEntity(
                    "institution.institutionType",
                    e.target.value
                  );
                }}
                label={LOCALE.institution}
              >
                {/* <MenuItem value={1}>Извршител</MenuItem> */}
                {/* <MenuItem value={2}>Адвокат</MenuItem> */}
                <MenuItem value={3}>{LOCALE.notar}</MenuItem>
              </Select>
              <FormHelperText>
                {_.get(formDataErrors, "institution.institutionType")}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grow>
        <Grow in={true} timeout={1000}>
          <Grid item xs={12}>
            <TextField
              label="Назив"
              size="small"
              variant="outlined"
              fullWidth={true}
              error={_.get(formDataErrors, "institution.name") !== undefined}
              helperText={_.get(formDataErrors, "institution.name")}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "institution.name",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={
                formDataLegalEntity.institution.name
                  ? formDataLegalEntity.institution.name
                  : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1100}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.edb}
              size="small"
              type="number"
              variant="outlined"
              fullWidth={true}
              error={_.get(formDataErrors, "institution.edb") !== undefined}
              helperText={_.get(formDataErrors, "institution.edb")}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "institution.edb",
                  e.target.value
                );
              }}
              value={
                formDataLegalEntity?.institution.edb
                  ? formDataLegalEntity?.institution.edb
                  : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1200}>
          <Grid item xs={12}>
            <Autocomplete
              noOptionsText={LOCALE.noOptionsText}
              size="small"
              value={
                formDataLegalEntity.institution.countryLocation?.name
                  ? formDataLegalEntity.institution.countryLocation?.name
                  : ""
              }
              onChange={(event, newValue) => {
                handleChangeFormDataLegalEntity(
                  "institution.countryLocation",
                  countries?.filter((country) => country.name === newValue)[0]
                );
              }}
              inputValue={countrySearch}
              onInputChange={(event, newInputValue) => {
                setCountrySearch(Transliteration.toUpperCyrilic(newInputValue));
              }}
              id="countryLocation"
              options={countries?.map((country) => country.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    _.get(formDataErrors, "institution.countryLocation") !==
                    undefined
                  }
                  helperText={_.get(
                    formDataErrors,
                    "institution.countryLocation"
                  )}
                  label={LOCALE.country}
                  fullWidth
                  onFocus={onFocus}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grow>
        {LOCALE && LOCALE.getLanguage() === "al" && (
          <Grow in={true} timeout={1200}>
            <Grid item xs={12}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                disabled={true}
                label={`${LOCALE.country} (SQ)`}
                value={
                  formDataLegalEntity?.institution?.countryLocation?.nameAl ??
                  ""
                }
              />
            </Grid>
          </Grow>
        )}
        {formDataLegalEntity.institution.countryLocation?.id === "0" ? (
          <Grow in={true} timeout={1300}>
            <Grid item xs={12}>
              <Autocomplete
                noOptionsText={LOCALE.noOptionsText}
                size="small"
                value={
                  formDataLegalEntity.institution.placeLocation?.name
                    ? formDataLegalEntity.institution.placeLocation?.name
                    : ""
                }
                onChange={(event, newValue) => {
                  handleChangeFormDataLegalEntity(
                    "institution.placeLocation",
                    places?.filter((place) => place.name === newValue)[0]
                  );
                }}
                inputValue={placeSearch}
                onInputChange={(event, newInputValue) => {
                  setPlaceSearch(Transliteration.toUpperCyrilic(newInputValue));
                }}
                id="placeLiving"
                options={places?.map((place) => place.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={LOCALE.place}
                    fullWidth
                    error={
                      _.get(formDataErrors, "institution.placeLocation") !==
                      undefined
                    }
                    helperText={_.get(
                      formDataErrors,
                      "institution.placeLocation"
                    )}
                    onFocus={onFocus}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grow>
        ) : (
          <Grow in={true} timeout={1300}>
            <Grid item xs={12}>
              <TextField
                label={LOCALE.place}
                size="small"
                variant="outlined"
                autoComplete="false"
                fullWidth={true}
                error={
                  _.get(formDataErrors, "institution.placeLocationManual") !==
                  undefined
                }
                helperText={_.get(
                  formDataErrors,
                  "institution.placeLocationManual"
                )}
                onChange={(e) => {
                  handleChangeFormDataLegalEntity(
                    "institution.placeLocationManual",
                    Transliteration.toUpperCyrilic(e.target.value)
                  );
                }}
                value={
                  formDataLegalEntity.institution.placeLocationManual
                    ? formDataLegalEntity.institution.placeLocationManual
                    : ""
                }
              />
            </Grid>
          </Grow>
        )}
        <Grow in={true} timeout={1400}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.sedishte}
              size="small"
              variant="outlined"
              error={_.get(formDataErrors, "institution.address") !== undefined}
              helperText={_.get(formDataErrors, "institution.address")}
              fullWidth={true}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "institution.address",
                  Transliteration.toUpperCyrilic(e.target.value)
                );
              }}
              value={
                formDataLegalEntity?.institution.address
                  ? formDataLegalEntity?.institution.address
                  : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1500}>
          <Grid item xs={12} md={6}>
            <TextField
              label={LOCALE.email}
              error={formDataErrors.email !== undefined}
              helperText={formDataErrors.email}
              size="small"
              variant="outlined"
              fullWidth={true}
              onChange={(e) => {
                handleChangeFormDataLegalEntity("email", e.target.value);
              }}
              value={
                formDataLegalEntity?.email ? formDataLegalEntity?.email : ""
              }
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1600}>
          <Grid item xs={12} md={6}>
            <MuiPhoneNumber
              size="small"
              label={LOCALE.contact_phone}
              fullWidth={true}
              error={formDataErrors.phoneNumber !== undefined}
              helperText={formDataErrors.phoneNumber}
              variant="outlined"
              defaultCountry={"mk"}
              value={formDataLegalEntity?.phoneNumber}
              onChange={(e) => {
                handleChangeFormDataLegalEntity("phoneNumber", e);
              }}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1700}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.username}
              error={formDataErrors.username !== undefined}
              helperText={formDataErrors.username}
              size="small"
              variant="outlined"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>person</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleChangeFormDataLegalEntity("username", e.target.value);
              }}
              value={formDataLegalEntity?.username}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1800}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.password}
              error={formDataErrors.password !== undefined}
              helperText={formDataErrors.password}
              type="password"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>vpn_key</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleChangeFormDataLegalEntity("password", e.target.value);
              }}
              value={formDataLegalEntity?.password}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={1900}>
          <Grid item xs={12}>
            <TextField
              label={LOCALE.confirm_password}
              error={formDataErrors.confirmPassword !== undefined}
              helperText={formDataErrors.confirmPassword}
              type="password"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>vpn_key</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleChangeFormDataLegalEntity(
                  "confirmPassword",
                  e.target.value
                );
              }}
              value={formDataLegalEntity?.confirmPassword}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={2000}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formDataLegalEntity?.confirmConditions}
                  onChange={(e) => {
                    handleChangeFormDataLegalEntity(
                      "confirmConditions",
                      e.target.checked
                    );
                  }}
                />
              }
              label={LOCALE.accept_terms}
            />
          </Grid>
        </Grow>
        <Grow in={true} timeout={2100}>
          <Grid item xs={12}>
            <Button
              size="medium"
              variant="contained"
              disabled={loading || !formDataLegalEntity?.confirmConditions}
              color="secondary"
              fullWidth={true}
              onClick={() => loginSubmitHandler()}
            >
              {LOCALE.sign_up}
            </Button>
          </Grid>
        </Grow>
      </Grid>
    </React.Fragment>
  );
}
