import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  CardHeader,
  Hidden,
  TextField,
  InputAdornment,
  Divider,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Grow,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useParams } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { Alert, AlertTitle } from "@material-ui/lab";
import { LoginFormValidator } from "./LoginFormValidator";
import { PropagateLoader } from "react-spinners";
import { AuthRepository } from "./AuthRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthService } from "./AuthService";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { ForgotPasswordRepository } from "../../repositories/ForgotPasswordRepository";
import { ResetPasswordValidator } from "./ResetPasswordValidator";

const useStyles = makeStyles(styles);

export default function VerifyRegistrationComponent() {
  const [isVerified, setIsVerified] = React.useState(false);
  const [notFound, setNotFound] = React.useState(false);
  const [counter, setCounter] = React.useState(5);
  const [redirectTo, setRedirectTo] = React.useState();
  const { id } = useParams();

  const classes = useStyles();

  React.useEffect(() => {
    if (id) {
      UsersRepository.verifyUser(id).then(
        (res) => {
          setIsVerified(true);
          countDown();
        },
        (err) => {
          setNotFound(true);
        }
      );
    }
  }, []);

  const countDown = () => {
    let number = 4;
    setInterval(() => {
      if (number === 0) {
        setRedirectTo("/login");
      }
      setCounter(number);
      number = number - 1;
    }, 1000);
  };

  return (
    <React.Fragment>
      {AuthService.getCurrentUser() !== null && <Redirect to={"/"} push />}
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Container>
          <Grid container style={{ paddingTop: "20%" }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              {isVerified ? (
                <div>
                  <Icon color="secondary" style={{ fontSize: "5.5em" }}>
                    check_circle_outline
                  </Icon>
                  <Typography variant="h3">{LOCALE.email_verified}</Typography>
                  <br></br>
                  <Typography variant="h5" component="h4">
                    {LOCALE.forbidden_h2 + counter}
                  </Typography>
                </div>
              ) : notFound ? (
                <div>
                  <Icon color="secondary" style={{ fontSize: "5.5em" }}>
                    highlight_off
                  </Icon>
                  <Typography variant="h3">
                    Проблем при потврдување на е-поштата
                  </Typography>
                  <br></br>
                  <Typography variant="h5" component="h4">
                    Ве молиме проверете го линкот за верификација
                  </Typography>
                </div>
              ) : (
                <div>
                  <CircularProgress color="secondary" size={80} />
                  <Typography variant="h3">{LOCALE.verifying_email}</Typography>
                </div>
              )}
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
