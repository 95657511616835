import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const RequestMarriagePortalRepository = {
  create: (formData, groomIdScan, brideIdScan) => {
    let data = new FormData();
    for (var i = 0; i < groomIdScan?.length; i++) {
      data.append("groomIdScan", groomIdScan[i]);
    }
    for (var i = 0; i < brideIdScan?.length; i++) {
      data.append("brideIdScan", brideIdScan[i]);
    }
    data.append(
      "requestMarriagePortal",
      new Blob([JSON.stringify(formData)], {
        type: "application/json",
      })
    );
    return Axios({
      url: `${SETTINGS.API_URL}requestMarriagePortal`,
      method: "POST",
      data: data,
      header: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getAll: (page, size, filterMap) => {
    return Axios({
      url: SETTINGS.API_URL + "requestMarriagePortal",
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: filterMap,
      },
    });
  },

  getById: (id) => {
    return Axios({
      url: SETTINGS.API_URL + "requestMarriagePortal/" + id,
      method: "GET",
    });
  },

  pay: (id) => {
    return Axios({
      url: SETTINGS.API_URL + "requestMarriagePortal/pay/",
      method: "POST",
      params: {
        requestMarriagePortalId: id,
      },
    });
  },

  payRequest: (id) => {
    return Axios({
      url: SETTINGS.API_URL + "requestMarriagePortal/payRequest/",
      method: "POST",
      params: {
        requestMarriagePortalId: id,
      },
    });
  },
};
