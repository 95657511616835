import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const SignUpFormValidator = yup.object().shape({
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  embg: yup
    .string()
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      return true;
    }),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_format),
  countryLiving: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeLiving: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_living + LOCALE.required_field,
      function (value) {
        if (this.options.parent.countryLiving?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeLivingManual: yup
    .string()
    .test(
      "placeLivingManual",
      LOCALE.place_living + LOCALE.required_field,
      function (value) {
        if (this.options.parent.countryLiving?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  addressLiving: yup
    .string()
    .required(LOCALE.address_living + LOCALE.required_field),
  username: yup
    .string()
    .matches(/^[A-Za-z_-_.][A-Za-z0-9_-_.]{5,}$/, {
      message:
        LOCALE.incorrect_format + LOCALE.username_complexity_validation_msg,
    })
    .required(LOCALE.username + LOCALE.required_field),
  password: yup
    .string()
    .required(LOCALE.login_password + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      LOCALE.password_complexity_validation_msg
    ),
  confirmPassword: yup
    .string()
    .test("confrimPassword", LOCALE.doesnt_match, function (value) {
      if (this.options.parent.password === value) {
        return true;
      } else {
        return false;
      }
    }),
  phoneNumber: yup
    .string()
    .required(LOCALE.contact_phone + LOCALE.required_field)
    .min(8, "Минимум 7 цифри"),
});
