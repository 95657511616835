import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const ApplicationUvmkPortalRepository = {
  create: (formData, fatherIdScan, motherIdScan) => {
    let data = new FormData();
    for (var i = 0; i < fatherIdScan?.length; i++) {
      data.append("fatherIdScan", fatherIdScan[i]);
    }
    for (var i = 0; i < motherIdScan?.length; i++) {
      data.append("motherIdScan", motherIdScan[i]);
    }
    data.append(
      "applicationUVMKPortal",
      new Blob([JSON.stringify(formData)], {
        type: "application/json",
      })
    );
    return Axios({
      url: `${SETTINGS.API_URL}applicationUvmkPortal`,
      method: "POST",
      data: data,
      header: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getAll: (page, size, filterMap) => {
    return Axios({
      url: SETTINGS.API_URL + "applicationUvmkPortal",
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: filterMap,
      },
    });
  },

  getById: (id) => {
    return Axios({
      url: SETTINGS.API_URL + "applicationUvmkPortal/" + id,
      method: "GET",
    });
  },

  approveApplicationUVMKPortalById: (id) => {
    return Axios({
      url: SETTINGS.API_URL + `applicationUvmkPortal/${id}/approve`,
      method: "POST",
    });
  },
};
