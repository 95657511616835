import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const PasswordChangeValidator = yup.object().shape({
  oldPassword: yup
    .string()
    .required(LOCALE.oldPassword + LOCALE.required_field),
  newPassword: yup
    .string()
    .required(LOCALE.newPassword + LOCALE.required_field)
    .matches(
      /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      LOCALE.password_complexity_validation_msg
    ),
  confirmPassword: yup
    .string()
    .required(LOCALE.confirmPassword + LOCALE.required_field)
    .test("confrimPassword", LOCALE.doesnt_match, function (value) {
      if (this.options.parent.newPassword === value) {
        return true;
      } else {
        return false;
      }
    }),
});
