import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  CardHeader,
  Hidden,
  TextField,
  InputAdornment,
  Divider,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Grow,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useParams } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { Alert, AlertTitle } from "@material-ui/lab";
import { LoginFormValidator } from "./LoginFormValidator";
import { PropagateLoader } from "react-spinners";
import { AuthRepository } from "./AuthRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthService } from "./AuthService";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { ForgotPasswordRepository } from "../../repositories/ForgotPasswordRepository";
import { ResetPasswordValidator } from "./ResetPasswordValidator";

const useStyles = makeStyles(styles);

export default function ResetPasswordComponent() {
  const [redirectTo, setRedirectTo] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [severity, setSeverity] = React.useState();
  const [responseMessage, setResponseMessage] = React.useState();
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [globalErrorMessage, setGlobalErrorMessage] = React.useState();
  const [changedPasswordSuccess, setChangedPasswordSuccess] =
    React.useState(false);
  const { id } = useParams();

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const resetPassword = () => {
    setLoading(true);
    setFormDataErrors({});
    if (
      ResetPasswordValidator.isValidSync({
        password: password,
        confirmPassword: confirmPassword,
      })
    ) {
      ForgotPasswordRepository.resetPassword(id, password).then(
        (res) => {
          setGlobalErrorMessage(null);
          setResponseMessage(res.data);
          setLoading(false);
          setChangedPasswordSuccess(true);
        },
        (err) => {
          setGlobalErrorMessage(ErrorMessageResolver.resolve(err));
          setLoading(false);
        }
      );
    } else {
      validateFormData();
    }
  };

  React.useEffect(() => {
    if (changedPasswordSuccess === true) {
      setTimeout(() => {
        setRedirectTo("/login");
      }, 3000);
    }
  }, [changedPasswordSuccess]);

  const validateFormData = () => {
    if (
      !ResetPasswordValidator.isValidSync({
        password: password,
        confirmPassword: confirmPassword,
      })
    ) {
      var validationErrors = {};
      (async () => {
        await ResetPasswordValidator.validate(
          {
            password: password,
            confirmPassword: confirmPassword,
          },
          {
            abortEarly: false,
          }
        ).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
      setLoading(true);
    }
  };

  return (
    <React.Fragment>
      {AuthService.getCurrentUser() !== null && <Redirect to={"/"} push />}
      {redirectTo && <Redirect to={redirectTo} push />}
      <Backdrop className={classes.backdrop} open={loading}>
        <PropagateLoader size={15} color={"#fff"} loading={loading} />
      </Backdrop>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Container>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Hidden smDown>
                <IconButton
                  onClick={() => setRedirectTo("/")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "45%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  onClick={() => setRedirectTo("/")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "5%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Hidden smDown>
                <div style={{ marginTop: "10vh" }}></div>
              </Hidden>
            </Grid>
            <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
            <Grid item md={6} lg={6} sm={6} xs={12} style={{ padding: "15px" }}>
              <Grid
                item
                xs={12}
                md={12}
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <Hidden smDown>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="60px"
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: "15px",
                      paddingTop: "5px",
                    }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      textAlign: "left",
                      paddingTop: "5px",
                      marginLeft: "15px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="30px"
                    style={{ paddingRight: "5px", marginTop: "5px" }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      paddingTop: "0px",
                      marginLeft: "5px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                      fontSize: "11px",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
              </Grid>

              <Card>
                <div
                  style={{
                    textAlign: "center",
                    padding: "0px",
                    margin: "0px",
                    color: "white",
                    backgroundColor: "#70c1c2",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "10px",
                    }}
                  >
                    <Icon>lock_open</Icon>
                    <span style={{ verticalAlign: "top", lineHeight: "1.4" }}>
                      {LOCALE.passwordChange}
                    </span>
                  </Typography>
                </div>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={false} sm={false} md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                      <Hidden smDown>
                        <br />
                      </Hidden>
                      <Grid container spacing={2}>
                        {responseMessage && (
                          <Grid item xs={12}>
                            <Alert severity={"success"}>
                              {LOCALE.getString(responseMessage)}
                            </Alert>
                          </Grid>
                        )}

                        {globalErrorMessage && (
                          <Grid item xs={12}>
                            <Alert severity={"error"}>
                              {globalErrorMessage}
                            </Alert>
                          </Grid>
                        )}
                        {!changedPasswordSuccess && (
                          <React.Fragment>
                            <Grow in={true} timeout={750}>
                              <Grid item xs={12} md={12}>
                                <TextField
                                  label={LOCALE.newPassword}
                                  size="small"
                                  disabled={loading}
                                  variant="outlined"
                                  type="password"
                                  fullWidth={true}
                                  error={formDataErrors.password !== undefined}
                                  helperText={formDataErrors.password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  value={password}
                                />
                              </Grid>
                            </Grow>
                            <Grow in={true} timeout={900}>
                              <Grid item xs={12} md={12}>
                                <TextField
                                  label={LOCALE.confirmPassword}
                                  size="small"
                                  disabled={loading}
                                  variant="outlined"
                                  type="password"
                                  fullWidth={true}
                                  error={
                                    formDataErrors.confirmPassword !== undefined
                                  }
                                  helperText={formDataErrors.confirmPassword}
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  value={confirmPassword}
                                />
                              </Grid>
                            </Grow>

                            <Grow in={true} timeout={1050}>
                              <Grid item xs={12}>
                                <Button
                                  disabled={loading}
                                  size="medium"
                                  variant="contained"
                                  color="secondary"
                                  fullWidth={true}
                                  onClick={() => resetPassword()}
                                >
                                  {LOCALE.passwordChange}
                                </Button>
                              </Grid>
                            </Grow>
                          </React.Fragment>
                        )}

                        <Grow in={true} timeout={1200}>
                          <Grid item xs={6}>
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/login")}
                            >
                              {LOCALE.login}
                            </Button>
                          </Grid>
                        </Grow>

                        <Grow in={true} timeout={1200}>
                          <Grid item xs={6} style={{ textAlign: "right" }}>
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/signup")}
                            >
                              {LOCALE.registration}
                            </Button>
                          </Grid>
                        </Grow>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
