import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  CardHeader,
  Hidden,
  TextField,
  InputAdornment,
  Divider,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Grow,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { Alert, AlertTitle } from "@material-ui/lab";
import { LoginFormValidator } from "./LoginFormValidator";
import { PropagateLoader } from "react-spinners";
import { AuthRepository } from "./AuthRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { AuthService } from "./AuthService";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { ForgotPasswordRepository } from "../../repositories/ForgotPasswordRepository";

const useStyles = makeStyles(styles);

export default function ForgotPasswordComponent() {
  const [redirectTo, setRedirectTo] = React.useState();

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState();
  const [success, setSuccess] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  const forgotPassword = () => {
    setLoading(true);
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      setError("Невалиден формат");
      setLoading(false);
      return;
    } else {
      setError(undefined);
      ForgotPasswordRepository.forgotPassword(email).then(
        (res) => {
          setError(null);
          setLoading(false);
          setSuccess(true);
        },
        (err) => {}
      );
    }
  };

  return (
    <React.Fragment>
      {AuthService.getCurrentUser() !== null && <Redirect to={"/"} push />}
      {redirectTo && <Redirect to={redirectTo} push />}
      <Backdrop className={classes.backdrop} open={loading}>
        <PropagateLoader size={15} color={"#fff"} loading={loading} />
      </Backdrop>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <Container>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Hidden smDown>
                <IconButton
                  onClick={() => setRedirectTo("/")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "45%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  onClick={() => setRedirectTo("/")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "5%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Hidden smDown>
                <div style={{ marginTop: "10vh" }}></div>
              </Hidden>
            </Grid>
            <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
            <Grid item md={6} lg={6} sm={6} xs={12} style={{ padding: "15px" }}>
              <Grid
                item
                xs={12}
                md={12}
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <Hidden smDown>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="60px"
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: "15px",
                      paddingTop: "5px",
                    }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      textAlign: "left",
                      paddingTop: "5px",
                      marginLeft: "15px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="30px"
                    style={{ paddingRight: "5px", marginTop: "5px" }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      paddingTop: "0px",
                      marginLeft: "5px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                      fontSize: "11px",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
              </Grid>

              <Card>
                <div
                  style={{
                    textAlign: "center",
                    padding: "0px",
                    margin: "0px",

                    color: "white",
                    backgroundColor: "#70c1c2",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "10px",
                    }}
                  >
                    <Icon>lock_open</Icon>
                    <span style={{ verticalAlign: "top", lineHeight: "1.4" }}>
                      {LOCALE.passwordChange}
                    </span>
                  </Typography>
                </div>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={false} sm={false} md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                      <Hidden smDown>
                        <br />
                      </Hidden>
                      <Grid container spacing={2}>
                        {success && (
                          <Grid item xs={12}>
                            <Alert severity="success">
                              Ако внесената е-пошта е валидна на истата ке биде
                              испратен линк за промена на лозинката!
                            </Alert>
                          </Grid>
                        )}
                        <Grow in={true} timeout={750}>
                          <Grid item xs={12}>
                            <TextField
                              id="email"
                              label={LOCALE.email}
                              size="small"
                              variant="outlined"
                              autoFocus
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  forgotPassword();
                                }
                              }}
                              fullWidth={true}
                              disabled={loading}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Icon>person</Icon>
                                  </InputAdornment>
                                ),
                              }}
                              error={error != undefined}
                              helperText={error}
                            />
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1050}>
                          <Grid item xs={12}>
                            <Button
                              disabled={loading}
                              size="medium"
                              variant="contained"
                              color="secondary"
                              fullWidth={true}
                              onClick={() => forgotPassword()}
                            >
                              {LOCALE.passwordChange}
                            </Button>
                          </Grid>
                        </Grow>
                        <Grow in={true} timeout={1200}>
                          <Grid item xs={6}>
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/login")}
                            >
                              {LOCALE.login}
                            </Button>
                          </Grid>
                        </Grow>

                        <Grow in={true} timeout={1200}>
                          <Grid item xs={6} style={{ textAlign: "right" }}>
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/signup")}
                            >
                              {LOCALE.registration}
                            </Button>
                          </Grid>
                        </Grow>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
        </Container>
      </div>
    </React.Fragment>
  );
}
