import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const LoginFormValidator = yup.object().shape({
  username: yup.string().required(LOCALE.username + LOCALE.required_field),
  password: yup.string().required(LOCALE.password + LOCALE.required_field),
});

export const LoginTokenFormValidator = yup.object().shape({
  username: yup.string().required(LOCALE.username + LOCALE.required_field),
  password: yup.string().required(LOCALE.password + LOCALE.required_field),
  signedXml: yup.string().required("Сертификат" + LOCALE.required_field),
});

export const RegisterTokenFormValidator = yup.object().shape({
  signedXml: yup.string().required("Сертификат" + LOCALE.required_field),
});

export const ChangeTokenFormValidator = yup.object().shape({
  username: yup.string().required(LOCALE.username + LOCALE.required_field),
  password: yup.string().required(LOCALE.password + LOCALE.required_field),
});
