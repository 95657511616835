import { transliterate as tr, slugify } from "transliteration";
import { transition } from "../../assets/jss/mainStyle";

export const Transliteration = {
  toUpperCyrilic: (originalString) => {
    var chars = originalString.split("");

    for (var i = 0; i < chars.length; i++) {
      if (!cyrilicLetters.includes(chars[i])) {
        chars[i] = tr(chars[i]);
      }
    }

    var trstr = chars;
    for (var i = 0; i < trstr.length; i++) {
      if (latinLetters.includes(trstr[i])) {
        var ind = latinLetters.indexOf(trstr[i]);
        if (ind !== -1) {
          trstr[i] = cyrilicLetters[ind];
        }
      }
    }
    return trstr.join("").toUpperCase();
  },

  macedonianToAlbanianTransliteration: (macedonianText) => {
    // console.log(macedonianText);
    return macedonianText
      .split("")
      .map((c) => macedonianToAlbanianMap[c] || c)
      .join("");
  },

  toCyrilic: (originalString) => {
    var chars = originalString.split("");

    for (var i = 0; i < chars.length; i++) {
      if (!cyrilicLetters.includes(chars[i])) {
        chars[i] = tr(chars[i]);
      }
    }

    var trstr = chars;
    for (var i = 0; i < trstr.length; i++) {
      if (latinLetters.includes(trstr[i])) {
        var ind = latinLetters.indexOf(trstr[i]);
        if (ind !== -1) {
          trstr[i] = cyrilicLetters[ind];
        }
      }
    }
    return trstr.join("");
  },

  ensureAlphanumeric: (originalString) => {},
};

var cyrilicLetters = [
  "А",
  "Б",
  "В",
  "Г",
  "Д",
  "Ѓ",
  "Е",
  "Ж",
  "З",
  "Ѕ",
  "И",
  "Ј",
  "К",
  "Л",
  "Љ",
  "М",
  "Н",
  "Њ",
  "О",
  "П",
  "Р",
  "С",
  "Т",
  "Ќ",
  "У",
  "Ф",
  "Х",
  "Ц",
  "Ч",
  "Џ",
  "Ш",
  "а",
  "б",
  "в",
  "г",
  "д",
  "ѓ",
  "е",
  "ж",
  "з",
  "ѕ",
  "и",
  "ј",
  "к",
  "л",
  "љ",
  "м",
  "н",
  "њ",
  "о",
  "п",
  "р",
  "с",
  "т",
  "ќ",
  "у",
  "ф",
  "х",
  "ц",
  "ч",
  "џ",
  "ш",
];

var latinLetters = [
  "A",
  "B",
  "V",
  "G",
  "D",
  "}",
  "E",
  "|",
  "Z",
  "Y",
  "I",
  "J",
  "K",
  "L",
  "Q",
  "M",
  "N",
  "W",
  "O",
  "P",
  "R",
  "S",
  "T",
  '"',
  "U",
  "F",
  "H",
  "C",
  ":",
  "X",
  "{",
  "a",
  "b",
  "v",
  "g",
  "d",
  "]",
  "e",
  "\\",
  "z",
  "y",
  "i",
  "j",
  "k",
  "l",
  "q",
  "m",
  "n",
  "w",
  "o",
  "p",
  "r",
  "s",
  "t",
  "'",
  "u",
  "f",
  "h",
  "c",
  ";",
  "x",
  "[",
];

const macedonianToAlbanianMap = {
  А: "A",
  Б: "B",
  В: "V",
  Г: "G",
  Д: "D",
  Е: "E",
  Ж: "Zh",
  З: "Z",
  И: "I",
  Ј: "J",
  К: "K",
  Л: "LL",
  М: "M",
  Н: "N",
  О: "O",
  П: "P",
  Р: "R",
  С: "S",
  Т: "T",
  У: "U",
  Ф: "F",
  Х: "X",
  Ц: "C",
  Ч: "Ç",
  Ш: "Sh",
  Ѕ: "Dz",
  Ј: "J",
  Љ: "L",
  Њ: "Nj",
  Ќ: "Q",
  Џ: "Xh",
  Ѓ: "Gj",
};
