import {
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  Icon,
  LinearProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import InfoIcon from "@material-ui/icons/Info";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { Details } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import bgImg from "../../assets/img/productCurvyLines.png";
import Header from "../../common/Header";
import { LOCALE } from "../../properties/Locale";

import { Alert } from "@material-ui/lab";
import Footer from "../../common/Footer";
import { UsersRepository } from "../../repositories/UsersRepository";
import { RequestForInformationRepository } from "../../repositories/RequestForInformationRepository";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { AuthRepository } from "../auth/AuthRepository";
import { AuthService } from "../auth/AuthService";
import { Transliteration } from "../../common/helpers/Transliteration";
import MaterialUiPhoneNumber from "material-ui-phone-number";
import { DropzoneArea } from "material-ui-dropzone";
import { CloudUpload } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import _ from "lodash";

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

function getSteps() {
  return [
    LOCALE.purposer_info,
    "Податоци за барањето",
    LOCALE.preview,
    LOCALE.payment,
  ];
}
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <PermIdentityIcon />,
    2: <InfoIcon />,
    3: <VisibilityIcon />,
    4: <CreditCardIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
export default function RequestForInformation() {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setFormData] = React.useState({
    subject: "",
    content: "",
    proposer: {
      name: "",
      surname: "",
      phone: "",
    },
    institution: {
      name: "",
      address: "",
      edb: "",
    },
  });
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [persistedRequest, setPersistedRequest] = React.useState();
  const [globalFormError, setGlobalFormError] = React.useState(null);
  const steps = getSteps();
  const [currentUser, setCurrentUser] = React.useState();
  const [files, setFiles] = React.useState([]);
  const inputRef = React.useRef();
  const [selectionStart, setSelectionStart] = React.useState();
  const { id } = useParams();

  const updateSelectionStart = () => {
    setSelectionStart(inputRef.current.selectionStart);
  };
  const useStyles = makeStyles((theme) =>
    createStyles({
      previewChip: {
        minWidth: 160,
        maxWidth: 210,
      },
      dropzoneRoot: {
        minHeight: "160px",
        height: "160px",
      },
    })
  );

  const classes = useStyles();

  useEffect(() => {
    // console.log(AuthService.getCurrentUser());
    if (!AuthService.getCurrentUser()) {
      setRedirectTo("/login");
      return;
    }
    if (AuthService.getCurrentUser()?.proposerType !== "ORGANIZATION") {
      setRedirectTo("/");
      return;
    }
    if (id) {
      RequestForInformationRepository.getById(id).then(
        (response) => {
          // console.log(response);
          setFormData(response.data);
          setActiveStep(2);
          setLoading(false);
        },
        (error) => {
          // console.log(error);
          setLoading(false);
        }
      );
    } else {
      UsersRepository.fetchCurrentUser().then(
        (res) => {
          // console.log(res);
          let tmp = { ...formData };
          tmp.proposer.name = res?.data?.firstName;
          tmp.proposer.surname = res?.data?.lastName;
          tmp.proposer.phone = res?.data?.phoneNumber;
          tmp.institution.name = res?.data?.institution?.name;
          tmp.institution.placeLocation = res?.data?.institution?.placeLocation;
          tmp.institution.address = res?.data?.institution?.address;
          tmp.institution.edb = res?.data?.institution?.edb;
          setFormData(tmp);
          setCurrentUser(res.data);
        },
        (err) => {
          if (err.message === "Network Error") {
            setGlobalFormError(ErrorMessageResolver.resolve(err));
          } else {
            AuthRepository.logout();
          }
        }
      );
    }
  }, []);

  const handleChange = (name, value) => {
    setFormData(_.set({ ...formData }, name, value));
  };

  const handleNextStep = () => {
    if (activeStep === 2 && !formData?.id) {
      handleSave();
      setActiveStep(activeStep + 1);
    } else {
      setActiveStep(activeStep + 1);
      setFormDataErrors({});
    }
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSave = () => {
    setLoading(true);
    RequestForInformationRepository.create(formData, files).then(
      (res) => {
        // console.log(res);
        setFormData(res.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (inputRef.current && selectionStart) {
      inputRef.current.selectionStart = selectionStart + 1;
      inputRef.current.selectionEnd = selectionStart + 1;
    }
  }, [formData.content]);

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />
        <Container>
          <Grid container>
            <Grid item md={12} style={{ marginBottom: "20px" }}>
              <Typography variant="h5">
                {"Барање за информации и податоци"}
              </Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: "0px" }}
                    display="flex"
                    alignItems="center"
                  >
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                          ></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      backgroundColor: "rgb(212 212 212 / 0.85)",
                      textAlign: "center",
                      marginTop: "-20px",
                      marginBottom: "20px",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      <strong>{steps[activeStep]}</strong>
                    </Typography>
                  </Grid>
                  {globalFormError && (
                    <React.Fragment>
                      <Hidden xsDown>
                        <Grid item xs={12} md={3}></Grid>
                      </Hidden>
                      <Grid item xs={12} md={6}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                    </React.Fragment>
                  )}
                  {activeStep === 0 && (
                    <Grid item xs={12} md={12}>
                      <Grid container>
                        <Grid item md={3} xs={false}></Grid>
                        <Grid item md={6} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.firstName}
                                size="small"
                                variant="outlined"
                                autoFocus
                                disabled
                                fullWidth={true}
                                value={
                                  formData?.proposer?.name
                                    ? formData?.proposer?.name
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.lastName}
                                size="small"
                                variant="outlined"
                                autoFocus
                                disabled
                                fullWidth={true}
                                value={
                                  formData?.proposer?.surname
                                    ? formData?.proposer?.surname
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Назив на институција"
                                size="small"
                                variant="outlined"
                                disabled
                                fullWidth={true}
                                value={
                                  formData?.institution?.name
                                    ? formData?.institution?.name
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.mesto_na_sedishte}
                                size="small"
                                disabled
                                variant="outlined"
                                fullWidth={true}
                                value={
                                  formData?.institution?.placeLocation?.name
                                    ? formData?.institution?.placeLocation?.name
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.ulica_na_sedishte}
                                size="small"
                                disabled
                                variant="outlined"
                                fullWidth={true}
                                value={
                                  formData?.institution?.address
                                    ? formData?.institution?.address
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.edb}
                                size="small"
                                disabled
                                type="number"
                                variant="outlined"
                                fullWidth={true}
                                value={
                                  formData?.institution?.edb
                                    ? formData?.institution?.edb
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <MaterialUiPhoneNumber
                                size="small"
                                label={LOCALE.contact_phone}
                                fullWidth={true}
                                variant="outlined"
                                defaultCountry={"mk"}
                                value={
                                  formData?.proposer?.phone
                                    ? formData?.proposer?.phone
                                    : ""
                                }
                                onChange={(value) => {
                                  handleChange(`proposer.phone`, value);
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={3} xs={false}></Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid item xs={12} md={12}>
                      <Grid container>
                        <Grid item md={3} xs={false}></Grid>
                        <Grid item md={6} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label="Ваш акт бр."
                                size="small"
                                variant="outlined"
                                fullWidth={true}
                                value={
                                  formData?.relatedToAct
                                    ? formData?.relatedToAct
                                    : ""
                                }
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    relatedToAct:
                                      Transliteration.toUpperCyrilic(
                                        e.target.value
                                      ),
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Предмет"
                                size="small"
                                variant="outlined"
                                fullWidth={true}
                                value={
                                  formData?.subject ? formData?.subject : ""
                                }
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    subject: Transliteration.toUpperCyrilic(
                                      e.target.value
                                    ),
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Содржина на барањето"
                                size="small"
                                variant="outlined"
                                fullWidth={true}
                                multiline
                                rows={13}
                                value={
                                  formData?.content ? formData?.content : ""
                                }
                                onSelect={updateSelectionStart}
                                inputRef={inputRef}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    content: Transliteration.toCyrilic(
                                      e.target.value
                                    ),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <DropzoneArea
                                multiple={false}
                                onChange={(files) => {
                                  // console.log(files);
                                  setFiles(files);
                                }}
                                initialFiles={files}
                                showPreviews={true}
                                showPreviewsInDropzone={false}
                                filesLimit={10}
                                useChipsForPreview
                                dropzoneText={"Прикачете датотеки тука"}
                                acceptedFiles={[
                                  "image/*",
                                  "application/pdf",
                                  "application/vnd.openxmlformats",
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                ]}
                                previewGridProps={{
                                  container: { spacing: 1, direction: "row" },
                                }}
                                previewChipProps={{
                                  classes: { root: classes.previewChip },
                                }}
                                previewText="Прикачени датотеки"
                                dropzoneClass={classes.dropzoneRoot}
                                icon={CloudUpload}
                              ></DropzoneArea>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={3} xs={false}></Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 2 && (
                    <>
                      <Grid item xs={3} md={2}></Grid>
                      <Grid item xs={6} md={8}>
                        <Table
                          size="small"
                          id="app-h-prev-tabl"
                          className="table-stripped"
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{ backgroundColor: "lightgray" }}
                              >
                                <strong>{LOCALE.proposer_data}</strong>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{LOCALE.firstName}</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.proposer?.name
                                  ? formData?.proposer?.name
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{LOCALE.lastName}</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.proposer?.surname
                                  ? formData?.proposer?.surname
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{LOCALE.naziv}</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.institution?.name
                                  ? formData?.institution?.name
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{LOCALE.mesto_na_sedishte}</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.institution?.placeLocation?.name
                                  ? formData?.institution?.placeLocation?.name
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{LOCALE.ulica_na_sedishte}</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.institution?.address
                                  ? formData?.institution?.address
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{LOCALE.edb}</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.institution?.edb
                                  ? formData?.institution?.edb
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{LOCALE.contact_phone}</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.proposer?.phone
                                  ? formData?.proposer?.phone
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                colSpan={3}
                                style={{ backgroundColor: "lightgray" }}
                              >
                                <strong>Податоци за барањето</strong>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Ваш акт бр.</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.relatedToAct
                                  ? formData?.relatedToAct
                                  : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Предмент</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.subject ? formData?.subject : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Содржина на барањето</TableCell>
                              <TableCell colSpan={2}>
                                {formData?.content ? formData?.content : ""}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Документи во прилог</TableCell>
                              <TableCell colSpan={2}>
                                {files?.length > 0 ? (
                                  <ul>
                                    {files?.map((file, index) => (
                                      <li key={index}>
                                        <span>{file.name}</span>
                                      </li>
                                    ))}
                                  </ul>
                                ) : (
                                  formData?.attachments?.length > 0 && (
                                    <ul>
                                      {formData.attachments.map(
                                        (attachment, index) => (
                                          <li key={index}>
                                            <span>{attachment?.fileName}</span>
                                          </li>
                                        )
                                      )}{" "}
                                    </ul>
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>{" "}
                      <Grid item xs={3} md={2}></Grid>
                    </>
                  )}
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Divider></Divider>

                    {activeStep !== 0 && activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={loading || formData?.id}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => handlePrevStep()}
                      >
                        {LOCALE.prev}
                      </Button>
                    )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => handleNextStep()}
                      >
                        {LOCALE.next}
                      </Button>
                    )}

                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={loading}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        // onClick={() => {
                        //   window.location.href = `${
                        //     SETTINGS.API_URL
                        //   }/payment/initialization?reasonType=transcript&rel=${
                        //     persistedRequest.id
                        //   }&access_token=${
                        //     AuthService.getToken().access_token
                        //   }`;
                        // }}
                      >
                        {LOCALE.iAgree}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <br></br>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
