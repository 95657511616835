import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Hidden,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import React from "react";
import { LOCALE } from "../../properties/Locale";

import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { Transliteration } from "../../common/helpers/Transliteration";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { moment } from "../../App";
import { AuthRepository } from "../auth/AuthRepository";
import { useEffect } from "react";
import { AuthService } from "../auth/AuthService";

if (localStorage.getItem("locale") && localStorage.getItem("locale") === "al") {
  moment.locale("al");
} else {
  moment.locale("mk");
}

export default function ApplicationUvmkMarriageDetails({
  handleChange,
  formData,
  formDataErrors,
  onFocus,
  loading,
  currentUser,
  deliveryRegistryAreaSearch,
  setDeliveryRegistryAreaSearch,
  registryAreas,
  marriageCountrySearch,
  setMarriageCountrySearch,
  countries,
  marriagePlaceSearch,
  setMarriagePlaceSearch,
  places,
}) {
  useEffect(() => {
    console.log(AuthService.getCurrentUser());
  }, []);
  return (
    <React.Fragment>
      <Grid item xs={12} style={{ paddingBottom: "10px" }}>
        <Grid container>
          <Hidden smUp>
            <Grid item xs={12}>
              <Divider
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              ></Divider>
            </Grid>
          </Hidden>
          <Grid item xs={false} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="info">
                  <AlertTitle>{LOCALE.warning}</AlertTitle>
                  {LOCALE.newborn_warning_1}
                </Alert>
              </Grid>
              <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {LOCALE.birth_status}
                  </FormLabel>
                  <RadioGroup
                    aria-label="bornInMarriage"
                    row
                    value={
                      formData?.bornInMarriage ? formData?.bornInMarriage : null
                    }
                    onChange={(e) =>
                      handleChange("bornInMarriage", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value={0}
                      checked={formData?.bornInMarriage == 0}
                      control={<Radio />}
                      label={LOCALE.in_marriage}
                    />
                    <FormControlLabel
                      value={1}
                      disabled={AuthService.getCurrentUser()?.gender == "MALE"}
                      checked={formData?.bornInMarriage == 1}
                      control={<Radio />}
                      label={LOCALE.out_of_wedlock}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formData?.bornInMarriage == 0 && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                    >
                      <KeyboardDatePicker
                        cancelLabel={LOCALE.cancel}
                        okLabel={LOCALE.ok}
                        label={LOCALE.marriage_date}
                        fullWidth
                        allowKeyboardControl={true}
                        keyboardIcon={<Icon>today</Icon>}
                        size="small"
                        format="DD/MM/YYYY"
                        inputVariant="outlined"
                        onChange={(date) => handleChange("dateMarriage", date)}
                        value={formData?.dateMarriage}
                        helperText={formDataErrors?.dateMarriage}
                        error={formDataErrors?.dateMarriage !== undefined}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={true}
                      noOptionsText={LOCALE.noOptionsText}
                      autoComplete={false}
                      size="small"
                      value={
                        formData?.countryMarriage?.name
                          ? formData?.countryMarriage.name
                          : ""
                      }
                      onChange={(event, newValue) => {
                        handleChange("countryMarriage", newValue);
                      }}
                      inputValue={Transliteration.toUpperCyrilic(
                        marriageCountrySearch
                      )}
                      onInputChange={(event, newInputValue) => {
                        setMarriageCountrySearch(
                          Transliteration.toUpperCyrilic(newInputValue)
                        );
                      }}
                      options={countries?.map((country) => country.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={LOCALE.marriageCountry}
                          fullWidth
                          error={formDataErrors?.countryMarriage !== undefined}
                          helperText={formDataErrors?.countryMarriage}
                          onFocus={onFocus}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={loading}
                      noOptionsText={LOCALE.noOptionsText}
                      autoComplete={false}
                      size="small"
                      value={
                        formData?.placeMarriage?.name
                          ? formData?.placeMarriage.name
                          : ""
                      }
                      onChange={(event, newValue) => {
                        handleChange(
                          "placeMarriage",
                          places?.find((place) => place.name == newValue)
                        );
                      }}
                      inputValue={Transliteration.toUpperCyrilic(
                        marriagePlaceSearch
                      )}
                      onInputChange={(event, newInputValue) => {
                        setMarriagePlaceSearch(
                          Transliteration.toUpperCyrilic(newInputValue)
                        );
                      }}
                      options={places?.map((place) => place.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={LOCALE.marriagePlace}
                          fullWidth
                          error={
                            formDataErrors["placeMarriage.id"] !== undefined
                          }
                          helperText={formDataErrors["placeMarriage.id"]}
                          onFocus={onFocus}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
