import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { AuthService } from "../auth/AuthService";
import { EmbgValidator } from "../../common/helpers/EmbgValidator";

export const BasicDataValidator = yup.object().shape({
  embg: yup
    .string()
    .test("embg-not-adult", LOCALE.is_adult_message, function (value) {
      if (EmbgToDate.getAge(EmbgToDate.getDate(value)) < 18) {
        return false;
      } else {
        return true;
      }
    })
    .test("embg-doesnt-match", LOCALE.embg_doesnt_match, function (value) {
      if (AuthService.getCurrentUser().embg !== value) {
        return false;
      } else {
        return true;
      }
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  country: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeBirth: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeBirthManual: yup
    .string()
    .test(
      "placeLivingManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  registryArea: yup
    .object()
    .test(
      "registryArea",
      "Матично подрачје во кое е направен уписот" + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});

export const ParentsDataValidator = yup.object().shape({
  fatherName: yup
    .string()
    .required(LOCALE.father_name + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  fatherSurname: yup
    .string()
    .required(LOCALE.father_surname + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),

  motherName: yup
    .string()
    .required(LOCALE.mother_name + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  motherSurname: yup
    .string()
    .required(LOCALE.mother_surname + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
});

export const PickUpWayValidator = yup.object().shape({
  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_UVMK") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});

export const ApplicantDocumentsValidator = yup.object().shape({
  idDocumentNumber: yup
    .string()
    .test(
      "idDocumentNumber",
      "Полето мора да има точно 8 карактери",
      function (value) {
        if (this.options.parent.passportDocumentNumber.length === 8) {
          return true;
        } else {
          if (value.length === 8) {
            return true;
          } else {
            return false;
          }
        }
      }
    ),
  passportDocumentNumber: yup
    .string()
    .test(
      "idDocumentNumber",
      "Полето мора да има точно 8 карактери",
      function (value) {
        if (this.options.parent.idDocumentNumber.length === 8) {
          return true;
        } else {
          if (value.length === 8) {
            return true;
          } else {
            return false;
          }
        }
      }
    ),
});

export const AllFormDataValidator = yup.object().shape({
  embg: yup
    .string()
    .test("embg-not-adult", LOCALE.is_adult_message, function (value) {
      if (EmbgToDate.getAge(EmbgToDate.getDate(value)) >= 18) {
        return true;
      } else {
        return false;
      }
    })
    .test("embg-doesnt-match", LOCALE.embg_doesnt_match, function (value) {
      if (AuthService.getCurrentUser().embg !== value) {
        return false;
      } else {
        return true;
      }
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  country: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeBirth: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeBirthManual: yup
    .string()
    .nullable()
    .test(
      "placeLivingManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  registryArea: yup
    .object()
    .test(
      "registryArea",
      "Матично подрачје во кое е направен уписот" + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  fatherName: yup
    .string()
    .required(LOCALE.father_name + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  fatherSurname: yup
    .string()
    .required(LOCALE.father_surname + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),

  motherName: yup
    .string()
    .required(LOCALE.mother_name + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  motherSurname: yup
    .string()
    .required(LOCALE.mother_surname + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_UVMK") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  idDocumentNumber: yup
    .string()
    .test(
      "idDocumentNumber",
      "Полето мора да има точно 8 карактери",
      function (value) {
        if (this.options.parent.passportDocumentNumber.length === 8) {
          return true;
        } else {
          if (value.length === 8) {
            return true;
          } else {
            return false;
          }
        }
      }
    ),
  passportDocumentNumber: yup
    .string()
    .test(
      "idDocumentNumber",
      "Полето мора да има точно 8 карактери",
      function (value) {
        if (this.options.parent.idDocumentNumber.length === 8) {
          return true;
        } else {
          if (value.length === 8) {
            return true;
          } else {
            return false;
          }
        }
      }
    ),
});
