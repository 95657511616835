import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const ChangeCertificateRepository = {
  changeCertificateRequest: (username, password, recaptchaToken) => {
    return Axios({
      url: `${SETTINGS.API_URL}/changeCertificate`,
      method: "POST",
      params: {
        username: username,
        password: password,
        "g-recaptcha-response": recaptchaToken,
      },
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  changeCertificate: (token, certificate) => {
    return Axios({
      url: `${SETTINGS.API_URL}/changeCertificate`,
      method: "PUT",
      data: { token: token, certificate: certificate },
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
};
