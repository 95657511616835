import MomentUtils from "@date-io/moment";
import {
  Divider,
  FormControl,
  Grid,
  Hidden,
  TextField,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import { LOCALE } from "../../properties/Locale";

import { Autocomplete } from "@material-ui/lab";
import { Transliteration } from "../../common/helpers/Transliteration";
import moment from "moment";
import MaterialUiPhoneNumber from "material-ui-phone-number";

if (localStorage.getItem("locale") && localStorage.getItem("locale") === "al") {
  moment.locale("al");
} else {
  moment.locale("mk");
}

export default function ApplicationUvmkPortalParentsData({
  handleChange,
  getParentIndex,
  formData,
  formDataErrors,
  countrySearch,
  setCountrySearch,
  onFocus,
  countries,
  defaultCountry,
  setPlaceSearch,
  placeSearch,
  places,
  parentType,
  citizenshipSearch,
  setCitizenshipSearch,
  educations,
  setEducationSearch,
  educationSearch,
  nationalities,
  setNationalitySearch,
  nationalitySearch,
  occupations,
  setOccupationSearch,
  occupationSearch,
  livingCountrySearch,
  setLivingCountrySearch,
  livingPlaceSearch,
  setLivingPlaceSearch,
}) {
  return (
    <React.Fragment>
      <Grid item xs={12} style={{ paddingBottom: "10px" }}>
        <Grid container>
          <Hidden smUp>
            <Grid item xs={12}>
              <Divider
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              ></Divider>
            </Grid>
          </Hidden>
          <Grid item xs={false} md={3}></Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={LOCALE.embg}
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="number"
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].embg`,
                      e.target.value
                    )
                  }
                  value={
                    formData?.parentsData[getParentIndex(parentType)]?.embg
                      ? formData?.parentsData[getParentIndex(parentType)]?.embg
                      : ""
                  }
                  error={formDataErrors["parentsData[0].embg"] !== undefined}
                  helperText={formDataErrors["parentsData[0].embg"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.firstName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].firstName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.parentsData[getParentIndex(parentType)]?.firstName
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.firstName
                      : ""
                  }
                  error={
                    formDataErrors["parentsData[0].firstName"] !== undefined
                  }
                  helperText={formDataErrors["parentsData[0].firstName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.lastName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].lastName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.parentsData[getParentIndex(parentType)]?.lastName
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.lastName
                      : ""
                  }
                  error={
                    formDataErrors["parentsData[0].lastName"] !== undefined
                  }
                  helperText={formDataErrors["parentsData[0].lastName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={LOCALE.birth_surname}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].maidenName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.maidenName
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.maidenName
                      : ""
                  }
                  error={
                    formDataErrors["parentsData[0].maidenName"] !== undefined
                  }
                  helperText={formDataErrors["parentsData[0].maidenName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      cancelLabel={LOCALE.cancel}
                      okLabel={LOCALE.ok}
                      disableToolbar
                      disableFuture
                      format="DD/MM/YYYY"
                      margin="dense"
                      inputVariant="outlined"
                      id="bride-birth-date"
                      label={LOCALE.birthDate}
                      value={
                        formData?.parentsData[getParentIndex(parentType)]
                          ?.dateBirth
                          ? formData?.parentsData[getParentIndex(parentType)]
                              ?.dateBirth
                          : null
                      }
                      onChange={(date) =>
                        handleChange(
                          `parentsData[${getParentIndex(
                            parentType
                          )}].dateBirth`,
                          date
                        )
                      }
                      error={
                        formDataErrors["parentsData[0].dateBirth"] !== undefined
                      }
                      helperText={
                        formDataErrors["parentsData[0].dateBirth"]
                          ? "Невалиден датум"
                          : ""
                      }
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  autoComplete={false}
                  size="small"
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.birthCountry?.name
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.birthCountry?.name
                      : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].birthCountry`,
                      countries.find((country) => country.name === newValue)
                    );
                  }}
                  inputValue={countrySearch}
                  onInputChange={(event, newInputValue) => {
                    setCountrySearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={countries?.map((country) => country.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.country_born}
                      fullWidth
                      error={
                        formDataErrors["parentsData[0].birthCountry.id"] !==
                        undefined
                      }
                      helperText={
                        formDataErrors["parentsData[0].birthCountry.id"]
                      }
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {formData?.parentsData[getParentIndex(parentType)]?.birthCountry
                ?.id === defaultCountry.id ? (
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    noOptionsText={LOCALE.noOptionsText}
                    size="small"
                    value={
                      formData?.parentsData[getParentIndex(parentType)]
                        ?.birthPlace?.name
                        ? formData?.parentsData[getParentIndex(parentType)]
                            ?.birthPlace?.name
                        : null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        `parentsData[${getParentIndex(parentType)}].birthPlace`,
                        places.find((place) => place.name === newValue)
                      );
                    }}
                    inputValue={placeSearch}
                    onInputChange={(event, newInputValue) => {
                      setPlaceSearch(
                        Transliteration.toUpperCyrilic(newInputValue)
                      );
                    }}
                    options={places?.map((place) => place.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={LOCALE.place_born}
                        fullWidth
                        error={
                          formDataErrors["parentsData[0].birthPlace"] !==
                          undefined
                        }
                        helperText={formDataErrors["parentsData[0].birthPlace"]}
                        onFocus={onFocus}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={LOCALE.place_born}
                      size="small"
                      variant="outlined"
                      autoComplete="false"
                      fullWidth={true}
                      onChange={(e) => {
                        handleChange(
                          `parentsData[${getParentIndex(
                            parentType
                          )}].birthPlaceManual`,
                          Transliteration.toUpperCyrilic(e.target.value)
                        );
                      }}
                      value={
                        formData?.parentsData[getParentIndex(parentType)]
                          ?.birthPlaceManual
                          ? formData?.parentsData[getParentIndex(parentType)]
                              ?.birthPlaceManual
                          : ""
                      }
                      error={
                        formDataErrors["parentsData[0].birthPlaceManual"] !==
                        undefined
                      }
                      helperText={
                        formDataErrors["parentsData[0].birthPlaceManual"]
                      }
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={6}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.citizenship?.name
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.citizenship?.id === defaultCountry.id
                        ? "МАКЕДОНСКО/..."
                        : formData?.parentsData[getParentIndex(parentType)]
                            ?.citizenship?.name
                      : null
                  }
                  onChange={(event, newValue) => {
                    // console.log(newValue);
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].citizenship`,
                      newValue === "МАКЕДОНСКО/..."
                        ? defaultCountry
                        : countries.find((country) => country.name === newValue)
                    );
                  }}
                  inputValue={citizenshipSearch}
                  onInputChange={(event, newInputValue) => {
                    setCitizenshipSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={countries?.map((country) =>
                    country.id == defaultCountry.id
                      ? "МАКЕДОНСКО/..."
                      : country.name
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.citizenship}
                      fullWidth
                      variant="outlined"
                      error={
                        formDataErrors["parentsData[0].citizenship.id"] !==
                        undefined
                      }
                      helperText={
                        formDataErrors["parentsData[0].citizenship.id"]
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  autoComplete={false}
                  size="small"
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.nationality?.title
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.nationality?.title
                      : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].nationality`,
                      nationalities.find(
                        (nationality) => nationality.title === newValue
                      )
                    );
                  }}
                  inputValue={nationalitySearch}
                  onInputChange={(event, newInputValue) => {
                    setNationalitySearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={nationalities?.map(
                    (nationality) => nationality.title
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.nationality}
                      fullWidth
                      error={
                        formDataErrors["parentsData[0].nationality.id"] !==
                        undefined
                      }
                      helperText={
                        formDataErrors["parentsData[0].nationality.id"]
                      }
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  value={
                    formData?.parentsData[getParentIndex(parentType)]?.education
                      ?.title
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.education?.title
                      : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].education`,
                      educations.find(
                        (education) => education.title === newValue
                      )
                    );
                  }}
                  inputValue={educationSearch}
                  onInputChange={(event, newInputValue) => {
                    setEducationSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={educations?.map((education) => education.title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.education}
                      fullWidth
                      error={
                        formDataErrors["parentsData[0].education.id"] !==
                        undefined
                      }
                      helperText={formDataErrors["parentsData[0].education.id"]}
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={LOCALE.schoolName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].schoolName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.schoolName
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.schoolName
                      : ""
                  }
                  error={
                    formDataErrors["parentsData[0].schoolName"] !== undefined
                  }
                  helperText={formDataErrors["parentsData[0].schoolName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.occupation?.title
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.occupation?.title
                      : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].occupation`,
                      occupations.find(
                        (occupation) => occupation.title === newValue
                      )
                    );
                  }}
                  inputValue={occupationSearch}
                  onInputChange={(event, newInputValue) => {
                    setOccupationSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={occupations?.map((occupation) => occupation.title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.occupation}
                      fullWidth
                      error={
                        formDataErrors["parentsData[0].occupation.id"] !==
                        undefined
                      }
                      helperText={
                        formDataErrors["parentsData[0].occupation.id"]
                      }
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={LOCALE.workingPosition}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `parentsData[${getParentIndex(
                        parentType
                      )}].workingPosition`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.workingPosition
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.workingPosition
                      : ""
                  }
                  error={
                    formDataErrors["parentsData[0].workingPosition"] !==
                    undefined
                  }
                  helperText={formDataErrors["parentsData[0].workingPosition"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  autoComplete={false}
                  size="small"
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.livingCountry?.name
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.livingCountry?.name
                      : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `parentsData[${getParentIndex(
                        parentType
                      )}].livingCountry`,
                      countries.find((country) => country.name === newValue)
                    );
                  }}
                  inputValue={livingCountrySearch}
                  onInputChange={(event, newInputValue) => {
                    setLivingCountrySearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={countries?.map((country) => country.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.country_living}
                      fullWidth
                      error={
                        formDataErrors["parentsData[0].livingCountry.id"] !==
                        undefined
                      }
                      helperText={
                        formDataErrors["parentsData[0].livingCountry.id"]
                      }
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {formData?.parentsData[getParentIndex(parentType)]?.livingCountry
                ?.id === defaultCountry.id ? (
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    noOptionsText={LOCALE.noOptionsText}
                    size="small"
                    value={
                      formData?.parentsData[getParentIndex(parentType)]
                        ?.livingPlace?.name
                        ? formData?.parentsData[getParentIndex(parentType)]
                            ?.livingPlace?.name
                        : null
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        `parentsData[${getParentIndex(
                          parentType
                        )}].livingPlace`,
                        places.find((place) => place.name === newValue)
                      );
                    }}
                    inputValue={livingPlaceSearch}
                    onInputChange={(event, newInputValue) => {
                      setLivingPlaceSearch(
                        Transliteration.toUpperCyrilic(newInputValue)
                      );
                    }}
                    options={places?.map((place) => place.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={LOCALE.place_living}
                        fullWidth
                        error={
                          formDataErrors["parentsData[0].livingPlace.id"] !==
                          undefined
                        }
                        helperText={
                          formDataErrors["parentsData[0].livingPlace.id"]
                        }
                        onFocus={onFocus}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label={LOCALE.place_living}
                      size="small"
                      variant="outlined"
                      autoComplete="false"
                      fullWidth={true}
                      onChange={(e) => {
                        handleChange(
                          `parentsData[${getParentIndex(
                            parentType
                          )}].livingPlaceManual`,
                          Transliteration.toUpperCyrilic(e.target.value)
                        );
                      }}
                      value={
                        formData?.parentsData[getParentIndex(parentType)]
                          ?.livingPlaceManual
                          ? formData?.parentsData[getParentIndex(parentType)]
                              ?.livingPlaceManual
                          : ""
                      }
                      error={
                        formDataErrors["parentsData[0].livingPlaceManual"] !==
                        undefined
                      }
                      helperText={
                        formDataErrors["parentsData[0].livingPlaceManual"]
                      }
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12} md={12}>
                <TextField
                  label={LOCALE.address_living}
                  size="small"
                  variant="outlined"
                  autoComplete="false"
                  fullWidth={true}
                  onChange={(e) => {
                    handleChange(
                      `parentsData[${getParentIndex(
                        parentType
                      )}].livingAddress`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    );
                  }}
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.livingAddress
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.livingAddress
                      : ""
                  }
                  error={
                    formDataErrors["parentsData[0].livingAddress"] !== undefined
                  }
                  helperText={formDataErrors["parentsData[0].livingAddress"]}
                />
              </Grid>
              <Grid item xs={12}>
                <MaterialUiPhoneNumber
                  size="small"
                  label={LOCALE.contact_phone}
                  fullWidth={true}
                  error={
                    formDataErrors["parentsData[0].phoneNumber"] !== undefined
                  }
                  helperText={formDataErrors["parentsData[0].phoneNumber"]}
                  variant="outlined"
                  defaultCountry={"mk"}
                  value={
                    formData?.parentsData[getParentIndex(parentType)]
                      ?.phoneNumber
                      ? formData?.parentsData[getParentIndex(parentType)]
                          ?.phoneNumber
                      : null
                  }
                  onChange={(e) => {
                    handleChange(
                      `parentsData[${getParentIndex(parentType)}].phoneNumber`,
                      e
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
