import Axios from "axios";
import { SETTINGS } from "../../properties/ApiProps";

export const AuthRepository = {
  smartCardLogin: (user) => {
    return Axios({
      url: SETTINGS.API_URL + "login_token",
      method: "POST",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        username: user.username,
        password: user.password,
        signedXml: user.signedXml,
      },
    });
  },
  fetchToken: (user) => {
    return Axios({
      url: SETTINGS.API_URL + "oauth/token",
      method: "POST",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        username: user.username,
        password: user.password,
        grant_type: "password",
      },
    });
  },

  refreshToken: () => {
    return Axios({
      url: SETTINGS.API_URL + "oauth/token",
      method: "POST",
      params: {
        refresh_token: JSON.parse(localStorage.getItem("accessToken"))
          .refresh_token,
        grant_type: "refresh_token",
      },
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  registerUser: (user) => {
    return Axios({
      url: SETTINGS.API_URL + "users/register",
      method: "POST",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      data: user,
    });
  },

  registerLegalEntityUser: (user, signedXml) => {
    return Axios({
      url: SETTINGS.API_URL + "users/register/legalEntity",
      method: "POST",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      data: user,
      params: {
        signedXml: signedXml,
      },
    });
  },

  logout: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    window.location.href =
      "https://eid.mk/issue/wsfed?wa=wsignout1.0&wreply=https%3A%2F%2Fe-portal.uvmk.gov.mk";
  },

  changeUserSmartCard: (details, recaptchaToken) => {
    return Axios({
      url: SETTINGS.API_URL + "users/tokenChange",
      method: "PUT",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        username: details.username,
        password: details.password,
        certificate: details.signedXml,
        "g-recaptcha-response": recaptchaToken,
      },
    });
  },
};
