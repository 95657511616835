import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const RequestMarriageCertificateRepository = {
  create: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestMarriageCertificates`,
      method: "POST",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  update: (data) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestMarriageCertificates`,
      method: "PUT",
      data: data,
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  get: (id) => {
    return Axios({
      url: `${SETTINGS.API_URL}requestMarriageCertificates/${id}`,
      method: "GET",
    });
  },
};
