import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Divider,
  Grow,
  Paper,
  CircularProgress,
  LinearProgress,
  TextField,
  Hidden,
  TablePagination,
  TableRow,
  TableCell,
  Table,
  IconButton,
  Tooltip,
  Chip,
  TableBody,
  TableHead,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Collapse,
} from "@material-ui/core";
import { moment } from "../../App";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";

const useStyles = makeStyles(styles);

export default function PriceListComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [data, setData] = React.useState();
  const [globalFormError, setGlobalFormError] = React.useState();
  const dispatch = useDispatch();
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);
  const [expanded4, setExpanded4] = React.useState(false);

  React.useEffect(() => {}, []);

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">{LOCALE.priceList}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Hidden smUp>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {LOCALE.cert_request_full_desc}
                    </Typography>
                  </CardContent>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    justify="flex-start"
                  >
                    <IconButton
                      onClick={() => {
                        setExpanded1(!expanded1);
                      }}
                      aria-expanded={expanded1}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                  <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">
                              {LOCALE.form_amount}
                            </TableCell>
                            <TableCell align="center">
                              50 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                            <TableCell align="center">
                              {LOCALE.administrative_tax}
                            </TableCell>
                            <TableCell align="center">
                              100 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center">
                              {LOCALE.commission}
                            </TableCell>
                            <TableCell align="center">
                              9%
                              <br /> (min. 10 МКД & max. 150 МКД)
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                            <TableCell align="center">
                              {LOCALE.postage}
                            </TableCell>
                            <TableCell align="center">
                              100 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Collapse>
                </Card>
                <br />
                <Card>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {LOCALE.cert_request_married_full_desc}
                    </Typography>
                  </CardContent>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    justify="flex-start"
                  >
                    <IconButton
                      onClick={() => {
                        setExpanded2(!expanded2);
                      }}
                      aria-expanded={expanded2}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                  <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">
                              {LOCALE.form_amount}
                            </TableCell>
                            <TableCell align="center">
                              50 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                            <TableCell align="center">
                              {LOCALE.administrative_tax}
                            </TableCell>
                            <TableCell align="center">
                              100 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center">
                              {LOCALE.commission}
                            </TableCell>
                            <TableCell align="center">
                              9%
                              <br /> (min. 10 МКД & max. 150 МКД)
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                            <TableCell align="center">
                              {LOCALE.postage}
                            </TableCell>
                            <TableCell align="center">
                              100 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Collapse>
                </Card>
                <br />
                <Card>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {LOCALE.cert_request_dead_full_desc}
                    </Typography>
                  </CardContent>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    justify="flex-start"
                  >
                    <IconButton
                      onClick={() => {
                        setExpanded3(!expanded3);
                      }}
                      aria-expanded={expanded3}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                  <Collapse in={expanded3} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">
                              {LOCALE.form_amount}
                            </TableCell>
                            <TableCell align="center">
                              50 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                            <TableCell align="center">
                              {LOCALE.administrative_tax}
                            </TableCell>
                            <TableCell align="center">
                              100 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center">
                              {LOCALE.commission}
                            </TableCell>
                            <TableCell align="center">
                              9%
                              <br /> (min. 10 МКД & max. 150 МКД)
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                            <TableCell align="center">
                              {LOCALE.postage}
                            </TableCell>
                            <TableCell align="center">
                              100 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Collapse>
                </Card>
                <br />
                <Card>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {LOCALE.request_marital_status_certificate}
                    </Typography>
                  </CardContent>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    justify="flex-start"
                  >
                    <IconButton
                      onClick={() => {
                        setExpanded4(!expanded4);
                      }}
                      aria-expanded={expanded4}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                  <Collapse in={expanded4} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="center">
                              {LOCALE.form_amount}
                            </TableCell>
                            <TableCell align="center">/</TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                            <TableCell align="center">
                              {LOCALE.administrative_tax}
                            </TableCell>
                            <TableCell align="center">
                              1050 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="center">
                              {LOCALE.commission}
                            </TableCell>
                            <TableCell align="center">
                              9%
                              <br /> (min. 10 МКД & max. 150 МКД)
                            </TableCell>
                          </TableRow>
                          <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                            <TableCell align="center">
                              {LOCALE.postage}
                            </TableCell>
                            <TableCell align="center">
                              100 {LOCALE.denars}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Collapse>
                </Card>
                <br></br>
              </Hidden>
              <Hidden xsDown>
                <Paper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {LOCALE.requestType}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {LOCALE.form_amount}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {LOCALE.administrative_tax}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {LOCALE.commission}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {LOCALE.postage}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {LOCALE.cert_request_full_desc}
                        </TableCell>
                        <TableCell align="center">50 {LOCALE.denars}</TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                        <TableCell align="center">
                          9%
                          <br />{" "}
                          <p style={{ fontSize: "8px" }}>
                            (min. 10 МКД & max. 150 МКД)
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                        <TableCell component="th" scope="row">
                          {LOCALE.cert_request_married_full_desc}
                        </TableCell>
                        <TableCell align="center">50 {LOCALE.denars}</TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                        <TableCell align="center">
                          9%
                          <br />{" "}
                          <p style={{ fontSize: "8px" }}>
                            (min. 10 МКД & max. 150 МКД)
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {LOCALE.cert_request_dead_full_desc}
                        </TableCell>
                        <TableCell align="center">50 {LOCALE.denars}</TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                        <TableCell align="center">
                          9%
                          <br />{" "}
                          <p style={{ fontSize: "8px" }}>
                            (min. 10 МКД & max. 150 МКД)
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                        <TableCell component="th" scope="row">
                          {LOCALE.request_marital_status_certificate}
                        </TableCell>
                        <TableCell align="center">/</TableCell>
                        <TableCell align="center">
                          1050 {LOCALE.denars}
                        </TableCell>
                        <TableCell align="center">
                          9%
                          <br />{" "}
                          <p style={{ fontSize: "8px" }}>
                            (min. 10 МКД & max. 150 МКД)
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {LOCALE.appointment_for_marriage_conclusion}
                        </TableCell>
                        <TableCell align="center">50 {LOCALE.denars}</TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                        <TableCell align="center">
                          9%
                          <br />{" "}
                          <p style={{ fontSize: "8px" }}>
                            (min. 10 МКД & max. 150 МКД)
                          </p>
                        </TableCell>
                        <TableCell align="center">/</TableCell>
                      </TableRow>
                      <TableRow style={{ backgroundColor: "#e2e2e2" }}>
                        <TableCell component="th" scope="row">
                          {LOCALE.appointment_for_marriage_conclusion_outdoor}
                        </TableCell>
                        <TableCell align="center">/</TableCell>
                        <TableCell align="center">/</TableCell>
                        <TableCell align="center">/</TableCell>
                        <TableCell align="center">/</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {
                            LOCALE.newborn_registration_and_issuanse_of_birth_certificate
                          }
                        </TableCell>
                        <TableCell align="center">50 {LOCALE.denars}</TableCell>
                        <TableCell align="center">
                          100 {LOCALE.denars}
                        </TableCell>
                        <TableCell align="center">
                          9%
                          <br />{" "}
                          <p style={{ fontSize: "8px" }}>
                            (min. 10 МКД & max. 150 МКД)
                          </p>
                        </TableCell>
                        <TableCell align="center">/</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <br></br>
                </Paper>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
        <Footer />
      </div>
    </React.Fragment>
  );
}
