import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const MarriageLocationRepository = {
  getMarriageLocations: (name) => {
    return Axios({
      url: SETTINGS.API_URL + "marriageLocations/filteredByName",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        nameFilter: name,
      },
    });
  },
  getMarriageLocationsByRegArea: (registryAreaId) => {
    return Axios({
      url: SETTINGS.API_URL + "marriageLocations/filteredByRegistryArea",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
      params: {
        registryAreaId: registryAreaId,
      },
    });
  },
};
