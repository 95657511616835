import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import { LOCALE } from "../../properties/Locale";

import { Alert, AlertTitle } from "@material-ui/lab";
import ReactInputMask from "react-input-mask";
import Upload from "../../common/components/Upload";

export default function RequestMarriageWizardDocuments({
  handleChange,
  getSpouseIndex,
  formData,
  formDataErrors,
  countrySearch,
  setCountrySearch,
  onFocus,
  countries,
  defaultCountry,
  setPlaceSearch,
  placeSearch,
  places,
  spouseType,
  citizenshipSearch,
  setCitizenshipSearch,
  educations,
  setEducationSearch,
  educationSearch,
  groomIdUpload,
  setGroomIdUpload,
  brideIdUpload,
  setBrideIdUpload,
}) {
  const onUploadGroomComponentClose = (files) => {
    // console.log("GROOM", files);
    setGroomIdUpload(files);
  };
  const onUploadBrideComponentClose = (files) => {
    // console.log("BRIDE", files);
    setBrideIdUpload(files);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={8} xs={false}>
            <Alert severity="info">
              <AlertTitle>{LOCALE.warning}!</AlertTitle>
              {LOCALE.request_marriage_warning}
            </Alert>
          </Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={8}>
            <Divider />
          </Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={2} xs={false}></Grid>
          <Grid item md={8} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <Typography style={{ marginTop: "8px" }}>
                  {LOCALE.groom_identity_card}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                  label={LOCALE.number}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        "GROOM"
                      )}].idDocumentNumber`,
                      e.target.value
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex("GROOM")]
                      ?.idDocumentNumber
                      ? formData?.spousesData[getSpouseIndex("GROOM")]
                          ?.idDocumentNumber
                      : ""
                  }
                  error={
                    formDataErrors[
                      `formData.spousesData[${getSpouseIndex(
                        "GROOM"
                      )}].idDocumentNumber`
                    ] !== undefined
                  }
                  helperText={
                    formDataErrors[
                      `formData.spousesData[${getSpouseIndex(
                        "GROOM"
                      )}].idDocumentNumber`
                    ]
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <ReactInputMask
                  mask="DD/MM/YYYY"
                  maskChar="_"
                  value={
                    formData?.spousesData[getSpouseIndex("GROOM")]
                      ?.idIssuingDate
                      ? formData?.spousesData[getSpouseIndex("GROOM")]
                          ?.idIssuingDate
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex("GROOM")}].idIssuingDate`,
                      e.target.value
                    )
                  }
                  formatChars={{
                    D: "[0-9]",
                    M: "[0-9]",
                    Y: "[0-9]",
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      label={LOCALE.date_of_issue}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={
                        formDataErrors[
                          `formData.spousesData[${getSpouseIndex(
                            "GROOM"
                          )}].idIssuingDate`
                        ] !== undefined
                      }
                      helperText={
                        formDataErrors[
                          `formData.spousesData[${getSpouseIndex(
                            "GROOM"
                          )}].idIssuingDate`
                        ]
                      }
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <ReactInputMask
                  mask="DD/MM/YYYY"
                  maskChar="_"
                  value={
                    formData?.spousesData[getSpouseIndex("GROOM")]?.idExpiryDate
                      ? formData?.spousesData[getSpouseIndex("GROOM")]
                          ?.idExpiryDate
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex("GROOM")}].idExpiryDate`,
                      e.target.value
                    )
                  }
                  formatChars={{
                    D: "[0-9]",
                    M: "[0-9]",
                    Y: "[0-9]",
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      label="Датум на Истекување"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={
                        formDataErrors[
                          `formData.spousesData[${getSpouseIndex(
                            "GROOM"
                          )}].idExpiryDate`
                        ] !== undefined
                      }
                      helperText={
                        formDataErrors[
                          `formData.spousesData[${getSpouseIndex(
                            "GROOM"
                          )}].idExpiryDate`
                        ]
                      }
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Upload
                  onUploadComponentClose={(files) =>
                    onUploadGroomComponentClose(files)
                  }
                  files={groomIdUpload}
                  error={formDataErrors.groomIdUpload ? true : false}
                ></Upload>
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography style={{ marginTop: "8px" }}>
                  {LOCALE.bride_identity_card}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                  label={LOCALE.number}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex(
                        "BRIDE"
                      )}].idDocumentNumber`,
                      e.target.value
                    )
                  }
                  value={
                    formData?.spousesData[getSpouseIndex("BRIDE")]
                      ?.idDocumentNumber
                      ? formData?.spousesData[getSpouseIndex("BRIDE")]
                          ?.idDocumentNumber
                      : ""
                  }
                  error={
                    formDataErrors[
                      `formData.spousesData[${getSpouseIndex(
                        "BRIDE"
                      )}].idDocumentNumber`
                    ] !== undefined
                  }
                  helperText={
                    formDataErrors[
                      `formData.spousesData[${getSpouseIndex(
                        "BRIDE"
                      )}].idDocumentNumber`
                    ]
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <ReactInputMask
                  mask="DD/MM/YYYY"
                  maskChar="_"
                  value={
                    formData?.spousesData[getSpouseIndex("BRIDE")]
                      ?.idIssuingDate
                      ? formData?.spousesData[getSpouseIndex("BRIDE")]
                          ?.idIssuingDate
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex("BRIDE")}].idIssuingDate`,
                      e.target.value
                    )
                  }
                  formatChars={{
                    D: "[0-9]",
                    M: "[0-9]",
                    Y: "[0-9]",
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      label={LOCALE.date_of_issue}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={
                        formDataErrors[
                          `formData.spousesData[${getSpouseIndex(
                            "BRIDE"
                          )}].idIssuingDate`
                        ] !== undefined
                      }
                      helperText={
                        formDataErrors[
                          `formData.spousesData[${getSpouseIndex(
                            "BRIDE"
                          )}].idIssuingDate`
                        ]
                      }
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <ReactInputMask
                  mask="DD/MM/YYYY"
                  maskChar="_"
                  value={
                    formData?.spousesData[getSpouseIndex("BRIDE")]?.idExpiryDate
                      ? formData?.spousesData[getSpouseIndex("BRIDE")]
                          ?.idExpiryDate
                      : ""
                  }
                  onChange={(e) =>
                    handleChange(
                      `spousesData[${getSpouseIndex("BRIDE")}].idExpiryDate`,
                      e.target.value
                    )
                  }
                  formatChars={{
                    D: "[0-9]",
                    M: "[0-9]",
                    Y: "[0-9]",
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      label="Датум на Истекување"
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={
                        formDataErrors[
                          `formData.spousesData[${getSpouseIndex(
                            "BRIDE"
                          )}].idExpiryDate`
                        ] !== undefined
                      }
                      helperText={
                        formDataErrors[
                          `formData.spousesData[${getSpouseIndex(
                            "BRIDE"
                          )}].idExpiryDate`
                        ]
                      }
                    />
                  )}
                </ReactInputMask>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Upload
                  onUploadComponentClose={(files) =>
                    onUploadBrideComponentClose(files)
                  }
                  files={brideIdUpload}
                  error={formDataErrors.brideIdUpload ? true : false}
                ></Upload>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} xs={false}></Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
