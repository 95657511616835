import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  Hidden,
  Tooltip,
  Menu,
  Select,
  FormControl,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/components/headerLinkStyle";
import DrawerMenu from "./DrawerMenu";
import { AuthService } from "../modules/auth/AuthService";
import topbarimg from "../assets/img/headerLine.png";
import Flag from "react-world-flags";
import { useEffect } from "react";
import { Transliteration } from "./helpers/Transliteration";

const useStyles = makeStyles((theme) => ({
  select: {
    paddingTop: "7.5px",
    paddingBottom: "1.5px",
  },
}));
export default function Header() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [voucherTypes, setVoucherTypes] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const [openDrawerMenu, setOpenDrawerMenu] = React.useState(false);
  const [myProfileMenuOpen, setMyProfileMenuOpen] = React.useState(false);
  const [myProfileMenuAnchorEl, setMyProfileMenuAnchorEl] =
    React.useState(null);

  const handleOpenMyProfileMenu = (event) => {
    setMyProfileMenuAnchorEl(event.currentTarget);
    setMyProfileMenuOpen(true);
  };

  const handleChangeOpenDrawerMenu = (value) => {
    setOpenDrawerMenu(value);
  };

  const handleCloseDrawerMenu = () => {
    setOpenDrawerMenu(false);
  };

  const handleLogout = () => {
    window.localStorage.removeItem("currentUser");
    window.localStorage.removeItem("accessToken");
    window.location.href =
      "https://eid.mk/issue/wsfed?wa=wsignout1.0&wreply=https%3A%2F%2Fe-portal.uvmk.gov.mk";
  };

  const handleDownloadFile = () => {
    //download file from public folder
    window.open("/УВМК e-портал корисничко упатство.docx", "_blank");
  };

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <Container
        style={{
          paddingTop: "2vh",
          paddingBottom: "2vh",
        }}
      >
        <Grid container>
          <Grid item xs={9} md={6}>
            <Hidden smDown>
              <img
                alt=""
                src={require("../assets/img/logo-uvmk.png")}
                width="60px"
                onClick={() => setRedirectTo("/")}
                style={{
                  borderRight: "2px solid grey",
                  paddingRight: "15px",
                  paddingTop: "5px",
                  cursor: "pointer",
                }}
              ></img>
              <Typography
                gutterBottom
                onClick={() => setRedirectTo("/")}
                style={{
                  paddingTop: "5px",
                  marginLeft: "15px",
                  marginTop: "6px",
                  display: "inline-block",
                  verticalAlign: "top",
                  cursor: "pointer",
                }}
              >
                {LOCALE.ministry_of_justice}
                <br />
                <strong>{LOCALE.uvmk}</strong>
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <img
                alt=""
                src={require("../assets/img/logo-uvmk.png")}
                width="30px"
                onClick={() => setRedirectTo("/")}
                style={{ paddingRight: "5px", marginTop: "5px" }}
              ></img>
              <Typography
                gutterBottom
                onClick={() => setRedirectTo("/")}
                style={{
                  paddingTop: "0px",
                  marginLeft: "5px",
                  marginTop: "6px",
                  display: "inline-block",
                  verticalAlign: "top",
                  fontSize: "11px",
                }}
              >
                {LOCALE.ministry_of_justice}
                <br />
                <strong>{LOCALE.uvmk}</strong>
              </Typography>
            </Hidden>
          </Grid>
          <Grid item xs={3} md={6}>
            <Hidden mdUp>
              <div style={{ width: "100%", textAlign: "right" }}>
                <IconButton
                  onClick={() => handleChangeOpenDrawerMenu(!openDrawerMenu)}
                >
                  <Icon>apps</Icon>
                </IconButton>
              </div>
            </Hidden>
            <Hidden smDown>
              <div style={{ textAlign: "right" }}>
                <br />

                {window.localStorage &&
                  !window.localStorage.getItem("currentUser") && (
                    <React.Fragment>
                      <Tooltip title={"Корисничко упатство"}>
                        <IconButton
                          onClick={() => {
                            handleDownloadFile();
                          }}
                          style={{ marginRight: "15px" }}
                          size="small"
                        >
                          <Icon>help_outline</Icon>
                        </IconButton>
                      </Tooltip>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => setRedirectTo("/signup")}
                      >
                        {LOCALE.registration}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: "15px" }}
                        onClick={() => setRedirectTo("/login")}
                      >
                        {LOCALE.login}
                      </Button>
                      <FormControl variant="outlined" size="small">
                        <Select
                          size="small"
                          style={{
                            marginLeft: "15px",
                          }}
                          classes={{ select: classes.select }}
                          value={
                            window.localStorage.getItem("locale")
                              ? window.localStorage.getItem("locale")
                              : "mk"
                          }
                          onChange={(e) => {
                            changeLanguage(e.target.value);
                          }}
                        >
                          <MenuItem
                            value={"mk"}
                            style={{ textAlign: "center" }}
                          >
                            <Flag
                              code={"mk"}
                              height="15"
                              width="25"
                              style={{
                                padding: "0px",
                              }}
                            />
                          </MenuItem>
                          <MenuItem
                            value={"al"}
                            style={{ textAlign: "center" }}
                          >
                            <Flag
                              code={"al"}
                              height="15"
                              width="25"
                              style={{
                                padding: "0px",
                              }}
                            />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </React.Fragment>
                  )}
                {AuthService.getCurrentUser() !== null && (
                  <React.Fragment>
                    <div style={{ marginTop: "5px" }}>
                      <Icon size="small">person</Icon>
                      <span
                        style={{
                          verticalAlign: "super",
                          fontSize: "0.8em",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {!LOCALE ||
                          (LOCALE.getLanguage().includes("mk") &&
                            AuthService.getCurrentUser().firstName)}{" "}
                        {!LOCALE ||
                          (LOCALE.getLanguage().includes("mk") &&
                            AuthService.getCurrentUser().lastName)}{" "}
                        {LOCALE &&
                          LOCALE.getLanguage().includes("al") &&
                          Transliteration.macedonianToAlbanianTransliteration(
                            AuthService.getCurrentUser().firstName
                          )}{" "}
                        {LOCALE &&
                          LOCALE.getLanguage().includes("al") &&
                          Transliteration.macedonianToAlbanianTransliteration(
                            AuthService.getCurrentUser().lastName
                          )}
                        <IconButton
                          aria-controls="myProfileMenu"
                          aria-haspopup="true"
                          size="small"
                          style={{ color: "black" }}
                          onClick={handleOpenMyProfileMenu}
                        >
                          <Icon>arrow_drop_down_icon</Icon>
                        </IconButton>
                      </span>
                      <Tooltip title={"Корисничко упатство"}>
                        <IconButton
                          onClick={() => {
                            handleDownloadFile();
                          }}
                          style={{
                            marginLeft: "20px",
                            marginBottom: "14px",
                          }}
                          size="small"
                        >
                          <Icon>help_outline</Icon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={LOCALE.logout}>
                        <IconButton
                          onClick={() => {
                            handleLogout();
                          }}
                          size="small"
                          style={{
                            marginLeft: "20px",
                            marginBottom: "14px",
                          }}
                        >
                          <Icon>logout</Icon>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="myProfileMenu"
                        anchorEl={myProfileMenuAnchorEl}
                        open={myProfileMenuOpen}
                        onClose={() => setMyProfileMenuOpen(false)}
                      >
                        <MenuItem
                          button
                          onClick={() => setRedirectTo("/profile")}
                        >
                          {LOCALE.myProfile}
                        </MenuItem>
                        <MenuItem
                          button
                          onClick={() => setRedirectTo("/passwordChange")}
                        >
                          {LOCALE.passwordChange}
                        </MenuItem>
                      </Menu>
                      <FormControl variant="outlined" size="small">
                        <Select
                          size="small"
                          style={{
                            marginLeft: "15px",
                            border: "none",
                          }}
                          classes={{ select: classes.select }}
                          value={
                            window.localStorage.getItem("locale")
                              ? window.localStorage.getItem("locale")
                              : "mk"
                          }
                          onChange={(e) => {
                            changeLanguage(e.target.value);
                          }}
                        >
                          <MenuItem
                            value={"mk"}
                            style={{ textAlign: "center" }}
                          >
                            <Flag
                              code={"mk"}
                              height="15"
                              width="25"
                              style={{
                                padding: "0px",
                              }}
                            />
                          </MenuItem>
                          <MenuItem
                            value={"al"}
                            style={{ textAlign: "center" }}
                          >
                            <Flag
                              code={"al"}
                              height="15"
                              width="25"
                              style={{
                                padding: "0px",
                              }}
                            />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </Hidden>
          </Grid>
        </Grid>
      </Container>

      <div
        style={{
          backgroundColor: "#70c1c2",
          width: "100%",
          height: "40px",
          marginBottom: "30px",
        }}
      >
        <Hidden smUp>
          <Grid container>
            <Grid item xs={1}>
              <IconButton
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "0px",
                  padding: "6px",
                }}
                onClick={() => {
                  setRedirectTo("/");
                }}
              >
                <Icon>home</Icon>
              </IconButton>
            </Grid>
            <Grid
              item
              xs={11}
              style={{ display: "flex", justifyContent: "right" }}
            >
              {AuthService.getCurrentUser() !== null && (
                <>
                  <div
                    style={{
                      paddingTop: "6px",
                      textAlign: "right",
                      color: "white",
                      marginRight: "5px",
                    }}
                  >
                    <Icon size="small" style={{ marginRight: "5px" }}>
                      person
                    </Icon>
                    <span
                      style={{
                        verticalAlign: "super",
                        fontSize: "0.8em",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {!LOCALE ||
                        (LOCALE.getLanguage().includes("mk") &&
                          AuthService.getCurrentUser().firstName)}{" "}
                      {!LOCALE ||
                        (LOCALE.getLanguage().includes("mk") &&
                          AuthService.getCurrentUser().lastName)}{" "}
                      {LOCALE &&
                        LOCALE.getLanguage().includes("al") &&
                        Transliteration.macedonianToAlbanianTransliteration(
                          AuthService.getCurrentUser().firstName
                        )}{" "}
                      {LOCALE &&
                        LOCALE.getLanguage().includes("al") &&
                        Transliteration.macedonianToAlbanianTransliteration(
                          AuthService.getCurrentUser().lastName
                        )}
                    </span>
                  </div>
                </>
              )}
              <FormControl
                variant="outlined"
                size="small"
                style={{
                  float: "right",
                  marginTop: "3px",
                  marginRight: "2px",
                }}
              >
                <Select
                  size="small"
                  style={{
                    marginLeft: "15px",
                  }}
                  classes={{ select: classes.select }}
                  value={
                    window.localStorage.getItem("locale")
                      ? window.localStorage.getItem("locale")
                      : "mk"
                  }
                  onChange={(e) => {
                    changeLanguage(e.target.value);
                  }}
                >
                  <MenuItem value={"mk"} style={{ textAlign: "center" }}>
                    <Flag
                      code={"mk"}
                      height="15"
                      width="25"
                      style={{
                        padding: "0px",
                        paddingBottom: "1.5px",
                      }}
                    />
                  </MenuItem>
                  <MenuItem value={"al"} style={{ textAlign: "center" }}>
                    <Flag
                      code={"al"}
                      height="15"
                      width="25"
                      style={{
                        padding: "0px",
                        paddingBottom: "1.5px",
                      }}
                    />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Container style={{ lineHeight: "2.5" }}>
            <Button
              style={{
                color: "white",
                fontWeight: "bold",
                marginRight: "10px",
              }}
              onClick={() => setRedirectTo("/")}
            >
              {LOCALE.home}
            </Button>

            {AuthService.getCurrentUser() !== null && (
              <React.Fragment>
                <Button
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                  onClick={() => setRedirectTo("/orders")}
                >
                  {LOCALE.myOrders}
                </Button>
                {AuthService.getCurrentUser()?.proposerType !==
                  "ORGANIZATION" && (
                  <>
                    <Button
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                      onClick={() => setRedirectTo("/appointments")}
                    >
                      {LOCALE.myAppointments}
                    </Button>
                    <Button
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginRight: "10px",
                      }}
                      onClick={() => setRedirectTo("/births/applicationUvmk")}
                    >
                      {LOCALE.newborns}
                    </Button>
                  </>
                )}
                {/* {AuthService.getCurrentUser()?.proposerType ===
                  "ORGANIZATION" && (
                  <Button
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                    onClick={() => setRedirectTo("/requestForInformationList")}
                  >
                    Барања за информации
                  </Button>
                )} */}
                <Button
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    marginRight: "6px",
                    float: "right",
                    lineHeight: "2.2",
                  }}
                  onClick={() => setRedirectTo("/profile")}
                >
                  {LOCALE.myProfile}
                </Button>
              </React.Fragment>
            )}
            <Button
              style={{
                color: "white",
                fontWeight: "bold",
                marginRight: "10px",
              }}
              onClick={() => setRedirectTo("/priceList")}
            >
              {LOCALE.priceList}
            </Button>
          </Container>
        </Hidden>
      </div>

      <DrawerMenu
        onCloseHandler={handleCloseDrawerMenu}
        open={openDrawerMenu}
        anchor="left"
      />
    </React.Fragment>
  );
}
