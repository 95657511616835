import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Hidden,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { LOCALE } from "../../properties/Locale";

import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { Transliteration } from "../../common/helpers/Transliteration";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { moment } from "../../App";
import InputMask from "react-input-mask";
import { hospitals } from "../../common/helpers/Hospitals";

if (localStorage.getItem("locale") && localStorage.getItem("locale") === "al") {
  moment.locale("al");
} else {
  moment.locale("mk");
}

export default function ApplicationUvmkNewbornData({
  handleChange,
  formData,
  formDataErrors,
  onFocus,
  loading,
  currentUser,
  deliveryRegistryAreaSearch,
  setDeliveryRegistryAreaSearch,
  registryAreas,
  places,
  placeSearch,
  setPlaceSearch,
}) {
  const [hospitalSearch, setHospitalSearch] = React.useState("");
  return (
    <React.Fragment>
      <Grid item xs={12} style={{ paddingBottom: "10px" }}>
        <Grid container>
          <Hidden smUp>
            <Grid item xs={12}>
              <Divider
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              ></Divider>
            </Grid>
          </Hidden>
          <Grid item xs={false} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <FormControl
                  disabled={loading}
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={formDataErrors?.gender}
                >
                  <InputLabel>{LOCALE.gender}</InputLabel>
                  <Select
                    value={formData.gender}
                    onChange={(e) => handleChange("gender", e.target.value)}
                    label={LOCALE.gender}
                  >
                    <MenuItem value="MALE">{LOCALE.male}</MenuItem>
                    <MenuItem value="FEMALE">{LOCALE.female}</MenuItem>
                  </Select>
                  {formDataErrors?.gender && (
                    <FormHelperText error={true}>
                      {formDataErrors?.gender}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.firstName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `firstName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={formData?.firstName ? formData?.firstName : ""}
                  error={formDataErrors["firstName"] !== undefined}
                  helperText={formDataErrors["firstName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "0!important",
                        margin: "0!important",
                      }}
                    >
                      {LOCALE.optional_field}
                    </p>
                  }
                  placement="top"
                  arrow
                >
                  <TextField
                    label={LOCALE.firstName + " " + LOCALE.albanian}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      handleChange(`firstNameAl`, e.target.value.toUpperCase())
                    }
                    value={formData?.firstNameAl ? formData?.firstNameAl : ""}
                    error={formDataErrors["firstNameAl"] !== undefined}
                    helperText={formDataErrors["firstNameAl"]}
                  ></TextField>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={LOCALE.lastName}
                  size="small"
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    handleChange(
                      `lastName`,
                      Transliteration.toUpperCyrilic(e.target.value)
                    )
                  }
                  value={formData?.lastName ? formData?.lastName : ""}
                  error={formDataErrors["lastName"] !== undefined}
                  helperText={formDataErrors["lastName"]}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <Tooltip
                  title={
                    <p
                      style={{
                        fontSize: "12px",
                        padding: "0!important",
                        margin: "0!important",
                      }}
                    >
                      {LOCALE.optional_field}
                    </p>
                  }
                  placement="top"
                  arrow
                >
                  <TextField
                    label={LOCALE.lastName + " " + LOCALE.albanian}
                    size="small"
                    fullWidth
                    variant="outlined"
                    onChange={(e) =>
                      handleChange(`lastNameAl`, e.target.value.toUpperCase())
                    }
                    value={formData?.lastNameAl ? formData?.lastNameAl : ""}
                    error={formDataErrors["lastNameAl"] !== undefined}
                    helperText={formDataErrors["lastNameAl"]}
                  ></TextField>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth size="small">
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <KeyboardDatePicker
                      cancelLabel={LOCALE.cancel}
                      okLabel={LOCALE.ok}
                      disableToolbar
                      disableFuture
                      format="DD/MM/YYYY"
                      size="small"
                      // margin="dense"
                      inputVariant="outlined"
                      id="bride-birth-date"
                      label={LOCALE.birthDate}
                      value={formData?.dateBirth ? formData?.dateBirth : null}
                      onChange={(date) => handleChange(`dateBirth`, date)}
                      error={formDataErrors["dateBirth"] !== undefined}
                      helperText={
                        formDataErrors["dateBirth"] ? "Невалиден датум" : ""
                      }
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputMask
                  mask="HH:MM"
                  maskChar="_"
                  disabled={loading}
                  value={formData?.birthTime ? formData?.birthTime : ""}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      handleChange("birthTime", null);
                    } else {
                      handleChange("birthTime", e.target.value);
                    }
                  }}
                  formatChars={{
                    M: "[0-9]",
                    H: "[0-9]",
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      label={LOCALE.birth_time}
                      size="small"
                      fullWidth
                      error={formDataErrors?.birthTime !== undefined}
                      helperText={formDataErrors?.birthTime}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <Icon>query_builder</Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  value={
                    formData?.birthPlace?.name
                      ? formData?.birthPlace?.name
                      : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `birthPlace`,
                      places.find((place) => place.name === newValue)
                    );
                  }}
                  inputValue={placeSearch}
                  onInputChange={(event, newInputValue) => {
                    setPlaceSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  options={places?.map((place) => place.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.place_born}
                      fullWidth
                      error={formDataErrors["birthPlace.id"] !== undefined}
                      helperText={formDataErrors["birthPlace.id"]}
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  noOptionsText={LOCALE.noOptionsText}
                  size="small"
                  value={
                    formData?.hospital?.name ? formData?.hospital?.name : null
                  }
                  onChange={(event, newValue) => {
                    handleChange(
                      `hospital`,
                      hospitals.find((hospital) => hospital?.name === newValue)
                    );
                  }}
                  options={hospitals?.map((hospital) => hospital?.name)}
                  inputValue={hospitalSearch}
                  onInputChange={(event, newInputValue) => {
                    setHospitalSearch(
                      Transliteration.toUpperCyrilic(newInputValue)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LOCALE.health_institution}
                      fullWidth
                      error={formDataErrors["hospital.id"] !== undefined}
                      helperText={formDataErrors["hospital.id"]}
                      onFocus={onFocus}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item md={12}>
                <FormControl
                  disabled={loading}
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  <InputLabel>{LOCALE.certificateType}</InputLabel>
                  <Select
                    value={formData.typeRequest}
                    onChange={(e) =>
                      handleChange("typeRequest", e.target.value)
                    }
                    label={LOCALE.certificateType}
                  >
                    <MenuItem value="BASIC">{LOCALE.regular_cert}</MenuItem>

                    <MenuItem value="MULTILINGUAL">
                      {LOCALE.multilingual_cert}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  disabled={loading}
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  <InputLabel>{LOCALE.pickup_way}</InputLabel>
                  <Select
                    value={formData.pickupWayCert}
                    onChange={(e) =>
                      handleChange("pickupWayCert", e.target.value)
                    }
                    label={LOCALE.pickup_way}
                  >
                    <MenuItem value="PICKUP_WAY_UVMK">
                      {LOCALE.pickup_way_uvmk}
                    </MenuItem>
                    {currentUser?.nationalEid !== null && (
                      <MenuItem value="PICKUP_WAY_ELECTRONIC_CERT">
                        {LOCALE.pickup_way_el}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {formData.pickupWayCert === "PICKUP_WAY_UVMK" && (
                <Grid item xs={12}>
                  <Autocomplete
                    noOptionsText={LOCALE.noOptionsText}
                    size="small"
                    disabled={loading}
                    value={
                      formData?.deliveryRegistryArea?.title
                        ? formData?.deliveryRegistryArea?.title
                        : ""
                    }
                    onChange={(event, newValue) => {
                      handleChange(
                        "deliveryRegistryArea",
                        registryAreas.find((area) => area.title === newValue)
                      );
                    }}
                    inputValue={deliveryRegistryAreaSearch}
                    onInputChange={(event, newInputValue) => {
                      setDeliveryRegistryAreaSearch(
                        Transliteration.toUpperCyrilic(newInputValue)
                      );
                    }}
                    options={registryAreas?.map((regArea) => regArea.title)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={LOCALE.pickup_way_reg_area}
                        fullWidth
                        error={formDataErrors.deliveryRegistryArea}
                        helperText={formDataErrors.deliveryRegistryArea}
                        onFocus={onFocus}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
