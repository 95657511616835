import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";

export const LegalEntityUserProfileValidator = yup.object().shape({
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  institution: yup.object().shape({
    institutionType: yup
      .number()
      .required(LOCALE.institutionType + LOCALE.required_field),
    name: yup.string().required(LOCALE.title + LOCALE.required_field),
    edb: yup
      .string()
      .required(LOCALE.edb + LOCALE.required_field)
      .length(13, "Мора да содржи точно 13 цифри"),
    countryLocation: yup
      .object()
      .required(LOCALE.country + LOCALE.required_field),
    placeLocation: yup
      .object()
      .test(
        "placeLocation",
        LOCALE.place + LOCALE.required_field,
        function (value) {
          if (this.options.parent.countryLocation?.id === "0") {
            if (!value?.id) return false;
            return true;
          } else {
            return true;
          }
        }
      )
      .nullable(),
    placeLocationManual: yup
      .string()
      .test(
        "placeLocationManual",
        LOCALE.place + LOCALE.required_field,
        function (value) {
          if (this.options.parent.countryLocation?.id !== "0") {
            if (!value) return false;
            return true;
          } else {
            return true;
          }
        }
      )
      .nullable(),
    address: yup.string().required(LOCALE.address + LOCALE.required_field),
  }),
  email: yup
    .string()
    .required(LOCALE.email + LOCALE.required_field)
    .email(LOCALE.invalid_email_format),
  username: yup
    .string()
    .matches(/^[A-Za-z_-_.][A-Za-z0-9_-_.]{5,}$/, {
      message:
        LOCALE.incorrect_format +
        " (минимум 6 карактери, дозволени се мали и големи латинични букви, цифри и -._)",
    })
    .required(LOCALE.username + LOCALE.required_field),
  phoneNumber: yup
    .string()
    .required(LOCALE.contact_phone + LOCALE.required_field)
    .min(8, "Минимум 7 цифри"),
});
