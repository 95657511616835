import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const RegistryAreaRepository = {
  getActiveRegistryAreas: () => {
    return Axios({
      url: SETTINGS.API_URL + "registryArea/active",
      method: "GET",
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },
};
