import moment from "moment";

export const EmbgValidator = {
  isValid: (value) => {
    if (!isValidCheckSum(value)) return false;
    if (!isValidDate(value)) return false;
    return true;
  },
};

function isValidCheckSum(embg) {
  var mask = "765432765432",
    sum = 0,
    K = parseInt(embg[embg.length - 1]);
  for (var i = 0; i < 12; ++i) {
    sum += parseInt(embg[i]) * parseInt(mask[i]);
  }
  var m = 11 - (sum % 11);
  if (m >= 10) {
    m = 0;
  }
  return m == K;
}

function isValidDate(embg) {
  var d = parseInt(embg.substr(0, 2)),
    m = parseInt(embg.substr(2, 2)) - 1,
    y = parseInt(embg.substr(4, 3)) + 1000;

  if (parseInt(embg.substr(4, 1)) < 8) {
    y += 1000;
  }

  return (
    m >= 0 &&
    m < 12 &&
    d > 0 &&
    d <= daysInMonth(m, y) &&
    y > 1800 &&
    y <= moment().year()
  );
}

function daysInMonth(m, y) {
  switch (m) {
    case 1:
      return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
    case 8:
    case 3:
    case 5:
    case 10:
      return 30;
    default:
      return 31;
  }
}
