import { Button, Container, Grid, Grow, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import bgImg from "../../assets/img/productCurvyLines.png";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { AuthService } from "../auth/AuthService";

const useStyles = makeStyles(styles);

export default function PaymentResponseCanceledComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [voucherTypes, setVoucherTypes] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [openLanguage, setOpenLanguage] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState();

  const handleCloseLanguage = (item) => {
    if (item === "al") {
      setOpenLanguage(null);
      changeLanguage(item);
    } else {
      setOpenLanguage(null);
      changeLanguage(item);
    }
  };

  useEffect(() => {
    setCurrentUser(AuthService.getCurrentUser());
  }, []);

  return (
    <div
      style={{
        backgroundImage: "url(" + bgImg + ")",
        backgroundSize: "100% 100%",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
      }}
    >
      {redirectTo && <Redirect to={redirectTo} push />}
      <Header />
      <Container>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} style={{ padding: "15px" }}>
            <Grid container spacing={2}>
              <Grow in={true} timeout={750}>
                <Grid item xs={12} md={12}>
                  <div style={{ textAlign: "center", marginTop: "100px" }}>
                    <Icon style={{ fontSize: "5em" }} color="secondary">
                      link_off
                    </Icon>
                    <p style={{ fontSize: "2em", fontWeight: "bold" }}>
                      {LOCALE.transaction_canceled}
                    </p>
                    <div style={{ textAlign: "center", marginTop: "25px" }}>
                      <Button
                        onClick={() => setRedirectTo("/orders")}
                        color="secondary"
                        size="small"
                        variant="contained"
                        style={{ marginTop: "30px" }}
                      >
                        Кон мои нарачки
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grow>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
