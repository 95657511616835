import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  CardHeader,
  Hidden,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grow,
  Divider,
  Tooltip,
  Backdrop,
  Checkbox,
  FormHelperText,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import { AuthRepository } from "./AuthRepository";
import { Autocomplete } from "@material-ui/lab";
import { CountryRepository } from "../../repositories/CountryRepository";
import { Transliteration } from "../../common/helpers/Transliteration";
import { SignUpFormValidator } from "./SignUpFormValidator";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { PropagateLoader } from "react-spinners";
import MuiPhoneNumber from "material-ui-phone-number";
import { SignUpFormValidatorLegalEntity } from "./SignUpFormValidatorLegalEntity";
import SignUpComponentPerson from "./SignUpComponentPerson";
import SignUpComponentLegalEntity from "./SignUpComponentLegalEntity";
import Flag from "react-world-flags";

const useStyles = makeStyles(styles);

export default function SignUpComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [userType, setUserType] = React.useState("PERSON");

  var _ = require("lodash");

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        <FormControl
          variant="outlined"
          size="small"
          style={{ float: "right", marginRight: "20px", marginTop: "5px" }}
        >
          <Select
            size="small"
            style={{
              marginLeft: "15px",
            }}
            classes={{ select: classes.select }}
            value={
              window.localStorage.getItem("locale")
                ? window.localStorage.getItem("locale")
                : "mk"
            }
            onChange={(e) => {
              changeLanguage(e.target.value);
            }}
          >
            <MenuItem value={"mk"} style={{ textAlign: "center" }}>
              <Flag
                code={"mk"}
                height="15"
                width="25"
                style={{
                  padding: "0px",
                  paddingTop: "4px",
                }}
              />
            </MenuItem>
            <MenuItem value={"al"} style={{ textAlign: "center" }}>
              <Flag
                code={"al"}
                height="15"
                width="25"
                style={{
                  padding: "0px",
                  paddingTop: "4px",
                }}
              />
            </MenuItem>
          </Select>
        </FormControl>
        <Container>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Hidden smDown>
                <IconButton
                  onClick={() => setRedirectTo("/")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "45%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  onClick={() => setRedirectTo("/")}
                  style={{
                    color: "black",
                    position: "absolute",
                    left: "5%",
                    top: "5%",
                  }}
                >
                  <Icon>arrow_back_ios</Icon>
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Hidden smDown>
                <div style={{ height: "10vh" }}>
                  <span></span>
                </div>
              </Hidden>
            </Grid>
            <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
            <Grid item md={6} lg={6} sm={6} xs={12} style={{ padding: "15px" }}>
              <Grid
                item
                xs={12}
                md={12}
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                <Hidden smDown>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="60px"
                    style={{
                      borderRight: "1px solid black",
                      paddingRight: "15px",
                      paddingTop: "5px",
                    }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      textAlign: "left",
                      paddingTop: "5px",
                      marginLeft: "15px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <img
                    alt=""
                    src={require("../../assets/img/logo-uvmk.png")}
                    width="30px"
                    style={{ paddingRight: "5px", marginTop: "5px" }}
                  ></img>
                  <Typography
                    gutterBottom
                    style={{
                      paddingTop: "0px",
                      marginLeft: "5px",
                      marginTop: "6px",
                      display: "inline-block",
                      verticalAlign: "top",
                      fontSize: "11px",
                    }}
                  >
                    {LOCALE.ministry_of_justice}
                    <br />
                    <strong>{LOCALE.uvmk}</strong>
                  </Typography>
                </Hidden>
              </Grid>

              <Card>
                <div
                  style={{
                    textAlign: "center",
                    padding: "0px",
                    margin: "0px",
                    color: "white",
                    backgroundColor: "#70c1c2",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "10px",
                    }}
                  >
                    <Icon>exit_to_app</Icon>
                    <span style={{ verticalAlign: "top", lineHeight: "1.4" }}>
                      {LOCALE.registration}
                    </span>
                  </Typography>
                </div>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={false} sm={false} md={1}></Grid>
                    <Grid item xs={12} sm={12} md={10}>
                      <Hidden smDown>
                        <br />
                      </Hidden>
                      <Grid container>
                        <Grid
                          item
                          lg={4}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="subtitle1">
                            {LOCALE.type_of_user}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ textAlign: "center" }}
                        >
                          <FormControl>
                            <RadioGroup
                              row
                              size="small"
                              value={userType ? userType : ""}
                              onChange={(e) => setUserType(e.target.value)}
                            >
                              <FormControlLabel
                                value="PERSON"
                                control={<Radio size="small" color="primary" />}
                                label={LOCALE.natural_entity}
                              />
                              <FormControlLabel
                                value="LEGAL_ENTITY"
                                control={<Radio size="small" color="primary" />}
                                label={LOCALE.legal_entity}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider style={{ marginBottom: "15px" }} />
                      </Grid>
                      {userType === "PERSON" && <SignUpComponentPerson />}
                      {userType === "LEGAL_ENTITY" && (
                        <SignUpComponentLegalEntity />
                      )}

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Divider style={{ marginTop: "15px" }} />
                        </Grid>

                        <Grow in={true} timeout={1850}>
                          <Grid item md={3}>
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/")}
                            >
                              {LOCALE.home}
                            </Button>
                          </Grid>
                        </Grow>

                        <Grow in={true} timeout={1850}>
                          <Grid item md={6} style={{ textAlign: "center" }}>
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() =>
                                window.open("/termsAndConditions.pdf", "_blank")
                              }
                            >
                              {LOCALE.terms_of_use}
                            </Button>
                          </Grid>
                        </Grow>

                        <Grow in={true} timeout={1850}>
                          <Grid item md={3} style={{ textAlign: "right" }}>
                            <Button
                              size="small"
                              style={{
                                textDecoration: "none",
                                color: "black",
                              }}
                              onClick={() => setRedirectTo("/login")}
                            >
                              {LOCALE.login}
                            </Button>
                          </Grid>
                        </Grow>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item md={3} lg={3} sm={3} xs={false}></Grid>
        </Container>
        <br></br>
      </div>
    </React.Fragment>
  );
}
