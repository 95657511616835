import React, { useEffect } from "react";
import MomentUtils from "@date-io/moment";
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Hidden,
  Icon,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { LOCALE } from "../../properties/Locale";
import Header from "../../common/Header";
import { Redirect } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from "@material-ui/icons/Info";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import TelegramIcon from "@material-ui/icons/Telegram";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import clsx from "clsx";
import bgImg from "../../assets/img/productCurvyLines.png";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { CountryRepository } from "../../repositories/CountryRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { Transliteration } from "../../common/helpers/Transliteration";
import Footer from "../../common/Footer";
import { RegistryAreaRepository } from "../../repositories/RegistryAreaRepository";
import { moment } from "../../App";
import PaymentComponent from "../payment/PaymentComponent";
import {
  ApplicantDocumentsValidator,
  DeathInfoValidator,
  PersonDeathInfoValidator,
  PickUpWayValidator,
} from "./RequestDeathCertificateValidator";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { RequestDeathCertificateRepository } from "../../repositories/RequestDeathCertificateRepository";
import { NoteAdd, TramOutlined } from "@material-ui/icons";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { notifyShowErrorMessage } from "../../common/actions/CommonActions";
import { SETTINGS } from "../../properties/ApiProps";
import { AuthService } from "../auth/AuthService";
import { FilesRepository } from "../../repositories/FilesRepository";
import axios from "axios";
import Upload from "../../common/components/Upload";

if (localStorage.getItem("locale") && localStorage.getItem("locale") === "al") {
  moment.locale("al");
} else {
  moment.locale("mk");
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

export default function RequestDeathCertificateWizard() {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [globalFormError, setGlobalFormError] = React.useState();

  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [registryAreas, setRegistryAreas] = React.useState([]);
  const [formDataErrors, setFormDataErrors] = React.useState({});
  const [printDialog, setPrintDialog] = React.useState(false);
  const [generatedDocumentURL, setGeneratedDocumentURL] = React.useState();
  const [printDetails, setPrintDetails] = React.useState({
    paperType: "0",
  });
  const [generatedDocumentFile, setGeneratedDocumentFile] = React.useState();
  const [saving, setSaving] = React.useState(false);
  const [powerOfAttorneyScan, setPowerOfAttorneyScan] = React.useState([]);
  const [ecertProcessStatus, setEcertProcessStatus] =
    React.useState("NOT_GENERATED");
  const [loading, setLoading] = React.useState(false);
  const [persistedRequest, setPersistedRequest] = React.useState();
  const [currentUser, setCurrentUser] = React.useState();
  const [formData, setFormData] = React.useState({
    typeRequest: "BASIC",
    embg: "",
    firstName: "",
    lastName: "",
    country: {
      countryCode: "807",
      id: "0",
      name: "СЕВЕРНА МАКЕДОНИЈА",
      nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      nameEn: "REPUBLIC OF NORTH MACEDONIA",
      printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
      printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      printNameEn: "REPUBLIC OF NORTH MACEDONIA",
    },
    placeBirth: { name: "" },
    placeBirthManual: "",
    deathDate: null,
    dateBirth: null,
    deathCountry: {
      countryCode: "807",
      id: "0",
      name: "СЕВЕРНА МАКЕДОНИЈА",
      nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      nameEn: "REPUBLIC OF NORTH MACEDONIA",
      printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
      printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
      printNameEn: "REPUBLIC OF NORTH MACEDONIA",
    },
    deathPlace: { name: "" },
    deathPlaceManual: "",
    deathAddress: "",
    pickupWayCert: "PICKUP_WAY_UVMK",
    registryArea: {},
    deliveryRegistryArea: {},

    passportDocumentNumber: "",
    passportExpiryDate: null,
    passportIssuingDate: null,

    idDocumentNumber: "",
    idExpiryDate: null,
    idIssuingDate: null,
  });
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");

  function getSteps() {
    if (currentUser?.proposerType === "ORGANIZATION") {
      return [
        LOCALE.certificateType,
        LOCALE.person_death_info,
        LOCALE.death_info,
        LOCALE.pickup_way,
        LOCALE.sign_request,
        LOCALE.payment,
      ];
    } else {
      return [
        LOCALE.certificateType,
        LOCALE.person_death_info,
        LOCALE.death_info,
        LOCALE.pickup_way,
        LOCALE.purposer_info,
        LOCALE.payment,
      ];
    }
  }
  const steps = getSteps();

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    let icons = {};
    if (currentUser?.proposerType)
      if (currentUser?.proposerType === "ORGANIZATION") {
        icons = {
          1: <SettingsIcon />,
          2: <InfoIcon />,
          3: <PermIdentityIcon />,
          4: <TelegramIcon />,
          5: <NoteAdd />,
          6: <CreditCardIcon />,
        };
      } else {
        icons = {
          1: <SettingsIcon />,
          2: <InfoIcon />,
          3: <PermIdentityIcon />,
          4: <TelegramIcon />,
          5: <PermIdentityIcon />,
          6: <CreditCardIcon />,
        };
      }

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  useEffect(() => {
    if (!AuthService.getCurrentUser()) {
      if (window.location.search.includes("eid=true")) {
        // console.log("EID TRUE");
        window.location.href = `https://eid.mk/issue/wsfed?wa=wsignin1.0&wtrealm=https://e-portal.uvmk.gov.mk/&wctx=redirectUrl=${window.location.pathname}&id=passive&ru=%2f&wreply=https://e-portal.uvmk.gov.mk/login_token_eid`;
      } else {
        setRedirectTo("/login");
        return;
      }
    } else {
      UsersRepository.fetchCurrentUser().then(
        (res) => {
          setCurrentUser(res.data);
        },
        (err) => {
          setRedirectTo("/login");
        }
      );
      getActiveRegistryAreas();
    }
  }, []);

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const getActiveRegistryAreas = () => {
    RegistryAreaRepository.getActiveRegistryAreas().then((res) => {
      setRegistryAreas(res.data);
    });
  };

  const handleChange = (name, value) => {
    let tmp = { ...formData };
    if (name === "country") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "deathCountry") {
      countries.map((country) => {
        if (country.name === value) {
          tmp[name] = country;
        }
      });
      setFormData(tmp);
    } else if (name === "placeBirth") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "deathPlace") {
      places.map((place) => {
        if (place.name === value) {
          tmp[name] = place;
        }
      });
      setFormData(tmp);
    } else if (name === "embg") {
      tmp[name] = value;
      setFormData(tmp);
    } else if (name === "deliveryRegistryArea") {
      registryAreas.map((registryArea) => {
        if (registryArea.title === value) {
          tmp[name] = registryArea;
        }
      });
      setFormData(tmp);
    } else {
      tmp[name] = value;
      setFormData(tmp);
    }
  };

  const generateCertificate = (id) => {
    setGlobalFormError();
    setSaving(true);
    setPrintDialog(false);
    setGlobalFormError();
    axios({
      url: `${SETTINGS.API_URL}/requestDeathCertificate/generate/${id}`,
      method: "POST",
      params: {
        access_token: AuthService.getToken().access_token,
      },
      responseType: "blob",
    })
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setEcertProcessStatus("GENERATED");
        setGeneratedDocumentFile(file);
        setGeneratedDocumentURL(fileURL);
        setPrintDialog(true);
        setSaving(false);
        setActiveStep(activeStep + 1);
      })
      .catch((err) => {
        console.log(err.message);
        setSaving(false);
        setGlobalFormError("Грешка при генерирање на документот");
      });
  };

  const downloadGeneratedDocument = () => {
    window.open(generatedDocumentURL, "_blank");
  };

  const onUploadComponentClose = (files) => {
    setPowerOfAttorneyScan(files);
  };

  const signDocument = () => {
    if (typeof AuctaSignerApp === "function") {
      setPrintDialog(false);
      setLoading(true);
      console.log(window.ext);
      var reader = new FileReader();
      reader.readAsDataURL(generatedDocumentFile);
      reader.onloadend = function () {
        var base64data = reader.result.split(",")[1];
        // console.log(base64data);
        window.ext.signAndCertPDF(
          base64data,
          null,
          null,
          false,
          0,
          400,
          75,
          530,
          135,
          false,
          "null",
          (status, message) => {
            signAndCertCallback(status, message);
          }
        );
      };
    } else {
      setPrintDialog(false);
      setLoading(false);
      setGlobalFormError(
        "Ве молиме инсталирајте ги потребните алатки за дигитално потпишување"
      );
    }
  };

  const uploadSignedContent = (signedContent) => {
    FilesRepository.uploadSignedRequestCertificate(
      signedContent,
      persistedRequest.id,
      "DEATHS"
    )
      .then((res) => {
        if (res.data === true) {
          setGlobalFormError();
          setEcertProcessStatus("SIGNED_AND_UPLOADED");
          setPrintDialog(false);
          setLoading(false);
        } else {
          setEcertProcessStatus("GENERATED");
          setPrintDialog(false);
          setGlobalFormError(
            "Грешка при прикачување на дигитално потпишаниот документ"
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setEcertProcessStatus("GENERATED");
        setPrintDialog(false);
        setGlobalFormError(
          "Грешка при прикачување на дигитално потпишаниот документ"
        );
        setLoading(false);
      });
  };

  function signAndCertCallback(status, message) {
    if (status === false && message.status === "Error") {
      if (message.error === "No valid certificates.") {
        setGlobalFormError(
          "Не е избран валиден сертификат за дигитално потпишување"
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else if (
        message.error.includes("The action was cancelled by the user.")
      ) {
        setGlobalFormError("Потпишувањето беше откажано на ваше барање");
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      } else {
        setGlobalFormError(
          "Грешка при потпишување на електронскиот документ. Ве молиме пријавете го овој проблем кај администраторот."
        );
        setPrintDialog(false);
        setEcertProcessStatus("GENERATED");
        setLoading(false);
      }
    } else if (status === true) {
      // console.log(status);
      // console.log(message);
      fetch(message.blobURL).then((r) => {
        r.blob().then((blob) => {
          // console.log(blob);
          let file = new Blob([blob], { type: "application/pdf" });
          uploadSignedContent(file);
        });
      });
    }
  }

  const handleChangeDate = (date, name) => {
    let data = { ...formData };
    if (date) {
      data[name] = moment(date.set({ hour: 0, minute: 0, second: 0 }));
    } else {
      data[name] = null;
    }
    setFormData(data);
  };

  const validatePersonDeathInfo = () => {
    if (!PersonDeathInfoValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await PersonDeathInfoValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateDeathInfo = () => {
    if (!DeathInfoValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await DeathInfoValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validatePickupWay = () => {
    if (!PickUpWayValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await PickUpWayValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const validateApplicantDocuments = () => {
    if (!ApplicantDocumentsValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await ApplicantDocumentsValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return;
        });
      })();
    } else {
      setFormDataErrors({});
    }
  };

  const handleNextStepLegalEntity = () => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
      setLoading(false);
    } else if (activeStep === 1) {
      validatePersonDeathInfo();
      if (!PersonDeathInfoValidator.isValidSync(formData)) {
        return;
      } else {
        let dateBirthTmp = EmbgToDate.getDate(formData.embg);
        let tmp = { ...formData };
        tmp.dateBirth = dateBirthTmp;
        setFormData(tmp);
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      validateDeathInfo();
      if (!DeathInfoValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 3) {
      validatePickupWay();
      if (!PickUpWayValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
        setLoading(true);
        RequestDeathCertificateRepository.create(formData).then(
          (res) => {
            setPersistedRequest(res.data);
            setLoading(false);
            setGlobalFormError(null);
            generateCertificate(res.data.id);
          },
          (err) => {
            dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
            setGlobalFormError(ErrorMessageResolver.resolve(err));
            setLoading(false);
          }
        );
      }
    } else if (activeStep === 4) {
      setLoading(true);
      if (
        currentUser.proposerType === "ORGANIZATION" &&
        !currentUser?.institution?.institutionType == 2
      ) {
        FilesRepository.uploadPowerOfAttorneyScan(
          powerOfAttorneyScan,
          persistedRequest.id,
          "DEATHS"
        ).then(
          (res) => {
            setLoading(false);
            setGlobalFormError(null);
            setActiveStep(activeStep + 1);
          },
          (err) => {
            dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
            setGlobalFormError(ErrorMessageResolver.resolve(err));
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
      setLoading(false);
    } else if (activeStep === 1) {
      validatePersonDeathInfo();
      if (!PersonDeathInfoValidator.isValidSync(formData)) {
        return;
      } else {
        let dateBirthTmp = EmbgToDate.getDate(formData.embg);
        let tmp = { ...formData };
        tmp.dateBirth = dateBirthTmp;
        setFormData(tmp);
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      validateDeathInfo();
      if (!DeathInfoValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 3) {
      validatePickupWay();
      if (!PickUpWayValidator.isValidSync(formData)) {
        return;
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 4) {
      validateApplicantDocuments();
      if (!ApplicantDocumentsValidator.isValidSync(formData)) {
        return;
      } else {
        setLoading(true);
        RequestDeathCertificateRepository.create(formData).then(
          (res) => {
            setPersistedRequest(res.data);
            setLoading(false);
            setGlobalFormError(null);
            setActiveStep(activeStep + 1);
          },
          (err) => {
            dispatch(notifyShowErrorMessage(ErrorMessageResolver.resolve(err)));
            setGlobalFormError(ErrorMessageResolver.resolve(err));
            setLoading(false);
          }
        );
      }
    }
  };

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />
        <Container>
          <Grid container>
            <Grid item md={12} style={{ marginBottom: "20px" }}>
              <Typography variant="h5">{LOCALE.req_death_cert}</Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: "0px" }}
                    display="flex"
                    alignItems="center"
                  >
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                          ></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      backgroundColor: "rgb(212 212 212 / 0.85)",
                      textAlign: "center",
                      marginTop: "-20px",
                      marginBottom: "20px",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <Typography>{steps[activeStep]}</Typography>
                  </Grid>
                  {globalFormError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{globalFormError}</Alert>
                    </Grid>
                  )}
                  {activeStep === 0 && (
                    <Grid item xs={12}>
                      <Grid container>
                        {currentUser?.proposerType !== "ORGANIZATION" && (
                          <>
                            <Grid item md={1} xs={false}></Grid>
                            <Grid item md={10} xs={12}>
                              <Alert severity="info">
                                <AlertTitle>НАПОМЕНА!</AlertTitle>
                                <ul>
                                  <li>
                                    Барање за извод од матична клига на умрените
                                    може да се поднесе само за починати лица кои
                                    имаат единствен матичен број!
                                  </li>
                                  <li>
                                    Извод од матична книга на умрените може да
                                    се подигне само на шалтер и истиот може да
                                    го подигне лице од прв наследен ред или лице
                                    ополномоштено со полномошно од наследникот
                                    заверено на нотар!
                                  </li>
                                  <li>
                                    Барање може да се поднесе за починати лица
                                    кои се веќе пријавени за починати на
                                    шалтерите на УВМК!
                                  </li>
                                </ul>
                              </Alert>
                            </Grid>
                            <Grid item md={1} xs={false}></Grid>
                          </>
                        )}
                        <Grid item md={2} xs={false}></Grid>
                        <Grid
                          item
                          md={8}
                          xs={12}
                          style={{ textAlign: "center" }}
                        >
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="typeRequest"
                              name="typeRequest"
                              value={formData?.typeRequest}
                              onChange={(e) =>
                                handleChange("typeRequest", e.target.value)
                              }
                              size="small"
                              row
                            >
                              <FormControlLabel
                                value="BASIC"
                                control={<Radio size="small" />}
                                label={LOCALE.regular_cert}
                              />
                              <FormControlLabel
                                value="INTERNATIONAL"
                                control={<Radio size="small" />}
                                label={LOCALE.internationale_cert}
                              />
                              <FormControlLabel
                                value="MULTILINGUAL"
                                control={<Radio size="small" />}
                                label={LOCALE.multilingual_cert}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={4} xs={false}></Grid>
                        <Grid item md={4} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.embg}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange("embg", e.target.value)
                                }
                                value={formData?.embg}
                                error={formDataErrors.embg ? true : false}
                                helperText={
                                  formDataErrors.embg ? formDataErrors.embg : ""
                                }
                              ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.firstName}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "firstName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.firstName}
                                error={formDataErrors.firstName !== undefined}
                                helperText={formDataErrors.firstName}
                              ></TextField>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label={LOCALE.lastName}
                                size="small"
                                fullWidth
                                value={formData?.lastName}
                                error={formDataErrors.lastName !== undefined}
                                helperText={formDataErrors.lastName}
                                onChange={(e) =>
                                  handleChange(
                                    "lastName",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                variant="outlined"
                              ></TextField>
                            </Grid>{" "}
                            <Grid item xs={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                autoComplete={false}
                                size="small"
                                value={
                                  formData.country?.name
                                    ? formData.country?.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("country", newValue);
                                }}
                                inputValue={countrySearch}
                                onInputChange={(event, newInputValue) => {
                                  setCountrySearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={countries?.map(
                                  (country) => country.name
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.country_born}
                                    fullWidth
                                    error={formDataErrors.country !== undefined}
                                    helperText={formDataErrors.country}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            {formData.country?.id === "0" ? (
                              <Grid item xs={12}>
                                <Autocomplete
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  value={
                                    formData.placeBirth?.name
                                      ? formData.placeBirth?.name
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange("placeBirth", newValue);
                                  }}
                                  inputValue={placeSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setPlaceSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={places?.map((place) => place.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.place_born}
                                      fullWidth
                                      error={
                                        formDataErrors.placeBirth !== undefined
                                      }
                                      helperText={formDataErrors.placeBirth}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                            ) : (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <TextField
                                    label={LOCALE.place_born}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="false"
                                    fullWidth={true}
                                    error={
                                      formDataErrors.placeBirthManual !==
                                      undefined
                                    }
                                    helperText={formDataErrors.placeBirthManual}
                                    onChange={(e) => {
                                      handleChange(
                                        "placeBirthManual",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      );
                                    }}
                                    value={formData?.placeBirthManual}
                                  />
                                </Grid>
                              </React.Fragment>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item md={4} xs={false}></Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 2 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={4} xs={false}></Grid>
                        <Grid item md={4} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider
                                  libInstance={moment}
                                  utils={MomentUtils}
                                >
                                  <KeyboardDatePicker
                                    cancelLabel={LOCALE.cancel}
                                    okLabel={LOCALE.ok}
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    margin="dense"
                                    inputVariant="outlined"
                                    id="date-to"
                                    error={formDataErrors?.deathDate}
                                    helperText={formDataErrors?.deathDate}
                                    label={LOCALE.deathDate}
                                    value={formData?.deathDate}
                                    onChange={(date) =>
                                      handleChangeDate(date, "deathDate")
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                noOptionsText={LOCALE.noOptionsText}
                                autoComplete={false}
                                size="small"
                                value={
                                  formData.deathCountry?.name
                                    ? formData.deathCountry?.name
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  handleChange("deathCountry", newValue);
                                }}
                                inputValue={countrySearch}
                                onInputChange={(event, newInputValue) => {
                                  setCountrySearch(
                                    Transliteration.toUpperCyrilic(
                                      newInputValue
                                    )
                                  );
                                }}
                                options={countries?.map(
                                  (country) => country.name
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={LOCALE.deathCountry}
                                    fullWidth
                                    error={
                                      formDataErrors.deathCountry !== undefined
                                    }
                                    helperText={formDataErrors.deathCountry}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            {formData.deathCountry?.id === "0" ? (
                              <Grid item xs={12}>
                                <Autocomplete
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  value={
                                    formData.deathPlace?.name
                                      ? formData.deathPlace?.name
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange("deathPlace", newValue);
                                  }}
                                  inputValue={placeSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setPlaceSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={places?.map((place) => place.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.deathPlace}
                                      fullWidth
                                      error={
                                        formDataErrors.deathPlace !== undefined
                                      }
                                      helperText={formDataErrors.deathPlace}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                            ) : (
                              <React.Fragment>
                                <Grid item xs={12}>
                                  <TextField
                                    label={LOCALE.deathPlace}
                                    size="small"
                                    variant="outlined"
                                    autoComplete="false"
                                    fullWidth={true}
                                    error={
                                      formDataErrors.deathPlaceManual !==
                                      undefined
                                    }
                                    helperText={formDataErrors.deathPlaceManual}
                                    onChange={(e) => {
                                      handleChange(
                                        "deathPlaceManual",
                                        Transliteration.toUpperCyrilic(
                                          e.target.value
                                        )
                                      );
                                    }}
                                    value={formData?.deathPlaceManual}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    error={formDataErrors?.registryArea}
                                  >
                                    <InputLabel>
                                      {
                                        "Матично подрачје во кое е направен уписот"
                                      }
                                    </InputLabel>
                                    <Select
                                      size="small"
                                      value={formData.registryArea}
                                      onChange={(e) =>
                                        handleChange(
                                          "registryArea",
                                          e.target.value
                                        )
                                      }
                                      label={
                                        "Матично подрачје во кое е направен уписот"
                                      }
                                    >
                                      {registryAreas &&
                                        registryAreas.map((regArea, index) => (
                                          <MenuItem
                                            key={index}
                                            value={regArea}
                                            size="small"
                                          >
                                            {regArea.title}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    {formDataErrors?.registryArea && (
                                      <FormHelperText error={true}>
                                        {formDataErrors.registryArea}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                  {formDataErrors?.deliveryRegistryArea && (
                                    <FormHelperText error={true}>
                                      {formDataErrors.deliveryRegistryArea}
                                    </FormHelperText>
                                  )}
                                </Grid>
                              </React.Fragment>
                            )}
                            <Grid item md={12} xs={12}>
                              <TextField
                                label={LOCALE.deathAddress}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) =>
                                  handleChange(
                                    "deathAddress",
                                    Transliteration.toUpperCyrilic(
                                      e.target.value
                                    )
                                  )
                                }
                                value={formData?.deathAddress}
                                error={
                                  formDataErrors.deathAddress !== undefined
                                }
                                helperText={formDataErrors.deathAddress}
                              ></TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 3 && (
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={4} xs={false}></Grid>
                        <Grid item md={4} xs={12}>
                          <Grid container spacing={2}>
                            {currentUser?.proposerType == "ORGANIZATION" &&
                              currentUser?.institution?.institutionType ==
                                2 && (
                                <Grid item xs={12}>
                                  <TextField
                                    label={"Број на предмет (правен интерес)"}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) =>
                                      handleChange(
                                        "legalInterestNumber",
                                        e.target.value
                                      )
                                    }
                                    value={formData?.legalInterestNumber}
                                    error={
                                      formDataErrors.legalInterestNumber !==
                                      undefined
                                    }
                                    helperText={
                                      formDataErrors.legalInterestNumber
                                    }
                                  ></TextField>
                                </Grid>
                              )}
                            <Grid item xs={12}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                              >
                                <InputLabel>{LOCALE.pickup_way}</InputLabel>
                                <Select
                                  value={formData.pickupWayCert}
                                  onChange={(e) =>
                                    handleChange(
                                      "pickupWayCert",
                                      e.target.value
                                    )
                                  }
                                  label={LOCALE.pickup_way}
                                >
                                  <MenuItem value="PICKUP_WAY_UVMK">
                                    {LOCALE.pickup_way_uvmk}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            {formData.pickupWayCert === "PICKUP_WAY_UVMK" && (
                              <Grid item xs={12}>
                                <Autocomplete
                                  noOptionsText={LOCALE.noOptionsText}
                                  size="small"
                                  disabled={loading}
                                  value={
                                    formData?.deliveryRegistryArea?.title
                                      ? formData?.deliveryRegistryArea?.title
                                      : ""
                                  }
                                  onChange={(event, newValue) => {
                                    handleChange(
                                      "deliveryRegistryArea",
                                      newValue
                                    );
                                  }}
                                  inputValue={deliveryRegistryAreaSearch}
                                  onInputChange={(event, newInputValue) => {
                                    setDeliveryRegistryAreaSearch(
                                      Transliteration.toUpperCyrilic(
                                        newInputValue
                                      )
                                    );
                                  }}
                                  options={registryAreas?.map(
                                    (regArea) => regArea.title
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={LOCALE.pickup_way_reg_area}
                                      fullWidth
                                      error={
                                        formDataErrors.deliveryRegistryArea
                                      }
                                      helperText={
                                        formDataErrors.deliveryRegistryArea
                                      }
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {currentUser?.proposerType !== "ORGANIZATION" &&
                    activeStep === 4 && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item md={2} xs={false}></Grid>
                          <Grid item md={8} xs={12}>
                            <Alert severity="info">
                              <AlertTitle>НАПОМЕНА!</AlertTitle>
                              Доволно е да внесете само еден документ, односно
                              документот кој ке го приложите како доказ кога ќе
                              го подигнувате изводот.
                            </Alert>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={3} md={3}>
                                <Typography
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  Лична карта на подносител
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                  label={LOCALE.number}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  onChange={(e) =>
                                    handleChange(
                                      "idDocumentNumber",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "4px",
                                  }}
                                  value={formData.idDocumentNumber}
                                  error={
                                    formDataErrors.idDocumentNumber !==
                                    undefined
                                  }
                                  helperText={formDataErrors.idDocumentNumber}
                                ></TextField>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                  <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}
                                  >
                                    <KeyboardDatePicker
                                      cancelLabel={LOCALE.cancel}
                                      okLabel={LOCALE.ok}
                                      disableToolbar
                                      format="DD/MM/YYYY"
                                      margin="dense"
                                      inputVariant="outlined"
                                      id="date-to"
                                      label={"Важи од"}
                                      value={formData.idIssuingDate}
                                      onChange={(date) =>
                                        handleChangeDate(date, "idIssuingDate")
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                  <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}
                                  >
                                    <KeyboardDatePicker
                                      cancelLabel={LOCALE.cancel}
                                      okLabel={LOCALE.ok}
                                      disableToolbar
                                      format="DD/MM/YYYY"
                                      margin="dense"
                                      inputVariant="outlined"
                                      id="date-to"
                                      label={"Важи до"}
                                      value={formData.idExpiryDate}
                                      onChange={(date) =>
                                        handleChangeDate(date, "idExpiryDate")
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </FormControl>
                              </Grid>
                              <Divider />
                              <Grid item xs={12} sm={3} md={3}>
                                <Typography
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "4px",
                                  }}
                                >
                                  Пасош на подносител
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <TextField
                                  label={LOCALE.number}
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  onChange={(e) =>
                                    handleChange(
                                      "passportDocumentNumber",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    marginTop: "8px",
                                    marginBottom: "4px",
                                  }}
                                  value={formData.passportDocumentNumber}
                                  error={
                                    formDataErrors.passportDocumentNumber !==
                                    undefined
                                  }
                                  helperText={
                                    formDataErrors.passportDocumentNumber
                                  }
                                ></TextField>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                  <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}
                                  >
                                    <KeyboardDatePicker
                                      cancelLabel={LOCALE.cancel}
                                      okLabel={LOCALE.ok}
                                      disableToolbar
                                      format="DD/MM/YYYY"
                                      margin="dense"
                                      inputVariant="outlined"
                                      id="date-to"
                                      label={"Важи од"}
                                      value={formData.passportIssuingDate}
                                      onChange={(date) =>
                                        handleChangeDate(
                                          date,
                                          "passportIssuingDate"
                                        )
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <FormControl fullWidth>
                                  <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}
                                  >
                                    <KeyboardDatePicker
                                      cancelLabel={LOCALE.cancel}
                                      okLabel={LOCALE.ok}
                                      disableToolbar
                                      format="DD/MM/YYYY"
                                      margin="dense"
                                      inputVariant="outlined"
                                      id="date-to"
                                      label={"Важи до"}
                                      value={formData.passportExpiryDate}
                                      onChange={(date) =>
                                        handleChangeDate(
                                          date,
                                          "passportExpiryDate"
                                        )
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={2} xs={false}></Grid>
                        </Grid>
                      </Grid>
                    )}
                  {currentUser?.proposerType === "ORGANIZATION" ? (
                    activeStep === 4 ? (
                      <>
                        <Grid item xs={2} />
                        <Grid item xs={8} style={{ textAlign: "center" }}>
                          {saving && (
                            <>
                              <CircularProgress color="secondary" />
                              <p>
                                <strong>Генерирање...</strong>
                              </p>
                            </>
                          )}
                          {ecertProcessStatus !== "NOT_GENERATED" && (
                            <>
                              <Grid container spacing={2}>
                                {ecertProcessStatus ===
                                "SIGNED_AND_UPLOADED" ? (
                                  <Grid item xs={12}>
                                    <Alert severity="success">
                                      <>
                                        <span>
                                          Електронскиот документ е успешно
                                          потпишан.
                                        </span>
                                      </>
                                    </Alert>
                                  </Grid>
                                ) : (
                                  <Grid item xs={12}>
                                    <Alert severity="info">
                                      <>
                                        <span>
                                          Документот е успешно генериран и чека
                                          на дигитален потпис. Ве молиме
                                          проверете ја точноста на податоците.
                                        </span>
                                      </>
                                    </Alert>
                                  </Grid>
                                )}
                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      paddingTop: "25px",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <Button
                                      color="secondary"
                                      onClick={() => {
                                        downloadGeneratedDocument();
                                      }}
                                      fullWidth
                                      size="small"
                                    >
                                      <Icon style={{ marginRight: "10px" }}>
                                        description
                                      </Icon>
                                      <strong>
                                        Преглед на генерираниот документ
                                      </strong>
                                    </Button>
                                  </div>
                                </Grid>

                                <Grid item xs={12}>
                                  <div
                                    style={{
                                      paddingTop: "25px",
                                      paddingBottom: "25px",
                                    }}
                                  >
                                    <Button
                                      color="primary"
                                      size="small"
                                      disabled={
                                        ecertProcessStatus ===
                                        "SIGNED_AND_UPLOADED"
                                      }
                                      variant="contained"
                                      onClick={() => {
                                        signDocument();
                                      }}
                                    >
                                      Потпиши
                                    </Button>
                                  </div>
                                </Grid>
                                {currentUser?.proposerType === "ORGANIZATION" &&
                                  currentUser?.institution?.institutionType !=
                                    2 && (
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ textAlign: "center" }}
                                    >
                                      <br></br>
                                      <Alert severity="info">
                                        <AlertTitle>НАПОМЕНА!</AlertTitle>
                                        <>
                                          <span>
                                            Овде треба да прикачите полномошно
                                            од лицето кое е ополномоштено за да
                                            добие извод од починатиот.
                                          </span>
                                        </>
                                      </Alert>
                                      <br></br>
                                      <Grid container spacing={2}>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          style={{
                                            alignItems: "center",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Upload
                                            onUploadComponentClose={(files) =>
                                              onUploadComponentClose(files)
                                            }
                                          ></Upload>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )}
                              </Grid>
                            </>
                          )}
                          <Grid item xs={2} />
                        </Grid>
                      </>
                    ) : activeStep === 5 ? (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid md={2} xs={false}></Grid>
                          <Grid md={8} xs={12}>
                            <PaymentComponent
                              reqDeathCert={persistedRequest}
                            ></PaymentComponent>
                          </Grid>
                          <Grid md={2} xs={false}></Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  {activeStep === 5 &&
                    currentUser?.proposerType !== "ORGANIZATION" && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item md={2} xs={false}></Grid>
                          <Grid item md={8} xs={12}>
                            <PaymentComponent
                              reqDeathCert={persistedRequest}
                            ></PaymentComponent>
                          </Grid>
                          <Grid item md={2} xs={false}></Grid>
                        </Grid>
                      </Grid>
                    )}
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Divider></Divider>

                    {currentUser?.proposerType !== "ORGANIZATION" &&
                      activeStep !== 0 &&
                      activeStep !== steps.length - 1 && (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => handlePrevStep()}
                        >
                          {LOCALE.prev}
                        </Button>
                      )}
                    {currentUser?.proposerType === "ORGANIZATION" &&
                      activeStep !== 0 &&
                      activeStep !== steps.length - 1 &&
                      activeStep !== steps.length - 2 && (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => handlePrevStep()}
                        >
                          {LOCALE.prev}
                        </Button>
                      )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={
                          loading ||
                          saving ||
                          (currentUser?.proposerType === "ORGANIZATION" &&
                            ecertProcessStatus !== "SIGNED_AND_UPLOADED" &&
                            activeStep === 4) ||
                          (activeStep === 4 &&
                            currentUser?.proposerType === "ORGANIZATION" &&
                            currentUser?.institution.institutionType != 2 &&
                            powerOfAttorneyScan?.length === 0)
                        }
                        onClick={() => {
                          if (
                            AuthService.getCurrentUser()?.proposerType ===
                            "ORGANIZATION"
                          )
                            handleNextStepLegalEntity();
                          else handleNextStep();
                        }}
                      >
                        {LOCALE.next}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() =>
                          setRedirectTo(
                            "/deaths/requestDeathCertificate/" +
                              persistedRequest.id
                          )
                        }
                      >
                        {"ПРЕГЛЕД НА БАРАЊЕТО"}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() =>
                          (window.location.href = `${
                            SETTINGS.API_URL
                          }/payment/initialization?reasonType=death&rel=${
                            persistedRequest.id
                          }&access_token=${
                            AuthService.getToken().access_token
                          }`)
                        }
                      >
                        {"СЕ СОГЛАСУВАМ"}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <br></br>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
