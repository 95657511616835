import React from "react";
import {
  Grid,
  Typography,
  Container,
  Icon,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  ClickAwayListener,
  MenuList,
  MenuItem,
  IconButton,
  Divider,
  Grow,
  Paper,
  CircularProgress,
  LinearProgress,
  TextField,
  Hidden,
} from "@material-ui/core";
import MagicHover from "magic-hover";
import { useDispatch } from "react-redux";
import bgImg from "../../assets/img/productCurvyLines.png";
import { Redirect, useHistory } from "react-router-dom";
import { LOCALE, changeLanguage } from "../../properties/Locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/components/headerLinkStyle";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { AuthService } from "../auth/AuthService";
import { UsersRepository } from "../../repositories/UsersRepository";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { Transliteration } from "../../common/helpers/Transliteration";
import { CountryRepository } from "../../repositories/CountryRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { UserProfileValidator } from "./UserProfileValidator";
import { notifyShowSuccessMessage } from "../../common/actions/CommonActions";
import { history } from "../..";
import MuiPhoneNumber from "material-ui-phone-number";
import UserProfileComponentPerson from "./UserProfileComponentPerson";
import UserProfileComponentLegalEntity from "./UserProfileComponentLegalEntity";

export default function UserProfileComponent() {
  const [redirectTo, setRedirectTo] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [globalFormError, setGlobalFormError] = React.useState();

  return (
    <React.Fragment>
      {redirectTo && <Redirect to={redirectTo} push />}
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />

        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">{LOCALE.myProfile}</Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "20px" }}>
                  {globalFormError && (
                    <Grid item xs={12}>
                      <Alert severity="error">{globalFormError}</Alert>
                    </Grid>
                  )}
                  <Grid md={3}></Grid>
                  <Grid md={6}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Hidden smDown>
                        <br />
                      </Hidden>
                      {AuthService.getCurrentUser()?.proposerType ===
                        "PERSON" && <UserProfileComponentPerson />}
                      {AuthService.getCurrentUser()?.proposerType ===
                        "ORGANIZATION" && <UserProfileComponentLegalEntity />}
                      <Grow in={true} timeout={1900}>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          style={{ marginBottom: "10px" }}
                        >
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => setRedirectTo("/passwordChange")}
                          >
                            {LOCALE.passwordChange}
                          </Button>
                        </Grid>
                      </Grow>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}
