import {
  Divider,
  Grid,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { LOCALE } from "../../properties/Locale";

import { Alert, AlertTitle } from "@material-ui/lab";
import ReactInputMask from "react-input-mask";
import Upload from "../../common/components/Upload";
import moment from "moment";

export default function ApplicationUvmkPreview({
  handleChange,
  getParentIndex,
  formData,
  formDataErrors,
  fatherUploadedDocs,
  setFatherUploadedDocs,
  motherUploadedDocs,
  setMotherUploadedDocs,
}) {
  const onUploadFatherComponentClose = (files) => {
    // console.log("FATHER", files);
    setFatherUploadedDocs(files);
  };
  const onUploadMotherComponentClose = (files) => {
    // console.log("MOTHER", files);
    setMotherUploadedDocs(files);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="subtitle1">
              <strong>Преглед на пријавата</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Table size="small" id="app-h-prev-tabl" className="table-stripped">
              <TableRow>
                <TableCell colSpan={2} style={{ backgroundColor: "lightgray" }}>
                  <strong>{LOCALE.newborn_information}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.gender}</TableCell>
                <TableCell>
                  {formData?.gender == "MALE" ? LOCALE.male : LOCALE.female}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.newborn_first_name}</TableCell>
                <TableCell>{formData?.firstName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.newborn_surname}</TableCell>
                <TableCell>{formData?.lastName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.newborn_first_name_al}</TableCell>
                <TableCell>{formData?.firstNameAl}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.newborn_surname_al}</TableCell>
                <TableCell>{formData?.lastNameAl}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.birthDate}</TableCell>
                <TableCell>
                  {formData?.dateBirth &&
                    moment(formData?.dateBirth).format("DD.MM.YYYY")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.birth_time}</TableCell>
                <TableCell>{formData?.birthTime}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.place_born}</TableCell>
                <TableCell>
                  {formData?.birthPlace?.name && formData?.birthPlace?.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.health_institution}</TableCell>
                <TableCell>
                  {formData?.hospital?.name && formData?.hospital?.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.certificateType}</TableCell>
                <TableCell>
                  {formData?.typeRequest == "BASIC"
                    ? LOCALE.regular_cert
                    : formData.typeRequest == "MULTILINGUAL"
                    ? LOCALE.multilingual_cert
                    : LOCALE.internationale_cert}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.method_of_cert_collection}</TableCell>
                <TableCell>
                  {formData?.pickupWayCert == "PICKUP_WAY_ELECTRONIC_CERT"
                    ? LOCALE.pickup_way_el
                    : LOCALE.pickup_way_uvmk}
                </TableCell>
              </TableRow>
              {formData?.pickupWayCert == "PICKUP_WAY_UVMK" && (
                <TableRow>
                  <TableCell>
                    Матично подрачје во кое би се подигнал изводот
                  </TableCell>
                  <TableCell>{formData?.deliveryRegistryArea?.title}</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={2} style={{ backgroundColor: "lightgray" }}>
                  <strong>{LOCALE.marriage_data}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.birth_status}</TableCell>
                <TableCell>
                  {formData?.bornInMarriage == 0
                    ? LOCALE.in_marriage
                    : LOCALE.out_of_wedlock}
                </TableCell>
              </TableRow>
              {formData?.bornInMarriage == 0 && (
                <React.Fragment>
                  <TableRow>
                    <TableCell>{LOCALE.marriageDate}</TableCell>
                    <TableCell>
                      {formData?.dateMarriage &&
                        moment(formData?.dateMarriage).format("DD.MM.YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.marriageCountry}</TableCell>
                    <TableCell>{formData?.countryMarriage?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.marriagePlace}</TableCell>
                    <TableCell>{formData?.placeMarriage?.name}</TableCell>
                  </TableRow>
                </React.Fragment>
              )}
              <TableRow>
                <TableCell colSpan={2} style={{ backgroundColor: "lightgray" }}>
                  <strong>{LOCALE.parents_info_mother}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.firstName}</TableCell>
                <TableCell>
                  {formData?.parentsData[getParentIndex("MOTHER")]?.firstName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.birth_surname}</TableCell>
                <TableCell>
                  {formData?.parentsData[getParentIndex("MOTHER")]?.maidenName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.lastName}</TableCell>
                <TableCell>
                  {formData?.parentsData[getParentIndex("MOTHER")]?.lastName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.embg}</TableCell>
                <TableCell>
                  {formData?.parentsData[getParentIndex("MOTHER")]?.embg}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.birthDate}</TableCell>
                <TableCell>
                  {formData?.parentsData[getParentIndex("MOTHER")]?.dateBirth &&
                    moment(
                      formData?.parentsData[getParentIndex("MOTHER")]?.dateBirth
                    ).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{LOCALE.country_born}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]
                      ?.birthCountry?.name
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.place_born}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]?.birthPlace
                      ?.name
                  }
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{LOCALE.citizenship}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]?.citizenship
                      ?.name
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.nationality}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]?.nationality
                      ?.title
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.education}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]?.education
                      ?.title
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.school_university_name}</TableCell>
                <TableCell>
                  {formData?.parentsData[getParentIndex("MOTHER")]?.schoolName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.occupation}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]?.occupation
                      ?.title
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.workingPosition}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]
                      ?.workingPosition
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.country_living}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]
                      ?.livingCountry?.name
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.place_living}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]?.livingPlace
                      ?.name
                  }
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{LOCALE.address_living}</TableCell>
                <TableCell>
                  {
                    formData?.parentsData[getParentIndex("MOTHER")]
                      ?.livingAddress
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{LOCALE.contact_phone}</TableCell>
                <TableCell>
                  {formData?.parentsData[getParentIndex("MOTHER")]?.phoneNumber}
                </TableCell>
              </TableRow>
              {formData.bornInMarriage == 0 && (
                <React.Fragment>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      style={{ backgroundColor: "lightgray" }}
                    >
                      <strong>
                        {LOCALE.parents_info} - {LOCALE.father}
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.firstName}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.firstName
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.birth_surname}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.maidenName
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.lastName}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.lastName
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.embg}</TableCell>
                    <TableCell>
                      {formData?.parentsData[getParentIndex("FATHER")]?.embg}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.birthDate}</TableCell>
                    <TableCell>
                      {formData?.parentsData[getParentIndex("FATHER")]
                        ?.dateBirth &&
                        moment(
                          formData?.parentsData[getParentIndex("FATHER")]
                            ?.dateBirth
                        ).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{LOCALE.country_born}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.birthCountry?.name
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.place_born}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.birthPlace?.name
                      }
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{LOCALE.citizenship}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.citizenship?.name
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.nationality}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.nationality?.title
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.education}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.education?.title
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.school_university_name}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.schoolName
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.occupation}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.occupation?.title
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.workingPosition}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.workingPosition
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.country_living}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.livingCountry?.name
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{LOCALE.place_living}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.livingPlace?.name
                      }
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{LOCALE.address_living}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.livingAddress
                      }
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{LOCALE.contact_phone}</TableCell>
                    <TableCell>
                      {
                        formData?.parentsData[getParentIndex("FATHER")]
                          ?.phoneNumber
                      }
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )}
              <TableRow>
                <TableCell colSpan={2} style={{ backgroundColor: "lightgray" }}>
                  <strong>{LOCALE.attached_documents}</strong>
                </TableCell>
              </TableRow>
              {formData?.bornInMarriage == 0 && (
                <React.Fragment>
                  <TableRow>
                    <TableCell>{LOCALE.father_identity_card}</TableCell>
                    <TableCell>
                      {formData?.parentsData[getParentIndex("FATHER")]
                        ?.idDocumentNumber &&
                        "Број на документ: " +
                          formData?.parentsData[getParentIndex("FATHER")]
                            ?.idDocumentNumber}
                      {formData?.parentsData[getParentIndex("FATHER")]
                        ?.idIssuingDate &&
                        " | Издаден на: " +
                          moment(
                            formData?.parentsData[getParentIndex("FATHER")]
                              ?.idIssuingDate
                          ).format("DD/MM/YYYY")}
                      {formData?.parentsData[getParentIndex("FATHER")]
                        ?.idExpiryDate &&
                        " | Истекува на: " +
                          moment(
                            formData?.parentsData[getParentIndex("FATHER")]
                              ?.idExpiryDate
                          ).format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )}
              <TableRow>
                <TableCell>{LOCALE.mother_identity_card}</TableCell>
                <TableCell>
                  {formData?.parentsData[getParentIndex("MOTHER")]
                    ?.idDocumentNumber &&
                    "Број на документ: " +
                      formData?.parentsData[getParentIndex("MOTHER")]
                        ?.idDocumentNumber}
                  {formData?.parentsData[getParentIndex("MOTHER")]
                    ?.idIssuingDate &&
                    " | Издаден на: " +
                      moment(
                        formData?.parentsData[getParentIndex("MOTHER")]
                          ?.idIssuingDate
                      ).format("DD/MM/YYYY")}
                  {formData?.parentsData[getParentIndex("MOTHER")]
                    ?.idExpiryDate &&
                    " | Истекува на: " +
                      moment(
                        formData?.parentsData[getParentIndex("MOTHER")]
                          ?.idExpiryDate
                      ).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
