import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const RequestForInformationRepository = {
  create: (formData, attachments) => {
    let data = new FormData();
    for (var i = 0; i < attachments?.length; i++) {
      data.append("attachments", attachments[i]);
    }
    data.append(
      "requestForInformation",
      new Blob([JSON.stringify(formData)], {
        type: "application/json",
      })
    );
    return Axios({
      url: `${SETTINGS.API_URL}requestForInformation`,
      method: "POST",
      data: data,
      header: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getAll: (page, size, filterMap) => {
    return Axios({
      url: SETTINGS.API_URL + "requestForInformation",
      method: "GET",
      params: {
        page: page,
        size: size,
        searchParams: filterMap,
      },
    });
  },

  getById: (id) => {
    return Axios({
      url: SETTINGS.API_URL + "requestForInformation/" + id,
      method: "GET",
    });
  },
};
