import { UsersRepository } from "../../repositories/UsersRepository";

export const AuthService = {
  getToken: function () {
    var currentToken = window.localStorage.getItem("accessToken");
    if (currentToken) {
      return JSON.parse(currentToken);
    }
    return null;
  },
  setToken: function (tokenData) {
    if (tokenData) {
      window.localStorage.setItem("accessToken", JSON.stringify(tokenData));
      AuthService.setCurrentUser();
    }
  },
  setCurrentUser: function (callback) {
    UsersRepository.fetchCurrentUser()
      .then((res) => {
        if (res.data) {
          window.localStorage.setItem("currentUser", JSON.stringify(res.data));
        }
      })
      .catch((err) => {});
  },
  getCurrentUser: function () {
    var currentUser = window.localStorage.getItem("currentUser");
    if (currentUser) {
      return JSON.parse(currentUser);
    }
    return null;
  },
  hasRole: (role) => {
    var currentUser = AuthService.getCurrentUser();
    if (currentUser.authorities.includes(role)) return true;
    return false;
  },
  isAuthenticated: () => {
    if (!this.getCurrentUser) {
      return false;
    }
    return true;
  },
};
