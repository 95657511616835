import React, { useEffect } from "react";

// @material-ui/core components
import { Grid, Typography, Divider } from "@material-ui/core";
import { LOCALE } from "../properties/Locale";
// @material-ui/styles

export default function Footer(props) {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          width: "100%",
          height: "3rem",
        }}
      >
        <Divider style={{ marginTop: "15px" }}></Divider>
        <Grid container style={{ backgroundColor: "white" }}>
          <Grid item md={2}></Grid>
          <Grid
            item
            md={8}
            style={{
              bottom: "0",
              left: "0",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2" color="textSecondary" gutterBottom>
              <br />
              {LOCALE.ministry_of_justice} - {LOCALE.uvmk}
              <br />
              {LOCALE.uvmk_address}
              <br />
              {LOCALE.uvmk_phone} | {LOCALE.uvmk_email}
              <br />
              <br />
            </Typography>
          </Grid>
          <Grid item md={2}></Grid>
        </Grid>
        <Grid
          item
          md={12}
          style={{
            textAlign: "center",
            bottom: "0",
            left: "0",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <Typography variant="caption" color="textSecondary" gutterBottom>
            © 2020 - {LOCALE.uvmk}. {LOCALE.uvmk_all_rights_reserved}
            <br />
          </Typography>
        </Grid>
      </div>
    </React.Fragment>
  );
}
