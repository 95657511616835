import {
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  LinearProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import PostAdd from "@material-ui/icons/PostAdd";
import LinkIcon from "@material-ui/icons/Link";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import bgImg from "../../assets/img/productCurvyLines.png";
import Header from "../../common/Header";
import { LOCALE } from "../../properties/Locale";

import VisibilityIcon from "@material-ui/icons/Visibility";
import { Alert, Skeleton } from "@material-ui/lab";
import _ from "lodash";
import moment from "moment";
import Footer from "../../common/Footer";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { ErrorMessageResolver } from "../../common/helpers/ErrorMessageResolver";
import { CountryRepository } from "../../repositories/CountryRepository";
import { EducationRepository } from "../../repositories/EducationRepository";
import { MarriageLocationRepository } from "../../repositories/MarriageLocationRepository";
import { PlaceRepository } from "../../repositories/PlaceRepository";
import { RequestMarriageCertificateRepository } from "../../repositories/RequestMarriageCertificateRepository";
import { RequestMarriageRepository } from "../../repositories/RequestMarriageRepository";
import { UsersRepository } from "../../repositories/UsersRepository";
import RequestMarriageWizardDocuments from "./RequestMarriageWizardDocuments";
import RequestMarriageWizardMarraigeDetails from "./RequestMarriageWizardMarraigeDetails";
import RequestMarriageWizardSpouseDetails from "./RequestMarriageWizardSpouseDetails";
import RequestMarriageWizardPreview from "./RequestMarriageWizardPreview";
import { RequestMarriagePortalRepository } from "../../repositories/RequestMarriagePortalRepository";
import {
  RequestMarriageBrideValidator,
  RequestMarriageGroomValidator,
  ValidateRequestMarriageData,
  ValidateRequestMarriageDocuments,
} from "./RequestMarriageValidator";
import RequestMarriageWizardPayment from "./RequestMarriageWizardPayment";
import { AuthService } from "../auth/AuthService";
import { SETTINGS } from "../../properties/ApiProps";

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(238 108 86) 0%, rgb(238 108 86) 50%, rgb(238 108 86) 100%)",
  },
});

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 20,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(239 109 87) 0%,rgb(112 193 194) 50%, rgb(238 108 86) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

export default function RequestMarriageComponent() {
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [countries, setCountries] = React.useState([]);
  const [countrySearch, setCountrySearch] = React.useState("");
  const [globalFormError, setGlobalFormError] = React.useState();
  const [places, setPlaces] = React.useState([]);
  const [placeSearch, setPlaceSearch] = React.useState("");
  const [citizenshipSearch, setCitizenshipSearch] = React.useState("");
  const [formDataErrors, setFormDataErrors] = React.useState({
    spousesData: [],
  });
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState();
  const [powerOfAttorneyScan, setPowerOfAttorneyScan] = React.useState([]);
  const [educations, setEducations] = React.useState([]);
  const [educationSearch, setEducationSearch] = React.useState("");
  const [marriagePlaceSearch, setMarriagePlaceSearch] = React.useState("");
  const [marriageLocations, setMarriageLocations] = React.useState([]);
  const [marriageLocationSearch, setMarriageLocationSearch] =
    React.useState("");
  const [disableAppointmentChoice, setDisableAppointmentChoice] =
    React.useState(true);
  const [groomIdUpload, setGroomIdUpload] = React.useState(null);
  const [brideIdUpload, setBrideIdUpload] = React.useState(null);
  const { id } = useParams();

  function getSteps() {
    return [
      LOCALE.bride_data,
      LOCALE.groom_data,
      LOCALE.marriage_data,
      LOCALE.documents,
      LOCALE.preview,
      LOCALE.payment,
    ];
  }

  const steps = getSteps();

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    let icons = {
      1: <PermIdentityIcon />,
      2: <PermIdentityIcon />,
      3: <LinkIcon />,
      4: <PostAdd />,
      5: <VisibilityIcon />,
      6: <CreditCardIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  const defaultCountry = {
    countryCode: "807",
    id: "0",
    name: "СЕВЕРНА МАКЕДОНИЈА",
    nameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
    nameEn: "REPUBLIC OF NORTH MACEDONIA",
    printName: "РЕПУБЛИКА СЕВЕРНА МАКЕДОНИЈА",
    printNameAl: "REPUBLIKA E MAQEDONISË SË VERIUT",
    printNameEn: "REPUBLIC OF NORTH MACEDONIA",
  };

  const [marriageAppointments, setMarriageAppointments] = React.useState([
    { appointment: "08:00", free: true },
    { appointment: "08:30", free: true },
    { appointment: "09:00", free: true },
    { appointment: "09:30", free: true },
    { appointment: "10:00", free: true },
    { appointment: "10:30", free: true },
    { appointment: "11:00", free: true },
    { appointment: "11:30", free: true },
    { appointment: "12:00", free: true },
    { appointment: "12:30", free: true },
    { appointment: "13:00", free: true },
    { appointment: "13:30", free: true },
    { appointment: "14:00", free: true },
    { appointment: "14:30", free: true },
    { appointment: "15:00", free: true },
    { appointment: "15:30", free: true },
    { appointment: "16:00", free: true },
    { appointment: "16:30", free: true },
    { appointment: "17:00", free: true },
    { appointment: "17:30", free: true },
    { appointment: "18:00", free: true },
    { appointment: "18:30", free: true },
    { appointment: "19:00", free: true },
    { appointment: "19:30", free: true },
    { appointment: "20:00", free: true },
    { appointment: "20:30", free: true },
    { appointment: "21:00", free: true },
    { appointment: "21:30", free: true },
    { appointment: "22:00", free: true },
    { appointment: "22:30", free: true },
    { appointment: "23:00", free: true },
  ]);
  const [busyAppointments, setBusyAppointments] = React.useState(false);

  const [formData, setFormData] = React.useState({
    spousesData: [
      {
        spousesType: "BRIDE",
        name: "",
        surname: "",
        surnameMarriage: "",
        embg: "",
        country: defaultCountry,
        citizenship: defaultCountry,
        dateBirth: "",
        placeBirth: "",
        place: { name: "" },
        addressLiving: "",
        education: { title: "" },
        schoolName: "",
        fatherFirstName: "",
        fatherLastName: "",
        fatherMaidenLastname: "",
        motherFirstName: "",
        motherLastName: "",
        motherMaidenLastname: "",
      },
      {
        spousesType: "GROOM",
        name: "",
        surname: "",
        surnameMarriage: "",
        embg: "",
        country: defaultCountry,
        citizenship: defaultCountry,
        dateBirth: "",
        placeBirth: "",
        place: { name: "" },
        addressLiving: "",
        education: { title: "" },
        schoolName: "",
        fatherFirstName: "",
        fatherLastName: "",
        fatherMaidenLastname: "",
        motherFirstName: "",
        motherLastName: "",
        motherMaidenLastname: "",
      },
    ],
    dateOfMarriage: null,
    marriageLocation: { name: "" },
    requestType: "REGULAR_OUTSIDE",
    marriageLocationManual: "",
    placeOfMarriage: { name: "" },
    placeOfMarriageManual: "",
    registryArea: {},
    requestDate: null,
    appointment: "",
  });
  const [deliveryRegistryAreaSearch, setDeliveryRegistryAreaSearch] =
    React.useState("");

  useEffect(() => {
    if (currentUser) {
      if (!currentUser?.nationalEid) {
        window.location.href = "/";
      }
      let data = { ...formData };
      if (currentUser.proposerType !== "ORGANIZATION") {
        if (currentUser.gender === "MALE") {
          data.spousesData[getSpouseIndex("GROOM")].embg = currentUser.embg;
          data.spousesData[getSpouseIndex("GROOM")].name =
            currentUser.firstName;
          data.spousesData[getSpouseIndex("GROOM")].surname =
            currentUser.lastName;
          data.spousesData[getSpouseIndex("GROOM")].country =
            currentUser.countryLiving;
          if (currentUser.embg) {
            data.spousesData[getSpouseIndex("GROOM")].dateBirth =
              EmbgToDate.getDate(currentUser.embg);
          }
          if (currentUser?.birthPlace !== undefined)
            data.spousesData[getSpouseIndex("GROOM")].place =
              currentUser?.birthPlace;
          data.spousesData[getSpouseIndex("GROOM")].addressLiving =
            currentUser?.addressLiving;
        } else {
          data.spousesData[getSpouseIndex("BRIDE")].embg = currentUser.embg;
          data.spousesData[getSpouseIndex("BRIDE")].name =
            currentUser.firstName;
          data.spousesData[getSpouseIndex("BRIDE")].surname =
            currentUser.lastName;
          data.spousesData[getSpouseIndex("BRIDE")].country =
            currentUser.countryLiving;
          if (currentUser.embg) {
            data.spousesData[getSpouseIndex("BRIDE")].dateBirth =
              EmbgToDate.getDate(currentUser.embg);
          }
          if (currentUser?.birthPlace !== undefined)
            data.spousesData[getSpouseIndex("BRIDE")].place =
              currentUser?.birthPlace;
          data.spousesData[getSpouseIndex("BRIDE")].addressLiving =
            currentUser?.addressLiving;
        }
        setFormData(data);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    setLoading(true);
    if (!AuthService.getCurrentUser()) {
      if (window.location.search.includes("eid=true")) {
        window.location.href = `https://eid.mk/issue/wsfed?wa=wsignin1.0&wtrealm=https://e-portal.uvmk.gov.mk/&wctx=redirectUrl=${window.location.pathname}&id=passive&ru=%2f&wreply=https://e-portal.uvmk.gov.mk/login_token_eid`;
      } else {
        setRedirectTo("/login");
        return;
      }
    } else {
      if (id) {
        RequestMarriagePortalRepository.getById(id).then(
          (response) => {
            // console.log(response);
            setFormData(response.data);
            setActiveStep(4);
            setLoading(false);
          },
          (error) => {
            console.log(error);
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
      }
      UsersRepository.fetchCurrentUser().then(
        (res) => {
          setCurrentUser(res.data);
        },
        (err) => {
          setRedirectTo("/login");
        }
      );
    }
  }, []);

  useEffect(() => {
    // console.log(formDataErrors);
  }, [formDataErrors]);

  useEffect(() => {
    if (countrySearch?.length > 1) {
      getCountries(countrySearch);
    }
  }, [countrySearch]);

  useEffect(() => {
    if (citizenshipSearch?.length > 1) {
      getCountries(citizenshipSearch);
    }
  }, [citizenshipSearch]);

  useEffect(() => {
    if (educationSearch?.length > 1) {
      getEducations(educationSearch);
    }
  }, [educationSearch]);

  const getCountries = (name) => {
    CountryRepository.getCountries(name).then(
      (res) => {
        setCountries(res.data);
      },
      (err) => {}
    );
  };

  const getEducations = (name) => {
    EducationRepository.getEducations(name).then((res) => {
      setEducations(res.data);
    });
  };

  const getMarriageLocations = (registryAreaId) => {
    MarriageLocationRepository.getMarriageLocationsByRegArea(
      registryAreaId
    ).then(
      (res) => {
        setMarriageLocations(res.data);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    if (placeSearch?.length > 1) {
      getPlaces(placeSearch);
    }
  }, [placeSearch]);

  useEffect(() => {
    if (marriagePlaceSearch?.length > 1) {
      getPlaces(marriagePlaceSearch);
    }
  }, [marriagePlaceSearch]);

  const getPlaces = (name) => {
    PlaceRepository.getPlaces(name).then(
      (res) => {
        setPlaces(res.data);
      },
      (err) => {}
    );
  };

  const getSpouseIndex = (spouseType) => {
    return formData.spousesData.findIndex(
      (spouse) => spouse.spousesType === spouseType
    );
  };

  const handleChange = (name, value) => {
    setFormData(_.set({ ...formData }, name, value));
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      if (!validateBrideData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 1) {
      if (!validateGroomData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      if (!validateMarriageData(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 3) {
      if (!validateRequestMarriageDocuments(formData)) return;
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 4) {
      if (!formData.id) {
        setLoading(true);
        let tmp = { ...formData };
        tmp.dateOfMarriage = tmp.dateOfMarriage.set({
          hour: tmp.appointment.split(":")[0],
          minute: tmp.appointment.split(":")[1],
          second: 0,
          millisecond: 0,
        });
        if (tmp.requestType === "REGULAR_OUTSIDE") {
          delete tmp.marriageLocation;
        }
        // if (!validatePickupData(formData)) return;
        RequestMarriagePortalRepository.create(
          tmp,
          groomIdUpload,
          brideIdUpload
        )
          .then((res) => {
            setFormData(res.data);
            setActiveStep(activeStep + 1);
            setLoading(false);
            setGlobalFormError(null);
          })
          .catch((err) => {
            setGlobalFormError(ErrorMessageResolver.resolve(err));
            setLoading(false);
          });
      } else {
        setActiveStep(activeStep + 1);
      }
    }
    setFormDataErrors({});
  };

  const handlePrevStep = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const validateRequestMarriageDocuments = () => {
    if (
      !ValidateRequestMarriageDocuments.isValidSync({
        formData,
        brideIdUpload,
        groomIdUpload,
      })
    ) {
      var validationErrors = {};
      (async () => {
        await ValidateRequestMarriageDocuments.validate(
          {
            formData,
            brideIdUpload,
            groomIdUpload,
          },
          {
            abortEarly: false,
          }
        ).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  const validateBrideData = () => {
    if (!RequestMarriageBrideValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await RequestMarriageBrideValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  const validateGroomData = () => {
    if (!RequestMarriageGroomValidator.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await RequestMarriageGroomValidator.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  useEffect(() => {
    // console.log(formData);
  }, [formData]);

  useEffect(() => {
    // console.log(brideIdUpload);
  }, [brideIdUpload]);

  const validateMarriageData = () => {
    if (!ValidateRequestMarriageData.isValidSync(formData)) {
      var validationErrors = {};
      (async () => {
        await ValidateRequestMarriageData.validate(formData, {
          abortEarly: false,
        }).catch((err) => {
          err.inner.forEach((validationError) => {
            validationErrors[validationError.path] = {};
            validationErrors[validationError.path] = validationError.message;
          });
          setFormDataErrors(validationErrors);
          setLoading(false);
          return false;
        });
      })();
    } else {
      setFormDataErrors({});
      return true;
    }
  };

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  useEffect(() => {
    getBusyAppointment();
  }, [formData?.dateOfMarriage]);

  useEffect(() => {
    if (!formData.id) {
      let tmp = { ...formData };
      tmp.marriageLocation = "";
      tmp.appointment = null;
      setFormData(tmp);
      getBusyAppointment();
      if (formData?.placeOfMarriage?.registryArea?.id) {
        getMarriageLocations(formData.placeOfMarriage.registryArea.id);
      }
    }
  }, [formData?.placeOfMarriage]);

  useEffect(() => {
    if (busyAppointments || busyAppointments.length == 0) {
      let tmp = [...marriageAppointments];
      tmp.forEach((element) => {
        let appointmentStartTime = element.appointment;
        let appointmentEndTime =
          element.appointment.split(":")[0] +
          ":" +
          (+element.appointment.split(":")[1] + +30);
        if (
          busyAppointments.find(
            (appointment) => appointment === appointmentStartTime
          ) ||
          busyAppointments.find(
            (appointment) => appointment === appointmentEndTime
          )
        ) {
          element.free = false;
        } else {
          element.free = true;
        }
      });
      setMarriageAppointments(tmp);
      setDisableAppointmentChoice(false);
    }
  }, [busyAppointments]);

  const getBusyAppointment = () => {
    if (
      !formData?.id &&
      formData?.dateOfMarriage &&
      formData?.dateOfMarriage?.isValid() &&
      formData?.placeOfMarriage?.registryArea?.id
    ) {
      setLoading(true);
      RequestMarriageRepository.getByDateOfMarriage(
        formData?.dateOfMarriage?.valueOf(),
        formData?.placeOfMarriage?.registryArea?.id
      ).then(
        (res) => {
          // console.log(res.data);
          let tmp = [];
          res.data
            .sort((a, b) => {
              if (
                moment(a.dateOfMarriage).hours() ===
                moment(b.dateOfMarriage).hours()
              ) {
                return (
                  moment(a.dateOfMarriage).minutes() -
                  moment(b.dateOfMarriage).minutes()
                );
              } else {
                return (
                  moment(a.dateOfMarriage).hours() -
                  moment(b.dateOfMarriage).hours()
                );
              }
            })
            .forEach((item) => {
              let durr = item.duration;
              for (let i = 0; i < durr; i++) {
                if (i === 0) {
                  tmp.push(
                    moment(item.dateOfMarriage).hours() +
                      ":" +
                      (moment(item.dateOfMarriage).minute() + i < 10
                        ? "0" + (moment(item.dateOfMarriage).minute() + i)
                        : moment(item.dateOfMarriage).minute() + i)
                  );
                }
                tmp.push(
                  moment(item.dateOfMarriage).hours() +
                    ":" +
                    (moment(item.dateOfMarriage).minute() + i + 1 < 10
                      ? "0" + (moment(item.dateOfMarriage).minute() + i + 1)
                      : moment(item.dateOfMarriage).minute() + i + 1)
                );
              }
            });
          setLoading(false);
          setBusyAppointments(tmp);
        },
        (err) => {
          setLoading(false);
        }
      );
    }
  };

  const handlePayment = (id) => {
    if (
      formData.status == "PRICE_REQUEST" &&
      formData.paymentStatus == "UNPAID"
    ) {
      RequestMarriagePortalRepository.payRequest(id).then(
        (res) => {
          window.location.href = "/payment/success";
        },
        (err) => {}
      );
    } else {
      RequestMarriagePortalRepository.pay(id).then(
        (res) => {
          window.location.href = "/payment/success";
        },
        (err) => {}
      );
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(" + bgImg + ")",
          backgroundSize: "100% 100%",
          minHeight: "100vh",
          backgroundAttachment: "fixed",
        }}
      >
        {redirectTo && <Redirect to={redirectTo} push />}
        <Header />
        <Container>
          <Grid container>
            <Grid item md={12} style={{ marginBottom: "20px" }}>
              <Typography variant="h5">{LOCALE.request_marriage}</Typography>
            </Grid>
            {loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <LinearProgress
                  style={{
                    borderTopRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                  }}
                  color="secondary"
                />
              </Grid>
            )}
            {!loading && (
              <Grid item xs={12} style={{ paddingBottom: "0px" }}>
                <div style={{ height: "4px" }}></div>
              </Grid>
            )}
            <Grid item md={12} xs={12}>
              <Paper style={{ marginBottom: "30px", padding: "15px" }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: "0" }}
                    display="flex"
                  >
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                          ></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      backgroundColor: "rgb(212 212 212 / 0.85)",
                      textAlign: "center",
                      marginTop: "-20px",
                      marginBottom: "20px",
                      color: "black",
                      width: "100%",
                    }}
                  >
                    <Typography>
                      <strong>{steps[activeStep]}</strong>
                    </Typography>
                  </Grid>
                  {globalFormError && (
                    <React.Fragment>
                      <Hidden xsDown>
                        <Grid item xs={12} md={3}></Grid>
                      </Hidden>
                      <Grid item xs={12} md={6}>
                        <Alert severity="error">{globalFormError}</Alert>
                      </Grid>
                    </React.Fragment>
                  )}

                  {loading && (activeStep === 0 || activeStep === 4) && (
                    <React.Fragment>
                      <Grid item md={3}></Grid>
                      <Grid item md={6}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                          <Grid item xs={12}>
                            <Skeleton style={{ height: "50px" }}></Skeleton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={3}></Grid>
                    </React.Fragment>
                  )}
                  {!loading && activeStep === 0 && (
                    <RequestMarriageWizardSpouseDetails
                      handleChange={handleChange}
                      getSpouseIndex={getSpouseIndex}
                      formData={formData}
                      formDataErrors={formDataErrors}
                      countrySearch={countrySearch}
                      setCountrySearch={setCountrySearch}
                      onFocus={onFocus}
                      countries={countries}
                      defaultCountry={defaultCountry}
                      placeSearch={placeSearch}
                      setPlaceSearch={setPlaceSearch}
                      places={places}
                      spouseType={"BRIDE"}
                      citizenshipSearch={citizenshipSearch}
                      setCitizenshipSearch={setCitizenshipSearch}
                      educations={educations}
                      setEducationSearch={setEducationSearch}
                      educationSearch={educationSearch}
                    ></RequestMarriageWizardSpouseDetails>
                  )}

                  {activeStep === 1 && (
                    <RequestMarriageWizardSpouseDetails
                      handleChange={handleChange}
                      getSpouseIndex={getSpouseIndex}
                      formData={formData}
                      formDataErrors={formDataErrors}
                      countrySearch={countrySearch}
                      setCountrySearch={setCountrySearch}
                      onFocus={onFocus}
                      countries={countries}
                      defaultCountry={defaultCountry}
                      placeSearch={placeSearch}
                      setPlaceSearch={setPlaceSearch}
                      places={places}
                      spouseType={"GROOM"}
                      citizenshipSearch={citizenshipSearch}
                      setCitizenshipSearch={setCitizenshipSearch}
                      educations={educations}
                      setEducationSearch={setEducationSearch}
                      educationSearch={educationSearch}
                    ></RequestMarriageWizardSpouseDetails>
                  )}
                  {activeStep === 2 && (
                    <RequestMarriageWizardMarraigeDetails
                      handleChange={handleChange}
                      getSpouseIndex={getSpouseIndex}
                      formData={formData}
                      formDataErrors={formDataErrors}
                      countrySearch={countrySearch}
                      setCountrySearch={setCountrySearch}
                      onFocus={onFocus}
                      countries={countries}
                      defaultCountry={defaultCountry}
                      placeSearch={placeSearch}
                      setPlaceSearch={setPlaceSearch}
                      places={places}
                      citizenshipSearch={citizenshipSearch}
                      setCitizenshipSearch={setCitizenshipSearch}
                      educations={educations}
                      setEducationSearch={setEducationSearch}
                      educationSearch={educationSearch}
                      marriagePlaceSearch={marriagePlaceSearch}
                      setMarriagePlaceSearch={setMarriagePlaceSearch}
                      marriageLocations={marriageLocations}
                      setMarriageLocationSearch={setMarriageLocationSearch}
                      marriageLocationSearch={marriageLocationSearch}
                      marriageAppointments={marriageAppointments}
                      disableAppointmentChoice={disableAppointmentChoice}
                    ></RequestMarriageWizardMarraigeDetails>
                  )}
                  {activeStep === 3 && (
                    <RequestMarriageWizardDocuments
                      handleChange={handleChange}
                      getSpouseIndex={getSpouseIndex}
                      formData={formData}
                      formDataErrors={formDataErrors}
                      countrySearch={countrySearch}
                      setCountrySearch={setCountrySearch}
                      onFocus={onFocus}
                      countries={countries}
                      defaultCountry={defaultCountry}
                      placeSearch={placeSearch}
                      setPlaceSearch={setPlaceSearch}
                      places={places}
                      spouseType={"GROOM"}
                      citizenshipSearch={citizenshipSearch}
                      setCitizenshipSearch={setCitizenshipSearch}
                      educations={educations}
                      setEducationSearch={setEducationSearch}
                      educationSearch={educationSearch}
                      groomIdUpload={groomIdUpload}
                      setGroomIdUpload={setGroomIdUpload}
                      brideIdUpload={brideIdUpload}
                      setBrideIdUpload={setBrideIdUpload}
                    ></RequestMarriageWizardDocuments>
                  )}
                  {!loading && activeStep === 4 && (
                    <RequestMarriageWizardPreview
                      formData={formData}
                      getSpouseIndex={getSpouseIndex}
                      brideIdUpload={brideIdUpload}
                      groomIdUpload={groomIdUpload}
                    ></RequestMarriageWizardPreview>
                  )}
                  {!loading && activeStep === 5 && (
                    <RequestMarriageWizardPayment
                      formData={formData}
                      getSpouseIndex={getSpouseIndex}
                      brideIdUpload={brideIdUpload}
                      groomIdUpload={groomIdUpload}
                    ></RequestMarriageWizardPayment>
                  )}

                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Divider></Divider>
                    {currentUser?.proposerType !== "ORGANIZATION" &&
                      activeStep !== 0 &&
                      activeStep !== steps.length &&
                      !formData.id && (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={loading}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => handlePrevStep()}
                        >
                          {LOCALE.prev}
                        </Button>
                      )}
                    {activeStep === 4 &&
                      ((formData.paymentStatus == "PAID" &&
                        formData.status == "PRICE_REQUEST_APPROVED") ||
                        formData.paymentStatus == "UNPAID") && (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          disabled={loading || saving}
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() => {
                            handleNextStep();
                          }}
                        >
                          {LOCALE.pay}
                        </Button>
                      )}
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        disabled={loading || saving}
                        style={{
                          margin: "10px",
                          minWidth: "100px",
                        }}
                        onClick={() => {
                          handleNextStep();
                        }}
                      >
                        {LOCALE.next}
                      </Button>
                    )}
                    {activeStep === steps.length - 1 &&
                      formData?.id &&
                      ((formData.paymentStatus == "PAID" &&
                        formData.status == "PRICE_REQUEST_APPROVED") ||
                        formData.paymentStatus == "UNPAID") &&
                      formData?.status !== "COMPLETED" && (
                        <Button
                          variant="contained"
                          size="small"
                          color="secondary"
                          style={{
                            margin: "10px",
                            minWidth: "100px",
                          }}
                          onClick={() =>
                            (window.location.href = `${
                              SETTINGS.API_URL
                            }/payment/initialization?reasonType=marriageAppointment&rel=${
                              formData.id
                            }&access_token=${
                              AuthService.getToken().access_token
                            }`)
                          }
                        >
                          {LOCALE.i_agree}
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <br></br>
        <Footer></Footer>
      </div>
    </React.Fragment>
  );
}
