import { LOCALE } from "../../properties/Locale";
import * as yup from "yup";
import { EmbgToDate } from "../../common/helpers/EmbgToDate";
import { AuthService } from "../auth/AuthService";
import { EmbgValidator } from "../../common/helpers/EmbgValidator";

export const BasicDataValidator = yup.object().shape({
  embg: yup
    .string()
    .test("embg-not-adult", LOCALE.is_adult_message, function (value) {
      if (this.options.parent.certForChild === "true") {
        if (EmbgToDate.getAge(EmbgToDate.getDate(value)) < 18) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .test("embg-doesnt-match", LOCALE.embg_doesnt_match, function (value) {
      if (this.options.parent.certForChild === "false") {
        if (AuthService.getCurrentUser().embg !== value) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  country: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeBirth: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeBirthManual: yup
    .string()
    .test(
      "placeLivingManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  registryArea: yup
    .object()
    .test(
      "registryArea",
      "Матично подрачје во кое е направен уписот" + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});

export const BasicDataValidatorLegalEntity = yup.object().shape({
  embg: yup
    .string()
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  firstName: yup.string().required(LOCALE.firstName + LOCALE.required_field),
  lastName: yup.string().required(LOCALE.lastName + LOCALE.required_field),
  country: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeBirth: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeBirthManual: yup
    .string()
    .test(
      "placeLivingManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  registryArea: yup
    .object()
    .test(
      "registryArea",
      "Матично подрачје во кое е направен уписот" + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});

export const ParentsDataValidator = yup.object().shape({
  fatherName: yup
    .string()
    .required(LOCALE.father_name + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  fatherSurname: yup
    .string()
    .required(LOCALE.father_surname + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  fatherEMBG: yup
    .string()
    .test(
      "embg-matches-currentUser",
      "Матичниот број на родителот не соодветсвува со корисничкиот профил",
      function (value) {
        if (this.options.parent.certForChild === "true") {
          if (AuthService.getCurrentUser()) {
            if (AuthService.getCurrentUser().gender === "MALE") {
              if (value !== AuthService.getCurrentUser().embg) {
                return false;
              }
              return true;
            }
            return true;
          }
          return true;
        } else {
          return true;
        }
      }
    )
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (
        this.options.parent.certForChild === "true" &&
        AuthService.getCurrentUser().gender === "MALE"
      ) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
        return true;
      } else {
        if (value) {
          if (value.length !== 13) return false;
          if (!value.match(/^[0-9]*$/)) return false;
          if (!EmbgValidator.isValid(value)) return false;
        }
        return true;
      }
    }),
  motherName: yup
    .string()
    .required(LOCALE.mother_name + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  motherSurname: yup
    .string()
    .required(LOCALE.mother_surname + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  motherEMBG: yup
    .string()
    .test(
      "embg-matches-currentUser",
      "Матичниот број на родителот не соодветсвува со корисничкиот профил",
      function (value) {
        if (this.options.parent.certForChild === "true") {
          if (AuthService.getCurrentUser()) {
            if (AuthService.getCurrentUser().gender === "FEMALE") {
              if (value !== AuthService.getCurrentUser().embg) {
                return false;
              }
              return true;
            }
            return true;
          }
          return true;
        } else {
          return true;
        }
      }
    )
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (
        this.options.parent.certForChild === "true" &&
        AuthService.getCurrentUser().gender === "FEMALE"
      ) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
        return true;
      } else {
        if (value) {
          if (value.length !== 13) return false;
          if (!value.match(/^[0-9]*$/)) return false;
          if (!EmbgValidator.isValid(value)) return false;
        }
        return true;
      }
    }),
});

export const PickUpWayValidator = yup.object().shape({
  legalInterestNumber: yup
    .string()
    .nullable()
    .test(
      "legalInterestNumber",
      LOCALE.legal_interest_number + LOCALE.required_field,
      function (value) {
        if (AuthService.getCurrentUser()?.institution?.institutionType == 2) {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),

  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_UVMK") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryPlace: yup
    .object()
    .test(
      "deliveryPlace",
      LOCALE.delivery_place + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryAddress: yup
    .string()
    .test(
      "deliveryAddress",
      LOCALE.delivery_address + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});

export const AllFormDataValidator = yup.object().shape({
  embg: yup
    .string()
    .test("embg-format-invalid", LOCALE.is_adult_message, function (value) {
      if (this.options.parent.certForChild === "true") {
        if (EmbgToDate.getAge(EmbgToDate.getDate(value)) < 18) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .test("embg-doesnt-match", LOCALE.embg_doesnt_match, function (value) {
      if (this.options.parent.certForChild === "false") {
        if (AuthService.getCurrentUser().embg !== value) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  firstName: yup
    .string()
    .required(LOCALE.firstName + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  lastName: yup
    .string()
    .required(LOCALE.lastName + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  country: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeBirth: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeBirthManual: yup
    .string()
    .nullable()
    .test(
      "placeLivingManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  fatherName: yup
    .string()
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(50, "Полето мора да има максимум 50 карактери")
    .required(LOCALE.father_name + LOCALE.required_field),
  fatherSurname: yup
    .string()
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(50, "Полето мора да има максимум 50 карактери")
    .required(LOCALE.father_surname + LOCALE.required_field),
  fatherEMBG: yup
    .string()
    .nullable()
    .test(
      "embg-matches-currentUser",
      "Матичниот број на родителот не соодветсвува со корисничкиот профил",
      function (value) {
        if (this.options.parent.certForChild === "true") {
          if (AuthService.getCurrentUser()) {
            if (AuthService.getCurrentUser().gender === "MALE") {
              if (value !== AuthService.getCurrentUser().embg) {
                return false;
              }
              return true;
            }
            return true;
          }
          return true;
        } else {
          return true;
        }
      }
    )
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (
        this.options.parent.certForChild === "true" &&
        AuthService.getCurrentUser().gender === "MALE"
      ) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
        return true;
      } else {
        if (value) {
          if (value.length !== 13) return false;
          if (!value.match(/^[0-9]*$/)) return false;
          if (!EmbgValidator.isValid(value)) return false;
        }
        return true;
      }
    }),
  motherName: yup
    .string()
    .required(LOCALE.mother_name + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  motherSurname: yup
    .string()
    .required(LOCALE.mother_surname + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  motherEMBG: yup
    .string()
    .nullable()
    .test(
      "embg-matches-currentUser",
      "Матичниот број на родителот не соодветсвува со корисничкиот профил",
      function (value) {
        if (this.options.parent.certForChild === "true") {
          if (AuthService.getCurrentUser()) {
            if (AuthService.getCurrentUser().gender === "FEMALE") {
              if (value !== AuthService.getCurrentUser().embg) {
                return false;
              }
              return true;
            }
            return true;
          }
          return true;
        } else {
          return true;
        }
      }
    )
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (
        this.options.parent.certForChild === "true" &&
        AuthService.getCurrentUser().gender === "FEMALE"
      ) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
        return true;
      } else {
        if (value) {
          if (value.length !== 13) return false;
          if (!value.match(/^[0-9]*$/)) return false;
          if (!EmbgValidator.isValid(value)) return false;
        }
        return true;
      }
    }),
  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_UVMK") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryPlace: yup
    .object()
    .test(
      "deliveryPlace",
      LOCALE.delivery_place + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryAddress: yup
    .string()
    .test(
      "deliveryAddress",
      LOCALE.delivery_address + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});

export const AllFormDataValidatorLegalEntity = yup.object().shape({
  embg: yup
    .string()
    .test("embg-format-invalid", LOCALE.is_adult_message, function (value) {
      if (this.options.parent.certForChild === "true") {
        if (EmbgToDate.getAge(EmbgToDate.getDate(value)) < 18) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    })
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (!value) return false;
      if (value.length !== 13) return false;
      if (!value.match(/^[0-9]*$/)) return false;
      if (!EmbgValidator.isValid(value)) return false;
      return true;
    }),
  firstName: yup
    .string()
    .required(LOCALE.firstName + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  lastName: yup
    .string()
    .required(LOCALE.lastName + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  country: yup.object().shape({
    id: yup.string().required(LOCALE.countryLiving + LOCALE.required_field),
  }),
  placeBirth: yup
    .object()
    .test(
      "placeLivingObject",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id === "0") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  placeBirthManual: yup
    .string()
    .nullable()
    .test(
      "placeLivingManual",
      LOCALE.place_born + LOCALE.required_field,
      function (value) {
        if (this.options.parent.country?.id !== "0") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  fatherName: yup
    .string()
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(50, "Полето мора да има максимум 50 карактери")
    .required(LOCALE.father_name + LOCALE.required_field),
  fatherSurname: yup
    .string()
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(50, "Полето мора да има максимум 50 карактери")
    .required(LOCALE.father_surname + LOCALE.required_field),
  fatherEMBG: yup
    .string()
    .nullable()
    .test(
      "embg-matches-currentUser",
      "Матичниот број на родителот не соодветсвува со корисничкиот профил",
      function (value) {
        if (this.options.parent.certForChild === "true") {
          if (AuthService.getCurrentUser()) {
            if (AuthService.getCurrentUser().gender === "MALE") {
              if (value !== AuthService.getCurrentUser().embg) {
                return false;
              }
              return true;
            }
            return true;
          }
          return true;
        } else {
          return true;
        }
      }
    )
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (
        this.options.parent.certForChild === "true" &&
        AuthService.getCurrentUser().gender === "MALE"
      ) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
        return true;
      } else {
        if (value) {
          if (value.length !== 13) return false;
          if (!value.match(/^[0-9]*$/)) return false;
          if (!EmbgValidator.isValid(value)) return false;
        }
        return true;
      }
    }),
  motherName: yup
    .string()
    .required(LOCALE.mother_name + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  motherSurname: yup
    .string()
    .required(LOCALE.mother_surname + LOCALE.required_field)
    .min(2, LOCALE.field_must_have_min_2_characters)
    .max(40, "Полето мора да има максимум 40 карактери"),
  motherEMBG: yup
    .string()
    .nullable()
    .test(
      "embg-matches-currentUser",
      "Матичниот број на родителот не соодветсвува со корисничкиот профил",
      function (value) {
        if (this.options.parent.certForChild === "true") {
          if (AuthService.getCurrentUser()) {
            if (AuthService.getCurrentUser().gender === "FEMALE") {
              if (value !== AuthService.getCurrentUser().embg) {
                return false;
              }
              return true;
            }
            return true;
          }
          return true;
        } else {
          return true;
        }
      }
    )
    .test("embg-format-invalid", LOCALE.invalid_embg_format, function (value) {
      if (
        this.options.parent.certForChild === "true" &&
        AuthService.getCurrentUser().gender === "FEMALE"
      ) {
        if (!value) return false;
        if (value.length !== 13) return false;
        if (!value.match(/^[0-9]*$/)) return false;
        if (!EmbgValidator.isValid(value)) return false;
        return true;
      } else {
        if (value) {
          if (value.length !== 13) return false;
          if (!value.match(/^[0-9]*$/)) return false;
          if (!EmbgValidator.isValid(value)) return false;
        }
        return true;
      }
    }),
  deliveryRegistryArea: yup
    .object()
    .nullable()
    .test(
      "deliveryRegistryArea",
      LOCALE.pickup_way_reg_area + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_UVMK") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryPlace: yup
    .object()
    .test(
      "deliveryPlace",
      LOCALE.delivery_place + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value?.id) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
  deliveryAddress: yup
    .string()
    .test(
      "deliveryAddress",
      LOCALE.delivery_address + LOCALE.required_field,
      function (value) {
        if (this.options.parent.pickupWayCert === "PICKUP_WAY_POSTOFFICE") {
          if (!value) return false;
          return true;
        } else {
          return true;
        }
      }
    ),
});
